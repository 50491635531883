import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import { CourierServiceLinks, CRUDCommands } from "../../../Utils/Axios/RequestParameters";
import { getAgentCode } from "../../../Utils/GetAgentsCode";

export default function OrderHistoryFilter({
  FilterFields,
  filterData,
  handleFilter,
  handleSubmit,
  handleAutoComplete
}) {
  const [AgentCodeArray, setAgentCodeArray] = useState([{label:"Please wait...",value:0}]);
  const [StatusOptions, setStatusOptions] = useState([{label:"Please wait...",value:""}]);

  useEffect(() => {
    const Agentcode = async () => {
      const Response = await getAgentCode();
      if (Response.length > 0) {
        setAgentCodeArray(Response);
      }


      const StatusOptions = await GetResponse(
        {
          statusId: 0,
          statusDescription: "",
          isCancel: true,
          isAction: true,
          isfCancel: 0,
          isfAction: -1,
          commandId: CRUDCommands.ListAll,
        },
        CourierServiceLinks.Status
      );
      if (StatusOptions.length > 0 && Array.isArray(StatusOptions)) {
        const Options = StatusOptions.map((option) => {
          return {
            label: option.statusDescription,
            value: option.statusId,
          };
        });

        setStatusOptions([{label:"All",value:0},...Options]);
      }

    };
    Agentcode();


    
  }, []);

  return (
    <Box
      component="form"
      validate="true"
      onSubmit={handleSubmit}
      sx={{ mt: 3, width: "100%" }}
      // key={Render}
    >
      <Grid container spacing={2}>
        {FilterFields.map((Fields, index) => {
          return (
            <Grid item xs={6} sm={3} lg={1.7} key={`${Fields.name}${index}`}>
              <TextField
                autoComplete="given-name"
                type={Fields.type}
                name={Fields.name}
                value={filterData[Fields.name]}
                fullWidth
                select={Fields.options.length > 0 ? true : false}
                id={Fields.name}
                label={Fields.label}
                onChange={(e) => handleFilter(e)}
                InputLabelProps={{ shrink: true }}
              >
                {Fields.options.length > 0 ? (
                  Fields.options.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </TextField>
            </Grid>
          );
        })}
        <Grid item xs={6} sm={3} lg={2.1} >
          <Autocomplete
            // value={filterData.statusId}
            onChange={(e,value) => handleAutoComplete('statusId',value)}
            id="statusId"
            options={StatusOptions}
            getOptionLabel={(option)=>option.label}
            fullWidth
            renderInput={(params) => (
              <TextField
              {...params}
                label=" Status"
                name="statusId"
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={1.9} >
          <Autocomplete
            // value={filterData.distributorId}
            onChange={(e,value) => handleAutoComplete("distributorId",value)}
            id="Agent"
            options={[{label:'All',value:0},...AgentCodeArray]}
            getOptionLabel={(option)=>option.label}
            fullWidth
            renderInput={(params) => (
              <TextField
              {...params}
                label="Agent"
                name="distributorId"
              />
            )}
          />
        </Grid>

        <Grid item xs={6} sm={3} lg={1.1}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 1, mb: 1 }}
          >
            Filter
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
