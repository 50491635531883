import { idID } from "@mui/material/locale";
import { GetResponse } from "./Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "./Axios/RequestParameters";
var idmain = 0;
const GetAgentCategoryOptions = async (idopt) => {
  let id = idopt ? idopt : idmain;
  const requestData = {
    typeDetId: 0,
    typeId: id,
    name: "",
    code: "",
    registrationAmount: 0,
    isPartialPaymentAllowed: true,
    isDisplayAdmin: true,
    isDisplayDistadmin: true,
    isDisplayUser: true,
    commandId:CRUDCommands.ListAll,
    createBy: 0,
    createDate: "2022-11-14T10:56:06.894Z",
    createIp: "string",
    updateBy: 0,
    updateDate: "2022-11-14T10:56:06.894Z",
    updateIp: "",
    isCoBranded: true,
    isRechargeAll: true,
    description: "",
    typeDetailDescription: "",
    typeCode: "string",
    isAllowedTran: true,
    reminderBalance: 0,
    reminderInterval: 0,
    verificationCode: "",
    isCustomer: true,
    contactUsPageDet: "",
    isActiveContactUsLink: true,
    isDisplayApicommission: true,
    applyConvenienceFeeOnRdspg: true,
    isInternal: true,
  };
  var AllOptions = [{}];
  const response = await GetResponse(
    requestData,
    RDSLinks.DistributorTypeDetailMaster,
    {}
  );

  if (response.length > 0) {
    const options = response.map((opt) => {
      return { label: opt.name, value: opt.typeDetId };
    });
    AllOptions = options;
  } else {
    AllOptions = [
      {
        label: "No Agent Category Found",
        value: "",
      },
    ];
  }
  return AllOptions;
};
export { GetAgentCategoryOptions };
