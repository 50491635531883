import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  Paper,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { Colors } from "../../Utils/Constants";
import moment from "moment";
import { useSelector } from "react-redux";
export default function TopUPOfflineUI(props) {
  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  const {
    PaymentModeOptions,
    handlePaymentMode,
    PaymentMode,
    handleSubmit,
    handleChange,
    data,
    AllError,
    StateOptions,
    CityOptions,
    BankOptions,
  } = props;
  const CenterBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const CenterColumn = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <Paper
      elevation={4}
      sx={{
        width: "80%",
        // height: { xs:"100%", },
        pb: 5,
        mt: 5,
      }}
    >
      <Typography
        sx={{
          width: "100%",
          height: "10%",
          ...CenterBox,
          fontSize: 25,
          fontWeight: "bold",
          backgroundColor: Colors.PrimaryBackgroundColor[800],
          color: "white",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        Top Up Request
      </Typography>
      <Box
        component="form"
        validate="true"
        onSubmit={handleSubmit}
        sx={{ p: 5 }}
        // key={Render}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <ToggleButtonGroup
              color="warning"
              value={PaymentMode}
              exclusive
              onChange={handlePaymentMode}
              aria-label="Platform"
              fullWidth
            >
              {PaymentModeOptions.map((PaytmentOptions) => {
                return (
                  <ToggleButton value={PaytmentOptions.pgName}>
                    {PaytmentOptions.pgName}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Grid>
          {PaymentMode !== "Cash" ? (
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                error={AllError["chqDdno"]}
                autoFocus={true}
                autoComplete="given-name"
                type={"number"}
                name={"chqDdno"}
                value={data["chqDdno"]}
                helperText={
                  AllError["chqDdno"] ? "Please Enter Correct Date" : ""
                }
                required
                fullWidth
                id={"chqDdno"}
                label={
                  PaymentMode === "Cheque" ? "Cheque No" : "Transaction No"
                }
                onChange={(e) => handleChange(e, /^[a-zA-Z0-9]/)}
              />
            </Grid>
          ) : (
            <></>
          )}
          {PaymentMode !== "Cash" ? (
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                error={AllError["cashChqDddate"]}
                autoFocus={true}
                autoComplete="given-name"
                type={"date"}
                name={"cashChqDddate"}
                value={data["cashChqDddate"]}
                helperText={AllError[""] ? "Please Enter Correct Date" : ""}
                required
                fullWidth
                id={"cashChqDddate"}
                label={
                  PaymentMode === "Cheque" ? "Cheque Date" : "Transaction Date"
                }
                InputLabelProps={{ shrink: true }}
                // InputProps={{
                //   inputProps: { min: moment().format("YYYY-MM-DD") },
                // }}
                onChange={(e) => handleChange(e, /^[a-zA-Z0-9]/)}
              />
            </Grid>
          ) : (
            <></>
          )}
          {PaymentMode !== "Cash" ? (
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                error={AllError["bankName"]}
                autoFocus={true}
                autoComplete="given-name"
                type={"text"}
                name={"bankName"}
                value={data["bankName"]}
                helperText={
                  AllError["bankName"] ? "Please Enter Correct Date" : ""
                }
                required
                fullWidth
                id={"bankName"}
                label={"Bank Name"}
                onChange={(e) => handleChange(e, /^[a-zA-Z0-9]/)}
              />
            </Grid>
          ) : (
            <></>
          )}
          {PaymentMode !== "Cash" ? (
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                error={AllError["bankBranch"]}
                autoFocus={true}
                autoComplete="given-name"
                type={"text"}
                name={"bankBranch"}
                value={data["bankBranch"]}
                helperText={AllError[""] ? "Please Enter Correct Date" : ""}
                required
                fullWidth
                id={"bankBranch"}
                label={"Bank Branch"}
                onChange={(e) => handleChange(e, /^[a-zA-Z0-9]/)}
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} sm={12} lg={6}>
            <TextField
              error={AllError["requestAmount"]}
              autoFocus={true}
              autoComplete="given-name"
              type={"text"}
              name={"requestAmount"}
              value={data["requestAmount"]}
              helperText={
                AllError["requestAmount"] ? "Please Enter Correct Date" : ""
              }
              required
              fullWidth
              id={"requestAmount"}
              label={"Top-Up Amount"}
              onChange={(e) => handleChange(e, /^[a-zA-Z0-9]/)}
            />
          </Grid>
          {AgentDetails.level1DistributorId === 0 && (
            <Grid item xs={12} sm={12} lg={6}>
              <TextField
                autoFocus={true}
                autoComplete="given-name"
                name="bankId"
                value={data["bankId"]}
                error={AllError["bankId"]}
                select={true}
                required
                fullWidth
                id={"bankId"}
                label={"Deposited In Account"}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => handleChange(e, /^[a-zA-Z0-9]/)}
              >
                {BankOptions.map((option) => (
                  <MenuItem key={`${option.bankName}`} value={option.bankId}>
                    {option.bankName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {PaymentMode !== "Cash" ? (
            <>
              <Grid item xs={12} sm={12} lg={6}>
                <TextField
                  autoFocus={true}
                  autoComplete="given-name"
                  name="state"
                  value={data["state"]}
                  error={AllError["state"]}
                  select={true}
                  required
                  fullWidth
                  id={"state"}
                  label={"Select State"}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleChange(e, /^[a-zA-Z0-9]/)}
                >
                  {StateOptions.map((option) => (
                    <MenuItem key={`${option.label}`} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} lg={6}>
                <TextField
                  autoFocus={true}
                  autoComplete="given-name"
                  name="city"
                  value={data["city"]}
                  error={AllError["city"]}
                  select={true}
                  required
                  fullWidth
                  id={"city"}
                  label={"Select City"}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => handleChange(e, /^[a-zA-Z0-9]/)}
                >
                  {CityOptions.map((option) => (
                    <MenuItem key={`${option.label}`} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item xs={12} sm={12} lg={12}>
            <TextField
              error={AllError["requestRemark"]}
              autoFocus={true}
              autoComplete="given-name"
              type={"text"}
              name={"requestRemark"}
              value={data["requestRemark"]}
              helperText={
                AllError["requestRemark"] ? "Please Enter Correct Date" : ""
              }
              required
              fullWidth
              id={"requestRemark"}
              label={"Remark"}
              onChange={(e) => handleChange(e, /^[a-zA-Z0-9]/)}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} lg={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Do Request
          </Button>
        </Grid>
      </Box>
    </Paper>
  );
}
