import { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Typography, Fab } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import { CourierServiceLinks, CRUDCommands } from "../../../Utils/Axios/RequestParameters";
import { Colors } from "../../../Utils/Constants";
import ActiveDeactive from "../ActiveDeactive";
import swal from "sweetalert";
import CourierServiceProviderCRUDActions from "./CourierServiceProviderCrudActions";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import CourierServiceProviderAddEditModal from "./CourierServiceProviderAddEditModal";
export default function CourierServiceProviderDataGrid() {
  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);

  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);

  const addData = {
    courierServiceProviderId: 0,
    courierServiceProviderCode: "",
    courierServiceProviderName: "",
    address1: "",
    address2: "",
    address3: "",
    country: "",
    state: "",
    city: "",
    pinCode: "",
    websiteUrl: "",
    emailId: "",
    mobileNo: "",
    thumbImageName: "",
    imageName: "",
    contactName: "",
    contactNo: "",
    shipmentTrackingUrl: "",
    isActive: true,
    commandId: CRUDCommands.ListAll,
    createBy: userId,
    createDate: "2022-10-13T16:40:38.057",
    createIp: "192.168.0.68",
    updateBy: userId,
    updateDate: "2022-10-13T16:49:40.053",
    updateIp: "192.168.0.68",
  };
  const InitialError={
    courierServiceProviderCode: false,
    courierServiceProviderName: false,
    address1: false,
    address2: false,
    address3: false,
    country: false,
    state: false,
    city: false,
    pinCode: false,
    websiteUrl: false,
    emailId: false,
    mobileNo: false,
    thumbImageName: false,
    imageName: false,
    contactName: false,
    contactNo: false,
    shipmentTrackingUrl: false,
   
  }
  const RequiredInputs=[
    "courierServiceProviderCode",
    "courierServiceProviderName",
    "pinCode",
    "mobileNo",
    "shipmentTrackingUrl"
  ]
  const dataFields = [
    // {
    //   Label: "Courier Service Provider Id",
    //   name: "courierServiceProviderId",
    //   Type: "text",
    //   IsError: false,
    //   ErrorMsg: " ",
    //   validReg:/[^\n]+/,
    //   options: [],
    // },
    {
      Label: "Courier Service Provider Code",
      name: "courierServiceProviderCode",
      Type: "text",
      required:true,
      IsError: false,
      ErrorMsg: "Please Enter Code ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Mobile NO",
      name: "mobileNo",
      required:true,
      Type: "number",
      IsError: false,

      ErrorMsg: "Invalid mobile number ",
      validReg: /^(\+\d{1,3}[- ]?)?\d{10}$/,
      options: [],
    },
    {
      Label: "Courier Service Provider Name",
      name: "courierServiceProviderName",
      Type: "text",
      IsError: false,
      required:true,
      ErrorMsg: "Please Enter Name ",
      validReg: /[^\n]+/,
      options: [],
    },

    {
      Label: "Website URL",
      name: "websiteUrl",
      Type: "text",
      required:false,
      IsError: false,
      ErrorMsg: "Please Enter Website URL ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Email Id",
      name: "emailId",
      Type: "text",
      IsError: false,
      ErrorMsg: "Invalid email address",
      required:false,
      validReg: /^[A-Za-z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      options: [],
    },
    {
      Label: "ThumbImage Name",
      name: "thumbImageName",
      Type: "text",
      IsError: false,
      required:false,
      ErrorMsg: " Please Enter Name",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Image Name",
      name: "imageName",
      Type: "text",
      IsError: false,
      required:false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Contact Name",
      name: "contactName",
      Type: "text",
      IsError: false,
      required:false,
      ErrorMsg: "Please Enter Name",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Contact No",
      name: "contactNo",
      required:false,
      Type: "text",
      IsError: false,

      ErrorMsg: "Invalid mobile number ",
      validReg: /^(\+\d{1,3}[- ]?)?\d{10}$/,
      options: [],
    },
    {
      Label: "Shipment Tracking URL",
      name: "shipmentTrackingUrl",
      Type: "text",
      IsError: false,
      required:true,
      ErrorMsg: "please Enter URL ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Address line 1",
      name: "address1",
      Type: "text",
      IsError: false,
      required:true,
      ErrorMsg: " Please Enter Address",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Address Line 2",
      name: "address2",
      Type: "text",
      IsError: false,
      required:false,
      ErrorMsg: " Please enter Address ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Address Line 3",
      name: "address3",
      Type: "text",
      IsError: false,
      required:false,
      ErrorMsg: " Please enter Address ",
      validReg: /[^\n]+/,
      options: [],
    },
    //   ,{
    //     Label: "isActive",
    //     name: "isActive",
    //     Type: "Checkbox",
    //     IsError: false,
    // ErrorMsg: " ",
    // validReg:/[^\n]+/,
    //     options: [],
    //   }
  ];
  const getServices = async () => {
    setLoader(true);

    try {
      const Response = await GetResponse(
        addData,
        CourierServiceLinks.CourierServiceProvider,
        {}
      );
      console.log(Response);
      if (Response.length > 0 && Array.isArray(Response)) {
        setServiceAPI(Response);
      } else {
        swal("error", "No Records Found", "error");
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Records Found", "error");
    }
    setLoader(false);
  };

  useEffect(() => {
    getServices();
  }, [Row]);

  const columns = useMemo(
    () => [
      {
        field: "courierServiceProviderCode",
        headerName: "Code",
        width: 100,
      },

      {
        field: "courierServiceProviderName",
        headerName: "Name",
        width: 200,
      },
      {
        field: "mobileNo",
        headerName: "Mobile No",
        width: 100,
      },
      {
        field: "country",
        headerName: "Country",
        width: 100,
        type: "singleSelect",
        valueOptions: ["basic", "editor", "admin"],
      },
      {
        field: "state",
        headerName: "State",
        width: 100,
        type: "singleSelect",
        valueOptions: ["basic", "editor", "admin"],
      },
      {
        field: "city",
        headerName: "City",
        width: 100,
        type: "singleSelect",
        valueOptions: ["basic", "editor", "admin"],
      },
      {
        field: "pinCode",
        headerName: "PinCode",
        width: 100,
      },
      {
        field: "websiteUrl",
        headerName: "Website URL",
        width: 200,
      },

      { field: "emailId", headerName: "Email ID", width: 200 },
      {
        field: "thumbImageName",
        headerName: "Thumb Image Name",
        width: 150,
      },
      {
        field: "imageName",
        headerName: "Image Name",
        width: 100,
      },
      {
        field: "contactName",
        headerName: "Contact Name",
        width: 100,
      },
      {
        field: "contactNo",
        headerName: "Contact No",
        width: 100,
      },
      {
        field: "shipmentTrackingUrl",
        headerName: "Shipment Tracking Url",
        width: 150,
      },

      {
        field: "isActive",
        headerName: "is Active",
        width: 100,
        renderCell: (params) => (
          <ActiveDeactive
            {...{
              UpdateURL: CourierServiceLinks.CourierServiceProvider,
              activeName: "isActive",
              SelectedFieldName: "this Courier Service Provider",
              row: params.row,
            }}
          />
        ),
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 200,
        renderCell: (params) =>
          ServiceAPI.length > 0 ? (
            <CourierServiceProviderCRUDActions
              {...{
                params,
                rowId,
                setRowId,
                dataFields,
                InitialError,
                RequiredInputs,
                EditURL: CourierServiceLinks.CourierServiceProvider,
                DeleteURL: CourierServiceLinks.CourierServiceProvider,
                RowDeleteId: params.row.courierServiceProviderId,
                name: "Courier Service Provider",
              }}
            />
          ) : (
            <></>
          ),
      },
    ],
    [rowId, Row, ServiceAPI]
  );

  return (
    <>
      <SimpleBackDrops Loader={Loader} />

      <CourierServiceProviderAddEditModal
        openModal={openModal}
        handleModalClose={handleModalClose}
        handleModalOpen={handleModalOpen}
        URL={CourierServiceLinks.CourierServiceProvider}
        row={addData}
        InitialError={InitialError}
        RequiredInputs={RequiredInputs}
        dataFields={dataFields}
        Type={"Add"}
        name={"Courier Service Provider"}
      />

      <Box
        sx={{
          height: 559,
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
          // backgroundColor:Colors.PrimaryBackgroundColor,
          // alignItems:'center'
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            textAlign: "center",
            p: 1,
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          Courier Service Provider
        </Typography>

        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            ml: 5,
            mt: 1,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
          onClick={handleModalOpen}
        >
          <Add />
        </Fab>

        <DataGrid
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          key={Row}
          rows={ServiceAPI}
          getRowId={(row) => row.courierServiceProviderId}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
