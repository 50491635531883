import { Box, Fab,Icon,  Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Edit, Tune } from "@mui/icons-material";
import { green, red } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { CRUDCommands, RDSLinks } from "../../../Utils/Axios/RequestParameters";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PostAddIcon from "@mui/icons-material/PostAdd";
import MenuAddEditModal from "./MenuAddEditModal";
const MenuActions = ({ row, EditURL, name }) => {
  const [openModal, setopenModal] = useState(false);

  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };


  const [Type, setType] = useState("Update");
  const [Modaltype, setModalType] = useState("Menu");

  const samplePageData = {
    pageId: 0,
    menuId: row.menuId,
    pageCaption:row.menuCaption,
    pageUrl: `/Admin/${row.menuCaption.replace(/\s/g, '')}`,
    isMenuDefaultPage: true,
    isMaintenance: false,
    maintenanceMsg: "",
    commandId: 0,
    createBy: 1,
    createDate: "0001-01-01T00:00:00",
    createIp: "",
    updateBy: 1,
    updateDate: "0001-01-01T00:00:00",
    updateIp: "",
    parentMenuCaption: row.menuCaption,
  };
  const sampleMenuData = {
    menuId: 0,
    roleId: 1,
    panelId:row.panelId?row.panelId:0,
    parentMenuId:row.menuId,
    menuCaption: "",
    menuControlName: "",
    menuLevel:0,
    orderNo: 0,
    logoUrl: "",
    isNewWindow: false,
    isActive: true,
    subMenuNo: 0,
    totSubMenu: 0,
    menuKey: "",
    isMaintenance: true,
    maintenanceMsg: "",
    commandId: 0,
    createBy: 0,
    createDate: "2022-12-13T12:09:03.062Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-12-13T12:09:03.062Z",
    updateIp: "",
    hasChildMenu: 0,
    parentMenuCaption: row.menuCaption,
  };

  const PageDataFields = [
    {
      name: "pageCaption",
      Label: "Page Caption",
      Type: "text",
      IsError: false,
      grid:12,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      name: "pageUrl",
      Label: "Page URL",
      Type: "url",
      grid:12,
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
  ];
  const MenuDataFields = [
   
    {
      name: "menuCaption",
      Label: "Menu Caption",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      grid:12,
      validReg: /[^\n]+/,
      options: [],
    },{
      name: "logoUrl",
      Label: "Font Awesome Icon",
      Type: "test",
      IsError: false,
      grid:12,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },{
      name: "orderNo",
      Label: "Order No",
      Type: "text",
      IsError: false,
      grid:12,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },{
      name: "isActive",
      Label: "Active ?",
      Type: "CheckBox",
      IsError: false,
      grid:12,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    }
    
    
  ];



  return (
    <>
      {openModal === true ? (
        <MenuAddEditModal
          openModal={openModal}
          handleModalClose={handleModalClose}
          handleModalOpen={handleModalOpen}
          row={Modaltype==='Menu' && Type==='Add'?sampleMenuData: Modaltype==='Page' && Type==='Add'?samplePageData: row}
          dataFields={Modaltype==='Menu'?MenuDataFields:PageDataFields}
          Type={Type}
          URL={Modaltype==='Menu'?RDSLinks.MenuMaster:RDSLinks.PageMaster}
          name={Modaltype}
        />
      ) : (
        <></>
      )}

      <Box
        sx={{
          // m: 1,
          position: "relative",
          // display: "flex",
          // justifyContent: "space-between",
          // width: "100%",
        }}
      >
        <Tooltip title="Edit" arrow>
          <Fab
            color="primary"
            sx={{
              m: 0,
              ml: 2,
              width: 40,
              height: 40,
              bgcolor: green[400],
              "&:hover": { bgcolor: green[700] },
            }}
            onClick={() => {setType('Update');setModalType("Menu");handleModalOpen();}}
          >
            <Edit />
          </Fab>
        </Tooltip>
        <Tooltip title="Add SubMenu" arrow>
          <Fab
            color="primary"
            sx={{
              m: 0,
              ml: 2,
              width: 40,
              height: 40,
              bgcolor: red[400],
              "&:hover": { bgcolor: red[700] },
            }}
            onClick={() => {setType('Add');setModalType("Menu");handleModalOpen();}}

          >
            <PlaylistAddIcon />
          </Fab>
        </Tooltip>

        <Tooltip title="Add Page" arrow>
          <Fab
            color="primary"
            sx={{
              m: 0,
              ml: 2,
              width: 40,
              height: 40,
            }}
            onClick={() => {setType('Add');setModalType("Page");handleModalOpen();}}

          >
            <PostAddIcon />
          </Fab>
        </Tooltip>
      </Box>
    </>
  );
};

export default MenuActions;
