import { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Typography, Fab } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import {
  CRUDCommands,
  RDSLinks,
} from "../../../Utils/Axios/RequestParameters";
import { Colors } from "../../../Utils/Constants";
import ActiveDeactive from "../ActiveDeactive";
import swal from "sweetalert";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import CRUDActions from "../CRUDActions";
import CommonAddEditModal from "../CommonAddEditModal";
export default function BankDataGrid() {
  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);

  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);

  const addData = {
    bankId: 0,
    bankType: "L",
    bankCode: "",
    bankName: "",
    bankBranch: "",
    acName:"",
    acno: "",
    add1: "",
    add2: "",
    add3: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    bankImageUrl: "",
    bankSlipFileName: "",
    bankUrl: "",
    bankACNo: "",
    commandId:CRUDCommands.ListAll,
    createBy: 0,
    createDate: "2022-11-16T07:00:21.360Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-11-16T07:00:21.360Z",
    updateIp: "",
    ifsccode: "",
    isActive: true,
    orderNo: 0,
  };

  const InitialError  = {
   
    bankCode:false,
    bankName:false,
    bankBranch:false,
    acName:false,
    acno:false,
    bankImageUrl:false,
    bankSlipFileName:false,
    bankUrl:false,
    ifsccode:false,
   
  };
  const RequiredInputs=[
    "bankCode",
    "bankName",
   "bankBranch",
  "acName",
    "acno",
    "ifsccode"
  ]
  const dataFields = [
    {
      Label: "Bank Code",
      name: "bankCode",
      Type: "text",
      required: true,
      IsError: false,
      ErrorMsg: "Enter Bank Code",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Bank Name",
      name: "bankName",
      Type: "text",
      IsError: false,
      required: true,
      ErrorMsg: "Enter Bank Name",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Bank Branch",
      name: "bankBranch",
      required: true,
      Type: "text",
      IsError: false,
      ErrorMsg: "Enter Bank Branch",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Account No.",
      name: "acno",
      Type: "text",
      IsError: false,
      required: true,
      ErrorMsg: "Enter Account Number",
      validReg: /[^\n]+/,
      options: [],
    },{
      Label: "Account Holder Name",
      name: "acName",
      Type: "text",
      IsError: false,
      required: true,
      ErrorMsg: "Enter Account Number",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Bank URL",
      name: "bankUrl",
      Type: "text",
      IsError: false,
      required: false,
      ErrorMsg: "Enter Bank URL",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Bank Slip File Name",
      name: "bankSlipFileName",
      Type: "text",
      IsError: false,
      required: false,
      ErrorMsg: "Enter Bank Slip File Name",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "IFSC CODE",
      name: "ifsccode",
      Type: "text",
      IsError: false,
      required: true,
      ErrorMsg: "Enter IFSC Code",
      validReg: /^[A-Z]{4}0[A-Z0-9]{6}$/,
      options: [],
    },
  ];
  const getServices = async () => {
    setLoader(true);
    try {
      //   const Response = await axios.get(RDSLinks.GetBank);
      const Response = await GetResponse(addData, RDSLinks.GetBank, {});
      console.log(Response);
      if (Response.length > 0 && Array.isArray(Response)) {
        setServiceAPI(Response);
      } else {
        swal("error", "No Records Found", "error");
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Records Found", "error");
    }
    setLoader(false);
  };

  useEffect(() => {
    getServices();
  }, [Row]);

  const columns = useMemo(
    () => [
      { field: "bankCode", headerName: "Bank Code", width: 200 },
      { field: "bankName", headerName: "Bank Name", width: 200 },
      { field: "bankBranch", headerName: "Bank Branch", width: 200 },
      { field: "acno", headerName: "Account No.", width: 200 },
      { field: "acName", headerName: "Account Holder Name", width: 200 },


      { field: "ifsccode", headerName: "IFSC Code", width: 250 },

      {
        field: "isActive",
        headerName: "is Active",
        width: 200,
        type: "boolean",
        renderCell: (params) => (
          <ActiveDeactive
            {...{
              UpdateURL: RDSLinks.GetBank,
              activeName: "isActive",
              SelectedFieldName: "this Bank",
              row: params.row,
            }}
            key={Row}
          />
        ),
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 150,
        renderCell: (params) => (
          <CRUDActions
            {...{
              params,
              rowId,
              setRowId,
              dataFields,
              InitialError,
              RequiredInputs,
              EditURL: RDSLinks.GetBank,
              DeleteURL: RDSLinks.GetBank,
              RowDeleteId: params.row.bankId,
              name: "Bank",
            }}
          />
        ),
      },
    ],
    [rowId, Row]
  );

  return (
    <>
      <SimpleBackDrops Loader={Loader} />
      <CommonAddEditModal
        openModal={openModal}
        handleModalClose={handleModalClose}
        handleModalOpen={handleModalOpen}
        URL={RDSLinks.GetBank}
        row={addData}
        RequiredInputs={RequiredInputs}
        InitialError={InitialError}
        dataFields={dataFields}
        Type={"Add"}
        name={"Bank"}
      />

      <Box
        sx={{
          height: { xs: 649, md: 569 },
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
          // backgroundColor:Colors.PrimaryBackgroundColor,
          // alignItems:'center'
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            textAlign: "center",
            p: 1,
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          Bank
        </Typography>

        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            ml: 5,
            mt: 1,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
          onClick={handleModalOpen}
        >
          <Add />
        </Fab>

        <DataGrid
          key={Row}
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          rows={ServiceAPI}
          getRowId={(row) => row.bankId}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
