import * as React from "react";
import {
  Grid,
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  TextField,
  MenuItem,
  CssBaseline,
  Container,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
  Skeleton,
} from "@mui/material";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../Utils/Constants";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {
  CourierServiceLinks,
  CRUDCommands,
} from "../../Utils/Axios/RequestParameters";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useState } from "react";
import moment from "moment";
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  paddingTop: 1,
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", md: "60%", lg: "50%" },
  height: { xs: 700, sm: 600 },
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  pb: 0,
  p: 0,
  m: 0,
};
// Type = Edit or ADD

export default function CourierTrackingModal({
  OrderData,
  openModal,
  handleModalOpen,
  handleModalClose,
}) {
  const [Loader, setLoader] = React.useState(true);
  const [TrackingdataArray, setTrackingDataArray] = useState([]);
  useEffect(() => {
    try {
      setLoader(true)
      const GetData = async () => {
        const request = {
          orders: [OrderData],
          errorDesc: null,
          errorCode: null,
          commandId: CRUDCommands.Tracking,
        };
        const TrackingRespone = await GetResponse(
          request,
          CourierServiceLinks.Order,
          {}
        );
        console.log({ TrackingRespone });
        if (
          TrackingRespone &&
          TrackingRespone.errorDesc !== "" &&
          TrackingRespone.errorDesc !== null
        ) {
          handleModalClose();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: TrackingRespone.errorDesc,
          });
        } else {
          setTrackingDataArray(TrackingRespone.orders[0].tracking);
          console.log({
            TrackingdataArray: TrackingRespone.orders[0].tracking,
          });
        }
        setLoader(false)
      };
      GetData();
    } catch (error) {
      setLoader(false)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    }
  }, [OrderData]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => {
          handleModalClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Container component="main" maxWidth="lg" sx={styles}>
            {/* <CssBaseline /> */}
            <Box
              sx={{
                width: { xs: "90%", sm: "100%" },
                height: 70,
                backgroundColor: Colors.PrimaryBackgroundColor[400],
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: 2,
                paddingRight: 2,
                marginTop: 3,
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: { xs: 15, sm: 25 },
                }}
              >
                {" "}
                Shipment Tracking{" "}
              </Typography>
              <Tooltip title="close">
                <IconButton
                  onClick={handleModalClose}
                  color="error"
                  aria-label="close"
                >
                  <CloseIcon color="white" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                margin: { xs: 1, sm: 3 },
                overflow: "auto",
              }}
            >
              <Grid container spacing={0.5}>
              {Loader ? (
                  Array(7).fill(
                    <>
                      <Grid item xs={3} sm={2}>
                        <Box sx={{ width: "100%", height: "100%" }}>
                          <Skeleton
                            sx={{
                              color: Colors.SecondaryBackGroundColor[600],
                              height: 30,
                              fontWeight: "bold",
                            }}
                          />
                          <Skeleton
                            sx={{
                              color: Colors.SecondaryBackGroundColor[600],
                              height: 20,
                              fontWeight: "bold",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            opacity: 0.2,
                            transition: "opacity 1s ease-out",
                          }}
                        >
                          <Box
                            sx={{
                              height: "5%",
                              color: Colors.PrimaryBackgroundColor[900],
                              borderStyle: "dashed",
                              width: 0,
                              borderWidth: 1,
                            }}
                          ></Box>
                          <RadioButtonCheckedIcon
                            fontSize="medium"
                            sx={{
                              color: Colors.SecondaryBackGroundColor[800],
                            }}
                          />

                          <Box
                            sx={{
                              height: "100%",
                              color: Colors.PrimaryBackgroundColor[900],
                              borderStyle: "dashed",
                              width: 0,
                              borderWidth:1,
                            }}
                          ></Box>
                        </Box>
                      </Grid>
                      <Grid item xs={7} sm={8}>
                        <Box sx={{ width: "100%", height: "100%" }}>
                          <Skeleton
                            sx={{
                              color: Colors.SecondaryBackGroundColor[600],
                              height: 30,
                              fontWeight: "bold",
                              width: 300,
                            }}
                          ></Skeleton>
                          <Skeleton
                            sx={{
                              color: Colors.SecondaryBackGroundColor[400],
                              height: 15,
                              fontWeight: "bold",
                              width: 200,
                            }}
                          ></Skeleton>
                          <Skeleton
                            sx={{
                              color: Colors.SecondaryBackGroundColor[400],
                              height: 15,
                              fontWeight: "bold",
                              width: 100,
                            }}
                          ></Skeleton>
                        </Box>
                      </Grid>
                    </>
                  )
                ) : TrackingdataArray.length > 0 ? (
                  TrackingdataArray.map((TrackData,index) => {
                    return (
                      <>
                        <Grid item xs={3} sm={2}>
                          <Box sx={{ width: "100%", height: "100%" }}>
                            <Typography
                              sx={{
                                color: Colors.SecondaryBackGroundColor[600],
                                fontSize: 15,
                                fontWeight: "bold",
                              }}
                            >
                             {moment(TrackData.checkpointTime).format("DD/MM/YYYY")}
                            </Typography>
                            <Typography
                              sx={{
                                color: Colors.SecondaryBackGroundColor[400],
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                            >
                              &nbsp;&nbsp;{moment(TrackData.checkpointTime).format("hh : mm A" )}

                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",

                            }}
                          >
                            <Box
                              sx={{
                                height: "5%",
                                color: Colors.PrimaryBackgroundColor[900],
                                borderStyle: "dashed",
                                width: 0,
                                borderWidth:1,
                              }}
                            ></Box>
                            <RadioButtonCheckedIcon
                              fontSize="medium"
                              sx={{
                                color: Colors.SecondaryBackGroundColor[800],
                              opacity:TrackingdataArray.length-1 ===index ? 1:0.5

                              }}
                            />

                            <Box
                              sx={{
                                height: "100%",
                                color: Colors.PrimaryBackgroundColor[900],
                                borderStyle: "dashed",
                                width: 0,
                                borderWidth: 1,
                              }}
                            ></Box>
                          </Box>
                        </Grid>
                        <Grid item xs={7} sm={8}>
                          <Box sx={{ width: "100%", height: "100%",paddingBottom:2 }}>
                            <Typography
                              sx={{
                                color: Colors.SecondaryBackGroundColor[600],
                                fontSize: 15,
                                fontWeight: "bold",
                              }}
                            >
                              {TrackData.message}
                            </Typography>
                            <Typography
                              sx={{
                                color: Colors.SecondaryBackGroundColor[400],
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                            >
                            {TrackData.location}

                            </Typography>
                            {TrackData.driverName !=="" ?<Typography
                              sx={{
                                color: Colors.SecondaryBackGroundColor[400],
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                            >
                              Driver Name :  {TrackData.driverName}
                            </Typography>:<></>}
                            {TrackData.driverNumber !==""?<Typography
                              sx={{
                                color: Colors.SecondaryBackGroundColor[400],
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                            >
                              Contact No :  {TrackData.driverNumber}
                            </Typography>:<></>}
                          </Box>
                        </Grid>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
              </Grid>
            </Box>
          </Container>
        </Fade>
      </Modal>
    </>
  );
}
