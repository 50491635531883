import React from "react";
import {
  Grid,
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  TextField,
  MenuItem,
  CssBaseline,
  Container,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Colors } from "../Utils/Constants";
import { NavigationLinks } from "../router/NavigationLinks";
import { useEffect } from "react";
import { useState } from "react";
import SimpleBackDrops from "../components/SimpleBackDrops";

import axios from "axios";
import {
  CourierServiceLinks,
  RequestObj,
} from "../Utils/Axios/RequestParameters";
import swal from "sweetalert";
import { green, red } from "@mui/material/colors";
import { GetResponse } from "../Utils/Axios/GetResponse";

export default function EditOrder({
  OldSearch,
  OpenEditOrder,
  CloseEditOrder,
  UpdateSearchData,
}) {
  const [Loader, setLoader] = React.useState(false);

  const [CourierType, SetCourierType] = useState([
    { label: "Please Wait", value: "" },
  ]);
  const getCourierType = async () => {
    try {
      // const Response = await axios.get(CourierServiceLinks.CourierType.GetAll);
      const Response = await GetResponse(
        RequestObj.Courier.courierType,
        CourierServiceLinks.CourierType,
        {}
      );
      console.log(Response);
      if (Response.length > 0) {
        const newArray = Response.map((type) => {
          return {
            label: type.courierTypeName,
            value: type.courierTypeId,
          };
        });
        SetCourierType(newArray);
      } else {
        swal("error", "No Records Found", "error");
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Records Found", "error");
    }
  };

  useEffect(() => {
    getCourierType();
  }, []);
  const navigation = useNavigate();
  const Validations = {
    pickupPincode: false,
    deliveryPincode: false,
    courierTypeID: false,
    weightInKg: false,
    lengthInCm: false,
    widthInCm: false,
    heightInCm: false,
  };
  const [AllError, SetAllError] = useState(Validations);

  const CourierTypeArray = [
    {
      value: `Parcel`,
      label: `Parcel`,
    },
    {
      value: `Document`,
      label: `Document`,
    },
  ];

  const [data, setdata] = React.useState({
    pickupPincode: OldSearch["pickupPincode"],
    deliveryPincode: OldSearch["deliveryPincode"],
    courierTypeID: OldSearch["courierTypeID"],
    isPrepaid: OldSearch["isPrepaid"],
    parcelValue: OldSearch["parcelValue"],
    weightInKg: OldSearch["weightInKg"],
    lengthInCm: OldSearch["lengthInCm"],
    widthInCm: OldSearch["widthInCm"],
    heightInCm: OldSearch["heightInCm"],
  });
  const handleChange = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const validate = (e, regex) => {
    setdata({ ...data, [e.target.name]: e.target.value });
    const IsValid = regex.test(e.target.value);
    console.log(IsValid);
    if (IsValid === true) {
      SetAllError({ ...AllError, [e.target.name]: false });
    } else {
      SetAllError({ ...AllError, [e.target.name]: true });
    }
  };
  useEffect(() => {
    console.log(data);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const NewData = {
      pickupPincode: data["pickupPincode"],
      deliveryPincode: data["deliveryPincode"],
      courierTypeID: parseFloat(data["courierTypeID"]),
      weightInKg: parseFloat(data["weightInKg"]),
      lengthInCm: parseFloat(data["lengthInCm"]),
      widthInCm: parseFloat(data["widthInCm"]),
      heightInCm: parseFloat(data["heightInCm"]),
    };
    UpdateSearchData(NewData);
    CloseEditOrder();
  };

  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    // paddingTop: 1,
    transform: "translate(-50%, -50%)",
    width: { xs: "80%", md: "60%", lg: "50%" },
    height: { xs: 700, sm: 400 },
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    pb: 0,
    p: 0,
    m: 0,
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={OpenEditOrder}
        onClose={CloseEditOrder}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={OpenEditOrder}>
          <Container component="main" maxWidth="lg" sx={styles}>
            <Typography
              component="h1"
              variant="h5"
              sx={{
                width: "40vw",
                backgroundColor: Colors.SecondaryBackGroundColor[400],
                color: "white",
                height: 70,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                boxShadow:
                  "0 5px 10px rgba(0,0,0,0.16), 0 5px 5px rgba(0,0,0,0.16)",
                // mb:1,
                fontSize: { xs: 15, sm: 20 },
              }}
            >
              Edit Search
            </Typography>

            <Box
              component="form"
              validate="true"
              onSubmit={handleSubmit}
              sx={{ mt: 3, pl: 2, pr: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    error={AllError["pickupPincode"]}
                    inputProps={{ pattern: "^[a-zA-Z0-9]{1,7}$" }}
                    helperText={
                      AllError["pickupPincode"] === true
                        ? "Please enter valid Pincode "
                        : ""
                    }
                    name="pickupPincode"
                    value={data["pickupPincode"]}
                    required
                    fullWidth
                    id="From PinCode"
                    label="From PinCode"
                    // type="number"
                    autoFocus
                    onChange={(e) => validate(e, /^[a-zA-Z0-9]{1,7}$/)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    error={AllError["deliveryPincode"]}
                    inputProps={{ pattern: "^[a-zA-Z0-9]{1,7}$" }}
                    helperText={
                      AllError["deliveryPincode"] === true
                        ? "Please enter valid Pincode "
                        : ""
                    }
                    fullWidth
                    id="To Pincode"
                    label="To Pincode"
                    name="deliveryPincode"
                    value={data["deliveryPincode"]}
                    autoComplete="family-name"
                    // type="number"
                    onChange={(e) => validate(e, /^[a-zA-Z0-9]{1,7}$/)}
                    step=".01"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    // inputProps={{ pattern: "^[\d\.\d{1,4}]" }}
                    value={data["weightInKg"]}
                    error={AllError["weightInKg"]}
                    helperText={
                      AllError["weightInKg"] === true
                        ? "Please enter valid Weight "
                        : ""
                    }
                    id="Parcel Weight"
                    label="Parcel Weight(kg)"
                    name="weightInKg"
                    autoComplete="family-name"
                    // type="number"
                    onChange={(e) => validate(e, /^\d+(\.\d{1,4})?$/)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    error={AllError["courierTypeID"]}
                    select
                    id="Type of Courier"
                    label="Type of Courier"
                    name="courierTypeID"
                    value={data["courierTypeID"]}
                    autoComplete="family-name"
                    // onChange={validate}
                    onChange={(e) => validate(e, /[a-zA-Z0-9]/)}
                  >
                    {CourierType.map((Courriertype) => {
                      return (
                        <MenuItem
                          key={Courriertype.value}
                          value={Courriertype.value}
                        >
                          {Courriertype.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    select
                    id="Height"
                    label="Payment Type "
                    name="isPrepaid"
                    value={data["isPrepaid"]}
                    autoComplete="family-name"
                    disabled
                    type="number"
                    step=".01"
                    onChange={(e) => validate(e, /^[a-zA-Z0-9]/)}
                  >
                    <MenuItem key={"Cash on Delivery"} value={false}>
                      Cash on Delivery
                    </MenuItem>
                    <MenuItem key={"online Payment"} value={true}>
                      Online Payment
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    error={AllError["parcelValue"]}
                    id="parcelValue"
                    label="Parcel Value( In Rupees )"
                    name="parcelValue"
                    value={data["parcelValue"]}
                    autoComplete="family-name"
                    type="number"
                    // onChange={validate}
                    onChange={(e) => validate(e, /[0-9]/)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    error={AllError["lengthInCm"]}
                    helperText={
                      AllError["lengthInCm"] === true
                        ? "Please enter valid length "
                        : ""
                    }
                    id="Length"
                    label="Length(cm)"
                    name="lengthInCm"
                    value={data["lengthInCm"]}
                    autoComplete="family-name"
                    type="number"
                    onChange={(e) => validate(e, /^[0-9]/)}
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    inputProps={{ pattern: "[0-9]" }}
                    error={AllError["widthInCm"]}
                    helperText={
                      AllError["widthInCm"] === true
                        ? "Please enter valid Width "
                        : ""
                    }
                    id="Width"
                    label="Width(cm)"
                    name="widthInCm"
                    value={data["widthInCm"]}
                    autoComplete="family-name"
                    type="number"
                    onChange={(e) => validate(e, /^[0-9]/)}
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    inputProps={{ pattern: "[0-9]" }}
                    error={AllError["heightInCm"]}
                    helperText={
                      AllError["heightInCm"] === true
                        ? "Please enter valid height "
                        : ""
                    }
                    id="Height"
                    label="Height(cm)"
                    name="heightInCm"
                    value={data["heightInCm"]}
                    autoComplete="family-name"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => validate(e, /^[0-9]/)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      mb: 1,
                      color: "white",

                      backgroundColor: red[500],
                      "&:hover": {
                        backgroundColor: red[800],
                      },
                    }}
                    onClick={() => CloseEditOrder()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mb: 1,
                      color: "white",

                      backgroundColor: green[500],
                      "&:hover": {
                        backgroundColor: green[800],
                      },
                    }}
                    onClick={() => handleSubmit}
                  >
                    Done
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Fade>
      </Modal>
    </>
  );
}
