import React from "react";
import { TextField, Button, MenuItem, Box, Grid, colors } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { Colors } from "../Utils/Constants";
import { NavigationLinks } from "../router/NavigationLinks";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import {
  CourierServiceLinks,
  RequestObj,
} from "../Utils/Axios/RequestParameters";
import swal from "sweetalert";
import { GetResponse } from "../Utils/Axios/GetResponse";
export default function NewOrder() {
  const [CourierType, SetCourierType] = useState([
    { label: "Please Wait", value: "" },
  ]);
  const getCourierType = async () => {
    try {
      // const Response = await axios.get(CourierServiceLinks.CourierType.GetAll);
      const Response = await GetResponse(
        RequestObj.Courier.courierType,
        CourierServiceLinks.CourierType,
        {}
      );
      console.log(Response);
      if (Response.length > 0) {
        const newArray = Response.map((type) => {
          return {
            label: type.courierTypeName,
            value: type.courierTypeId,
          };
        });
        SetCourierType(newArray);
      } else {
        swal("error", "No Records Found", "error");
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Records Found", "error");
    }
  };

  useEffect(() => {
    getCourierType();
  }, []);
  const navigation = useNavigate();
  const Validations = {
    pickupPincode: false,
    deliveryPincode: false,
    courierTypeID: false,
    weightInKg: false,
    lengthInCm: false,
    widthInCm: false,
    heightInCm: false,
  };
  const [AllError, SetAllError] = useState(Validations);

  const CourierTypeArray = [
    {
      value: `Parcel`,
      label: `Parcel`,
    },
    {
      value: `Document`,
      label: `Document`,
    },
  ];

  const [data, setdata] = React.useState({
    pickupPincode: "",
    deliveryPincode: "",
    courierTypeID: 0,
    weightInKg: 0,
    lengthInCm: 0,
    widthInCm: 0,
    isPrepaid: true,
    heightInCm: 0,
  });
  const handleChange = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const validate = (e, regex) => {
    setdata({ ...data, [e.target.name]: e.target.value });
    const IsValid = regex.test(e.target.value);
    console.log(IsValid);
    if (IsValid === true) {
      SetAllError({ ...AllError, [e.target.name]: false });
    } else {
      SetAllError({ ...AllError, [e.target.name]: true });
    }
  };
  useEffect(() => {
    console.log(data);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const NewData = {
      pickupPincode: data["pickupPincode"],
      deliveryPincode: data["deliveryPincode"],
      isPrepaid: data["isPrepaid"],
      parcelValue: data["parcelValue"],
      courierTypeID: parseFloat(data["courierTypeID"]),
      weightInKg: parseFloat(data["weightInKg"]),
      lengthInCm: parseFloat(data["lengthInCm"]),
      widthInCm: parseFloat(data["widthInCm"]),
      heightInCm: parseFloat(data["heightInCm"]),
    };
    navigation(NavigationLinks.AvailablePartners, { state: NewData });
  };
  return (
    <Box
      className="SignInContainer"
      sx={{
        width: { xs: "100%", sm: "100%", lg: "100%" },

        minHeight: { xs: 600, sm: "87.5vh" },
        // maxHeight: "100vh",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Box
        className="SignInBox"
        sx={{
          backgroundColor: "white",
          borderRadius: 5,
          marginLeft: { xs: 0, sm: 15, lg: 10 },
        }}
      >
        <Container
          component="main"
          maxWidth="sm"
          sx={{
            width: { xs: "100%", sm: "100%" },

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              validate="true"
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    error={AllError["pickupPincode"]}
                    inputProps={{ pattern: "^[a-zA-Z0-9]{1,7}$" }}
                    helperText={
                      AllError["pickupPincode"] === true
                        ? "Please enter valid Pincode "
                        : ""
                    }
                    name="pickupPincode"
                    required
                    fullWidth
                    id="From PinCode"
                    label="From PinCode"
                    // type="number"
                    autoFocus
                    onChange={(e) => validate(e, /^[a-zA-Z0-9]{1,7}$/)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    error={AllError["deliveryPincode"]}
                    inputProps={{ pattern: "^[a-zA-Z0-9]{1,7}$" }}
                    helperText={
                      AllError["deliveryPincode"] === true
                        ? "Please enter valid Pincode "
                        : ""
                    }
                    fullWidth
                    id="To Pincode"
                    label="To Pincode"
                    name="deliveryPincode"
                    autoComplete="family-name"
                    // type="number"
                    onChange={(e) => validate(e, /^[a-zA-Z0-9]{1,7}$/)}
                    step=".01"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    // inputProps={{ pattern: "" }}

                    error={AllError["weightInKg"]}
                    helperText={
                      AllError["weightInKg"] === true
                        ? "Please enter valid Weight "
                        : ""
                    }
                    id="Parcel Weight"
                    label="Parcel Weight(kg)"
                    name="weightInKg"
                    autoComplete="family-name"
                    // type="number"
                    onChange={(e) => validate(e, /^\d+(\.\d{1,4})?$/)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    error={AllError["courierTypeID"]}
                    select
                    id="Type of Courier"
                    label="Type of Courier"
                    name="courierTypeID"
                    autoComplete="family-name"
                    // onChange={validate}
                    onChange={(e) => validate(e, /[a-zA-Z0-9]/)}
                  >
                    {CourierType.map((Courriertype) => {
                      return (
                        <MenuItem
                          key={Courriertype.value}
                          value={Courriertype.value}
                        >
                          {Courriertype.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    error={AllError["parcelValue"]}
                    id="parcelValue"
                    label="Parcel Value( In Rupees )"
                    name="parcelValue"
                    autoComplete="family-name"
                    type="number"
                    // onChange={validate}
                    onChange={(e) => validate(e, /[0-9]/)}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    select
                    id="Height"
                    label="Payment Type "
                    name="isPrepaid"
                    autoComplete="family-name"
                    value={data["isPrepaid"]}
                    type="number"
                    disabled
                    step=".01"
                    onChange={(e) => validate(e, /^[a-zA-Z0-9]/)}
                  >
                    <MenuItem key={"Cash on Delivery"} value={false}>
                      Cash on Delivery
                    </MenuItem>
                    <MenuItem key={"online Payment"} value={true}>
                      Online Payment
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    error={AllError["lengthInCm"]}
                    helperText={
                      AllError["lengthInCm"] === true
                        ? "Please enter valid length "
                        : ""
                    }
                    id="Length"
                    label="Length(cm)"
                    name="lengthInCm"
                    autoComplete="family-name"
                    type="number"
                    onChange={(e) => validate(e, /^[0-9]/)}
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    inputProps={{ pattern: "[0-9]" }}
                    error={AllError["widthInCm"]}
                    helperText={
                      AllError["widthInCm"] === true
                        ? "Please enter valid Width "
                        : ""
                    }
                    id="Width"
                    label="Width(cm)"
                    name="widthInCm"
                    autoComplete="family-name"
                    type="number"
                    onChange={(e) => validate(e, /^[0-9]/)}
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    inputProps={{ pattern: "[0-9]" }}
                    error={AllError["heightInCm"]}
                    helperText={
                      AllError["heightInCm"] === true
                        ? "Please enter valid height "
                        : ""
                    }
                    id="Height"
                    label="Height(cm)"
                    name="heightInCm"
                    autoComplete="family-name"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(e) => validate(e, /^[0-9]/)}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      color: "white",

                      backgroundColor: Colors.PrimaryColor[500],
                      "&:hover": {
                        backgroundColor: Colors.SecondaryBackGroundColor[500],
                      },
                    }}
                    onClick={() => handleSubmit}
                  >
                    Get Quote
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
