import StarBorder from "@mui/icons-material/StarBorder";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReportIcon from "@mui/icons-material/Report";
import PsychologyIcon from "@mui/icons-material/Psychology";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FeedbackIcon from "@mui/icons-material/Feedback";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HelpIcon from "@mui/icons-material/Help";
import { NavigationLinks } from "../router/NavigationLinks";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import HistoryIcon from "@mui/icons-material/History";
import TimelineIcon from "@mui/icons-material/Timeline";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Wallet } from "@mui/icons-material";
import InventoryIcon from "@mui/icons-material/Inventory";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";

const MainLinkList = [
  {
    Name: "DashBoard",
    Link:"/DashBoard",
    NestedLinks: [],
    Icon: DashboardIcon,
  },
  {
    Name: "Add Agent",
    Link: "/AddAgent",
    NestedLinks: [],
    Icon: GroupAddIcon,
  },
  {
    Name: "Downline Top Up Requests",
    Link: "/DownlineTopUpRequest",
    NestedLinks: [],
    Icon: RequestQuoteIcon,
  },
  {
    Name: "Downline Agents",
    Link: "/DownlineAgents",
    NestedLinks: [],
    Icon: ReduceCapacityIcon,
  },

  {
    Name: "Wallet",
    Link: "/",
    NestedLinks: [
      // {
      //   Name: "Top Up Online",
      //   Link: "/",
      //   NestedLinks: [

      //   ],
      //   Icon: AccountBalanceWalletIcon,
      // },
      {
        Name: "Top Up Offline",
        Link: "/TopUpOffline",
        NestedLinks: [],
        Icon: AccountBalanceWalletIcon,
      },
    ],
    Icon: AccountBalanceWalletIcon,
  },
  {
    Name: "NewOrder",
    Link: NavigationLinks.NewOrder,
    NestedLinks: [],
    Icon: LocalShippingIcon,
  },
  {
    Name: "Booking History",
    Link: "/bookinghistory",
    NestedLinks: [],
    Icon: HistoryIcon,
  },
  //  {
  //   Name: "Check Status",
  //   Link: "/checkStatus",
  //   NestedLinks: [],
  //   Icon: TimelineIcon,
  // },
  // {
  //   Name: "Cancellation",
  //   Link: "/cancellation",
  //   NestedLinks: [],
  //   Icon: CancelPresentationIcon,
  // },
  {
    Name: "Bank Details",
    Link: "/BankDetails",
    NestedLinks: [],
    Icon: AccountBalanceIcon,
  },
  {
    Name: "Report",
    Link: "/",
    NestedLinks: [
      {
        Name: "Wallet",
        Link: "/",
        NestedLinks: [
          {
            Name: "Account Ledger",
            Link: "/AccountLedger",
            NestedLinks: [],
            Icon: AccountBalanceWalletIcon,
          },
        ],
        Icon: Wallet,
      },
      {
        Name: "Courier",
        Link: "/courier",
        NestedLinks: [
          {
            Name: "Order History",
            Link: "/OrderHistory",
            NestedLinks: [],
            Icon: InventoryIcon,
          },
        ],
        Icon: InventoryIcon,
      },
    ],
    Icon: AssessmentIcon,
  },
  {
    Name: "Help",
    Link: "/help",
    NestedLinks: [],
    Icon: HelpIcon,
  },
];
export { MainLinkList };
