import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
  SubMenu,
} from "react-pro-sidebar";

import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Link } from "react-router-dom";
import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import { NavLink } from "react-router-dom";
import { Box, Button, Checkbox, colors, IconButton } from "@mui/material";
import { Colors } from "../Utils/Constants";
import { red } from "@mui/material/colors";
import { Container } from "@mui/system";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { GetResponse } from "../Utils/Axios/GetResponse";
import { RDSLinks } from "../Utils/Axios/RequestParameters";
import { ArrowRight,  ReadMore, } from "@mui/icons-material";

export default function AdminResponsiveSideBar({ openDrawer, LinkList }) {
  const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
    useProSidebar();

  React.useEffect(() => {
    toggleSidebar(openDrawer);
  }, [openDrawer]);

  const [Icon, SetIcon] = React.useState(false);
  const UpdateIcon = () => {
    SetIcon(Icon === false ? true : false);
  };
  // console.log(collapseSidebar);

  const MenuItems = ({ Links, index, level }) => {
    // console.log(Links.page.pageUrl)
    return (
      <Tooltip title={Links.page.pageCaption} placement="right">
        <MenuItem
          // icon={<i className={Links.logoUrl}></i>}
          level={level}
          component={
            <NavLink
              key={Links.page.pageCaption}
              style={({ isActive }) => ({
                textDecoration: "none",
                ...(isActive
                  ? { color: Colors.PrimaryColor[300] ,backgroundColor: Colors.SecondaryColor[800]}
                  : { color: Colors.SecondaryColor[800],}),
              })}
              to={Links.page.pageUrl}
            />
          }
          onClick={() => collapseSidebar(true)}
        >
          {Links.page.pageCaption}
        </MenuItem>
      </Tooltip>
    );
  };

  const Submenu = ({ Links, index, level }) => {
    return (
      <Tooltip title={Links.menuCaption} placement="right">
        <SubMenu
          label={Links.menuCaption}
          icon={<i className={Links.logoUrl}></i>}
          style={{ color: Colors.SecondaryColor[900] }}
          level={level}
        >
          {Links.submenu.map((Link, index) => {
            if (
              Link.submenu &&
              Link.submenu !== null &&
              Link.submenu.length > 0
            ) {
              return (
                <Submenu
                  Links={Link}
                  index={index}
                  key={`${Link.menuCaption}${index}`}
                  level={Link.menuLevel-1}
                />
              );
            } else if (Link.page && Link.page !== null) {
              return (
                <MenuItems
                  Links={Link}
                  index={index}
                  key={`${Link.menuCaption}${index}`}
                  level={level}
                />
              );
            }
          })}
        </SubMenu>
      </Tooltip>
    );
  };

  return (
    <Box
      sx={{
        height: "88%",
        backgroundColor: "white",
        color: Colors.SecondaryColor[900],
        position: "fixed",
        // display: { xs: openDrawer === true ? "block" : "none", sm: "block" },
        // width: "5vw" ,
        zIndex: 1200,
      }}
    >
      <Box
        sx={{
          height: "100%",
          backgroundColor: "white",
          overflowY: "auto",
          // display:{xs:'none'}
        }}
      >
        <Sidebar
          style={{ maxWidth: "8vw", top: broken ? 80 : 0 }}
          defaultCollapsed={true}
          breakPoint="sm"
        >
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                return {
                  // color: disabled ? '#f5d9ff' : '#d359ff',
                  color: active
                    ? Colors.PrimaryColor[800]
                    : Colors.SecondaryColor[800],
                };
              },
            }}
          >
             <Tooltip title={Icon===false?'Expand':'Collapse'} placement="right">
            <MenuItem
              icon={
                <MenuOpenIcon
                  sx={{
                    transform:
                      Icon === true ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                />
              }
              onClick={() => {
                collapseSidebar();
                UpdateIcon();
              }}
              style={{ top: 0, position: "sticky" }}
            >
              Collapse
            </MenuItem>
            </Tooltip>
            {LinkList.map((Links, index) => {
              if (
                Links.submenu &&
                Links.submenu !== null &&
                Links.submenu.length > 0
              ) {
                return (
                  <Submenu
                    Links={Links}
                    key={`${Links.menuCaption}${index}`}
                    level={Links.menuLevel-1}
                  />
                );
              } else if (Links.page && Links.page !== null) {
                return (
                  <MenuItems
                    Links={Links}
                    key={`${Links.menuCaption}${index}`}
                    level={Links.menuLevel-1}
                  />
                );
              }
            })}
          </Menu>
        </Sidebar>
        <main></main>
      </Box>
    </Box>
  );
}
