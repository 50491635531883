import React from "react";
import { GetResponse } from "./Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "./Axios/RequestParameters";

async function GetCityId(SelectedState) {
  console.log({ SelectedStateInGetCity: SelectedState });
  var CityOptions = [{}];
  if (SelectedState["stateId"] !== "") {
    const AllCity = await GetResponse(
      {
        cityId: 0,
        cityCode: "",
        cityName: "",
        stateName: "",
        countryname: "",
        gstcode: "",
        stateId: SelectedState.stateId,
        displayOrder: 0,
        isActive: true,
        commandId: CRUDCommands.ListAll,
        createBy: 0,
        createDate: "2022-11-01T13:00:17.426Z",
        createIp: "",
        updateBy: 0,
        updateDate: "2022-11-01T13:00:17.426Z",
        updateIp: "",
        countryId: 0,
      },
      RDSLinks.CityMaster,
      {}
    );
    if (AllCity.length > 0) {
      const newArray = AllCity.map((city) => {
        return {
          value: city.cityId,
          label: city.cityName,
          isActive: city.isActive,
          stateName: city.stateName,
          countryname: city.countryname,
        };
      });
      CityOptions = newArray;
    } else {
      CityOptions = [
        {
          label: `No City Found in ${SelectedState.stateName}`,
          value: "",
        },
      ];
    }
  }
  return CityOptions;
}

export { GetCityId };
