import { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import { grey } from "@mui/material/colors";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import { useDispatch, useSelector } from "react-redux";
import {
  CourierServiceLinks,
  RDSLinks,
  WalletLinks,
} from "../../Utils/Axios/RequestParameters";
import { Colors } from "../../Utils/Constants";
import swal from "sweetalert";
import SimpleBackDrops from "../../components/SimpleBackDrops";
import { useLocation, useNavigate } from "react-router-dom";
import matchers from "@testing-library/jest-dom/matchers";
export default function ReportDataGrid() {
  const location = useLocation();
  const Requestdata = location.state.requestData;
  const name = location.state.name;
  const Row = useSelector((state) => state.Row.value);
  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  const distributorId = AgentDetails.distributorId;
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [Loader, setLoader] = useState(false);

  const [ServiceAPI, setServiceAPI] = useState([]);
  const navigate = useNavigate();
  console.log(name);
  const URL =
    name === "Order History"
      ? CourierServiceLinks.CourierAPIReport
      : RDSLinks.APIReport;
  const getServices = async () => {
    setLoader(true);

    try {
      const Response = await GetResponse(Requestdata, URL, {});
      console.log(Response);
      if (Response.length > 0) {
        setServiceAPI(Response);
      } else {
        setServiceAPI([]);
        swal("Empty!", "No Data Found", "error");
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Data Found", "error");
      navigate(-1);
    }
    setLoader(false);
  };

  useEffect(() => {
    getServices();
  }, [Row]);

  const [columns, setColumns] = useState([
    {
      field: "",
      headerName: "",
      width: 100,
    },
  ]);

  const UpdateColumns = () => {
    if (ServiceAPI.length > 0) {
      const newColumns = Object.keys(ServiceAPI[0]).map((key) => {
        return {
          field: key,
          headerName:
            key
              .replace(/([A-Z])/g, " $1")
              .charAt(0)
              .toUpperCase() + key.replace(/([A-Z])/g, " $1").slice(1),
          width: key.length * 12,
        };
      });
      console.log(newColumns);
      setColumns(newColumns);
    }
  };

  useEffect(() => {
    UpdateColumns();
  }, [ServiceAPI]);

  return (
    <>
      <SimpleBackDrops Loader={Loader} />

      <Box
        sx={{
          height: { xs: 700, sm: 560 },
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            p: 1,
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          {name} Report
        </Typography>

        <DataGrid
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          key={Row}
          rows={ServiceAPI}
          getRowId={(row) => Math.random() * 1000}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
