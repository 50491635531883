// const IPAddress = "192.168.0.90:8080";
// const IPAddress = "182.70.120.65";
const IPAddress = "www.instacourier.in";
const CRUDCommands = {
  Add: 1,
  Update: 2,
  Delete: 3,
  ActivateDeactivate: 4,
  ListAll: 5,
  VerifyLogin: 6,
  ChangePassword: 7,
  ChangeTranPass: 8,
  UpdateProfile: 9,
  GetBalance: 10,
  RegRequest: 11,
  ConfirmRegRequest: 12,
  RejectRegRequest: 13,
  PendingRegList: 14,
  DeActive: 15,
  ActiveSearch: 16,
  DeActivateSearch: 17,
  ActiveBooking: 18,
  DeActivateBooking: 19,
  ForgotPassword: 20,
  OrderHistory: 21,
  VerifyPayment: 22,
  ProcessBooking: 23,
  RejectBooking: 24,
  Tracking: 25,
  CancellationRequest: 26,
  CancellationUpdate: 27,
  CancellationUpdatePayment: 28,
  CancelHistory: 29,
  Booking: 30,
  RejectTopUpRequest: 12,
  AddTopUpRequest: 11, //TopUpOffline.js
};
const CourierServiceserver = "courierapi";
// const RDSserver = "api";
const RDSserver = "mainapi";

const CourierServiceLinks = {
  Search: `http://${IPAddress}/${CourierServiceserver}/Search`,
  CourierAPIReport: `http://${IPAddress}/${CourierServiceserver}/Report`,
  CommissionConfiguration: `http://${IPAddress}/${CourierServiceserver}/CommissionConfiguration`,
  CourierAPI: `http://${IPAddress}/${CourierServiceserver}/CourierAPI`,
  CourierType: `http://${IPAddress}/${CourierServiceserver}/CourierType`,
  CourierServiceProvider: `http://${IPAddress}/${CourierServiceserver}/CourierServiceProvider`,
  Booking: `http://${IPAddress}/${CourierServiceserver}/Booking`,
  Order: `http://${IPAddress}/${CourierServiceserver}/Order`,
  Status: `http://${IPAddress}/${CourierServiceserver}/Status`,
  CancelOrder: `http://${IPAddress}/${CourierServiceserver}/Cancellation`,
};

const RDSLinks = {
  GetBank: `http://${IPAddress}/${RDSserver}/BankMaster`,
  CountryMaster: `http://${IPAddress}/${RDSserver}/CountryMaster`,
  CityMaster: `http://${IPAddress}/${RDSserver}/CityMaster`,
  StateMaster: `http://${IPAddress}/${RDSserver}/StateMaster`,
  MenuMaster: `http://${IPAddress}/${RDSserver}/MenuMaster`,
  PageMaster: `http://${IPAddress}/${RDSserver}/PageMaster`,
  PanelMaster: `http://${IPAddress}/${RDSserver}/PanelMaster`,
  InterfaceMaster: `http://${IPAddress}/${RDSserver}/InterfaceMaster`,
  EmailTemplateMaster: `http://${IPAddress}/${RDSserver}/EmailTemplateMaster`,
  APIReport: `http://${IPAddress}/${RDSserver}/Report`,
  MenuRight: {
    ListAll: `http://${IPAddress}/${RDSserver}/MenuRight`,
    Update: `http://${IPAddress}/${RDSserver}/MenuRight/Update`,
    GetMenuByPanelRole: `http://${IPAddress}/${RDSserver}/MenuRight/GetMenuByPanelRole`,
  },
  PaymentGateWay: `http://${IPAddress}/${RDSserver}/PaymentGateWay/GetPaymentGateWay`,
  DistributorMaster: {
    DistributorMaster: `http://${IPAddress}/${RDSserver}/DistributorMaster`,
    DistributorTypeMaster: `http://${IPAddress}/${RDSserver}/DistributorTypeMaster`,
    DistributorTypeDetailMaster: `http://${IPAddress}/${RDSserver}/DistributorTypeDetailMaster`,
    DistributorDetails: `http://${IPAddress}/${RDSserver}/DistributorMaster/DistributorDetails`,
    GetAll: `http://${IPAddress}/${RDSserver}/DistributorMaster/GetAll`,
    Create: `http://${IPAddress}/${RDSserver}/DistributorMaster/Create`,
    TestsendEmail: `http://${IPAddress}/${RDSserver}/DistributorMaster/TestsendEmail`,
    Edit: `http://${IPAddress}/${RDSserver}/DistributorMaster/Edit`,
    Delete: `http://${IPAddress}/${RDSserver}/DistributorMaster/Delete`,
    VerifyDistributor: `http://${IPAddress}/${RDSserver}/DistributorMaster/VerifyDistributor`,
    DistributorRegistration: `http://${IPAddress}/${RDSserver}/DistributorMaster/DistributorReg`,
    DistRegWithPostFile: `http://${IPAddress}/${RDSserver}/DistributorMaster/DistRegWithPostFile`,
    UpdateDistRegWithPostFile: `http://${IPAddress}/${RDSserver}/DistributorMaster/UpdateDistRegWithPostFile`,
  },
  DistributorTypeMaster: `http://${IPAddress}/${RDSserver}/DistributorTypeMaster`,
  DistributorTypeDetailMaster: `http://${IPAddress}/${RDSserver}/DistributorTypeDetailMaster`,

  GroupMaster: `http://${IPAddress}/${RDSserver}/GroupMaster`,
  GroupTypeMaster: `http://${IPAddress}/${RDSserver}/GroupTypeMaster`,
};
const UserMaster = {
  VerifyUser: `http://${IPAddress}/${RDSserver}/UserMaster/VerifyUser`,
  GetAll: `http://${IPAddress}/${RDSserver}/UserMaster/UserMaster`,
};
const Roles = {
  GetAdminRole: `http://${IPAddress}/${RDSserver}/RoleMaster`,
};

const WalletLinks = {
  Wallet: `http://${IPAddress}/${RDSserver}/Wallet`,
  GetPaymentMode: `http://${IPAddress}/${RDSserver}/Wallet/GetPaymentMode`,
  GetPaymentGateWay: `http://${IPAddress}/${RDSserver}/Wallet/GetPaymentGateWayDetails`,
  GetPGGroupType: `http://${IPAddress}/${RDSserver}/Wallet/GetPGGroupType`,
  GetDebitCreditNote: `http://${IPAddress}/${RDSserver}/Wallet/GetDebitCreditNote`,
  GetPendingTopupRequests: `http://${IPAddress}/${RDSserver}/Wallet/GetPendingTopupRequests`,
  ConfrimTopUpRequest: `http://${IPAddress}/${RDSserver}/Wallet/ConfrimTopUpRequest`,
};

const PaymentGateWayLinks = {
  GetPaymentGateWay: `http://${IPAddress}/${RDSserver}/PaymentGateWay/GetPaymentGateWayDetails`,
  GetPGGroupType: `http://${IPAddress}/${RDSserver}/PaymentGateWay/GetPGGroupType`,
};

const RequestObj = {
  Courier: {
    courierType: {
      courierTypeId: 0,
      courierTypeCode: "",
      courierTypeName: "",
      isActive: true,
      commandId: 5,
      createBy: 0,
      createDate: "2022-11-29T06:00:34.948Z",
      createIp: "",
      updateBy: 0,
      updateDate: "2022-11-29T06:00:34.948Z",
      updateIp: "",
    },
    BookingRequest: {
      orders: [
        {
          distributorId: 0,
          interfaceId: 0,
          pickupPincode: "",
          deliveryPincode: "",
          courierTypeId: 0,
          weightInKg: 0,
          lengthInCm: 0,
          widthInCm: 0,
          heightInCm: 0,
          isPrepaid: true,
          valueInRs: 0,
          typeDetId: 0,
          courierTypeName: "",
          deliveryCharge: 0,
          codcharge: 0,
          fuelCharge: 0,
          supplierGst: 0,
          totalAmount: 0,
          companyCharges: 0,
          commissionAmount: 0,
          tdsper: 0,
          tdsamount: 0,
          sgst: 0,
          cgst: 0,
          igst: 0,
          netAmount: 0,
          amount: 0,
          commissionId: 0,
          totalTranCharges: 0,
          totalPGSerTaxAmt: 0,
          apiid: 0,
          courierServiceProviderId: 0,
          courierServiceProviderName: "",
          orderId: 0,
          refNo: "",
          parcelDetails: "",
          pickupCompanyName: "",
          pickupMobileNo: "",
          pickupFirstName: "",
          pickupLastName: "",
          pickupEmail: "",
          pickupAddress1: "",
          pickupAddress2: "",
          pickupAddress3: "",
          pickupCityId: 0,
          pickupStateId: 0,
          pickupCountryId: 0,
          pickupCity: "",
          pickupState: "",
          pickupCountry: "",
          publishedTotalAmount: 0,
          deliveryCompanyName: "",
          deliveryMobileNo: "",
          deliveryFirstName: "",
          deliveryLastName: "",
          deliveryEmail: "",
          deliveryAddress1: "",
          deliveryAddress2: "",
          deliveryAddress3: "",
          deliveryCityId: 0,
          deliveryStateId: 0,
          deliveryCountryId: 0,
          deliveryCity: "",
          deliveryState: "",
          deliveryCountry: "",
          historyCommissionConfigId: 0,
          level1DistributorId: 0,
          level1CommCalculated: 0,
          level1CommTdscalculated: 0,
          level1CommNet: 0,
          level1CommIsProcessed: true,
          level1CommProcessedDateTime: "2022-12-07T08:55:36.860Z",
          level2DistributorId: 0,
          level2CommCalculated: 0,
          level2CommTdscalculated: 0,
          level2CommNet: 0,
          level2CommIsProcessed: true,
          level2CommProcessedDateTime: "2022-12-07T08:55:36.860Z",
          totalCancelRefundAmount: 0,
          totalRefundAmount: 0,
          totalAgencyCharges: 0,
          status: "",
          statusDescription: "",
          requestDateTime: "2022-12-07T08:55:36.860Z",
          bookingDateTime: "2022-12-07T08:55:36.860Z",
          pgid: 0,
          tranClientIp: "",
          serverIp: "",
          paymentGatewayPGAPILinkID: 0,
          paymentGatewayPGAPIProcessLinkID: 0,
          bookedAdminUserId: 0,
          userDistributorId: 0,
          apitransactionNo: "",
          apitrackingNumber: "",
          shipmentLabel: "",
          isPickupDone: true,
          createBy: 0,
          createDate: "2022-12-07T08:55:36.860Z",
          createIp: "",
          updateBy: 0,
          updateDate: "2022-12-07T08:55:36.860Z",
          updateIp: "",
          serviceType: "",
          apiValue: "",
          otherCharge: 0,
          serviceDays: 0,
          estimateDate: "2022-12-07T08:55:36.860Z",
          tranPwd: "",
          remark: "",
          orderDetails: [
            {
              orderDetId: 0,
              orderId: 0,
              productDesc: "",
              sku: "",
              hsn: "",
              qty: 0,
              price: 0,
              totalAmount: 0,
              createBy: 0,
              createDate: "2022-12-07T08:55:36.860Z",
              createIP: "",
              updateBy: 0,
              updateDate: "2022-12-07T08:55:36.860Z",
              updateIP: "",
            },
          ],
          orderRequest: {
            orderRequestId: 0,
            orderRequestDetID: 0,
            currentSessionID: "",
            clientRequestID: "",
            gstNo: "",
            gstEmailID: "",
            gstCompanyName: "",
            gstMobileNo: "",
            gstAddress: "",
            gstFlat: "",
            gstStreet: "",
            gstpin: "",
            gstState: "",
            gstCity: "",
            remark: "",
            bookingRequestNo: "",
            productID: 0,
          },
          orderPaymentDetail: [
            {
              orderPaymentDetID: 0,
              merchantTxnID: "",
              orderReferenceNo: "",
              invoiceNo: "",
              paymentAffiliateTranNo: "",
              paymentAffiliateDateTime: "2022-12-08T10:34:26.355Z",
              rootPNRef: "",
              rootAuthCode: "",
              isSucceed: true,
              remark: "",
              merchantID: "",
              vendor: "",
              partner: "",
              custIPAddress: "",
              respURL: "",
              respMethod: "",
              currCode: "",
              messageType: "",
              gmtOffset: "",
              ext1: "",
              ext2: "",
              ext3: "",
              ext4: "",
              ext5: "",
              amount: 0,
              isOrder: true,
              isCancel: true,
              isRefund: true,
            },
          ],
          orderCancel: [
            {
              cgst: 0,
              codChargeRefund: 0,
              commissionAmountRefund: 0,
              companyCancellationCharges: 0,
              companyChargesRefund: 0,
              couOrderCancelID: 0,
              courierServiceProviderCancellationCharges: 0,
              deliveryChargeRefund: 0,
              fuelChargeRefund: 0,
              igst: 0,
              isCancelSuccess: true,
              isComplaint: true,
              orderCancelDetId: 0,
              orderCancelId: 0,
              sgst: 0,
              status: "string",
              supplierGSTRefund: 0,
              tdsAmountRefund: 0,
              totalAmountRefund: 0,
              totalRefundAmount: 0,
              userID: 0,
            },
          ],
          tracking: [
            {
              checkpoints: [
                {
                  location: "",
                },
              ],
            },
          ],
          fromDate: "2022-12-03T08:55:36.861Z",
          toDate: "2022-12-07T08:55:36.861Z",
          statusId: 0,
        },
      ],
      errorDesc: "",
      errorCode: "",
      commandId: 20,
    },
    BookingDeliveryDetails: {},
  },
  API: {},
  wallet: {
    AddTopUpRequest: {
      commandId: 11,
      paymentAffiliateTranNo: "",
      amount: 0,
      paymentDetId: 0,
      topupRequestID: 0,
      fromDistID: 0,
      toDistID: 0,
      requestRemark: "",
      requestAmount: 0,
      paymentGatewayPGAPIProcessLinkID: 0,
      paymentGatewayPgapilinkId: 0,
      createBy: 0,
      createIP: "",
      ext1: "",
      ext2: "",
      ext3: "",
      ext4: "",
      ext5: "",
      chqDdno: "",
      cashChqDddate: "2022-11-16T04:24:59.987Z",
      bankName: "",
      bankBranch: "",
      city: "",
      state: "",
      bankId: 0,
      accountTypeID: 0,
      processCode: "",
      isPGProcessedFromAdmin: 0,
      isSuccess: true,
    },
    GetPaymentMode: {
      pgGroupTypeID: 0,
      isAutoLimitProcessing: 0,
      processCode: "RFCD",
      paymentGatewayPGAPILinkID: 0,
      typeDetID: 0,
      commandID: 1,
    },
    GetPaymentGateWay: {
      pgGroupTypeID: 0,
      isAutoLimitProcessing: 0,
      processCode: "",
      paymentGatewayPGAPILinkID: 0,
      typeDetID: 0,
    },
    GetPGGroupType: {
      pggroupTypeId: 0,
      pggroupTypeName: "",
      description: "",
      isActive: 1,
      commandId: 5,
      createBy: 0,
      createDate: "2022-11-21T11:13:41.064Z",
      createIp: "",
      updateBy: 0,
      updateDate: "2022-11-21T11:13:41.064Z",
      updateIp: "",
      isAutoLimitProcessing: true,
      pggroupTypeCode: "",
    },
    GetDebitCreditNote: {
      accCndnid: 0,
      tranDate: "2022-11-16T04:31:48.552Z",
      remark: "",
      creditDebit: 0,
      isProcessed: true,
      processedDateTime: "2022-11-16T04:31:48.552Z",
      createBy: 0,
      createDate: "2022-11-16T04:31:48.552Z",
      createIp: "",
      updateBy: 0,
      updateDate: "2022-11-16T04:31:48.552Z",
      updateIp: "",
      accountTypeId: 0,
      accountTypeCode: "",
      accountTypeName: "",
    },
    GetPendingTopupRequests: {
      fromRequestDate: "",
      toRequestDate: "",
      distributorCode: "",
      distributorName: "",
      orderReferenceNo: "",
      refillAmount: 0,
      topupRequestID: 0,
    },
    ConfrimTopUpRequest: {
      paymentAffiliateTranNo: "",
      amount: 0,
      paymentDetId: 0,
      topupRequestID: 0,
      fromDistID: 0,
      toDistID: 0,
      requestRemark: "",
      requestAmount: 0,
      paymentGatewayPGAPIProcessLinkID: 0,
      paymentGatewayPgapilinkId: 0,
      createBy: 0,
      createIP: "",
      ext1: "",
      ext2: "",
      ext3: "",
      ext4: "",
      ext5: "",
      chqDdno: "",
      cashChqDddate: "2022-11-16T04:34:24.029Z",
      bankName: "",
      bankBranch: "",
      city: "",
      state: "",
      bankId: 0,
      accountTypeID: 0,
      processCode: "",
      isPGProcessedFromAdmin: 0,
      isSuccess: true,
    },
  },
  Bank: {
    GetBank: {
      bankId: 0,
      bankType: "",
      bankCode: "",
      bankName: "",
      bankBranch: "",
      acno: "",
      add1: "",
      add2: "",
      add3: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
      bankImageUrl: "",
      bankSlipFileName: "",
      bankUrl: "",
      bankACNo: "",
      commandId: 5,
      createBy: 0,
      createDate: "2022-11-16T07:00:21.360Z",
      createIp: "",
      updateBy: 0,
      updateDate: "2022-11-16T07:00:21.360Z",
      updateIp: "",
      ifsccode: "",
      isActive: true,
      orderNo: 0,
    },
  },
  DistributorMaster: {
    DistributorTypeMaster: {
      typeId: 0,
      typeCode: "",
      typeCodeDesc: "",
      description: "",
      commandId: 5,
      createBy: 0,
      createDate: "2022-11-24T04:51:13.781Z",
      createIp: "",
      updateBy: 0,
      updateDate: "2022-11-24T04:51:13.781Z",
      updateIp: "",
      isInternal: true,
    },
    DistributorTypeDetailsMaster: {
      typeDetId: 0,
      typeId: 0,
      name: "",
      code: "",
      registrationAmount: 0,
      isPartialPaymentAllowed: true,
      isDisplayAdmin: true,
      isDisplayDistadmin: true,
      isDisplayUser: true,
      commandId: 5,
      createBy: 0,
      createDate: "2022-11-24T05:06:16.658Z",
      createIp: "",
      updateBy: 0,
      updateDate: "2022-11-24T05:06:16.658Z",
      updateIp: "",
      isCoBranded: true,
      isRechargeAll: true,
      description: "",
      typeDetailDescription: "",
      typeCode: "",
      isAllowedTran: true,
      reminderBalance: 0,
      reminderInterval: 0,
      verificationCode: "",
      isCustomer: true,
      contactUsPageDet: "",
      isActiveContactUsLink: true,
      isDisplayApicommission: true,
      applyConvenienceFeeOnRdspg: true,
      isInternal: true,
      isAllowToAddDownline: true,
    },
  },
  InterfaceMaster: {
    interfaceId: 0,
    description: "",
    authKey: "",
    interfaceVersionNo: "",
    domesticSerTaxPer: 0,
    internationalSerTaxPer: 0,
    isActive: true,
    commandId: 5,
    createdBy: 0,
    createdDate: "2022-11-24T04:47:26.408Z",
    createIp: "",
    updatedBy: 0,
    updatedDate: "2022-11-24T04:47:26.408Z",
    updateIp: "",
    ipaddressEnabled: "",
    isWhiteLabel: true,
    loginUidexpiredSec: 0,
    applyOnServiceTax: "",
    effectiveServiceTaxDate: "2022-11-24T04:47:26.408Z",
    effectiveApplyOnServiceTax: "",
    effDomesticSerTaxPer: 0,
    effInternationalSerTaxPer: 0,
    isActiveServiceTax: true,
    distributorId: 0,
    isApi: true,
    lastInterfaceVersionNo: "",
    isAllowLastVersion: true,
    sgstper: 0,
    cgstper: 0,
    igstper: 0,
    effectiveGstdate: "2022-11-24T04:47:26.408Z",
    effSgstper: 0,
    effCgstper: 0,
    effIgstper: 0,
    isActiveGst: true,
    distributorCode: "",
    tranPwd: "",
  },
  PanelMaster: {
    panelId: 0,
    panelCode: "",
    panelName: "",
    isActive: true,
    commandId: 5,
    createBy: 0,
    createDate: "2022-11-29T03:56:58.000Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-11-29T03:56:58.000Z",
    updateIp: "",
  },
  EmailTemplateMaster: {
    emailTemplateId: 0,
    templateCode: "",
    template: "",
    isActive: true,
    description: "",
    commandId: 5,
    createBy: 0,
    createDate: "2022-11-29T04:35:38.553Z",
    createIp: "",
    updateBy: 0,
    isActiveDeActive: true,
    updateDate: "2022-11-29T04:35:38.553Z",
    updateIp: "",
  },
};
export {
  CourierServiceLinks,
  RDSLinks,
  UserMaster,
  Roles,
  CRUDCommands,
  WalletLinks,
  PaymentGateWayLinks,
  RequestObj,
};
