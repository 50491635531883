import axios from "axios";
// const port = "http://192.168.0.90/"
const GetResponse = async (Data, URL, Header) => {
  try {
    console.log(Data, URL);
    const response = await axios.post(URL, Data, {
      headers: {
        ...Header,
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
export { GetResponse };
