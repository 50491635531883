import { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Typography, Fab, Grid } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import { grey } from "@mui/material/colors";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../../../Utils/Constants";
import swal from "sweetalert";
import { SignUpInputFields } from "../../../Utils/SignUpInputFields";
import { WalletLinks } from "../../../Utils/Axios/RequestParameters";
import PendingTopUpRequestActions from "./PendingTopUpRequestActions";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import DateNoFilter from "../DateNoFilter";
import { Toast } from "../../../components/Toaster";
export default function PendingTopUpOfflineDataGrid() {
  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const AgentDetails = useSelector((state) => state.AgentDetails.value);

  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState({
    fromRequestDate:moment(new Date("2022-1-1")).format("YYYY-MM-DD"),
    toRequestDate:  moment().format("YYYY-MM-DD"),
    distributorCode: "",
    distributorName: "",
    orderReferenceNo: "",
    refillAmount: 0,
    topupRequestId: 0,
    toDistId: 0,
  });

  const handleFilter = (e) => {
    e.preventDefault();
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };

  const FilterFields = [
    {
      name: "fromRequestDate",
      label: "From Date",
      type: "date",
    },
    {
      name: "toRequestDate",
      label: "To Date",
      required: true,
      type: "date",
    },
    {
      name: "topupRequestId",
      label: "Request ID",
      required: true,
      type: "text",
    },
  ];
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);
  // const fromRequestDatex=new Date(2022,10,1)
  // const toRequestDatex=new Date()
  // const [fromRequestDate, setfromRequestDate] = useState(fromRequestDatex);
  // const [toRequestDate, settoRequestDate] = useState(toRequestDatex);
  const addData = {
    topupRequestId: "",
    distributorCode: "",
    name: "",
    requestDate: "",
    requestAmount: "",
    distributorID: 1,
    paymentGatewayPGAPILinkID: 142,
    typeDetID: 10,
    accountTypeID: 1,
    orderReferenceNo: "iSureLimit9",
  };

  const FilterPendingToptopRequest = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const Requestdata = {
        fromRequestDate:
          filterData["fromRequestDate"] !== ""
            ? moment(filterData["fromRequestDate"]).format(
                "MM/DD/YYYY 00:00:01"
              )
            : moment(new Date("2022-1-1")).format("MM/DD/YYYY 00:00:01"),
        toRequestDate:
          filterData["toRequestDate"] !== ""
            ? moment(filterData["toRequestDate"]).format("MM/DD/YYYY 23:59:59")
            : moment().format("MM/DD/YYYY 23:59:59"),
        distributorCode: "",
        distributorName: "",
        orderReferenceNo: "",
        refillAmount: 0,
        toDistId: AgentDetails !== null ? AgentDetails.distributorId : 0,
        topupRequestId:
          filterData["topupRequestId"] !== null &&
          filterData["topupRequestId"] !== ""
            ? parseInt(filterData["topupRequestId"])
            : 0,
      };
      console.log({ Requestdata });
      console.log({ filterData });

      const Response = await GetResponse(
        Requestdata,
        WalletLinks.GetPendingTopupRequests,
        {}
      );
      console.log(Response);
      if (Response.length > 0) {
        setServiceAPI(Response);
      } else {
        swal("error", "No Top-Up Request Found with Selected Filter", "error");
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Top-Up Request Found", "error");
    }
    setLoader(false);
  };

  const getServices = async () => {
    setLoader(true);
    try {
      //   const Response = await axios.get(
      //     CourierServiceLinks.CourierServiceProvider.GetAll
      //   );

      const Requestdata = {
        fromRequestDate: moment(new Date("2022-1-1")).format(
          "MM/DD/YYYY 00:00:01"
        ),
        toRequestDate: moment().format("MM/DD/YYYY 23:59:59"),
        distributorCode: "",
        distributorName: "",
        orderReferenceNo: "",
        refillAmount: 0,
        toDistId: AgentDetails !== null ? AgentDetails.distributorId : 0,
        topupRequestId: 0,
      };
      const Response = await GetResponse(
        Requestdata,
        WalletLinks.GetPendingTopupRequests,
        {}
      );
      console.log(Response);
      if (Response.length > 0 && Array.isArray(Response)) {
        setServiceAPI(Response);
      } else {
        setServiceAPI([]);
        Toast.fire({
          icon: 'error',
          title: 'No Top-Up Request is Pending'
        })
        // swal("Empty!", "No Top-Up Request is Pending", "error");
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Top-Up Request Found", "error");
    }
    setLoader(false);
  };

  useEffect(() => {
    getServices();
  }, [Row]);

  const columns = useMemo(
    () => [
      {
        field: "topupRequestId",
        headerName: "Request Id",
        width: 100,
      },

      {
        field: "distributorCode",
        headerName: "Distributor Code",
        width: 150,
      },
      {
        field: "name",
        headerName: "Name",
        width: 100,
      },
      {
        field: "requestDate",
        headerName: "Requested Date",
        type: "date",
        width: 150,
        renderCell: (params) => (
          <p>{moment(params.row.requestDate).format("DD/MMM/YYYY")}</p>
        ),
      },

      {
        field: "requestAmount",
        headerName: "Requested Amount",
        width: 200,
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 220,
        renderCell: (params) => (
          <PendingTopUpRequestActions
            {...{
              params,
              rowId,
              setRowId,
              dataFields: SignUpInputFields,
              //   EditURL: CourierServiceLinks.CourierServiceProvider.Edit,
              //   DeleteURL: CourierServiceLinks.CourierServiceProvider.Delete,
              URL: WalletLinks.Wallet,
              name: "Top Up Request",
              Type: "Approve",
            }}
            key={params.row.topupRequestId}
          />
        ),
      },
    ],
    [rowId, Row]
  );

  return (
    <>
      <SimpleBackDrops Loader={Loader} />

      <Box
        sx={{
          height: 559,
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
          // backgroundColor:Colors.PrimaryBackgroundColor,
          // alignItems:'center'
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            textAlign: "center",
            p: 1,
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          Pending Top Up Request
        </Typography>
        <Box sx={{ display: "flex", width: "100%" }}>
          <DateNoFilter
            handleFilter={handleFilter}
            FilterFields={FilterFields}
            filterData={filterData}
            handleSubmit={FilterPendingToptopRequest}
          />
        </Box>

        <DataGrid
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          key={Row}
          rows={ServiceAPI}
          getRowId={(row) => row.topupRequestId}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
