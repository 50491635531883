const SignUpInputFields = [
  {
    name: "title",
    label: "Title",
    type: "Select",
    required: true,
    grid: 4,
    options: [
      { value: "Mr", label: "Mr" },
      { value: "Ms", label: "Ms" },
      { value: "Mrs", label: "Mrs" },
    ],
    value: "",
    regex: /^.{0,15}$/,
    errorMsg: "Please Select Title",
  },
  {
    name: "name",
    label: "First Name",
    type: "text",
    required: true,
    options: [],
    value: "",
    regex: /^[a-z ,.'-]+$/i,

    grid: 4,
    errorMsg: "Please Enter Correct First Name No Space",
  },
  {
    name: "lastName",
    label: "Last Name",
    required: true,
    type: "text",
    options: [],
    value: "",
    regex: /^[a-z ,.'-]+$/i,
    grid: 4,
    errorMsg: "Please Enter Correct Last Name No Space",
  },

  {
    name: "mobileNo",
    label: "Mobile No",
    type: "number",
    options: [],
    value: "",
    required: true,
    grid: 2.5,
    regex: /^(\+\d{1,3}[- ]?)?\d{10}$/,
    errorMsg: "Please Enter Correct Mobile NO ",
  },
  {
    name: "phone",
    label: "Phone No",
    type: "number",
    options: [],
    value: "",
    grid: 2.5,
    regex: /^(\+\d{1,3}[- ]?)?\d{10}$/,
    errorMsg: "Please Enter Correct Phone NO ",
  },
  {
    name: "cmpName",
    label: "Company Name",
    type: "text",
    options: [],
    value: "",
    required: true,
    grid: 4,
    regex: /^[A-Za-z]([a-zA-Z0-9]|[- @\.#&!])*$/,
    errorMsg: "Please Enter Correct Company Name",
  },

  {
    name: "dateOfBirth",
    label: "Date Of Birth",
    required: true,
    type: "date",
    options: [],
    value: "",
    grid: 3,
    errorMsg: "Single Word is allowed",
    errorMsg: "Please Select D.O.B",
  },

  {
    name: "aadharNo",
    label: "Aadhar No",
    type: "text",
    required: false,
    options: [],
    value: "",
    grid: 3,
    regex: /^\d{12}$/,
    errorMsg: "Please Enter Correct Aadhar No",
  },
  {
    name: "pan",
    label: "Pan NO ",
    type: "text",
    options: [],
    required: true,
    value: "",
    grid: 3,
    regex: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
    errorMsg: "Please Enter Correct PAN No",
  },

  {
    name: "email",
    label: "Email Id",
    type: "email",
    options: [],
    value: "",
    required: true,
    grid: 6,
    regex:
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    errorMsg: "Please Enter Correct Email Id",
  },
  {
    name: "alternateEmail",
    label: "Alternate Email ID",
    type: "email",
    options: [],
    value: "",
    grid: 6,
    regex:
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    errorMsg: "Please Enter Correct Email ID",
  },

  {
    name: "add1",
    label: "Address Line 1",
    type: "text",
    options: [],
    required: true,
    grid: 4,
    value: "",
    regex: /[A-Za-z0-9'\.\-\s\,]/,
    errorMsg: "Please Enter Correct Address",
  },
  {
    name: "add2",
    label: "Address Line 2",
    type: "text",
    options: [],
    value: "",
    grid: 4,
    regex: /[A-Za-z0-9'\.\-\s\,]/,
    errorMsg: "Please Enter Correct Address",
  },
  {
    name: "add3",
    label: "Address Line 3",
    type: "text",
    options: [],
    value: "",
    grid: 4,
    regex: /[A-Za-z0-9'\.\-\s\,]/,

    errorMsg: "Please Enter Correct Address",
  },
];

const FileInputFields = [
  {
    name: "logo",
    label: "Logo",
    type: "file",
    options: [],
    value: "",
    grid: 3,
    errorMsg: "Please Upload Company Logo",
  },
  {
    name: "pandocImg",
    label: "Pan Card",
    type: "file",
    options: [],
    value: "",
    grid: 3,
    errorMsg: "Please Upload Pan Card Img",
  },
];
export { SignUpInputFields };
