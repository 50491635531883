import { Box, Fab, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Edit } from "@mui/icons-material";
import { green, red, yellow } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { CRUDCommands, RDSLinks } from "../../../Utils/Axios/RequestParameters";
import RegisterAgentModal from "./RegisterAgentModal";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import Swal from "sweetalert2";
const AgentsAction = ({ data }) => {
  const dispatch = useDispatch();
  const [openModal, setopenModal] = useState(false);
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const Type = "Update";
  console.log(data);
  // const DeleteRow = async () => {
  //   swal({
  //     title: "Are you sure?",
  //     text: "You will not be able to recover this row data!",
  //     icon: "warning",
  //     buttons: ["No, cancel it !", "Yes, I am sure!"],
  //     dangerMode: true,
  //     timer: 5000,
  //   }).then(async function (isConfirm) {
  //     if (isConfirm) {
  //       console.log(`${DeleteURL}${RowDeleteId}`);

  //       var Data = data;

  //       Data.commandId = CRUDCommands.Delete;
  //       console.log({ Data });
  //       const Response = await GetResponse(Data, DeleteURL);
  //       console.log(Response);
  //       dispatch(UpdateRow());
  //       if (Response === true) {
  //         swal({
  //           title: "Deleted!",
  //           text: `The Selected row  is Deleted Successfully!`,
  //           icon: "success",
  //         });
  //       } else {
  //         swal({
  //           title: "Error!",
  //           text: `Some Error Occured`,
  //           icon: "error",
  //         });
  //       }
  //     } else {
  //       swal({
  //         title: "Cancelled",
  //         text: "Your Data is safe :)",
  //         icon: "error",
  //       });
  //     }
  //   });
  // };

  // useEffect(() => {
  //   if (rowId === params.id && success) setSuccess(false);
  // }, [rowId]);

  const SendEmail = async () => {
    try {
      setLoading(true);
      const response = await GetResponse(
        {},
        `${RDSLinks.DistributorMaster.TestsendEmail}?DistributorID=${data.distributorId}`,
        {}
      );
      if (response && response === true) {
        Swal.fire("success", "Email Send Successfully", "success");
      } else {
        Swal.fire("error", "some Error occurred", "error");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      {openModal === true ? (
        <RegisterAgentModal
          openModal={openModal}
          handleModalClose={handleModalClose}
          handleModalOpen={handleModalOpen}
          row={data}
          Type={"Update"}
          URL={RDSLinks.DistributorMaster.DistributorMaster}
          name={"Agent"}
        />
      ) : (
        <></>
      )}
      <SimpleBackDrops Loader={loading} />
      <Box
        sx={{
          // m: 1,
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Tooltip title="Edit" arrow>
          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
              bgcolor: green[400],
              zIndex: 5,
              "&:hover": { bgcolor: green[700] },
            }}
            onClick={() => handleModalOpen()}
          >
            <Edit />
          </Fab>
        </Tooltip>
        <Tooltip title="Email agent details" arrow>
          <Fab
            color="primary"
            sx={{
              width: 40,
              zIndex: 5,
              height: 40,
              bgcolor: red[300],
              "&:hover": { bgcolor: red[700] },
            }}
            onClick={() => SendEmail()}
          >
            <ForwardToInboxIcon />
          </Fab>
        </Tooltip>
      </Box>
    </>
  );
};

export default AgentsAction;
