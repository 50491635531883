import { Box, Fab, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Edit, Tune } from "@mui/icons-material";
import { green, red } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { CRUDCommands, RDSLinks } from "../../../Utils/Axios/RequestParameters";
import CommonAddEditModal from "../../../Admin/Components/CommonAddEditModal";
const PageActions = ({ row, EditURL }) => {
  const [openModal, setopenModal] = useState(false);
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };

 
  const [Modaltype, setModalType] = useState("Page");

  const samplePageData = {
    pageId: 0,
    menuId: row.menuId,
    pageCaption: "",
    pageUrl: "",
    isMenuDefaultPage: true,
    isMaintenance: false,
    maintenanceMsg: "",
    commandId: 0,
    createBy: 1,
    createDate: "0001-01-01T00:00:00",
    createIp: "",
    updateBy: 1,
    updateDate: "0001-01-01T00:00:00",
    updateIp: "",
  };
  const InitialError={
    pageCaption: false,
    pageUrl: false,
   
  }
  const RequiredInputs=[
    "gropageCaptionupId",
    "pageUrl",
   
  ]
  const PageDataFields = [
    {
      name: "pageCaption",
      Label: "Page Caption",
      Type: "text",
      IsError: false,
      grid:12,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      name: "pageUrl",
      Label: "Page URL",
      Type: "text",
      grid:12,
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
   
  ];
  
    
  return (
    <>
      {openModal === true ? (
        <CommonAddEditModal
          openModal={openModal}
          handleModalClose={handleModalClose}
          handleModalOpen={handleModalOpen}
          row={row}
          InitialError={InitialError}
          RequiredInputs={RequiredInputs}
          dataFields={PageDataFields}
          Type={'Update'}
          URL={RDSLinks.PageMaster}
          name={Modaltype}
        />
      ) : (
        <></>
      )}

      <Box
        sx={{
          // m: 1,
          position: "relative",
          // display: "flex",
          // justifyContent: "space-between",
          // width: "100%",
        }}
      >
        <Tooltip title="Edit" arrow>
          <Fab
            color="primary"
            sx={{
              m: 0,
              ml: 2,
              width: 40,
              height: 40,
              bgcolor: green[400],
              "&:hover": { bgcolor: green[700] },
            }}
            onClick={() => {handleModalOpen();}}
          >
            <Edit />
          </Fab>
        </Tooltip>
     
      </Box>
    </>
  );
};

export default PageActions;
