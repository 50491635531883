import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import InstaCourier from "../assets/images/InstaCourierLogo.png";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Colors } from "../Utils/Constants";
import { Balance } from "@mui/icons-material";
import { Link, NavLink } from "react-router-dom";
import { Stack, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { NavigationLinks } from "../router/NavigationLinks";
import { ClearAgentToken } from "../features/AgentTokenSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PasswordIcon from "@mui/icons-material/Password";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import {
  ClearAgentDetails,
  UpdateDetails,
} from "../features/AgentDetailsSlice";
import { GetResponse } from "../Utils/Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "../Utils/Axios/RequestParameters";
import moment from "moment";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function LoggedInNavbar({ HandleDrawer }) {
  const [loading, setloading] = React.useState(false);
  const [success, setsuccess] = React.useState(false);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  const Token = useSelector((state) => state.AgentToken.value);

  console.log({ AgentDetails });
  const SelectedAccount = AgentDetails.accountType[0];
  const WalletType1 = SelectedAccount.accountTypeName;
  const CreditBalance = parseFloat(
    SelectedAccount.limitBalance +
      SelectedAccount.transactionCredit -
      SelectedAccount.tranCreditUtilized -
      SelectedAccount.holdAmt
  ).toFixed(3);

  // const SelectedAccount2 = AgentDetails.accountType[1];
  // const WalletType2 = SelectedAccount2.accountTypeName;
  // const CashBalance = parseFloat(
  //   SelectedAccount2.limitBalance +
  //     SelectedAccount2.transactionCredit -
  //     SelectedAccount2.tranCreditUtilized -
  //     SelectedAccount2.holdAmt
  // ).toFixed(3);
  const ProfileLinkList = [
    {
      Name: "Update Profile",
      Icon: AccountCircle,
      Link: NavigationLinks.UpdateProfile,
    },
    {
      Name: "Update Password",
      Icon: PasswordIcon,
      Link: NavigationLinks.UpdatePassword,
    },
    {
      Name: "Update Transaction Password",
      Icon: PasswordIcon,
      Link: NavigationLinks.UpdateTnxPassword,
    },
  ];
  const BadgesLinkList = [
    {
      Name: `${WalletType1} Balance : ₹ ${CreditBalance} 
     
        `,
      Icon: AccountBalanceWalletIcon,
      badgeContent: 0,
      Link: "/TopUpOffline",
    },
    // {
    //   Name: "Mail",
    //   Icon: MailIcon,
    //   badgeContent:5,
    //   Link:"/",
    // },
    // {
    //   Name: "Notifications",
    //   Icon: NotificationsIcon,
    //   Link:"/",
    // },
  ];

  const AgentName = AgentDetails.name;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(ClearAgentToken());
    dispatch(ClearAgentDetails());
    sessionStorage.removeItem("AgentToken");
    sessionStorage.removeItem("AgentDetails");

    navigate("/");
  };

  const updateAgentDetails = async () => {
    setloading(true);
    try {
      const Data = {
        token: Token,
        typeDetId: 0,
        name: "",
        distributorCode: "",
        cmpName: "",
        add1: "",
        add2: "",
        add3: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        phone: "",
        mobile: "",
        fax: "",
        pan: "",
        email: "",
        tranPwd: "",
        dateOfBirth: "2022-11-26T09:26:28.086Z",
        distributorLevel: 0,
        level1DistributorId: 0,
        level2DistributorId: 0,
        joiningDate: "2022-11-26T09:26:28.086Z",
        padd1: "",
        padd2: "",
        padd3: "",
        pcity: "",
        pstate: "",
        pcountry: "",
        ppincode: "",
        pphone: "",
        pmobile: "",
        pfax: "",
        isCustomer: true,
        isActive: true,
        activationRemark: "",
        commandId:CRUDCommands.ListAll,
        createBy: 0,
        createDate: "2022-11-26T09:26:28.086Z",
        createIp: "",
        updateBy: 0,
        updateDate: "2022-11-26T09:26:28.086Z",
        updateIp: "",
        gender: "",
        isPaid: true,
        isProfileUpdated: true,
        invoiceNo: 0,
        paidDateTime: "2022-11-26T09:26:28.086Z",
        paidAmount: 0,
        isForm60: true,
        registrationAmount: 0,
        maintenanceChargeDetId: 0,
        isGenerateComm: true,
        isTranPwdUpdated: true,
        isTranAllowed: true,
        tranAllowedRemark: "",
        maritalStatus: "",
        occupation: "",
        activationDate: "2022-11-26T09:26:28.086Z",
        isEmailSmssent: true,
        isFirstLogin: true,
        logoImageUrl: "",
        isLogoDisplay: true,
        masterDistributorId: 0,
        userSeqNo: 0,
        loginName: "",
        pwd: "",
        isAllowLimitAllocationToDownline: true,
        alternateEmail: "",
        pandocImg: "",
        userRemark: "",
        isSendBalanceReminder: true,
        reminderBalance: 0,
        reminderInterval: 0,
        isSendReminderEmail: true,
        isSendReminderSms: true,
        isSerTaxOnSerCharge: true,
        isAllowToAddDownline: true,
        isTranPwdMendate: true,
        anniversaryDate: "2022-11-26T09:26:28.086Z",
        isSendDobintimationToCompany: true,
        title: "",
        lastName: "",
        designation: "",
        labelCaption: "",
        isSendPromotionalEmail: true,
        distributorUserAccessTimeId: 0,
        activenessCategoryId: 0,
        visitingCardReceiptDate: "2022-11-26T09:26:28.086Z",
        aadharNo: "",
        gstcompanyName: "",
        gstno: "",
        gstphone: "",
        gstemail: "",
        gstaddress: "",
        gstpincode: "",
        gststate: "",
        gstcity: "",
        countryCode: "",
        logo: "",
        userDistributorID: AgentDetails.distributorId,
        distributorId: AgentDetails.distributorId,
        limitBalance: 0,
        creditAllowed: 0,
        level1DistributorCode: "",
        transactionCredit: 0,
        tranCreditUtilized: 0,
        salesPersonId: 0,
        verificationCode: "",
        distributorGroupConfiguration: "",
        holdAmt: 0,
        countryLastUpdated: "2022-11-26T09:26:28.087Z",
        masterDistributorName: "",
        masterDistributorCode: "",
        bankLastUpdated: "2022-11-26T09:26:28.087Z",
        stateLastUpdated: "2022-11-26T09:26:28.087Z",
        cityLastUpdated: "2022-11-26T09:26:28.087Z",
        rewardPointBalance: 0,
        taxExemptionRate: 0,
        taxExemptionAmt: 0,
        taxExemptionCertImg: "",
        isDisplayApicommission: true,
        applyConvenienceFeeOnRdspg: true,
        distributorTaxID: 0,
        temporaryCredit: 0,
        cashBackBalance: 0,
        accountBalance: 0,
        effTaxExemptionAmt: 0,
        effTaxExemptionRate: 0,
        categoryName: "",
        description: "",
        typeDetailName: "",
        typeCode: "",
        code: "",
        typeMasterName: "",
        isTdsexemptionCertificateProvided: true,
        isRechargeAll: true,
        isCoBranded: true,
        effTaxExemptionDate: "2022-11-26T09:26:28.087Z",
        fromJoiningDate: moment(new Date("2022-1-1")).format(
          "MM/DD/YYYY 23:59:59"
        ),
        toJoiningDate: moment().format("MM/DD/YYYY 23:59:59"),
        accountType: [
          {
            distributorId: AgentDetails.distributorId,
            accountBalance: 0,
            limitBalance: 0,
            creditAllowed: 0,
            transactionCredit: 0,
            tranCreditUtilized: 0,
            cashBackBalance: 0,
            distAccBalance: 0,
            holdAmt: 0,
            temporaryCredit: 0,
            accountdistributorId: 0,
            accountTypeCode: "",
            accountTypeName: "",
          },
        ],
      };
      const Response = await GetResponse(
        Data,
        RDSLinks.DistributorMaster.DistributorMaster,
        {}
      );
      console.log({ UpdatedProfileResponse: Response[0] });
      if (Object.keys(Response[0]).length > 5) {
        dispatch(UpdateDetails(Response[0]));
        sessionStorage.setItem("AgentDetails", JSON.stringify(Response[0]));
      }
    } catch (error) {
      setloading(false);
    }

    setloading(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {ProfileLinkList.map((Links) => (
        <MenuItem key={Links.Name} onClick={handleMenuClose}>
          <NavLink
            key={Links.Name}
            style={({ isActive }) => ({
              textDecoration: "none",
              marginTop: 2,
              marginBottom: 2,
              color: "white",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              ...(isActive
                ? { color: Colors.PrimaryColor[800] }
                : { color: Colors.SecondaryColor[700] }),
            })}
            to={Links.Link}
          >
            <Typography textAlign="center">{Links.Name}</Typography>
          </NavLink>
        </MenuItem>
      ))}
      <MenuItem onClick={LogOut}>
        <Link
          to={NavigationLinks.HomePage}
          style={{ textDecoration: "none", color: Colors.PrimaryColor[800] }}
        >
          Logout
        </Link>
      </MenuItem>

      {/* <MenuItem >Downlines</MenuItem> */}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {BadgesLinkList.map((Links) => (
        <MenuItem key={Links.Name} onClick={handleMobileMenuClose}>
          <NavLink
            key={Links.Name}
            style={({ isActive }) => ({
              textDecoration: "none",
              marginTop: 2,
              marginBottom: 2,
              color: "white",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              ...(isActive
                ? { color: Colors.PrimaryColor[800] }
                : { color: Colors.SecondaryColor[700] }),
            })}
            to={Links.Link}
          >
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="warning"
            >
              <Badge badgeContent={Links.badgeContent} color="primary">
                <Links.Icon />
              </Badge>
            </IconButton>
            <p>{Links.Name}</p>
          </NavLink>
        </MenuItem>
      ))}

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="warning"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "sticky",
        top: 1,
        backgroundColor: "white",
        zIndex: 1200,
      }}
    >
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: "white",
          position: "sticky",
          top: 0,
          zIndex: 1200,
        }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => HandleDrawer()}
              color="inherit"
            >
              <MenuIcon sx={{ color: Colors.PrimaryColor[800] }} />
            </IconButton>
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="warning"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <img src={InstaCourier} alt="logo" width={150} />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {BadgesLinkList.map((Links) => (
              <NavLink
                key={Links.Name}
                style={({ isActive }) => ({
                  textDecoration: "none",
                  marginTop: 2,
                  marginBottom: 2,
                  color: "white",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  ...(isActive
                    ? { color: Colors.PrimaryColor[800] }
                    : { color: Colors.SecondaryColor[700] }),
                })}
                to={Links.Link}
              >
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="warning"
                >
                  <Badge badgeContent={Links.badgeContent} color="primary">
                    <Links.Icon />
                  </Badge>
                </IconButton>
                <p>{Links.Name}</p>
              </NavLink>
            ))}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ m: 1, position: "relative" }}>
                <Tooltip title="Refresh Balance">
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    sx={{
                      ...buttonSx,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={updateAgentDetails}
                    color="warning"
                  >
                    <CloudSyncIcon />
                  </IconButton>
                </Tooltip>
                {loading && (
                  <CircularProgress
                    size={32}
                    sx={{
                      color: green[500],
                      position: "absolute",

                      top: 10,
                      left: 10,
                      zIndex: 1,
                    }}
                  />
                )}
              </Box>
            </Box>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="warning"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex",
                color: Colors.PrimaryColor[800],
              },
            }}
          >
            <Typography sx={{ color: Colors.PrimaryColor[800] }}>
              Hello! {AgentName}
            </Typography>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="warning"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
