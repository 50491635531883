import React from "react";
import { useEffect, useMemo, useState } from "react";
import { Box, Typography, Fab } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CRUDCommands, RDSLinks, RequestObj } from "../../../Utils/Axios/RequestParameters";
import { Colors } from "../../../Utils/Constants";
import ActiveDeactive from "../ActiveDeactive";
import { Roles } from "../../../Utils/Axios/RequestParameters";
import swal from "sweetalert";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import UserRoleActions from "./RoleActions";
import CommonAddEditModal from "../CommonAddEditModal";
export default function UserRolesDataGrid() {
  const [PanelOptions, setPanelOPtions] = useState(["pleasewait"]);
  const [PanelOptionsObj, setPanelOPtionsObj] = useState(["pleasewait"]);
  const [SelectedPanel, setSelectedPanel] = React.useState("Admin");
  const [inputSelectedPanel, setInputSelectedPanel] = React.useState("");
  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);

  const [Loader, setLoader] = useState(false);
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };

  const [ServiceAPI, setServiceAPI] = useState([]);

  const addData = {
    roleId: 0,
    roleName: "",
    roleDesc: "",
    isActive: true,
    commandId:CRUDCommands.ListAll,
    createBy: 0,
    createDate: "2022-11-08T06:46:46.789Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-11-08T06:46:46.789Z",
    updateIp: "",
    panelId: 0,
  };

  const InitialError = {
    roleName: false,
    roleDesc: false,
  };
  const RequiredInputs = ["roleName", "roleDesc"];

  const dataFields = [
    {
      name: "roleName",
      Label: "Role Name",
      Type: "text",
      IsError: false,
      ErrorMsg: "Enter Role Name",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Role Description",
      name: "roleDesc",
      Type: "text",
      IsError: false,
      ErrorMsg: "Enter Role Description",

      validReg: /[^\n]+/i,
      options: [],
    },
  ];
  const getServices = async () => {
    try {
      if (PanelOptions.length < 2) {
        const Options = await GetResponse(
          RequestObj.PanelMaster,
          RDSLinks.PanelMaster,
          {}
        );
        setPanelOPtions(() => Options.map((Opt) => Opt.panelName));
        setPanelOPtionsObj(Options);
      }

      if (PanelOptions.length > 1) {
        console.log(PanelOptions, PanelOptionsObj, SelectedPanel);
        const SelectedPanelIdObj = PanelOptionsObj.filter(
          (opt) => opt.panelName === SelectedPanel
        );
        addData.panelId = SelectedPanelIdObj[0].panelId;
      }
      // const Response = await axios.get(Roles.GetAdminRole);
      console.log(addData);
      const Response = await GetResponse(addData, Roles.GetAdminRole, {});
      console.log(Response);
      if (Response.length > 0) {
        setServiceAPI(Response);
      } else {
        swal("Error", "No Records Found", "error");
      }
    } catch (error) {
      console.log(error);
      swal("Error", "No Records Found", "error");
    }
  };

  useEffect(() => {
    getServices();
  }, [Row, SelectedPanel]);

  const columns = useMemo(
    () => [
      { field: "roleName", headerName: "Name", width: 200 },

      { field: "roleDesc", headerName: "Description", width: 200 },

      {
        field: "isActive",
        headerName: "isActive",
        width: 100,
        type: "boolean",
        renderCell: (params) => (
          <ActiveDeactive
            {...{
              UpdateURL: Roles.GetAdminRole,
              activeName: "isActive",
              SelectedFieldName: "this User Role",
              row: params.row,
            }}
          />
        ),
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 200,
        renderCell: (params) => (
          <UserRoleActions
            {...{
              params,
              rowId,
              setRowId,
              dataFields,
              InitialError,
              RequiredInputs,
              EditURL: Roles.GetAdminRole,
              DeleteURL: Roles.GetAdminRole,
              RowDeleteId: params.row.roleId,
              name: "Role Management",
            }}
          />
        ),
      },
    ],
    [rowId]
  );

  return (
    <>
      <SimpleBackDrops Loader={Loader} />

      {openModal && (
        <CommonAddEditModal
          openModal={openModal}
          handleModalClose={handleModalClose}
          handleModalOpen={handleModalOpen}
          URL={Roles.GetAdminRole}
          row={addData}
          InitialError={InitialError}
          RequiredInputs={RequiredInputs}
          dataFields={dataFields}
          Type={"Add"}
          name={"Role"}
        />
      )}

      <Box
        sx={{
          height: 559,
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            textAlign: "center",
            p: 1,
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          Role Management
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            p: 2,
          }}
        >
          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
              ml: 5,
              mt: 1,
              mr: 5,
              bgcolor: green[500],
              "&:hover": { bgcolor: green[700] },
            }}
            onClick={handleModalOpen}
          >
            <Add />
          </Fab>
          <Autocomplete
            value={SelectedPanel}
            onChange={(event, newValue) => {
              setSelectedPanel(newValue);
            }}
            inputValue={inputSelectedPanel}
            onInputChange={(event, newInputValue) => {
              setInputSelectedPanel(newInputValue);
            }}
            id="controllable-states-demo"
            options={PanelOptions}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Select Panel" />
            )}
          />
        </Box>

        <DataGrid
          key={Row}
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          rows={ServiceAPI}
          getRowId={(row) => row.roleId}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
