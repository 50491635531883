import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import HomeNavbar from "./HomeNavbar";
import StateCityCountry from "../Admin/Components/StateCityCountry";
import { useState } from "react";
import GSTInputs from "./GSTInputs";

function Copyright(props) {
  const { handleSubmit } = props;
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link to="/">Courier Service</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp(props) {
  const {
    handleSubmit,
    handleChange,
    data,
    AllError,
    SignUpInputFields,
    Render,
    name,
  } = props;

const [EnterGST,setEnterGST]=useState(false)
  return (
    <ThemeProvider theme={theme}>
      {name === "Sign Up" ? <HomeNavbar /> : <></>}
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {name}
          </Typography>
          <Box
            component="form"
            validate="true"
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
            // key={Render}
          >
            <Grid container spacing={2}>
              {SignUpInputFields.map((Fields, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={3}
                    key={`${Fields.name}${index}`}
                  >
                    <TextField
                      //  error={true}
                      autoFocus={Fields.autoFocus}
                      autoComplete="given-name"
                      type={Fields.type}
                      name={Fields.name}
                      value={data[Fields.name]}
                      error={AllError[Fields.name]}
                      helperText={AllError[Fields.name] ? Fields.errorMsg : ""}
                      select={
                        Fields.options.length > 0 || Fields.type === "Select"
                          ? true
                          : false
                      }
                      required={Fields.required}
                      fullWidth
                      id={Fields.name}
                      label={Fields.label}
                      InputLabelProps={
                        Fields.type === "file" || Fields.type === "date"
                          ? { shrink: true }
                          : false
                      }
                      onChange={(e) => handleChange(e, Fields.regex)}
                    >
                      {Fields.options.length > 0 ? (
                        Fields.options.map((option) => (
                          <MenuItem
                            key={`${option.label}`}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </TextField>
                  </Grid>
                );
              })}
              <StateCityCountry
                state={data.state}
                city={data.city}
                country={data.country}
                pincode={data.pincode}
                ValidateInput={handleChange}
                grid={3}
              />


{EnterGST ? (
                    <GSTInputs
                      gstCompanyValue={data.gstcompanyName}
                      gstnovalue={data.gstno}
                      gstphoneValue={data.gstphone}
                      gstemailValue={data.gstemail}
                      gstaddressValue={data.gstaddress}
                      gststateValue={data.gststate}
                      gstcityValue={data.gstcity}
                      gstpincodeValue={data.gstpincode}
                      gstcompanyError={AllError["gstcompanyName"]}
                      gstnoError={AllError["gstno"]}
                      gstphoneError={AllError["gstphone"]}
                      gstemailError={AllError["gstemail"]}
                      gstaddressError={AllError["gstaddress"]}
                      gstpincodeError={AllError["gstpincode"]}
                      ValidateInput={handleChange}
                      grid={3}
                    />
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={EnterGST}
                            onChange={(e) => setEnterGST(e.target.checked)}
                          />
                        }
                        label="Enter GST Details"
                      />
                    </Grid>
                  )}
            </Grid>

            <Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {name}
                </Button>
              </Grid>
              {name === "Sign Up" ? (
                <Grid item xs={12} sm={6} lg={4}>
                  <Link to="/" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Box>
        </Box>
        {name === "Sign Up" ? <Copyright sx={{ mt: 5 }} /> : ""}
      </Container>
    </ThemeProvider>
  );
}
