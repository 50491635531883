import moment from "moment";
import { GetResponse } from "./Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "./Axios/RequestParameters";

const getAgentCode=async()=>{
    const addData = {
        token: "",
        distributorId: 0,
        typeDetId: 0,
        name: "",
        distributorCode: "",
        cmpName: "",
        add1: "",
        add2: "",
        add3: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
        phone: "",
        mobile: "",
        fax: "",
        pan: "",
        email: "",
        tranPwd: "",
        dateOfBirth: "",
        distributorLevel: 0,
        level1DistributorId:0,
        level2DistributorId: 0,
        joiningDate: "2022-11-26T09:26:28.086Z",
        padd1: "",
        padd2: "",
        padd3: "",
        pcity: "",
        pstate: "",
        pcountry: "",
        ppincode: "",
        pphone: "",
        pmobile: "",
        pfax: "",
        isCustomer: true,
        isActive: true,
        activationRemark: "",
        commandId:CRUDCommands.ListAll,
        createBy: 0,
        createDate: "2022-11-26T09:26:28.086Z",
        createIp: "",
        updateBy: 0,
        updateDate: "2022-11-26T09:26:28.086Z",
        updateIp: "",
        gender: "",
        isPaid: true,
        isProfileUpdated: true,
        invoiceNo: 0,
        paidDateTime: "2022-11-26T09:26:28.086Z",
        paidAmount: 0,
        isForm60: true,
        registrationAmount: 0,
        maintenanceChargeDetId: 0,
        isGenerateComm: true,
        isTranPwdUpdated: true,
        isTranAllowed: true,
        tranAllowedRemark: "",
        maritalStatus: "",
        occupation: "",
        activationDate: "2022-11-26T09:26:28.086Z",
        isEmailSmssent: true,
        isFirstLogin: true,
        logoImageUrl: "",
        isLogoDisplay: true,
        masterDistributorId: 0,
        userSeqNo: 0,
        loginName: "",
        pwd: "",
        isAllowLimitAllocationToDownline: true,
        alternateEmail: "",
        pandocImg: "",
        userRemark: "",
        isSendBalanceReminder: true,
        reminderBalance: 0,
        reminderInterval: 0,
        isSendReminderEmail: true,
        isSendReminderSms: true,
        isSerTaxOnSerCharge: true,
        isAllowToAddDownline: true,
        isTranPwdMendate: true,
        anniversaryDate: "2022-11-26T09:26:28.086Z",
        isSendDobintimationToCompany: true,
        title: "",
        lastName: "",
        designation: "",
        labelCaption: "",
        isSendPromotionalEmail: true,
        distributorUserAccessTimeId: 0,
        activenessCategoryId: 0,
        visitingCardReceiptDate: "2022-11-26T09:26:28.086Z",
        aadharNo: "",
        gstcompanyName: "",
        gstno: "",
        gstphone: "",
        gstemail: "",
        gstaddress: "",
        gstpincode: "",
        gststate: "",
        gstcity: "",
        countryCode: "",
        logo: "",
        userDistributorID: 0,
        distributorId: 0,
        limitBalance: 0,
        creditAllowed: 0,
        level1DistributorCode: "",
        transactionCredit: 0,
        tranCreditUtilized: 0,
        salesPersonId: 0,
        verificationCode: "",
        distributorGroupConfiguration: "",
        holdAmt: 0,
        countryLastUpdated: "2022-11-26T09:26:28.087Z",
        masterDistributorName: "",
        masterDistributorCode: "",
        bankLastUpdated: "2022-11-26T09:26:28.087Z",
        stateLastUpdated: "2022-11-26T09:26:28.087Z",
        cityLastUpdated: "2022-11-26T09:26:28.087Z",
        rewardPointBalance: 0,
        taxExemptionRate: 0,
        taxExemptionAmt: 0,
        taxExemptionCertImg: "",
        isDisplayApicommission: true,
        applyConvenienceFeeOnRdspg: true,
        distributorTaxID: 0,
        temporaryCredit: 0,
        cashBackBalance: 0,
        accountBalance: 0,
        effTaxExemptionAmt: 0,
        effTaxExemptionRate: 0,
        categoryName: "",
        description: "",
        typeDetailName: "",
        typeCode: "",
        code: "",
        typeMasterName: "",
        isTdsexemptionCertificateProvided: true,
        isRechargeAll: true,
        isCoBranded: true,
        effTaxExemptionDate: "2022-11-26T09:26:28.087Z",
        fromJoiningDate: moment(new Date("2022-1-1")).format("MM/DD/YYYY 23:59:59"),
        toJoiningDate: moment().format("MM/DD/YYYY 23:59:59"),
        accountType: [
          {
            distributorId: 0,
            accountBalance: 0,
            limitBalance: 0,
            creditAllowed: 0,
            transactionCredit: 0,
            tranCreditUtilized: 0,
            cashBackBalance: 0,
            distAccBalance: 0,
            holdAmt: 0,
            temporaryCredit: 0,
            accountdistributorId: 0,
            accountTypeCode: "",
            accountTypeName: "",
          },
        ],
      };

        try {
          //   const Response = await axios.get(CourierServiceLinks.CourierType.GetAll);
          const RequestData= {...addData,dateOfBirth:'01/01/1990'}
          const Response = await GetResponse(
            RequestData,
            RDSLinks.DistributorMaster.DistributorMaster,
            {}
          );
          console.log(Response);
          if (Response.length > 0 && Array.isArray(Response)) {
           const AgentCodeArray=   Response.map((Agents)=>{return{value:Agents.distributorId,label:Agents.distributorCode}})
            console.log(AgentCodeArray)
            
           return AgentCodeArray
          }else{
            return [{value:0,label:'No Agents found'}]
          }
        } catch (error) {
          console.log(error);
        }
}
export {getAgentCode}