import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import InstaCourier from "../../assets/images/InstaCourierLogo.png"; //"../../assets/images/InstaCourierLogo.png";
import { Colors } from "../../Utils/Constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavigationLinks } from "../../router/NavigationLinks";
import { ClearSiteMGMTToken } from "../../features/SiteMGMTTokenSlice";
export default function SiteManagementNavbar({ HandleDrawer }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(ClearSiteMGMTToken());
    sessionStorage.removeItem("SiteMGMTToken");
    navigate("/SiteMGMT");
  };
  return (
    <Box
      sx={{
        top: 0,
        flexGrow: 1,
        bgcolor: "white",
        zIndex: 1200,
        position: "sticky",
        color: Colors.PrimaryColor[800],
      }}
    >
      <AppBar
        sx={{
          top: 0,
          bgcolor: "white",
          color: Colors.PrimaryColor[800],
          position: "fixed",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "flex",
                sm: "none",
                color: Colors.PrimaryColor[800],
              },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => HandleDrawer()}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="warning"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <img src={InstaCourier} alt="logo" width={140} />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            Site Management
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "block", sm: "none" } }}
          ></Typography>
          <Button color="inherit" onClick={LogOut}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
