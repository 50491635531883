import * as React from "react";
import {
  Grid,
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  TextField,
  MenuItem,
  CssBaseline,
  Container,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
  Skeleton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import CloseIcon from "@mui/icons-material/Close";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import swal from "sweetalert";
import { Colors } from "../../../Utils/Constants";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {
  CourierServiceLinks,
  CRUDCommands,
} from "../../../Utils/Axios/RequestParameters";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useState } from "react";
import moment from "moment";
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  paddingTop: 1,
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", md: "90%", lg: "90%" },
  height: { xs: 700, sm: 600 },
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  pb: 0,
  p: 0,
  m: 0,
};
// Type = Edit or ADD
const ProdItemStyle = {
  padding: "5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
};
export default function CourierDetailModal({
  OrderData,
  openDetailModal,
  handleDetailModalClose,
}) {
  function createShippingData(Details, PickupDetails, DeliveryDetails) {
    return { Details, PickupDetails, DeliveryDetails };
  }

  const ShippingDetails = [
    createShippingData(
      "Company Name",
      OrderData.pickupCompanyName,
      OrderData.deliveryCompanyName
    ),
    createShippingData(
      "Mobile No",
      OrderData.pickupMobileNo,
      OrderData.deliveryMobileNo
    ),
    createShippingData(
      "Name",
      `${OrderData.pickupFirstName} ${OrderData.pickupLastName}`,
      `${OrderData.deliveryFirstName} ${OrderData.deliveryLastName}`
    ),

    createShippingData("Email", OrderData.pickupEmail, OrderData.deliveryEmail),
    createShippingData(
      "Address",
      `${OrderData.pickupAddress1},${OrderData.pickupAddress2},${OrderData.pickupAddress3}`,
      `${OrderData.deliveryAddress1},${OrderData.deliveryAddress2},${OrderData.deliveryAddress3}`
    ),
    createShippingData("City", OrderData.pickupCity, OrderData.deliveryCity),
    createShippingData("State", OrderData.pickupState, OrderData.deliveryState),
    createShippingData(
      "Country",
      OrderData.pickupCountry,
      OrderData.deliveryCountry
    ),
    createShippingData(
      "Pincode",
      OrderData.pickupPincode,
      OrderData.deliveryPincode
    ),
  ];

  const ProductDetails = OrderData.orderDetails.map((order, index) => {
    return {
      SrNO: index + 1,
      productDesc: order.productDesc,
      sku: order.sku,

      hsn: order.hsn,

      qty: order.qty,

      price: order.price,

      totalAmount: order.totalAmount,
    };
  });

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDetailModal}
        onClose={() => {
          handleDetailModalClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDetailModal}>
          <Container component="main" maxWidth="lg" sx={styles}>
            {/* <CssBaseline /> */}
            <Box
              sx={{
                width: { xs: "90%", sm: "100%" },
                height: 70,
                backgroundColor: Colors.PrimaryBackgroundColor[400],
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: 2,
                paddingRight: 2,
                marginTop: 3,
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: { xs: 15, sm: 25 },
                }}
              >
                {" "}
                Shipment Details{" "}
              </Typography>
              <Tooltip title="close">
                <IconButton
                  onClick={() => handleDetailModalClose()}
                  color="error"
                  aria-label="close"
                >
                  <CloseIcon color="white" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                margin: { xs: 1, sm: 2 },
                padding: { xs: 1, sm: 2 },

                overflow: "auto",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={6}>
                  Booking Date :{" "}
                  {new Date(OrderData.bookingDateTime).toUTCString()}
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  Reference No : {OrderData.refNo}
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  Status : {OrderData.statusDescription}
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  Provider : {OrderData.courierServiceProviderName}
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                elevation={0}
                component={Paper}
                style={{
                  margin: "10px 10px 2px 2px",
                  width: "98%",
                  backgroundColor: "whitesmoke",
                  padding: "2px",
                }}
              >
                <Grid item xs={6} sm={4} lg={2}>
                  <Box style={ProdItemStyle}>{OrderData.courierTypeName}</Box>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <Box style={ProdItemStyle}>
                    {" "}
                    weight : {OrderData.weightInKg} Kg
                  </Box>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <Box style={ProdItemStyle}>
                    Length : {OrderData.lengthInCm} cm
                  </Box>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <Box style={ProdItemStyle}>
                    Width : {OrderData.widthInCm} cm
                  </Box>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <Box style={ProdItemStyle}>
                    {" "}
                    height : {OrderData.heightInCm} cm
                  </Box>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                  <Box style={ProdItemStyle}>
                    Value : {OrderData.valueInRs} Rs
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ Padding: 2, paddingTop: 5 }}>
                <Grid item xs={12} sm={12} lg={6}>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ maxWidth: 550 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", fontSize: 16 }}
                            align="center"
                            colSpan={3}
                          >
                            Shipping Details
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Details
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            Pickup Details
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            Delivery Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ShippingDetails.map((row) => (
                          <TableRow
                            key={row.Details}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            align="left"
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                fontWeight: "bold",
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                              }}
                              width={100}
                            >
                              {row.Details}
                            </TableCell>
                            <TableCell
                              align="left"
                              width={100}
                              style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                              }}
                            >
                              {row.PickupDetails}
                            </TableCell>
                            <TableCell
                              align="left"
                              width={100}
                              style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                              }}
                            >
                              {row.DeliveryDetails}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <TableContainer component={Paper}>
                    <Table
                      // sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: "bold", fontSize: 16 }}
                            align="center"
                            colSpan={8}
                          >
                            Product Details
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: "bold" }}>
                            SrNo
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                            colSpan={2}
                          >
                            Product Desc
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            SKU
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            HSN
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            QTY
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            Price
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            Total
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ProductDetails.map((row) => (
                          <TableRow
                            key={row.SrNo}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            align="left"
                          >
                            <TableCell component="th" scope="row">
                              {row.SrNo}
                            </TableCell>
                            <TableCell
                              scope="row"
                              colSpan={2}
                              style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                              }}
                            >
                              {row.productDesc}
                            </TableCell>
                            <TableCell align="left">{row.sku}</TableCell>
                            <TableCell align="left">{row.hsn}</TableCell>
                            <TableCell align="left">{row.qty}</TableCell>
                            <TableCell align="left">{row.price}</TableCell>
                            <TableCell align="left">
                              {row.totalAmount}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Fade>
      </Modal>
    </>
  );
}
