import { GetResponse } from "./Axios/GetResponse";
import {
  RequestObj,
  WalletLinks,
  PaymentGateWayLinks,
} from "./Axios/RequestParameters";

const GetPaymentMode = async () => {
  try {
    const response = await GetResponse(
      RequestObj.wallet.GetPaymentMode,
      PaymentGateWayLinks.GetPaymentGateWay,
      {}
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};
export { GetPaymentMode };
