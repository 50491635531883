import { Box, Chip, Fab, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import { green, red, grey, orange } from "@mui/material/colors";
import { useSelector, useDispatch } from "react-redux";
import TopUpOfflineProcessModal from "./TopUpOfflineProcessModal";
import Swal from "sweetalert2";
import { CRUDCommands, WalletLinks } from "../../../Utils/Axios/RequestParameters";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import { UpdateRow } from "../../../features/RowSlice";
import moment from "moment";
const PendingTopUpRequestActions = ({
  params,
  rowId,
  name,
  Type,
  URL,
  dataFields,
}) => {
  const data = params.row;
  const dispatch = useDispatch();
  const [openModal, setopenModal] = useState(false);
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (rowId === params.id && success) setSuccess(false);
  }, [rowId]);

  var RejectTopUpRequestData = {
    commandId: CRUDCommands.RejectTopUpRequest,
    paymentAffiliateTranNo: "",
    amount: data["requestAmount"],
    paymentDetId: data["paymentDetId"],
    topupRequestID: data["topupRequestId"],
    fromDistID: data["fromDistId"],
    toDistID: data["toDistId"],
    requestRemark: "",
    requestAmount: data["requestAmount"],
    paymentGatewayPGAPIProcessLinkID: 0, //data['requestAmount'],
    paymentGatewayPgapilinkId: data["paymentGatewayPgapilinkId"],
    createBy: data["createBy"],
    createIP: data["createIp"],
    ext1: "",
    ext2: "",
    ext3: "",
    ext4: "",
    ext5: "",
    chqDdno: data["chqDdno"],
    cashChqDddate: moment(data["cashChqDddate"]).format("MM/DD/YYYY 23:59:59"),
    bankName: data["bankName"],
    bankBranch: data["bankBranch"],
    city: data["city"],
    state: data["state"],
    bankId: data["bankId"],
    accountTypeID: data["accountTypeID"],
    processCode: "RFCD",
    isPGProcessedFromAdmin: 1,
    isSuccess: false,
  };
  const handleDelete = () => {
    Swal.fire({
      title: "Enter Reason for Rejection",
      input: "text",
      icon: "warning",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Reject",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        // data.commandId = 13;
        RejectTopUpRequestData.rejectionRemark = login;
        RejectTopUpRequestData.isRejected = true;

        // data.remark = login;
        // data.Pwd = "";
        // data.TranPwd = "";
        // data.VerifiedIp = "";

        console.log(data);
        return await GetResponse(RejectTopUpRequestData, WalletLinks.Wallet, {})
          .then((response) => {
            if (!response) {
              throw new Error(response.errorDesc);
            }
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      console.log(result);
      if (result.value) {
        dispatch(UpdateRow());

        Swal.fire({
          title: "Success",
          text: `${result.value}!`,
          icon: "success",
        });
      }
    });
  };

  return (
    <>
      {openModal === true ? (
        <TopUpOfflineProcessModal
          openModal={openModal}
          handleModalClose={handleModalClose}
          handleModalOpen={handleModalOpen}
          row={params.row}
          dataFields={dataFields}
          Type={Type}
          URL={URL}
          name={name}
          key={params.id}
        />
      ) : (
        <></>
      )}

      <Box
        sx={{
          m: 1,
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {/* <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
              bgcolor: red[400],
              "&:hover": { bgcolor: red[700] },
            }}
            onClick={DeleteRow}
          >
            <DeleteForeverIcon />
          </Fab> */}
        <Chip
          label="Approve"
          onClick={handleModalOpen}
          //   onDelete={handleDelete}
          deleteIcon={<DoneIcon />}
          sx={{
            width: 100,
            // height: 40,
            color: "white",
            bgcolor: green[300],
            "&:hover": { bgcolor: green[700] },
          }}
        />
        <Chip
          label="Reject"
          onClick={handleDelete}
          // onDelete={handleDelete}
          deleteIcon={<DeleteIcon />}
          sx={{
            width: 100,
            // height: 40,
            color: "white",

            bgcolor: red[300],
            "&:hover": { bgcolor: red[700] },
          }}
        />
      </Box>
    </>
  );
};

export default PendingTopUpRequestActions;
