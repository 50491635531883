import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MailIcon from "@mui/icons-material/Mail";
import ListIcon from "@mui/icons-material/List";
import WebIcon from "@mui/icons-material/Web";
const SiteMGMTLinkList = [
  {
    Name: "Email  Templates",
    Icon: MailIcon,
    Link: "/SiteMGMT/EmailTemplateMaster",
    NestedLinks: [],
  },
  {
    Name: "Menu",
    Icon: ListIcon,
    Link: "/SiteMGMT/Menu",
    NestedLinks: [],
  },
  
];
export { SiteMGMTLinkList };
