import { useEffect, useMemo, useState } from "react";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import { grey } from "@mui/material/colors";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import { useDispatch, useSelector } from "react-redux";
import { CRUDCommands, RDSLinks } from "../../../Utils/Axios/RequestParameters";
import { Colors } from "../../../Utils/Constants";
import swal from "sweetalert";
import PendingRegistrationRequestActions from "./PendingRegistrationRequestActions";
import { RegistrationRequestInputFields } from "../../../Utils/RegistrationRequestInputFields";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import DateNoFilter from "../DateNoFilter";
export default function PendingRegistrationRequestDataGrid() {
  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [ShowSnackbar, setShowSnackbar] = useState(false);

  const [filterData, setFilterData] = useState({
    fromRequestDate: "",
    toRequestDate: "",
    requestNo: "",
  });

  const handleFilter = (e) => {
    e.preventDefault();
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };

  const FilterFields = [
    {
      name: "fromRequestDate",
      label: "From Date",
      type: "date",
    },
    {
      name: "toRequestDate",
      label: "To Date",
      required: true,
      type: "date",
    },
    {
      name: "requestNo",
      label: "Request No",
      required: true,
      type: "text",
    },
  ];
  const addData = {
    commandId: CRUDCommands.PendingRegList,
    verificationId: 0,
    name: "",
    email: "",
    countryCode: "",
    mobileNo: "",
    verificationCode: "",
    isVerified: true,
    verifiedDateTime: "2022-11-11T07:57:03.422Z",
    verifiedIp: "",
    createBy: 0,
    createDate: "2022-11-11T07:57:03.422Z",
    createIp: "",
    distributorId: 0,
    updateBy: 0,
    updateDate: "2022-11-11T07:57:03.422Z",
    updateIp: "",
    typeDetId: 0,
    referredByDistributorCode: "",
    tranPwd: "",
    pwd: "",
    cmpName: "",
    add1: "",
    add2: "",
    add3: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    phone: "",
    alternateEmail: "",
    officeSpace: "",
    businessType: "",
    isIataagent: true,
    yearInBusiness: "",
    pan: "",
    pandocImg: "",
    taxExemptionRate: 0,
    taxExemptionAmt: 0,
    taxExemptionCertImg: "",
    padd1: "",
    padd2: "",
    padd3: "",
    pcity: "",
    pstate: "",
    pcountry: "",
    ppincode: "",
    pphone: "",
    eduQualification: "",
    occupation: "",
    userRemark: "",
    status: "",
    remark: "",
    dateOfBirth: "2022-11-01T07:57:03.422Z",
    requestedDistributorId: 0,
    anniversaryDate: "2022-01-11T07:57:03.422Z",
    title: "",
    lastName: "",
    designation: "",
    aadharNo: "",
    gstcompanyName: "",
    gstno: "",
    gstphone: "",
    gstemail: "",
    gstaddress: "",
    gstpincode: "",
    gststate: "",
    gstcity: "",
    logo: "",
    requestNo: "",
    requestType: "",
    ownerName: "",
    agencyName: "",
    requestTypeDet: "",
    requestedDistributorCode: "",
    requestDateTime: "2022-11-11T07:57:03.423Z",
    typeID: 0,
    fromRequestDate: "2022-11-01T07:57:03.423Z",
    toRequestDate: moment().format("MM/DD/YYYY 23:59:59"),
  };
  const FilterPendingToptopRequest = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const Requestdata = {
        ...addData,
        fromRequestDate:
          filterData["fromRequestDate"] !== ""
            ? moment(filterData["fromRequestDate"]).format("MM/DD/YYYY")
            : "2022-11-01T07:57:03.423Z",
        toRequestDate:
          filterData["toRequestDate"] !== ""
            ? moment(filterData["toRequestDate"]).format("MM/DD/YYYY 23:59:59")
            : moment().format("MM/DD/YYYY 23:59:59"),
        requestNo:
          filterData["requestNo"] !== null || filterData["requestNo"]
            ? filterData["requestNo"]
            : "",
      };
      console.log({ Requestdata });
      console.log({ filterData });

      const Response = await GetResponse(
        Requestdata,
        RDSLinks.DistributorMaster.DistributorRegistration,
        {}
      );
      console.log(Response);
      if (Response.length > 0) {
        setServiceAPI(Response);
      } else {
        swal(
          "error",
          "No Registration Request Found with Selected Filter",
          "error"
        );
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Registration Request Found", "error");
    }
    setLoader(false);
  };
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);
  // const fromRequestDatex=new Date(2022,10,1)
  // const toRequestDatex=new Date()
  // const [fromRequestDate, setfromRequestDate] = useState(fromRequestDatex);
  // const [toRequestDate, settoRequestDate] = useState(toRequestDatex);

  const getServices = async () => {
    setLoader(true);
    try {
      //   const Response = await axios.get(
      //     CourierServiceLinks.CourierServiceProvider.GetAll
      //   );

      const Response = await GetResponse(
        addData,
        RDSLinks.DistributorMaster.DistributorRegistration,
        {}
      );
      console.log(Response);
      if (Response.length > 0 && Array.isArray(Response)) {
        setServiceAPI(Response);
      } else {
        setServiceAPI(Response);
       setShowSnackbar(true)
        // swal("Empty!", "No Registration Request is Pending", "error");
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Registration Request Found", "error");
    }
    setLoader(false);
  };

  useEffect(() => {
    getServices();
  }, [Row]);

  const columns = useMemo(
    () => [
      {
        field: "requestNo",
        headerName: "Request No",
        width: 100,
      },

      {
        field: "requestDateTime",
        headerName: "Request Date",
        width: 150,
        renderCell: (params) => (
          <p>{moment(params.row.requestDateTime).format("DD/MMM/YYYY")}</p>
        ),
      },
      {
        field: "name",
        headerName: "First Name",
        width: 100,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        width: 100,
      },
      // {
      //   field: "requestType",
      //   headerName: "Request Type",
      //   width: 100,
      //   type: "singleSelect",
      //   valueOptions: ["basic", "editor", "admin"],
      // },
      // {
      //   field: "requestTypeDet",
      //   headerName: "Request Type Det",
      //   width: 150,
      //   type: "singleSelect",
      //   valueOptions: ["basic", "editor", "admin"],
      // },
      {
        field: "cmpName",
        headerName: "Company Name",
        width: 230,
        type: "singleSelect",
        valueOptions: ["basic", "editor", "admin"],
      },

      {
        field: "mobileNo",
        headerName: "Mobile NO ",
        width: 100,
      },

      { field: "email", headerName: "Email ID", width: 250 },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 220,
        renderCell: (params) => (
          <PendingRegistrationRequestActions
            {...{
              params,
              rowId,
              setRowId,
              dataFields: RegistrationRequestInputFields,
              //   EditURL: CourierServiceLinks.CourierServiceProvider.Edit,
              //   DeleteURL: CourierServiceLinks.CourierServiceProvider.Delete,
              URL: RDSLinks.DistributorMaster.DistributorMaster,
              name: "Registration Request",
              Type: "Approve",
            }}
            key={params.row.verificationId}
          />
        ),
      },
    ],
    [rowId, Row]
  );

  return (
    <>
      <SimpleBackDrops Loader={Loader} />
      <Snackbar
        anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
        open={ShowSnackbar}
        autoHideDuration={15000}
        onClose={()=>setShowSnackbar(!ShowSnackbar)}
        direction='up'
      >
      <Alert  severity="success" sx={{ width: '100%' }}>
      No Registration Request is Pending
      </Alert>
    </Snackbar>
      <Box
        sx={{
          height: 559,
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
          // backgroundColor:Colors.PrimaryBackgroundColor,
          // alignItems:'center'
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            textAlign: "center",
            p: 1,
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          Pending Registration Request
        </Typography>
        <Box sx={{ display: "flex", width: "100%" }}>
          <DateNoFilter
            handleFilter={handleFilter}
            FilterFields={FilterFields}
            filterData={filterData}
            handleSubmit={FilterPendingToptopRequest}
          />
        </Box>

        <DataGrid
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          key={Row}
          rows={ServiceAPI}
          getRowId={(row) => row.verificationId}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
