import  React,{useState,useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { UpdateRow } from "../../features/RowSlice";
import {
  Grid,
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  TextField,
  MenuItem,
  CssBaseline,
  Container,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import SimpleBackDrops from "../../components/SimpleBackDrops";
import swal from "sweetalert";
import { Colors } from "../../Utils/Constants";
import { CRUDCommands } from "../../Utils/Axios/RequestParameters";
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  paddingTop: 1,
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", md: "60%", lg: "50%" },
  height: { xs: 700, sm: 400 },
  //   scrollY:true,
  //   flexWrap:Scr,
  // overflow: "auto",
  bgcolor: "white",
  //   border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  pb: 0,
  p: 0,
  m: 0,
};
// Type = Edit or ADD
export default function CommonAddEditModal({
    row,
    dataFields,
    InitialError,
    RequiredInputs,
    openModal,
    handleModalOpen,
    handleModalClose,
    Type,
    URL,
    name,
  }) {
    const dispatch = useDispatch();
    const [Loader, setLoader] = React.useState(false);
    const [data, setData] = React.useState(row);
    const [Errors, setErrors] = useState(InitialError);
    
  
   
      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        try {
          const AllErrors = Object.keys(Errors).filter((key) => Errors[key] === true);
          const AllMissingFields = RequiredInputs.filter(
            (inputs) => data[inputs] === ""
          );
          if (AllErrors.length === 0 && AllMissingFields.length === 0) {
            var RequestData = data
           if(Type==='Add'){
            RequestData.commandId=CRUDCommands.Add
           }else{
            RequestData.commandId=CRUDCommands.Update

           }
            console.log(RequestData);
    
            const response = await GetResponse(RequestData, URL, {});
    
            if (response === true) {
                swal("Success!", `${name}  ${Type}'d Successfully!`, "success", {});
      
                handleModalClose();
                dispatch(UpdateRow());
                GotoDefault();
                setData(row);
              } else if (response === false) {
                swal("Error!", `Some Error Occured!`, "error", {});
              } else if (response.errorDesc) {
                swal("Error!", response.errorDesc, "error", {});
              }
          } else {
            var NewErrors={...Errors}
          AllMissingFields.map((key)=>{
            return  NewErrors[key]=true
           })
                setErrors(NewErrors)
            // swal(
            //   "Sorry!",
            //   `Please Enter All Fields Correctly! \n ${AllErrors.join(
            //     ","
            //   )}  ${AllMissingFields.join(",")} - not filled Correctly`,
            //   "error",
            //   {
            //     // timer: 2000,
            //   }
            // );
          }
        } catch (error) {
          console.log(error);
          setLoader(false);
        }
        setLoader(false);
      };
      const handleCheck = (event) => {
        setData({ ...data, [event.target.name]: event.target.checked });
      };
     
      const ValidateInput = (event, regex) => {
        setData({ ...data, [event.target.name]: event.target.value });
        console.log(event.target.name, event.target.value);
    
        if (data[event.target.name]) {
          const IsValid = regex.test(event.target.value);
          if (IsValid === false) {
            setErrors({ ...Errors, [event.target.name]: true });
          } else {
            setErrors({ ...Errors, [event.target.name]: false });
          }
        }
      };
  
      const UpdateError=(event,regex)=>{
        if (data[event.target.name]    ) {
          const IsValid = regex.test(event.target.value);
          if (IsValid === false) {
            setErrors({ ...Errors, [event.target.name]: true });
          } else {
            setErrors({ ...Errors, [event.target.name]: false });
          }
        }
        if(RequiredInputs.includes(event.target.name) ===false  &&  data[event.target.name] ===""){
          setErrors({ ...Errors, [event.target.name]: false });
        }
      }
      const GotoDefault = () => {
        setData(row);
      };
      React.useEffect(() => {
        console.log({data,Errors});

      }, [data,Errors]);
     
      return (
        <>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={() => {
              handleModalClose();
              GotoDefault();
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModal}>
              <Container component="main" maxWidth="lg" sx={styles}>
                <SimpleBackDrops Loader={Loader} />
                {/* <CssBaseline /> */}
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{
                    width: "40vw",
                    backgroundColor: Colors.SecondaryBackGroundColor[400],
                    color: "white",
                    height: 70,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderBottomLeftRadius: 15,
                    borderBottomRightRadius: 15,
                    boxShadow:
                      "0 5px 10px rgba(0,0,0,0.16), 0 5px 5px rgba(0,0,0,0.16)",
                    mb: 1,
                    fontSize: { xs: 15, sm: 20 },
                  }}
                >
                  {Type} {name}
                </Typography>
                <Box
                  component="form"
                  validate='true'
                  
                  // onSubmit={handleSubmit}
                  sx={{
                    // overflow: "auto",
                    height: { xs: 700, sm: 400 },
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  <Box
                    sx={{
                      overflow: "auto",
                      height: { xs: 500, sm: 260 },
                      paddingTop: 2,
                    }}
                  >
                    <Grid container spacing={2} sx={{ overflow: "auto" }}>
                      {dataFields.map((Fields, index) => {
                        return Fields.Type === "CheckBox" ? (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={Fields.grid ? Fields.grid : 4}
                            key={Fields.name}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    data[Fields.name] === true ||
                                    data[Fields.name] === "on"
                                      ? true
                                      : false
                                  }
                                  onChange={handleCheck}
                                  name={Fields.name}
                                />
                              }
                              label={Fields.name}
                            />
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={12}
                            sm={Fields.grid ? Fields.grid : 6}
                            lg={Fields.grid ? Fields.grid : 6}
                            key={Fields.name}
                          >
                            <TextField
                              error={Errors[Fields.name]}
                              // autoComplete="given-name"
                              type={Fields.Type}
                              name={Fields.name}
                              value={data[Fields.name]}
                              select={Fields.Type === "Select" ? true : false}
                              required={Fields.required?Fields.required:true}
                              fullWidth
                              id={Fields.name}
                              label={Fields.Label}
                              InputLabelProps={{ shrink: true }}
                              InputProps={
                                Fields.Type === "number"
                                  ? { inputProps: { min: 0, maxLength: 10 } }
                                  : {}
                              }
                              autoFocus={
                                index === 0 || Fields.autoFocus ? true : false
                              }
                              onBlur={(event)=>UpdateError(event, Fields.validReg)}
                              onChange={(event) => ValidateInput(event, Fields.validReg)}
                              helperText={
                                Errors[Fields.name] === true ? Fields.ErrorMsg : ``
                              }
                            >
                              {Fields.Type === "Select" ||
                              Fields.options.length > 0 ? (
                                Fields.options.map((option) => (
                                  <MenuItem key={option.label} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </TextField>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                  <Grid container spacing={1} sx={{ padding: 2 }}>
                    <Grid item xs={6} sm={6} lg={6}>
                      <Button
                        fullWidth
                        color="error"
                        variant="contained"
                        sx={{ mt: 1, mb: 1 }}
                        onClick={() => {
                          handleModalClose();
                          GotoDefault();
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={6}>
                      <Button
                        type="submit"
                        fullWidth
                        color="success"
                        variant="contained"
                        sx={{ mt: 1, mb: 1 }}
                        onClick={(e) => handleSubmit(e)}
                      >
                        {Type}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Fade>
          </Modal>
        </>
      );
  }