import { createSlice } from "@reduxjs/toolkit";
  
const SiteMGMTDetail=sessionStorage.getItem('SiteMGMTDetails')
export const SiteMGMTDetailsSlice = createSlice({
  
  name: "SiteMGMTDetails",
  initialState: {
    value:SiteMGMTDetail?JSON.parse(SiteMGMTDetail):{},
  },
  reducers: {
    UpdateSiteMGMTDetails: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    },
    ClearSiteMGMTDetails: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { UpdateSiteMGMTDetails,ClearSiteMGMTDetails } = SiteMGMTDetailsSlice.actions;

export default SiteMGMTDetailsSlice.reducer;
