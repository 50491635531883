import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import ResponsiveSidebar from "../../components/ResponsiveSidebar";
import SiteManagementNavbar from "../Components/SiteManagementNavbar";
import { SiteMGMTLinkList } from "../../Utils/SiteMGMTLinkList";

export default function SiteManagementMainPage() {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const HandleDrawer = () => {
    console.log(openDrawer);
    setOpenDrawer(openDrawer === true ? false : true);
  };
  return (
    <ProSidebarProvider>
      <Box sx={{ display: "flex" }}>
        <SiteManagementNavbar HandleDrawer={HandleDrawer} />
      </Box>
      <Box>
        {/* <MainNavbar AppName={'Insta Courier'} Applink='/'/> */}
        <Box sx={{ display: "flex", marginTop: 10 }}>
          <ResponsiveSidebar
            openDrawer={openDrawer}
            LinkList={SiteMGMTLinkList}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100vw",
              pl: { sx: 0, sm: 10 },
            }}
          >
            <Outlet />
          </Box>
        </Box>

        {/* <Container  ></Container> */}
      </Box>
    </ProSidebarProvider>
  );
}
