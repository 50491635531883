import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Chip, CircularProgress, IconButton, Typography } from "@mui/material";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import { Colors } from "../../Utils/Constants";
import { useSelector } from "react-redux";
import ActiveDeactive from "../../Admin/Components/ActiveDeactive";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import { useMemo } from "react";
import moment from "moment";
import {
  CourierServiceLinks,
  CRUDCommands,
  RDSLinks,
} from "../../Utils/Axios/RequestParameters";
import swal from "sweetalert";
import DateNoFilter from "../../Admin/Components/DateNoFilter";
import OrderHistoryFilter from "./OrderHistoryFilter";
import axios from "axios";
import BookingHistoryAction from "./BookingHistoryActions";

const BookingHistoryDataGrid = (props) => {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            responsiveScrollMaxHeight: { maxHeight: "399px" },
            root: {
              width: "98%",
            },
          },
        },
        MUIDataTableHeadCell: {
          data: {
            fontSize: "10px",
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: Colors.PrimaryBackgroundColor[700],
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              color: "white",
            },
          },
        },
        MuiTableHead: {
          styleOverrides: {
            root: {
              maxHeight: "100px",
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              maxHeight: "100px",
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: "10px",
            },
          },
        },
      },
    });

  const Row = useSelector((state) => state.Row.value);
  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  const Token = useSelector((state) => state.AgentToken.value);

  const distributorId = AgentDetails.distributorId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState({
    // fromDate: moment().subtract(1, "d").format("YYYY-MM-DD"),
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    requestNo: "",
    statusId: 0,
  });

  const handleFilter = (e) => {
    e.preventDefault();
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };
  const [StatusOptions, setStatusOptions] = useState([]);
  const [FilterFields, setFilterFields] = useState([
    {
      name: "fromDate",
      label: "From Date",
      type: "date",
      options: [],
    },
    {
      name: "toRequestDate",
      label: "To Date",
      options: [],

      type: "date",
    },
    {
      name: "refNo",
      label: "Reference No",
      options: [],

      type: "text",
    },
    {
      name: "statusId",
      label: "Status ",
      options: StatusOptions,
      type: "text",
    },
    {
      name: "apitrackingNumber",
      label: "Tracking No ",
      options: [],

      type: "text",
    },
  ]);

  useEffect(() => {
    setFilterFields([
      {
        name: "fromDate",
        label: "From Date",
        type: "date",
        options: [],
      },
      {
        name: "toDate",
        label: "To Date",
        options: [],

        type: "date",
      },
      {
        name: "refNo",
        label: "Reference No",
        options: [],

        type: "text",
      },
      {
        name: "statusId",
        label: "Status ",
        options: [{ label: "ALL", value: 0 }, ...StatusOptions],
        type: "text",
      },
      {
        name: "apitrackingNumber",
        label: "Tracking No ",
        options: [],

        type: "text",
      },
    ]);
    console.log({ StatusOptions });
  }, [StatusOptions]);
  const FilterPendingToptopRequest = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const RequestData = {
        orders: [
          {
            distributorId: distributorId,
            interfaceId: 0,
            pickupPincode: "",
            deliveryPincode: "",
            courierTypeId: 0,
            weightInKg: 0,
            lengthInCm: 0,
            widthInCm: 0,
            heightInCm: 0,
            isPrepaid: true,
            valueInRs: 0,
            typeDetId: 0,
            courierTypeName: "",
            deliveryCharge: 0,
            codcharge: 0,
            fuelCharge: 0,
            supplierGst: 0,
            totalAmount: 0,
            companyCharges: 0,
            commissionAmount: 0,
            tdsper: 0,
            tdsamount: 0,
            sgst: 0,
            cgst: 0,
            igst: 0,
            netAmount: 0,
            amount: 0,
            commissionId: 0,
            totalTranCharges: 0,
            totalPGSerTaxAmt: 0,
            apiid: 0,
            courierServiceProviderId: 0,
            courierServiceProviderName: "",
            orderId: 0,
            refNo:
              filterData["refNo"] !== null || filterData["refNo"]
                ? filterData["refNo"]
                : "",
            parcelDetails: "",
            pickupCompanyName: "",
            pickupMobileNo: "",
            pickupFirstName: "",
            pickupLastName: "",
            pickupEmail: "",
            pickupAddress1: "",
            pickupAddress2: "",
            pickupAddress3: "",
            pickupCityId: 0,
            pickupStateId: 0,
            pickupCountryId: 0,
            pickupCity: "",
            pickupState: "",
            pickupCountry: "",
            publishedTotalAmount: 0,
            deliveryCompanyName: "",
            deliveryMobileNo: "",
            deliveryFirstName: "",
            deliveryLastName: "",
            deliveryEmail: "",
            deliveryAddress1: "",
            deliveryAddress2: "",
            deliveryAddress3: "",
            deliveryCityId: 0,
            deliveryStateId: 0,
            deliveryCountryId: 0,
            deliveryCity: "",
            deliveryState: "",
            deliveryCountry: "",
            historyCommissionConfigId: 0,
            level1DistributorId: 0,
            level1CommCalculated: 0,
            level1CommTdscalculated: 0,
            level1CommNet: 0,
            level1CommIsProcessed: true,
            level1CommProcessedDateTime: "2022-12-06T09:17:38.857Z",
            level2DistributorId: 0,
            level2CommCalculated: 0,
            level2CommTdscalculated: 0,
            level2CommNet: 0,
            level2CommIsProcessed: true,
            level2CommProcessedDateTime: "2022-12-06T09:17:38.857Z",
            totalCancelRefundAmount: 0,
            totalRefundAmount: 0,
            totalAgencyCharges: 0,
            status: "",
            statusDescription: "",
            requestDateTime: "2022-12-06T09:17:38.858Z",
            bookingDateTime: "2022-12-06T09:17:38.858Z",
            pgid: 0,
            tranClientIp: "",
            serverIp: "",
            paymentGatewayPGAPILinkID: 0,
            paymentGatewayPGAPIProcessLinkID: 0,
            bookedAdminUserId: 0,
            userDistributorId: 0,
            apitransactionNo: "",
            apitrackingNumber:
              filterData["apitrackingNumber"] !== null ||
              filterData["apitrackingNumber"]
                ? filterData["apitrackingNumber"]
                : "",
            shipmentLabel: "",
            isPickupDone: true,
            createBy: 0,
            createDate: "2022-12-06T09:17:38.858Z",
            createIp: "",
            updateBy: 0,
            updateDate: "2022-12-06T09:17:38.858Z",
            updateIp: "",
            serviceType: "",
            apiValue: "",
            otherCharge: 0,
            serviceDays: 0,
            estimateDate: "2022-12-06T09:17:38.858Z",
            tranPwd: "",
            orderDetails: [
              {
                orderDetId: 0,
                orderId: 0,
                productDesc: "",
                sku: "",
                hsn: "",
                qty: 0,
                price: 0,
                totalAmount: 0,
                createBy: 0,
                createDate: "2022-12-06T09:17:38.858Z",
                createIP: "",
                updateBy: 0,
                updateDate: "2022-12-06T09:17:38.858Z",
                updateIP: "",
              },
            ],
            orderRequest: {
              orderRequestId: 0,
              orderRequestDetID: 0,
              currentSessionID: "",
              clientRequestID: "",
              gstNo: "",
              gstEmailID: "",
              gstCompanyName: "",
              gstMobileNo: "",
              gstAddress: "",
              gstFlat: "",
              gstStreet: "",
              gstpin: "",
              gstState: "",
              gstCity: "",
              remark: "",
              bookingRequestNo: "",
              productID: 0,
            },
            orderPaymentDetail: [
              {
                orderPaymentDetID: 0,
                merchantTxnID: "",
                orderReferenceNo: "",
                invoiceNo: "",
                paymentAffiliateTranNo: "",
                paymentAffiliateDateTime: "2022-12-08T10:34:26.355Z",
                rootPNRef: "",
                rootAuthCode: "",
                isSucceed: true,
                remark: "",
                merchantID: "",
                vendor: "",
                partner: "",
                custIPAddress: "",
                respURL: "",
                respMethod: "",
                currCode: "",
                messageType: "",
                gmtOffset: "",
                ext1: "",
                ext2: "",
                ext3: "",
                ext4: "",
                ext5: "",
                amount: 0,
                isOrder: true,
                isCancel: true,
                isRefund: true,
              },
            ],
            orderCancel: [
              {
                cgst: 0,
                codChargeRefund: 0,
                commissionAmountRefund: 0,
                companyCancellationCharges: 0,
                companyChargesRefund: 0,
                couOrderCancelID: 0,
                courierServiceProviderCancellationCharges: 0,
                deliveryChargeRefund: 0,
                fuelChargeRefund: 0,
                igst: 0,
                isCancelSuccess: true,
                isComplaint: true,
                orderCancelDetId: 0,
                orderCancelId: 0,
                sgst: 0,
                status: "string",
                supplierGSTRefund: 0,
                tdsAmountRefund: 0,
                totalAmountRefund: 0,
                totalRefundAmount: 0,
                userID: 0,
              },
            ],
            tracking: [
              {
                checkpoints: [
                  {
                    location: "",
                  },
                ],
              },
            ],
            fromDate:
              filterData["fromDate"] !== ""
                ? moment(filterData["fromDate"]).format("MM/DD/YYYY")
                : "2022-11-01T07:57:03.423Z",
            toDate:
              filterData["toDate"] !== ""
                ? moment(filterData["toDate"]).format("MM/DD/YYYY 23:59:59")
                : moment().format("MM/DD/YYYY 23:59:59"),
            statusId:
              filterData["statusId"] !== null || filterData["statusId"]
                ? filterData["statusId"]
                : "",
          },
        ],
        errorDesc: "",
        errorCode: "",
        commandId: CRUDCommands.OrderHistory,
      };

      console.log({ RequestData });
      console.log({ filterData });

      const Response = await GetResponse(
        RequestData,
        CourierServiceLinks.Order,
        {}
      );
      console.log(Response.orders);
      if (Response.orders.length > 0) {
        setServiceAPI(Response.orders);
      } else {
        swal("error", "No Booking Found with Selected Filter", "error");
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Booking Found", "error");
    }
    setLoader(false);
  };
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);

  const addData = {
    orders: [
      {
        distributorId: distributorId,
        interfaceId: 0,
        pickupPincode: "",
        deliveryPincode: "",
        courierTypeId: 0,
        weightInKg: 0,
        lengthInCm: 0,
        widthInCm: 0,
        heightInCm: 0,
        isPrepaid: true,
        valueInRs: 0,
        typeDetId: 0,
        courierTypeName: "",
        deliveryCharge: 0,
        codcharge: 0,
        fuelCharge: 0,
        supplierGst: 0,
        totalAmount: 0,
        companyCharges: 0,
        commissionAmount: 0,
        tdsper: 0,
        tdsamount: 0,
        sgst: 0,
        cgst: 0,
        igst: 0,
        netAmount: 0,
        amount: 0,
        commissionId: 0,
        totalTranCharges: 0,
        totalPGSerTaxAmt: 0,
        apiid: 0,
        courierServiceProviderId: 0,
        courierServiceProviderName: "",
        orderId: 0,
        refNo: "",
        parcelDetails: "",
        pickupCompanyName: "",
        pickupMobileNo: "",
        pickupFirstName: "",
        pickupLastName: "",
        pickupEmail: "",
        pickupAddress1: "",
        pickupAddress2: "",
        pickupAddress3: "",
        pickupCityId: 0,
        pickupStateId: 0,
        pickupCountryId: 0,
        pickupCity: "",
        pickupState: "",
        pickupCountry: "",
        publishedTotalAmount: 0,
        deliveryCompanyName: "",
        deliveryMobileNo: "",
        deliveryFirstName: "",
        deliveryLastName: "",
        deliveryEmail: "",
        deliveryAddress1: "",
        deliveryAddress2: "",
        deliveryAddress3: "",
        deliveryCityId: 0,
        deliveryStateId: 0,
        deliveryCountryId: 0,
        deliveryCity: "",
        deliveryState: "",
        deliveryCountry: "",
        historyCommissionConfigId: 0,
        level1DistributorId: 0,
        level1CommCalculated: 0,
        level1CommTdscalculated: 0,
        level1CommNet: 0,
        level1CommIsProcessed: true,
        level1CommProcessedDateTime: "2022-12-06T09:17:38.857Z",
        level2DistributorId: 0,
        level2CommCalculated: 0,
        level2CommTdscalculated: 0,
        level2CommNet: 0,
        level2CommIsProcessed: true,
        level2CommProcessedDateTime: "2022-12-06T09:17:38.857Z",
        totalCancelRefundAmount: 0,
        totalRefundAmount: 0,
        totalAgencyCharges: 0,
        status: "",
        statusDescription: "",
        requestDateTime: "2022-12-06T09:17:38.858Z",
        bookingDateTime: "2022-12-06T09:17:38.858Z",
        pgid: 0,
        tranClientIp: "",
        serverIp: "",
        paymentGatewayPGAPILinkID: 0,
        paymentGatewayPGAPIProcessLinkID: 0,
        bookedAdminUserId: 0,
        userDistributorId: 0,
        apitransactionNo: "",
        apitrackingNumber: "",
        shipmentLabel: "",
        isPickupDone: true,
        createBy: 0,
        createDate: "2022-12-06T09:17:38.858Z",
        createIp: "",
        updateBy: 0,
        updateDate: "2022-12-06T09:17:38.858Z",
        updateIp: "",
        serviceType: "",
        apiValue: "",
        otherCharge: 0,
        serviceDays: 0,
        estimateDate: "2022-12-06T09:17:38.858Z",
        tranPwd: "",
        orderDetails: [
          {
            orderDetId: 0,
            orderId: 0,
            productDesc: "",
            sku: "",
            hsn: "",
            qty: 0,
            price: 0,
            totalAmount: 0,
            createBy: 0,
            createDate: "2022-12-06T09:17:38.858Z",
            createIP: "",
            updateBy: 0,
            updateDate: "2022-12-06T09:17:38.858Z",
            updateIP: "",
          },
        ],
        orderRequest: {
          orderRequestId: 0,
          orderRequestDetID: 0,
          currentSessionID: "",
          clientRequestID: "",
          gstNo: "",
          gstEmailID: "",
          gstCompanyName: "",
          gstMobileNo: "",
          gstAddress: "",
          gstFlat: "",
          gstStreet: "",
          gstpin: "",
          gstState: "",
          gstCity: "",
          remark: "",
          bookingRequestNo: "",
          productID: 0,
        },
        orderPaymentDetail: [
          {
            orderPaymentDetID: 0,
            merchantTxnID: "",
            orderReferenceNo: "",
            invoiceNo: "",
            paymentAffiliateTranNo: "",
            paymentAffiliateDateTime: "2022-12-08T10:34:26.355Z",
            rootPNRef: "",
            rootAuthCode: "",
            isSucceed: true,
            remark: "",
            merchantID: "",
            vendor: "",
            partner: "",
            custIPAddress: "",
            respURL: "",
            respMethod: "",
            currCode: "",
            messageType: "",
            gmtOffset: "",
            ext1: "",
            ext2: "",
            ext3: "",
            ext4: "",
            ext5: "",
            amount: 0,
            isOrder: true,
            isCancel: true,
            isRefund: true,
          },
        ],
        orderCancel: [
          {
            cgst: 0,
            codChargeRefund: 0,
            commissionAmountRefund: 0,
            companyCancellationCharges: 0,
            companyChargesRefund: 0,
            couOrderCancelID: 0,
            courierServiceProviderCancellationCharges: 0,
            deliveryChargeRefund: 0,
            fuelChargeRefund: 0,
            igst: 0,
            isCancelSuccess: true,
            isComplaint: true,
            orderCancelDetId: 0,
            orderCancelId: 0,
            sgst: 0,
            status: "string",
            supplierGSTRefund: 0,
            tdsAmountRefund: 0,
            totalAmountRefund: 0,
            totalRefundAmount: 0,
            userID: 0,
          },
        ],
        tracking: [
          {
            checkpoints: [
              {
                location: "",
              },
            ],
          },
        ],
        fromDate: moment().subtract(1, "d").format("MM/DD/YYYY 00:00:01"),
        toDate: moment().format("MM/DD/YYYY 23:59:59"),
        statusId: 0,
      },
    ],
    errorDesc: "",
    errorCode: "",
    commandId: CRUDCommands.OrderHistory,
  };
  const dataFields = [
    {
      Label: "",
      name: "description",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
  ];
  const getServices = async () => {
    setLoader(true);

    try {
      const StatusOptions = await GetResponse(
        {
          statusId: 0,
          statusDescription: "",
          isCancel: true,
          isAction: true,
          isfCancel: 0,
          isfAction: -1,
          commandId: CRUDCommands.ListAll,
        },
        CourierServiceLinks.Status
      );
      if (StatusOptions.length > 0 && Array.isArray(StatusOptions)) {
        const Options = StatusOptions.map((option) => {
          return {
            label: option.statusDescription,
            value: option.statusId,
          };
        });

        setStatusOptions(Options);
      }

      const Response = await GetResponse(
        addData,
        CourierServiceLinks.Order,
        {}
      );
      console.log(Response);
      if (Response.orders.length > 0 && Array.isArray(Response.orders)) {
        setServiceAPI(Response.orders);
      } else {
        setServiceAPI([]);
        swal("error", "No Records Found", "error");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      swal("error", "No Records Found", "error");
    }
    setLoader(false);
  };

  useMemo(() => {
    getServices();
  }, [Row]);

  const columns = useMemo(
    () => [
      {
        name: "bookingDateTime",
        options: {
          customHeadLabelRender: () => (
            <div style={{ width: 120 }}>Booking Date</div>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return <div>{moment(value).format("DD/MMM/YYYY hh:mm a")}</div>;
          },
        },
      },
      {
        name: "refNo",
        options: {
          customHeadLabelRender: () => (
            <div style={{ width: 117 }}>Reference No</div>
          ),
        },
      },
      {
        name: "statusDescription",
        options: {
          customHeadLabelRender: () => <div style={{ width: 100 }}>Status</div>,
        },
      },
      {
        name: "pickupPincode",
        options: {
          customHeadLabelRender: () => (
            <div style={{ width: 127 }}>PickUp Pincode</div>
          ),
        },
      },
      {
        name: "deliveryPincode",
        options: {
          customHeadLabelRender: () => (
            <div style={{ width: 150 }}>Delivery Pincode</div>
          ),
        },
      },

      {
        name: "pickupFirstName",
        options: {
          customHeadLabelRender: () => (
            <div style={{ width: 105 }}>Pickup From</div>
          ),
        },
      },
      {
        name: "deliveryFirstName",
        options: {
          customHeadLabelRender: () => (
            <div style={{ width: 105 }}>Deliver To</div>
          ),
        },
      },

      {
        name: "courierServiceProviderName",
        options: {
          customHeadLabelRender: () => (
            <div style={{ width: 180 }}>Courier Provider</div>
          ),
        },
      },

      {
        name: "shipmentLabel",
        options: {
          customHeadLabelRender: () => (
            <div style={{ width: 180 }}>Shipment Label</div>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return value === "" ? (
              "Not Available"
            ) : (
              <a href={value} target="_blank">
                Click Here to View
              </a>
            );
          },
        },
        //
      },

      {
        name: "status",
        options: {
          customHeadLabelRender: () => (
            <div style={{ width: 180 }}>Actions</div>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <BookingHistoryAction
                status={value}
                OrderData={ServiceAPI[tableMeta["rowIndex"]]}
              />
            );
          },
        },
        //
      },
    ],
    [rowId, Row, ServiceAPI]
  );

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: "none", //"single",
    filterType: "textField",
    responsive: "scrollMaxHeight",
    rowsPerPage: 100,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      return (
        <React.Fragment>
          <tr>
            <td colSpan={6}>
              <TableContainer component={Paper} elevation={4} sx={{ m: 1 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Description</TableCell>
                      <TableCell align="right">SKU</TableCell>
                      <TableCell align="right">HSN&nbsp;</TableCell>
                      <TableCell align="right">Quantity&nbsp;</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Total Amount&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ServiceAPI[rowMeta["rowIndex"]].orderDetails.map((row) => (
                      <TableRow key={row.orderId}>
                        <TableCell component="th" scope="row">
                          {row.productDesc}
                        </TableCell>
                        <TableCell align="right">{row.sku}</TableCell>
                        <TableCell align="right">{row.hsn}</TableCell>
                        <TableCell align="right">{row.qty}</TableCell>
                        <TableCell align="right">{row.price}</TableCell>
                        <TableCell align="right">{row.totalAmount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };
  useEffect(() => {
    document.getElementsByClassName(
      "tss-1qacubo-MUIDataTable-responsiveScrollMaxHeight"
    )[0].style.maxHeight = "50vh";
  }, []);
  const Title = ({ name, handleAdd }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {Loader && (
          <CircularProgress
            size={24}
            style={{ marginLeft: 15, position: "relative", top: 4 }}
          />
        )}
        <Box sx={{ fontSize: "25px", fontWeight: "bold", width: "50%" }}>
          Booking History
        </Box>
      </Box>
    );
  };

  const theme = getMuiTheme();
  return (
    <Box
      sx={{
        // backgroundColor:"lightgreen",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        flexDirection: "column",
        pl: 2,
      }}
    >
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "96%",
            pt: 1,
            pb: 1,
          }}
        >
          <OrderHistoryFilter
            handleFilter={handleFilter}
            FilterFields={FilterFields}
            filterData={filterData}
            handleSubmit={FilterPendingToptopRequest}
          />
        </Box>
        <MUIDataTable
          title={<Title />}
          data={ServiceAPI}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </Box>
  );
};

export default BookingHistoryDataGrid;
