import React, { useEffect } from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import NavBar from "../components/LoggedInNavbar";
import { Outlet } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import { MainLinkList } from "../Utils/MainLinkList";
import ResponsiveSidebar from "../components/ResponsiveSidebar";
import { Colors } from "../Utils/Constants";
import { useSelector } from "react-redux";
export default function AgentHomePage() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [SideBarLinks, setSideBarLinks] = React.useState(MainLinkList);

  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  console.log({ AgentDetails });
  useEffect(() => {
    if (AgentDetails) {
      console.log(AgentDetails.level1DistributorId);
     if(AgentDetails.level1DistributorId !==0){
      let links = MainLinkList.filter((Link)=>{
return Link.Name !==Link.Name !== "Bank Details"
      })
      setSideBarLinks(links)
     }
      console.log({ havedownline: AgentDetails.isAllowToAddDownline });
      if(SideBarLinks.length>0){
        if (AgentDetails.isAllowToAddDownline === false) {
          let newTempLinks = SideBarLinks.filter((Links) => {
            return (
              Links.Name !== "Add Agent" &&
              Links.Name !== "Downline Top Up Requests" &&
              Links.Name !== "Downline Agents" 
              
            );
          });
  
          setSideBarLinks(newTempLinks);
        } else {
          setSideBarLinks(MainLinkList);
        }
      }
    
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const HandleDrawer = () => {
    console.log(openDrawer);
    setOpenDrawer(() => (openDrawer === true ? false : true));
  };

  return (
    <ProSidebarProvider>
      <Box sx={{ width: "100%" }}>
        <CssBaseline />
        <Box>
          <NavBar
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            HandleDrawer={HandleDrawer}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            {/* <Box
            sx={{
              width: { sm: "10vw" },
              // display: { xs: "none", sm: "block", padding: 0 },
            }}
          > */}
            <ResponsiveSidebar
              openDrawer={openDrawer}
              LinkList={SideBarLinks}
            />
            {/* </Box> */}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: { xs: "100vw", lg: "100vw" },
                pl: { sx: 0, sm: 10 },
                m: 0,
                // ,bgcolor:Colors.PrimaryColor[200]
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Box>
    </ProSidebarProvider>
  );
}
