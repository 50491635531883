import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Chip, CircularProgress, IconButton } from "@mui/material";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import { Colors } from "../../Utils/Constants";
import { useSelector } from "react-redux";
import ActiveDeactive from "../../Admin/Components/ActiveDeactive";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import { useMemo } from "react";
import moment from "moment";
import { CRUDCommands, RDSLinks } from "../../Utils/Axios/RequestParameters";
import swal from "sweetalert";

const DownlinesAgentsDataGrid = (props) => {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            responsiveScrollMaxHeight: { maxHeight: "399px" },
            root: {
              width: "97%",
            },
          },
        },
        MUIDataTableHeadCell: {
          data: {
            fontSize: "10px",
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: Colors.PrimaryBackgroundColor[700],
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              color: "white",
            },
          },
        },
        MuiTableHead: {
          styleOverrides: {
            root: {
              maxHeight: "100px",
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              maxHeight: "100px",
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: "10px",
            },
          },
        },
      },
    });

  const Row = useSelector((state) => state.Row.value);
  const AgentDetails = useSelector((state) => state.AgentDetails.value);

  const Token = useSelector((state) => state.AgentToken.value);

  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);

  const addData = {
    token: Token,
    distributorId: 0,
    typeDetId: 0,
    name: "",
    distributorCode: "",
    cmpName: "",
    add1: "",
    add2: "",
    add3: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    phone: "",
    mobile: "",
    fax: "",
    pan: "",
    email: "",
    tranPwd: "",
    dateOfBirth: "2022-11-26T09:26:28.086Z",
    distributorLevel: 0,
    level1DistributorId: AgentDetails !== null ? AgentDetails.distributorId : 0,
    level2DistributorId: 0,
    joiningDate: "2022-11-26T09:26:28.086Z",
    padd1: "",
    padd2: "",
    padd3: "",
    pcity: "",
    pstate: "",
    pcountry: "",
    ppincode: "",
    pphone: "",
    pmobile: "",
    pfax: "",
    isCustomer: true,
    isActive: true,
    activationRemark: "",
    commandId: CRUDCommands.ListAll,
    createBy: 0,
    createDate: "2022-11-26T09:26:28.086Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-11-26T09:26:28.086Z",
    updateIp: "",
    gender: "",
    isPaid: true,
    isProfileUpdated: true,
    invoiceNo: 0,
    paidDateTime: "2022-11-26T09:26:28.086Z",
    paidAmount: 0,
    isForm60: true,
    registrationAmount: 0,
    maintenanceChargeDetId: 0,
    isGenerateComm: true,
    isTranPwdUpdated: true,
    isTranAllowed: true,
    tranAllowedRemark: "",
    maritalStatus: "",
    occupation: "",
    activationDate: "2022-11-26T09:26:28.086Z",
    isEmailSmssent: true,
    isFirstLogin: true,
    logoImageUrl: "",
    isLogoDisplay: true,
    masterDistributorId: 0,
    userSeqNo: 0,
    loginName: "",
    pwd: "",
    isAllowLimitAllocationToDownline: true,
    alternateEmail: "",
    pandocImg: "",
    userRemark: "",
    isSendBalanceReminder: true,
    reminderBalance: 0,
    reminderInterval: 0,
    isSendReminderEmail: true,
    isSendReminderSms: true,
    isSerTaxOnSerCharge: true,
    isAllowToAddDownline: true,
    isTranPwdMendate: true,
    anniversaryDate: "2022-11-26T09:26:28.086Z",
    isSendDobintimationToCompany: true,
    title: "",
    lastName: "",
    designation: "",
    labelCaption: "",
    isSendPromotionalEmail: true,
    distributorUserAccessTimeId: 0,
    activenessCategoryId: 0,
    visitingCardReceiptDate: "2022-11-26T09:26:28.086Z",
    aadharNo: "",
    gstcompanyName: "",
    gstno: "",
    gstphone: "",
    gstemail: "",
    gstaddress: "",
    gstpincode: "",
    gststate: "",
    gstcity: "",
    countryCode: "",
    logo: "",
    userDistributorID: 0,
    distributorId: 0,
    limitBalance: 0,
    creditAllowed: 0,
    level1DistributorCode:
      AgentDetails !== null ? AgentDetails.distributorCode : "",
    transactionCredit: 0,
    tranCreditUtilized: 0,
    salesPersonId: 0,
    verificationCode: "",
    distributorGroupConfiguration: "",
    holdAmt: 0,
    countryLastUpdated: "2022-11-26T09:26:28.087Z",
    masterDistributorName: "",
    masterDistributorCode: "",
    bankLastUpdated: "2022-11-26T09:26:28.087Z",
    stateLastUpdated: "2022-11-26T09:26:28.087Z",
    cityLastUpdated: "2022-11-26T09:26:28.087Z",
    rewardPointBalance: 0,
    taxExemptionRate: 0,
    taxExemptionAmt: 0,
    taxExemptionCertImg: "",
    isDisplayApicommission: true,
    applyConvenienceFeeOnRdspg: true,
    distributorTaxID: 0,
    temporaryCredit: 0,
    cashBackBalance: 0,
    accountBalance: 0,
    effTaxExemptionAmt: 0,
    effTaxExemptionRate: 0,
    categoryName: "",
    description: "",
    typeDetailName: "",
    typeCode: "",
    code: "",
    typeMasterName: "",
    isTdsexemptionCertificateProvided: true,
    isRechargeAll: true,
    isCoBranded: true,
    effTaxExemptionDate: "2022-11-26T09:26:28.087Z",
    fromJoiningDate: moment(new Date("2022-1-1")).format("MM/DD/YYYY 23:59:59"),
    toJoiningDate: moment().format("MM/DD/YYYY 23:59:59"),
    accountType: [
      {
        distributorId: 0,
        accountBalance: 0,
        limitBalance: 0,
        creditAllowed: 0,
        transactionCredit: 0,
        tranCreditUtilized: 0,
        cashBackBalance: 0,
        distAccBalance: 0,
        holdAmt: 0,
        temporaryCredit: 0,
        accountdistributorId: 0,
        accountTypeCode: "",
        accountTypeName: "",
      },
    ],
  };
  const dataFields = [
    {
      Label: "",
      name: "description",
      Type: "string",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
  ];
  const getServices = async () => {
    setLoader(true);
    try {
      //   const Response = await axios.get(CourierServiceLinks.CourierType.GetAll);
      const Response = await GetResponse(
        addData,
        RDSLinks.DistributorMaster.DistributorMaster,
        {}
      );
      console.log(Response);
      if (Response.length > 0 && Array.isArray(Response)) {
        setServiceAPI(Response);
      } else {
        setServiceAPI([]);
        swal("error", "No Records Found", "error");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      swal("error", "No Records Found", "error");
    }
    setLoader(false);
  };

  useMemo(() => {
    getServices();
  }, [Row]);

  const columns = useMemo(
    () => [
      {
        name: "distributorCode",
        label: "AgentCode",
        customHeadLabelRender: (columnMeta) => (
          <Box sx={{ fontSize: "10px" }}>{columnMeta.label}fgfg</Box>
        ),
      },
      { name: "name", label: "FirstName" },
      { name: "lastName", label: "LastName" },
      { name: "cmpName", label: "CompanyName" },
      { name: "mobile", label: "MobileNo." },
      { name: "email", label: "EmailID" },
      {
        name: "joiningDate",
        label: "JoiningDate",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return <p>{moment(value).format("DD/MMM/YYYY")}</p>;
          },
        },
      },
      { name: "aadharNo", label: "AadharNo", width: 100 },
      // {
      //   name: "level1DistributorCode",
      //   label: "ParentDistributor",
      //   width: 150,
      // },
      { name: "city", label: "City", width: 100 },
      { name: "state", label: "State", width: 100 },

      // {
      //   name: "isActive",
      //   label: "Active",
      //   // width: 100,
      //   // type: "boolean",
      //   options: {
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         <ActiveDeactive
      //           {...{
      //             UpdateURL: RDSLinks.DistributorMaster.DistributorMaster,
      //             activeName: "isActive",
      //             SelectedFieldName: "this Agent ",
      //             row: ServiceAPI[tableMeta.rowIndex],
      //           }}
      //         />
      //       );
      //     },
      //   },
      //   //
      // },

      // {
      //   name: "isAllowToAddDownline",
      //   label: "AllowToAddDownline",
      //   width: 200,
      //   type: "boolean",
      //   options: {
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         <ActiveDeactive
      //           {...{
      //             UpdateURL: RDSLinks.DistributorMaster.DistributorMaster,
      //             activeName: "isAllowToAddDownline",
      //             SelectedFieldName: "this Agent ",
      //             row: ServiceAPI[tableMeta.rowIndex],
      //           }}
      //         />
      //       );
      //     },
      //   },
      // },

      // {
      //   name: "isTranPwdMendate",
      //   label: "TransactionPasswordisMendatory",
      //   options: {
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         <ActiveDeactive
      //           {...{
      //             UpdateURL: RDSLinks.DistributorMaster.DistributorMaster,
      //             activeName: "isTranPwdMendate",
      //             SelectedFieldName: "this Agent ",
      //             row: ServiceAPI[tableMeta.rowIndex],
      //           }}
      //         />
      //       );
      //     },
      //   },
      // },
      // {
      //   name: "isAllowLimitAllocationToDownline",
      //   label: "AllowLimitToDownline",
      //   width: 250,
      //   type: "boolean",
      //   options: {
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         <ActiveDeactive
      //           {...{
      //             UpdateURL: RDSLinks.DistributorMaster.DistributorMaster,
      //             activeName: "isAllowLimitAllocationToDownline",
      //             SelectedFieldName: "this Agent ",
      //             row: ServiceAPI[tableMeta.rowIndex],
      //           }}
      //         />
      //       );
      //     },
      //   },
      // },

      // {
      //   name: "actions",
      //   label: "Actions",
      // },
    ],
    [rowId, Row, ServiceAPI]
  );

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false, //"single",
    filterType: "textField",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      return (
        <React.Fragment>
          <tr>
            <td colSpan={6}>
              <TableContainer component={Paper} elevation={4} sx={{ m: 2 }}>
                <Table style={{ minWidth: "1200px" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Account Balance</TableCell>
                      <TableCell align="right">Account Type Code</TableCell>
                      <TableCell align="right">
                        Account Type Name&nbsp;
                      </TableCell>
                      <TableCell align="right">
                        Cash Back Balance&nbsp;
                      </TableCell>
                      <TableCell align="right">Dist Acc Balance</TableCell>
                      <TableCell align="right">Hold Amt&nbsp;</TableCell>
                      <TableCell align="right">Limit Balance</TableCell>
                      <TableCell align="right">
                        Transaction Credit&nbsp;
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ServiceAPI[rowMeta["rowIndex"]].accountType.map((row) => (
                      <TableRow key={row.accountTypeCode}>
                        <TableCell component="th" scope="row">
                          {row.accountBalance}
                        </TableCell>
                        <TableCell align="right">
                          {row.accountTypeCode}
                        </TableCell>
                        <TableCell align="right">
                          {row.accountTypeName}
                        </TableCell>
                        <TableCell align="right">
                          {row.cashBackBalance}
                        </TableCell>
                        <TableCell align="right">
                          {row.distAccBalance}
                        </TableCell>
                        <TableCell align="right">{row.holdAmt}</TableCell>
                        <TableCell align="right">{row.limitBalance}</TableCell>
                        <TableCell align="right">
                          {row.transactionCredit}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };
  useEffect(() => {
    document.getElementsByClassName(
      "tss-1qacubo-MUIDataTable-responsiveScrollMaxHeight"
    )[0].style.maxHeight = "66vh";
  }, []);
  const Title = ({ name, handleAdd }) => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {Loader && (
          <CircularProgress
            size={24}
            style={{ marginLeft: 15, position: "relative", top: 4 }}
          />
        )}
        <Box sx={{ fontSize: "25px", fontWeight: "bold" }}>
          {" "}
          Downline Agents{" "}
        </Box>
        {/* <Chip
          icon={<AddCircleTwoToneIcon />}
          onClick={() => console.log("chal nikal")}
          label="Add Agent"
          color="success"
        /> */}
      </Box>
    );
  };

  const theme = getMuiTheme();
  return (
    <Box
      sx={{
        // backgroundColor:"lightgreen",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        pt: 2,
      }}
    >
      <ThemeProvider theme={theme}>
        <MUIDataTable
          title={<Title />}
          // style={{ width: "100vw" }}
          data={ServiceAPI}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </Box>
  );
};

export default DownlinesAgentsDataGrid;
