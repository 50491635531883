import React, { useEffect, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
  RadialLinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";
import OrdersGrid from "../components/OrdersGrid";
import { Colors } from "../Utils/Constants";
import { GetResponse } from "../Utils/Axios/GetResponse";
import { RDSLinks } from "../Utils/Axios/RequestParameters";
import { useSelector } from "react-redux";
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  RadialLinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function OverViewPage() {
  // ......................................PIe Chart Donut Chart...............................................................
  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  const [Wallets, setWallets] = useState([
    {
      prepaidBalance: 0,
      postpaidBalance: 0,
      outstandingPayment: 0,
      holdingAmt: 0,
      accountTypeId: 1,
      accountTypeCode: "",
      accountTypeName: "",
    },
  ]);
  const getBalance = async () => {
    try {
      const Response = await GetResponse(
        {
          reportType: "LedgerCurrentBalanceSummary",
          distributorCode: AgentDetails.distributorCode,
          cmpName: AgentDetails.cmpName,
          distributorID: AgentDetails.distributorId,
          fromDate: "2022-12-02T11:27:00.129Z",
          toDate: "2022-12-02T11:27:00.129Z",
          accountTypeID: 0,
        },
        RDSLinks.APIReport,
        {}
      );
      if (Response.length > 0) {
        setWallets(Response);
      }
      console.log({ Response });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBalance();
  }, []);

  // ............................................Bar Chart .................................................................

  const Baroptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Orders",
      },
    },
  };

  const Barlabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
  ];

  const Bardata = {
    labels: Barlabels,
    datasets: [
      {
        label: "2022",
        data: [10, 20, 22, 19, 25, 35, 46, 95],
        backgroundColor: "rgba(255, 99, 132, 1)",
        borderColor: "rgba(255, 99, 132, 1)",
      },
      {
        label: "2021",
        data: [8, 10, 19, 26, 24, 44, 56, 64],
        backgroundColor: "rgba(53, 162, 235, 1)",
        borderColor: "rgba(53, 162, 235, 1)",
      },
    ],
  };
  return (
    <Box
      sx={{ p: 5, pt: 2, bgcolor: Colors.PrimaryColor[50], width: "100%" }}
      key={Wallets}
    >
      <Grid container spacing={2}>
        {Wallets.map((wallet) => {
          return (
            <Grid item xs={12} sm={6} lg={3}>
              <Paper
                elevation={4}
                sx={{
                  height: "270px",
                  p: 2,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Doughnut
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: wallet.accountTypeName,
                      },
                    },
                  }}
                  data={{
                    labels: [
                      `Prepaid Balance : ₹ ${wallet.prepaidBalance}`,
                      `postpaid Balance : ₹ ${wallet.postpaidBalance}`,
                      `Hold Amount : ₹ -${wallet.holdingAmt}`,
                    ],
                    datasets: [
                      {
                        label: " ₹ ",
                        data: [
                          wallet.prepaidBalance,
                          wallet.postpaidBalance,
                          -wallet.holdingAmt,
                        ],
                        backgroundColor: [
                          "rgba(255, 99, 132, 1)",
                          "rgba(54, 162, 235, 1)",
                          "rgba(255, 206, 86, 1)",
                        ],
                        borderColor: [
                          "rgba(255, 99, 132, 1)",
                          "rgba(54, 162, 235, 1)",
                          "rgba(255, 206, 86, 1)",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={2} sx={{ pt: 2 }}>
        <Grid item xs={12} sm={6} lg={6}>
          <Paper elevation={4} sx={{ height: "400px" }}>
            {" "}
            <OrdersGrid />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <Paper elevation={4} sx={{ height: "400px" }}>
            <Line options={Baroptions} data={Bardata} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
