import { useEffect, useMemo, useState } from "react";
import { Box, Typography, Fab } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import CRUDActions from "./CRUDActions";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  CourierServiceLinks,
  CRUDCommands,
  RDSLinks,
} from "../../Utils/Axios/RequestParameters";
import { Colors } from "../../Utils/Constants";
import swal from "sweetalert";
import SimpleBackDrops from "../../components/SimpleBackDrops";
import CommonAddEditModal from "./CommonAddEditModal";
import { Toast } from "../../components/Toaster";
export default function CommissionConfigurationDataGrid() {
  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [render, setrender] = useState("Comrender");
  const Rerender = () => {
    setrender(() => (render === "comrender" ? "comrerender" : "comrender"));
  };

  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);
  const [AgentGroupOptions, setAgentGroupOptions] = useState([
    { label: "No Group Found", value: "" },
  ]);

  const addData = {
    commissionConfigId: 0,
    groupId: 0,
    toAmount: "",
    fromAmount: "",
    companyChargesPer: "",
    companyChargesAmount: "",
    companyChargesAmountForQty: 0,
    commPer: "",
    commAmount: "",
    commAmountForQty: 0,
    level1CommPer: "",
    level1CommAmount: "",
    level1CommAmountForQty: 0,
    level2CommPer: "",
    level2CommAmount: "",
    level2CommAmountForQty: 0,
    commandId: 0,
    createBy: userId,
    createDate: "2022-05-11T11:26:35.673",
    createIp: "192.168.0.54",
    updateBy: userId,
    updateDate: "2022-07-28T17:12:04.14",
    updateIp: "192.168.0.51",
    groupType: "str",
    groupCode: "str",
  };
  const dataFieldsOrignal = [
    {
      Label: "Select Group",
      name: "groupId",
      Type: "Select",
      IsError: false,
      ErrorMsg: " ",
      validReg: /.*?/,
      grid: 12,
      options: AgentGroupOptions,
    },
    {
      Label: "From Amount",
      name: "fromAmount",
      Type: "number",
      IsError: false,
      ErrorMsg: " ",
      validReg: /.*?/,
      options: [],
    },
    {
      Label: "To Amount",
      name: "toAmount",
      Type: "number",
      IsError: false,
      ErrorMsg: " ",
      validReg: /.*?/,
      options: [],
    },
    {
      Label: "Company Charges %",
      name: "companyChargesPer",
      Type: "number",
      IsError: false,
      ErrorMsg: "Charges % should be less then 100%",
      validReg: /^100$|^\d{0,2}(\.\d{1,2})? *%?$/,
      options: [],
    },
    {
      Label: "Company Charges Amount",
      name: "companyChargesAmount",
      Type: "number",
      IsError: false,
      ErrorMsg: " ",
      validReg: /.*?/,
      options: [],
    },

    {
      Label: "Retailer Commisssion %",
      name: "commPer",
      Type: "number",
      IsError: false,
      ErrorMsg: "Commisssion % should be less then 100%",
      validReg: /^100$|^\d{0,2}(\.\d{1,2})? *%?$/,
      options: [],
    },
    {
      Label: "Retailer Commisssion Amount",
      name: "commAmount",
      Type: "number",
      IsError: false,
      ErrorMsg: " ",
      validReg: /.*?/,
      options: [],
    },

    {
      Label: "Level 1 Commisssion %",
      name: "level1CommPer",
      Type: "number",
      IsError: false,
      ErrorMsg: "Commisssion % should be less then 100%",
      validReg: /^100$|^\d{0,2}(\.\d{1,2})? *%?$/,
      options: [],
    },
    {
      Label: "Level 1 Commisssion Amount",
      name: "level1CommAmount",
      Type: "number",
      IsError: false,
      ErrorMsg: " ",
      validReg: /.*?/,
      options: [],
    },

    {
      Label: "Level 2 Commisssion %",
      name: "level2CommPer",
      Type: "number",
      IsError: false,
      ErrorMsg: "Commisssion % should be less then 100%",
      validReg: /^100$|^\d{0,2}(\.\d{1,2})? *%?$/,
      options: [],
    },
    {
      Label: "Level 2 Commisssion Amount",
      name: "level2CommAmount",
      Type: "number",
      IsError: false,
      ErrorMsg: " ",
      validReg: /.*?/,
      options: [],
    },
  ];

  const InitialError = {
    groupId: false,
    toAmount: false,
    fromAmount: false,
    companyChargesPer: false,
    companyChargesAmount: false,
    commPer: false,
    commAmount: false,
    commAmountForQty: 0,
    level1CommPer: false,
    level1CommAmount: false,
    level2CommPer: false,
    level2CommAmount: false,
  };
  const RequiredInputs = [
    "groupId",
    "toAmount",
    "fromAmount",
    "companyChargesPer",
    "companyChargesAmount",
    "commPer",
    "commAmount",
    "commAmountFor",
    "level1CommPer",
    "level1CommAmount",
    "level2CommPer",
    "level2CommAmount",
  ];
  const [dataFields, setDataFields] = useState(dataFieldsOrignal);
  useEffect(() => {
    setDataFields(dataFieldsOrignal);
    Rerender();
  }, [AgentGroupOptions]);

  const getServices = async () => {
    setLoader(true);
    try {
      const AgentGroupRequest = {
        groupId: 0,
        groupType: "",
        groupCode: "",
        groupDescription: "",
        groupTypeDesc: "",
        isActive: true,
        commandId:CRUDCommands.ListAll,
        createBy: 0,
        createDate: "2022-11-09T05:42:40.170Z",
        createIp: "",
        updateBy: 0,
        updateDate: "2022-11-09T05:42:40.170Z",
        updateIp: "",
        refId: 0,
      };
      const AgentGroup = await GetResponse(
        AgentGroupRequest,
        RDSLinks.GroupMaster,
        {}
      );

      const AgentGroupOptions = AgentGroup.filter((opt) => {
        return opt.groupType === "COU";
      });

      const AgentGroupFilteredOptions = AgentGroupOptions.map((opt) => {
        return { label: opt.groupDescription, value: opt.groupId };
      });
      console.log({ AgentGroup });
      console.log({ AgentGroupOptions });
      console.log({ AgentGroupFilteredOptions });
      setAgentGroupOptions(AgentGroupFilteredOptions);
      const Response = await GetResponse(
        {
          commissionConfigId: 0,
          groupId: 0,
          toAmount: 0,
          fromAmount: 0,
          companyChargesPer: 0,
          companyChargesAmount: 0,
          companyChargesAmountForQty: 0,
          commPer: 0,
          commAmount: 0,
          commAmountForQty: 0,
          level1CommPer: 0,
          level1CommAmount: 0,
          level1CommAmountForQty: 0,
          level2CommPer: 0,
          level2CommAmount: 0,
          level2CommAmountForQty: 0,
          commandId: CRUDCommands.ListAll,
          createBy: 0,
          createDate: "2022-10-20T10:05:21.782Z",
          createIp: "",
          updateBy: 0,
          updateDate: "2022-10-20T10:05:21.782Z",
          updateIp: "",
          groupType: "",
          groupCode: "",
        },
        CourierServiceLinks.CommissionConfiguration,
        {}
      );

      console.log(Response);
      if (Response.length > 0 && Array.isArray(Response)) {
        setServiceAPI(Response);
      } else {
        Toast.fire({
          icon: 'error',
          title: 'No Records found'
        })
        // swal("error", "No Records found", "error");
      }
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: 'error',
        title: 'No Records found'
      })
      // swal("error", "No Records Found", "error");
    }
    setLoader(false);
  };

  useEffect(() => {
    getServices();
  }, [Row]);

  const columns = useMemo(
    () => [
      {
        field: "groupCode",
        headerName: "Group Code",
        width: 120,
      },

      {
        field: "fromAmount",
        headerName: "From Amount",
        width: 100,
      },
      {
        field: "toAmount",
        headerName: "To Amount",
        width: 100,
      },
      {
        field: "companyChargesPer",
        headerName: " company Charges %",
        width: 150,
      },
      {
        field: "companyChargesAmount",
        headerName: "company Charges Amount",
        width: 200,
      },

      {
        field: "commPer",
        headerName: "Commission %",
        width: 120,
      },
      {
        field: "commAmount",
        headerName: "Commission Amount",
        width: 150,
      },

      {
        field: "level1CommPer",
        headerName: "Level 1 Commission %",
        width: 170,
      },
      {
        field: "level1CommAmount",
        headerName: "Level 1 Commission Amount",
        width: 200,
      },
      {
        field: "level2CommPer",
        headerName: "Level 2 Commission %",
        width: 170,
      },
      {
        field: "level2CommAmount",
        headerName: "Level 2 Commission Amount",
        width: 200,
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 150,
        renderCell: (params) => (
          <CRUDActions
            {...{
              params,
              rowId,
              setRowId,
              dataFields,
              InitialError,
              RequiredInputs,
              EditURL: CourierServiceLinks.CommissionConfiguration,
              DeleteURL: CourierServiceLinks.CommissionConfiguration,
              RowDeleteId: params.row.commissionConfigId,
              name: "Commission Configuration",
            }}
          />
        ),
      },
    ],
    [rowId, render, Row]
  );

  return (
    <>
      <SimpleBackDrops Loader={Loader} />

      <CommonAddEditModal
        openModal={openModal}
        handleModalClose={handleModalClose}
        handleModalOpen={handleModalOpen}
        URL={CourierServiceLinks.CommissionConfiguration}
        row={addData}
        dataFields={dataFields}
        Type={"Add"}
        InitialError={InitialError}
        RequiredInputs={RequiredInputs}
        name={"Commission Configuration"}
        key={render}
      />

      <Box
        sx={{
          height: 559,
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
          // backgroundColor:Colors.PrimaryBackgroundColor,
          // alignItems:'center'
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            textAlign: "center",
            p: 1,
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          Commission Configuration
        </Typography>

        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            ml: 5,
            mt: 1,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
          onClick={handleModalOpen}
        >
          <Add />
        </Fab>

        <DataGrid
          key={Row}
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          rows={ServiceAPI}
          getRowId={(row) => row.commissionConfigId}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
