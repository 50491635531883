import { GetResponse } from "./Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "./Axios/RequestParameters";

const GetAgentGroupCOU = async () => {
  const AgentGroupRequest = {
    groupId: 0,
    groupType: "",
    groupCode: "",
    groupDescription: "",
    groupTypeDesc: "",
    isActive: true,
    commandId: CRUDCommands.ListAll,
    createBy: 0,
    createDate: "2022-11-09T05:42:40.170Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-11-09T05:42:40.170Z",
    updateIp: "",
    refId: 0,
  };

  const AgentGroup = await GetResponse(
    AgentGroupRequest,
    RDSLinks.GroupMaster,
    {}
  );

  const AgentGroupOptions = AgentGroup.filter((opt) => {
    return opt.groupType === "COU";
  });

  const AgentGroupFilteredOptions = AgentGroupOptions.map((opt) => {
    return { label: opt.groupDescription, value: `COU:${opt.groupId}#1` };
  });

  return AgentGroupFilteredOptions;
};
export { GetAgentGroupCOU };
