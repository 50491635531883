import { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Typography, Fab } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Colors } from "../../../Utils/Constants";
import ActiveDeactive from "../ActiveDeactive";
import swal from "sweetalert";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import { CRUDCommands, RDSLinks } from "../../../Utils/Axios/RequestParameters";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import CRUDActions from "../CRUDActions";
import CommonAddEditModal from "../CommonAddEditModal";
export default function InterfaceDataGrid() {
  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);

  const addData = {
    interfaceId: 0,
    description: "",
    authKey: "",
    interfaceVersionNo: "",
    domesticSerTaxPer: 0,
    internationalSerTaxPer: 0,
    isActive: true,
    commandId:CRUDCommands.ListAll,
    createdBy: 0,
    createdDate: "2022-11-24T04:47:26.408Z",
    createIp: "",
    updatedBy: 0,
    updatedDate: "2022-11-24T04:47:26.408Z",
    updateIp: "",
    ipaddressEnabled: "",
    isWhiteLabel: true,
    loginUidexpiredSec: 0,
    applyOnServiceTax: "",
    effectiveServiceTaxDate: "2022-11-24T04:47:26.408Z",
    effectiveApplyOnServiceTax: "",
    effDomesticSerTaxPer: 0,
    effInternationalSerTaxPer: 0,
    isActiveServiceTax: true,
    distributorId: 0,
    isApi: true,
    lastInterfaceVersionNo: "",
    isAllowLastVersion: true,
    sgstper: 0,
    cgstper: 0,
    igstper: 0,
    effectiveGstdate: "2022-11-24T04:47:26.408Z",
    effSgstper: 0,
    effCgstper: 0,
    effIgstper: 0,
    isActiveGst: true,
    distributorCode: "",
    tranPwd: "",
  };
  const InitialError={
    description: false,
    interfaceVersionNo: false,
    sgstper: false,
    cgstper: false,
    igstper: false,
  };
  const RequiredInputs=[
    "description",
   "interfaceVersionNo",
    "sgstper",
    "cgstper",
    "igstper"
  ];
  const dataFields = [
    {
      Label: "Interface Version No",
      name: "interfaceVersionNo",
      Type: "text",
      IsError: false,
      ErrorMsg: "Enter Version",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Description",
      name: "description",
      Type: "text",
      IsError: false,
      ErrorMsg: "Enter Description ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "S GST %",
      name: "sgstper",
      Type: "text",
      IsError: false,
      ErrorMsg: "% should be < 100  & not empty",
      validReg: /^100$|^\d{0,2}(\.\d{1,2})? *%?$/,
      options: [],
    },
    {
      Label: "C GST %",
      name: "cgstper",
      Type: "text",
      IsError: false,
      ErrorMsg: "% should be < 100  & not empty",
      validReg: /^100$|^\d{0,2}(\.\d{1,2})? *%?$/,
      options: [],
    },
    {
      Label: "I GST %",
      name: "igstper",
      Type: "text",
      IsError: false,
      ErrorMsg: "% should be < 100  & not empty",
      validReg: /^100$|^\d{0,2}(\.\d{1,2})? *%?$/,
      options: [],
    },
  ];
  const getServices = async () => {
    setLoader(true);
    try {
      //   const Response = await axios.get(CourierServiceLinks.CourierType.GetAll);
      const Response = await GetResponse(addData, RDSLinks.InterfaceMaster, {});
      console.log(Response);
      if (Response.length > 0 && Array.isArray(Response)) {
        setServiceAPI(Response);
      } else {
        swal("error", "No Records Found", "error");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      swal("error", "No Records Found", "error");
    }
    setLoader(false);
  };

  useEffect(() => {
    getServices();
  }, [Row]);

  const columns = useMemo(
    () => [
      { field: "interfaceVersionNo", headerName: "Version No.", width: 200 },

      {
        field: "description",
        headerName: "Description",
        width: 200,
      },
      {
        field: "ipaddressEnabled",
        headerName: "IP address Enabled",
        width: 200,
      },
      {
        field: "sgstper",
        headerName: "SGST %",
        width: 100,
      },
      {
        field: "cgstper",
        headerName: "CGST %",
        width: 100,
      },
      {
        field: "igstper",
        headerName: "IGST %",
        width: 100,
      },

      {
        field: "isActive",
        headerName: "is Active",
        width: 100,
        type: "boolean",
        renderCell: (params) => (
          <ActiveDeactive
            {...{
              UpdateURL: RDSLinks.InterfaceMaster,
              activeName: "isActive",
              SelectedFieldName: "this Interface",
              row: params.row,
            }}
          />
        ),
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 150,
        renderCell: (params) => (
          <CRUDActions
            {...{
              params,
              rowId,
              setRowId,
              dataFields,
              InitialError,
              RequiredInputs,
              EditURL: RDSLinks.InterfaceMaster,
              DeleteURL: RDSLinks.InterfaceMaster,
              RowDeleteId: params.row.interfaceId,
              name: "Interface",
            }}
            key={Row}
          />
        ),
      },
    ],
    [rowId, Row]
  );

  return (
    <>
      <SimpleBackDrops Loader={Loader} />

      <CommonAddEditModal
        openModal={openModal}
        handleModalClose={handleModalClose}
        handleModalOpen={handleModalOpen}
        URL={RDSLinks.InterfaceMaster}
        row={addData}
        InitialError={InitialError}
        RequiredInputs={RequiredInputs}
        dataFields={dataFields}
        Type={"Add"}
        name={"Interface"}
        key={Row}
      />

      <Box
        sx={{
          height: 559,
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
          // backgroundColor:Colors.PrimaryBackgroundColor,
          // alignItems:'center'
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            textAlign: "center",
            p: 1,
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          Interface Master
        </Typography>

        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            ml: 5,
            mt: 1,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
          onClick={handleModalOpen}
        >
          <Add />
        </Fab>

        <DataGrid
          key={Row}
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          rows={ServiceAPI}
          getRowId={(row) => row.interfaceId}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
