import React, { useState, useEffect } from "react";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  Paper,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { Colors } from "../../Utils/Constants";
export default function GetPaymentGetwayDetailsOptions(props) {
  const {
    PGGroupTypeOptions,
    handlePGGroupType,
    PGGroupType,
    handlePaymentGetway,
    PaymentGetwayDetailsOptions,
    PaymentGetwayDetails,
  } = props;
  const CenterBox = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const CenterColumn = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <Paper
      elevation={4}
      sx={{
        width: "100%",
        height: { xs: "100%" },
        pb: 2,
        mt: 1,
      }}
    >
      <Typography
        sx={{
          width: "100%",
          height: "10%",
          ...CenterBox,
          fontSize: 25,
          fontWeight: "bold",
          backgroundColor: Colors.PrimaryBackgroundColor[800],
          color: "white",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        Make Payment
      </Typography>
      <Box
        component="form"
        validate="true"
        // onSubmit={handleSubmit}
        sx={{ p: 5 }}
        // key={Render}
      >
        <Grid container spacing={2}>
          <Grid xs={6} lg={4}>
            <ToggleButtonGroup
              orientation="vertical"
              color="primary"
              value={PGGroupType}
              exclusive
              onChange={handlePGGroupType}
              aria-label="Platform"
              fullWidth
              sx={{ p: 2 }}
            >
              {PGGroupTypeOptions.map((IndPGGroupType) => {
                return (
                  <ToggleButton value={JSON.stringify(IndPGGroupType)}>
                    {IndPGGroupType.pggroupTypeName}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Grid>
          <Grid xs={6} lg={6}>
            <ToggleButtonGroup
              color="warning"
              orientation="vertical"
              value={PaymentGetwayDetails}
              exclusive
              onChange={handlePaymentGetway}
              aria-label="Platform"
              fullWidth
              sx={{ p: 2 }}
            >
              {PaymentGetwayDetailsOptions.map((IndPaymentGetwayDetails) => {
                return (
                  <ToggleButton value={JSON.stringify(IndPaymentGetwayDetails)}>
                    {IndPaymentGetwayDetails.pgName}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
