import React, { useEffect, useState } from "react";
import { Grid, TextField, MenuItem } from "@mui/material";
import { GetAgentTypeOptions } from "../Utils/GetAgentTypeOptions";
import { GetAgentCategoryOptions } from "../Utils/GetAgentCategoryOptions";
import { GetAgentGroupCOU } from "../Utils/GEtAgentGroupCOU";


// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<How To Use Component>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
{/* <AgentSelection 
 AgentTypeValue={}
 AgentCategoryValue={}
 AgentGroupValue={}
 grid={}
 ValidateInput={}
/> */}
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<How To Use Component>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

function AgentSelection({
  AgentTypeName = "typeId",
  AgentCategoryName = "typeDetId",
  AgentGroupName = "distributorGroupConfiguration",
  AgentTypeValue='',
  AgentCategoryValue='',
  AgentGroupValue='',
  grid,
  ValidateInput,
}) {
  const [AgentTypeOptions, setAgentTypeOptions] = useState([
    {
      label: AgentTypeValue ? AgentTypeValue : "Refresh the Page",
      value: AgentTypeValue,
    },
  ]);
  const [AgentCategoryOptions, setAgentCategoryOptions] = useState([
    {
      label: AgentCategoryValue ? AgentCategoryValue : "Please Select Agent Type",
      value: AgentCategoryValue,
    },
  ]);
  const [AgentGroupOptions, setAgentGroupOptions] = useState([
    {
      label: AgentGroupValue ? AgentGroupValue : "Referesh",
      value: AgentGroupValue,
    },
  ]);

  const GetAllOptions = async () => {
    if (AgentTypeOptions.length < 2) {
      const TempAgentTypeOptions = await GetAgentTypeOptions();
      setAgentTypeOptions(TempAgentTypeOptions);

      if (TempAgentTypeOptions.length > 0) {
        if (AgentTypeValue !== ""  && AgentTypeValue !== 0) {
          const TempAgentCategoryOptions = await GetAgentCategoryOptions(AgentTypeValue);
          setAgentCategoryOptions(TempAgentCategoryOptions);
        
        }
      }


    }

    const TempAgentGroupOptions = await GetAgentGroupCOU();
    setAgentGroupOptions(TempAgentGroupOptions);
  };
  useEffect(() => {
    GetAllOptions();
  }, []);

  const GetAgentCategoryOption = async (typeId) => {
    if (typeId !== "") {
      const TempAgentCategoryOptions = await GetAgentCategoryOptions(typeId);
      setAgentCategoryOptions(TempAgentCategoryOptions);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          name={AgentTypeName}
          value={AgentTypeValue}
          select={true}
          required={true}
          fullWidth
          id={AgentTypeName}
          label="Select Agent Type"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            ValidateInput(e, /[^\n]+/);
            GetAgentCategoryOption(e.target.value);
          }}
        >
          {AgentTypeOptions.length > 0 ? (
            AgentTypeOptions.map((option) => (
              <MenuItem key={`${option.label}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          ) : (
            <></>
          )}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          name={AgentCategoryName}
          value={AgentCategoryValue}
          select={true}
          required={true}
          fullWidth
          id={AgentCategoryName}
          label="Select Agent Category"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            ValidateInput(e, /[^\n]+/);
          }}
        >
          {AgentCategoryOptions.length > 0 ? (
            AgentCategoryOptions.map((option) => (
              <MenuItem key={`${option.label}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          ) : (
            <></>
          )}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          name={AgentGroupName}
          value={AgentGroupValue}
          select={true}
          required={true}
          fullWidth
          id={AgentGroupName}
          label="Select Agent Group"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => ValidateInput(e, /[^\n]+/)}
        >
          {AgentGroupOptions.length > 0 ? (
            AgentGroupOptions.map((option) => (
              <MenuItem key={`${option.label}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          ) : (
            <></>
          )}
        </TextField>
      </Grid>
    </>
  );
}
export default React.memo(AgentSelection);
