import { configureStore } from "@reduxjs/toolkit";
import AdminDetailsSlice from "./features/AdminDetailsSlice";
import AdminTokenSlice from "./features/AdminTokenSlice";
import AgentDetailSlice from "./features/AgentDetailsSlice";
import AgentTokenSlice from "./features/AgentTokenSlice";
import ModeSlice from "./features/ModeSlice";
import RowSlice from "./features/RowSlice";
import  SiteMGMTDetailsSlice  from "./features/SiteMGMTDetailSlice";
import SiteMGMTTokenSlice from "./features/SiteMGMTTokenSlice";
export default configureStore({
  reducer: {
    AgentToken: AgentTokenSlice,
    AdminToken: AdminTokenSlice,
    SiteMGMTToken:SiteMGMTTokenSlice,
    SiteMGMTDetails:SiteMGMTDetailsSlice,
    AgentDetails: AgentDetailSlice,
    AdminDetails:AdminDetailsSlice,
    Mode: ModeSlice,
    Row: RowSlice,
  },
});
