import { useEffect, useMemo, useState } from "react";
import { Box, Typography, Fab } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import CRUDActions from "./CRUDActions";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { CourierServiceLinks, CRUDCommands } from "../../Utils/Axios/RequestParameters";
import { Colors } from "../../Utils/Constants";
import ActiveDeactive from "./ActiveDeactive";
import swal from "sweetalert";
import SimpleBackDrops from "../../components/SimpleBackDrops";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import CommonAddEditModal from "./CommonAddEditModal";
export default function CourierAPIDataGrid() {
  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);

  const addData = {
    apiid: 0,
    apiname: "",
    apicode: "",
    apiurl: "",
    emailId: "",
    mobileNo: "",
    apiuserId: "",
    apipassword: "",
    apiSetting: "",
    isActive: false,
    isActiveBooking: false,
    isActiveSearch: false,
    commandId:CRUDCommands.ListAll,
    createBy: userId,
    createDate: "2022-11-29T12:02:30.929Z",
    createIp: "",
    updateBy: userId,
    updateDate: "2022-11-29T12:02:30.929Z",
    updateIp: "",
  };
  const dataFields = [
    {
      name: "apicode",
      Label: "API Code",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      name: "apiurl",
      Label: "API URL",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      name: "apiname",
      Label: "Name",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      name: "apiSetting",
      Label: "API Setting",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Email ID",
      name: "emailId",
      Type: "email",
      IsError: false,
      ErrorMsg: "Invalid email address",

      validReg: /^[A-Za-z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      options: [],
    },
    {
      Label: "Mobile NO",
      name: "mobileNo",
      Type: "number",
      IsError: false,

      ErrorMsg: "Invalid mobile number ",
      validReg: /^(\+\d{1,3}[- ]?)?\d{10}$/,
      options: [],
    },
    {
      Label: "API USer ID",
      name: "apiuserId",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "API Password",
      name: "apipassword",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    // {Label:'IS Active',
    //   name: "isActive",
    //   Type: "CheckBox",
    //   IsError: false,
    // ErrorMsg: " ",
    // validReg:/[^\n]+/,
    //   options: [],
    // },
    // { Label:'IS Active Booking',
    //   name: "isActiveBooking",
    //   Type: "CheckBox",
    //   IsError: false,
    // ErrorMsg: " ",
    // validReg:/[^\n]+/,
    //   options: [],
    // },
    // {Label:'IS Active Search',
    //   name: "isActiveSearch",
    //   Type: "CheckBox",
    //   IsError: false,
    // ErrorMsg: " ",
    // validReg:/[^\n]+/,
    //   options: [],
    // },
  ];

  const InitialError = {
    apiname: false,
    apicode: false,
    apiurl: false,
    emailId: false,
    mobileNo: false,
    apiuserId: false,
    apipassword: false,
    apiSetting: false,
  };
  const RequiredInputs = [
    "apiname",
    "apicode",
    "apiurl",
    "emailId",
    "mobileNo",
    "apiuserId",
    "apipassword",
    "apiSetting",
  ];
  const getServices = async () => {
    setLoader(true);
    try {
      const Response = await GetResponse(
        addData,
        CourierServiceLinks.CourierAPI,
        {}
      );
      console.log(Response);
      if (Response.length > 0 && Array.isArray(Response)) {
        setServiceAPI(Response);
      } else {
        swal("error", "No Records Found", "error");
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Records Found", "error");
    }
    setLoader(false);
  };

  useEffect(() => {
    getServices();
  }, [Row]);

  const columns = useMemo(
    () => [
      { field: "apicode", headerName: "API Code", width: 150 },
      { field: "apiname", headerName: "Name", width: 120 },
      { field: "apiurl", headerName: "API URL", width: 250 },

      { field: "apiSetting", headerName: "API Setting", width: 150 },

      { field: "emailId", headerName: "Email", width: 200 },
      {
        field: "mobileNo",
        headerName: "MobileNo",
        width: 100,
      },
      {
        field: "apiuserId",
        headerName: "UserId",
        width: 100,
      },
      {
        field: "apipassword",
        headerName: "Password",
        width: 100,
      },
      {
        field: "isActive",
        headerName: "isActive",
        width: 100,
        type: "boolean",
        renderCell: (params) => (
          <ActiveDeactive
            {...{
              UpdateURL: CourierServiceLinks.CourierAPI,
              activeName: "isActive",
              SelectedFieldName: "this Courier API",
              row: params.row,
            }}
          />
        ),
      },
      {
        field: "isActiveBooking",
        headerName: "ActiveBooking",
        width: 110,
        type: "boolean",
        renderCell: (params) => (
          <ActiveDeactive
            {...{
              UpdateURL: CourierServiceLinks.CourierAPI,
              activeName: "isActiveBooking",
              SelectedFieldName: "Booking of this Courier API",
              row: params.row,
            }}
          />
        ),
      },
      {
        field: "isActiveSearch",
        headerName: "ActiveSearch",
        width: 110,
        type: "boolean",
        renderCell: (params) => (
          <ActiveDeactive
            {...{
              UpdateURL: CourierServiceLinks.CourierAPI,
              activeName: "isActiveSearch",
              SelectedFieldName: "Search Availibility of this Courier API",
              row: params.row,
            }}
          />
        ),
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 200,
        renderCell: (params) => (
          <CRUDActions
            {...{
              params,
              rowId,
              setRowId,
              dataFields,
              InitialError,
              RequiredInputs,
              EditURL: CourierServiceLinks.CourierAPI,
              DeleteURL: CourierServiceLinks.CourierAPI,
              RowDeleteId: params.row.apiid,
              name: "Courier Api",
            }}
          />
        ),
      },
    ],
    [rowId, Row]
  );

  return (
    <>
      <SimpleBackDrops Loader={Loader} />

      <CommonAddEditModal
        openModal={openModal}
        handleModalClose={handleModalClose}
        handleModalOpen={handleModalOpen}
        URL={CourierServiceLinks.CourierAPI}
        row={addData}
        InitialError={InitialError}
        RequiredInputs={RequiredInputs}
        dataFields={dataFields}
        Type={"Add"}
        name={"Courier Api"}
      />

      <Box
        sx={{
          height: 559,
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            textAlign: "center",
            p: 1,
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          Courier Service API
        </Typography>

        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            ml: 5,
            mt: 1,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
          onClick={handleModalOpen}
        >
          <Add />
        </Fab>

        <DataGrid
          key={Row}
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          rows={ServiceAPI}
          getRowId={(row) => row.apiid}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
