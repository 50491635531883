const NavigationLinks = {
  HomePage: "/",
  OverView: "/OverView",
  NewOrder: "/NewOrder",
  UpdatePassword: "/UpdatePassword",
  UpdateTnxPassword: "/UpdateTnxPassword",

  UpdateProfile: "/UpdateProfile",
  Orders: "/Order",
  Tracking: "/Tracking",
  Billing: "/Billing",
  Report: "/Report",
  Plan: "/Plan",
  Settings: "/Settings",
  Feedback: "/FeedBack",
  RDS: "/RDS",
  Help: "/Help",

  AvailablePartners: "/AvailablePartners",

  Admin: {
    AdminSignIn: "/Admin",
    Admin: "/Admin/",
    CourierType: "/Admin/CourierType",
    CourierAPI: "/Admin/CourierAPI",
    CourierServiceProvider: "/Admin/CourierServiceProvider",
    CommissionConfiguration: "/Admin/CommissionConfiguration",
    APICourierServiceProviderLink: "/Admin/APICourierServiceProviderLink",
    Users: "/Admin/Users",
    Role: "/Admin/Role",
    AgentsGroup: "/Admin/AgentGroup",
  },
};
export { NavigationLinks };

// <Route path="/" element={<HomePage />}></Route>

// <Route path="/DashBoard" element={<DashBoared />}>
//   <Route path="/DashBoard" index element={<OverViewPage />}></Route>
//   <Route path="/DashBoard/NewOrder" element={<NewOrder />}></Route>
//   <Route
//     path="/DashBoard/UpdatePassword"
//     element={<ChangePassword />}
//   ></Route>
//   <Route
//     path="/DashBoard/UpdateProfile"
//     element={<UpdateProfile />}
//   ></Route>
//   <Route path="/DashBoard/Order" element={<OverViewPage />}></Route>
//   <Route path="/DashBoard/Tracking" element={<OverViewPage />}></Route>
//   <Route path="/DashBoard/Billing" element={<OverViewPage />}></Route>
//   <Route path="/DashBoard/Report" element={<OverViewPage />}></Route>
//   <Route path="/DashBoard/Plan" element={<OverViewPage />}></Route>
//   <Route path="/DashBoard/Settings" element={<OverViewPage />}></Route>
//   <Route path="/DashBoard/FeedBack" element={<OverViewPage />}></Route>
//   <Route path="/DashBoard/Help" element={<OverViewPage />}></Route>
//   <Route path="/DashBoard/RDS" element={<OverViewPage />}></Route>
// </Route>
// <Route path="/pages" element={<Page />}>
//   <Route
//     path="/pages/AvailablePartners"
//     element={<AvailablePartners />}
//   ></Route>
// </Route>

// <Route path="/Admin" element={<AdminDashBoard />}>
// <Route path="/Admin" element={<AdminSignInPage/>}/>

// <Route path="/Admin/CourierAPI" element={<CourierAPIDataGrid/>}/>

// </Route>

// <Route path="/signup" element={<SignUpPage />}></Route>
// </Routes>
