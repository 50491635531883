import React from "react";
import { useEffect, useMemo, useState } from "react";
import { Box, Typography, Fab } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import CRUDActions from "../CRUDActions";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Colors } from "../../../Utils/Constants";
import ActiveDeactive from "../ActiveDeactive";
import swal from "sweetalert";
import { CRUDCommands, RDSLinks } from "../../../Utils/Axios/RequestParameters";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import CommonAddEditModal from "../CommonAddEditModal";
export default function AgentsGroupDataGrid() {
  const PanelOptions = ["Admin", "Agent", "Provider"];
  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [render, setrender] = useState("render");
  const Rerender = () => {
    setrender(() => (render === "render" ? "rerender" : "render"));
  };
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);
  const [GroupTypeOptions, SetGroupTypeOptions] = useState([
    { value: "", label: "Please Wait " },
  ]);
  const addData = {
    groupId: 0,
    groupType: "",
    groupCode: "",
    groupDescription: "",
    groupTypeDesc: "",
    isActive: true,
    commandId: CRUDCommands.ListAll,
    createBy: 0,
    createDate: "2022-11-09T05:42:40.170Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-11-09T05:42:40.170Z",
    updateIp: "",
    refId: 0,
  };

  const InitialError = {
    groupType: false,
    groupCode: false,
    groupDescription: false,
    groupTypeDesc: false,
  };
  const RequiredInputs = [
    "groupType",
    "groupCode",
    "groupDescription",
    "groupTypeDesc",
  ];
  const dataFieldsorgin = [
    {
      name: "groupType",
      Label: "Group Type",
      Type: "Select",
      IsError: false,
      ErrorMsg: "Enter Group Type ",
      validReg: /[^\n]+/,
      options: GroupTypeOptions,
    },
    {
      Label: "Group Code",
      name: "groupCode",
      Type: "text",
      IsError: false,
      ErrorMsg: "Enter Group Code",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Group Description",
      name: "groupDescription",
      Type: "text",
      IsError: false,
      ErrorMsg: "Enter Group Description",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Group Type Description",
      name: "groupTypeDesc",
      Type: "text",
      IsError: false,
      ErrorMsg: "Enter Group Type Description",
      validReg: /[^\n]+/,
      options: [],
    },
  ];
  const [dataFields, setDataFields] = useState(dataFieldsorgin);
  useMemo(() => {
    const tempFields = dataFieldsorgin;
    tempFields[0] = { ...tempFields[0], options: GroupTypeOptions };
    setDataFields(tempFields);
  }, [GroupTypeOptions]);

  useEffect(() => {
    Rerender();
  }, [dataFields]);
  const getServices = async () => {
    setLoader(true);
    try {
      // const Response = await axios.get(Roles.GetAdminRole);
      const GroupTypeOptions = await GetResponse(
        {
          groupTypeId: 0,
          groupType: "",
          groupTypeDesc: "",
          orderType: "",
          orderRequestTypeId: 0,
          commandId: CRUDCommands.ListAll,
          createBy: 0,
          createDate: "2022-11-12T04:29:18.650Z",
          createIp: "text",
          updateBy: 0,
          updateDate: "2022-11-12T04:29:18.650Z",
          updateIp: "text",
          orderTypeName: "text",
        },
        RDSLinks.GroupTypeMaster,
        {}
      );
      if (GroupTypeOptions.length > 0 && Array.isArray(GroupTypeOptions)) {
        console.log(GroupTypeOptions);
        const Options = GroupTypeOptions.map((indOptions) => {
          return {
            label: indOptions.groupTypeDesc,
            value: indOptions.groupType,
          };
        });
        console.log(Options);
        SetGroupTypeOptions(Options);
      } else {
        SetGroupTypeOptions([{ label: "No Group Found", value: "" }]);
      }

      const Response = await GetResponse(addData, RDSLinks.GroupMaster, {});

      console.log(Response);
      if (Response.length > 0 && Array.isArray(Response)) {
        setServiceAPI(Response);
      } else {
        swal("error", "No Records Found", "error");
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Records Found", "error");
    }
    setLoader(false);
  };

  useMemo(() => {
    getServices();
  }, [Row]);

  const columns = useMemo(
    () => [
      { field: "groupType", headerName: "Group Type", width: 200 },
      { field: "groupCode", headerName: "Group Code", width: 200 },
      {
        field: "groupDescription",
        headerName: "Group Description",
        width: 200,
      },
      {
        field: "groupTypeDesc",
        headerName: "Group Type Description",
        width: 200,
      },
      {
        field: "isActive",
        headerName: "isActive",
        width: 100,
        type: "boolean",
        renderCell: (params) => (
          <ActiveDeactive
            {...{
              UpdateURL: RDSLinks.GroupMaster,
              activeName: "isActive",
              SelectedFieldName: "this Agent Group",
              row: params.row,
            }}
          />
        ),
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 150,
        renderCell: (params) => (
          <CRUDActions
            {...{
              params,
              rowId,
              setRowId,
              dataFields,
              RequiredInputs,
              InitialError,
              EditURL: RDSLinks.GroupMaster,
              DeleteURL: RDSLinks.GroupMaster,
              RowDeleteId: params.row.groupId,
              name: "Agents Group",
            }}
          />
        ),
      },
    ],
    [rowId, render, Row]
  );

  return (
    <>
      <SimpleBackDrops Loader={Loader} />

      <CommonAddEditModal
        openModal={openModal}
        handleModalClose={handleModalClose}
        handleModalOpen={handleModalOpen}
        URL={RDSLinks.GroupMaster}
        row={addData}
        RequiredInputs={RequiredInputs}
        InitialError={InitialError}
        dataFields={dataFields}
        Type={"Add"}
        name={"Agent Group"}
      />

      <Box
        sx={{
          height: 559,
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            textAlign: "center",
            p: 1,
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          Agent Group
        </Typography>

        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            ml: 5,
            mt: 1,
            mr: 5,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
          onClick={handleModalOpen}
        >
          <Add />
        </Fab>

        <DataGrid
          key={`${Row}${render}`}
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          rows={ServiceAPI}
          getRowId={(row) => row.groupId}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
