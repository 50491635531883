import { Box, Chip, Fab } from "@mui/material";
import { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import { green, red, grey, orange } from "@mui/material/colors";
import Swal from "sweetalert2";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "../../../Utils/Axios/RequestParameters";
import { useDispatch } from "react-redux";
import { UpdateRow } from "../../../features/RowSlice";
import ApproveAgentRegistrationRequestModal from "./ApproveAgentRegistrationRequestModal";

const PendingRegistrationRequestActions = ({
  params,
  rowId,
  name,
  Type,
  URL,
  dataFields,
}) => {
  const dispatch = useDispatch();

  const [openModal, setopenModal] = useState(false);
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [success, setSuccess] = useState(false);

  const handleDelete = () => {
    Swal.fire({
      title: "Enter Reason for Rejection",
      input: "text",
      icon: "warning",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Reject",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        const data = params.row;
        data.commandId = CRUDCommands.RejectRegRequest;
        data.userRemark = login;
        data.remark = login;
        data.Pwd = "";
        data.TranPwd = "";
        data.VerifiedIp = "";

        console.log(data);
        return await GetResponse(
          data,
          RDSLinks.DistributorMaster.DistributorRegistration,
          {}
        )
          .then((response) => {
            if (!response) {
              throw new Error(response.errorDesc);
            }
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      console.log(result);
      if (result.value) {
        dispatch(UpdateRow());

        Swal.fire({
          title: "Success",
          text: `${result.value}!`,
          icon: "success",
        });
      }
    });
  };
  useEffect(() => {
    if (rowId === params.id && success) setSuccess(false);
  }, [rowId]);

  return (
    <>
      {openModal === true ? (
        <ApproveAgentRegistrationRequestModal
          openModal={openModal}
          handleModalClose={handleModalClose}
          handleModalOpen={handleModalOpen}
          row={params.row}
          dataFields={dataFields}
          Type={Type}
          URL={URL}
          name={name}
          key={params.id}
        />
      ) : (
        <></>
      )}

      <Box
        sx={{
          m: 1,
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Chip
          label="Approve"
          onClick={handleModalOpen}
          //   onDelete={handleDelete}
          deleteIcon={<DoneIcon />}
          sx={{
            width: 100,
            // height: 40,
            color: "white",
            bgcolor: green[300],
            "&:hover": { bgcolor: green[700] },
          }}
        />
        <Chip
          label="Reject"
          onClick={handleDelete}
          // onDelete={handleDelete}
          deleteIcon={<DeleteIcon />}
          sx={{
            width: 100,
            // height: 40,
            color: "white",

            bgcolor: red[300],
            "&:hover": { bgcolor: red[700] },
          }}
        />
      </Box>
    </>
  );
};

export default PendingRegistrationRequestActions;
