import { Switch, Tooltip } from "@mui/material";
import React from "react";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import { useDispatch } from "react-redux";
import { UpdateRow } from "../../features/RowSlice";
import swal from "sweetalert";
import { CRUDCommands } from "../../Utils/Axios/RequestParameters";
export default function ActiveDeactive({
  UpdateURL,
  row,
  activeName,
  SelectedFieldName,
}) {
  console.log({ row });
  const Value = row[activeName];
  const dispatch = useDispatch();
  const UpdateSelectedRow = async () => {
    swal({
      title: "Are you sure?",
      text:
        Value === true
          ? `You will Deactive ${SelectedFieldName}`
          : `You will Activate ${SelectedFieldName}`,
      icon: "warning",
      buttons: ["No, cancel it !", "Yes, I am sure!"],
      dangerMode: true,
      timer: 5000,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        console.log(`${UpdateURL}${row}`);
        const newRow = row;
        newRow[activeName] = Value === true ? false : true;
        newRow["commandId"] = CRUDCommands.Update;
        const response = await GetResponse(newRow, UpdateURL, {});
        console.log(response, "From Active Deactive", newRow, UpdateURL);
      if(response===true) { swal({
          title: Value === true ? "Deactivated!" : "Activated!",
          text: `Changes Updated Successfully`,
          icon: "success",
          timer: 3000,
        });}else{
          swal({
            title: 'Error',
            text: `unable to process!`,
            icon: "error",
            timer: 3000,
          });
        }
        dispatch(UpdateRow());
      } else {
        swal({
          title: "Cancelled",
          text: "Nothing Changed :)",
          icon: "error",
          timer: 3000,
        });
      }
    });
  };

  return (
    <Tooltip title={` Click to ${Value===true?" Deactivate ":" Activate "} `} arrow>
    <Switch
      checked={Value}
      onChange={() => UpdateSelectedRow()}
      inputProps={{ "aria-label": "controlled" }}
    />
    </Tooltip>

  );
}
