import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Colors } from "../../Utils/Constants";
export default function BookingDetails() {
  const location = useLocation();
  const BookingDetailsObj = location.state;
  const BookingDetails = BookingDetailsObj.orders[0];
  const [fileType, setFileType] = useState("pdf");
  console.log(BookingDetails);
  function getExtension(filename) {
    return filename.split(".").pop();
  }
  const TypographyStyleHead = {
    height: "50px",
    fontWeight: "bold",
    fontSize: "15px",
    p: 2,
    color: Colors.SecondaryColor[500],
  };
  const TypographyStyleData = {
    height: "50px",
    fontWeight: "light",
    fontSize: "15px",
    p: 2,
    color: Colors.SecondaryColor[500],
  };

  return (
    <Box sx={{ width: "100%", height: "100%", p: 5 }}>
      <Paper elevation={4} sx={{ width: "100%", height: "100%", p: 2 }}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                height: "50px",
                bgcolor: Colors.PrimaryBackgroundColor[300],
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "25px" }}>
                Booking Details
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography sx={TypographyStyleHead}>Invoice No</Typography>
              <Typography
                sx={{
                  ...TypographyStyleHead,
                  bgcolor: Colors.SecondaryColor[50],
                }}
              >
                Reference No
              </Typography>
              <Typography sx={TypographyStyleHead}>Booking Status</Typography>
              <Typography
                sx={{
                  ...TypographyStyleHead,
                  bgcolor: Colors.SecondaryColor[50],
                }}
              >
                Tracking No
              </Typography>
              <Typography sx={TypographyStyleHead}>
                Transaction Number
              </Typography>
              <Typography
                sx={{
                  ...TypographyStyleHead,
                  bgcolor: Colors.SecondaryColor[50],
                }}
              >
                Service Provider
              </Typography>
              <Typography sx={TypographyStyleHead}>Amount</Typography>
              <Typography
                sx={{
                  ...TypographyStyleHead,
                  bgcolor: Colors.SecondaryColor[50],
                }}
              >
                Shimpent Label Link
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={8}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography sx={TypographyStyleData}>
                : {BookingDetails.orderPaymentDetail[0].invoiceNo}
              </Typography>

              <Typography
                sx={{
                  ...TypographyStyleData,
                  bgcolor: Colors.SecondaryColor[50],
                }}
              >
                : {BookingDetails.refNo}
              </Typography>

              <Typography sx={TypographyStyleData}>
                : {BookingDetails.statusDescription}
              </Typography>

              <Typography
                sx={{
                  ...TypographyStyleData,
                  bgcolor: Colors.SecondaryColor[50],
                }}
              >
                : {BookingDetails.apitrackingNumber}
              </Typography>

              <Typography sx={TypographyStyleData}>
                : {BookingDetails.apitransactionNo}
              </Typography>

              <Typography
                sx={{
                  ...TypographyStyleData,
                  bgcolor: Colors.SecondaryColor[50],
                }}
              >
                : {BookingDetails.courierServiceProviderName}
              </Typography>

              <Typography sx={TypographyStyleData}>
                : {BookingDetails.amount}
              </Typography>
              <Typography
                sx={{
                  ...TypographyStyleData,
                  bgcolor: Colors.SecondaryColor[50],
                  flexWrap: "wrap",
                }}
              >
                :{" "}
                {BookingDetails.shipmentLabel !== "" &&
                BookingDetails.shipmentLabel !== null ? (
                  <a href={BookingDetails.shipmentLabel} target="_blank">
                    Click Here to View
                  </a>
                ) : (
                  "Not Available"
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
