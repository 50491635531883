import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { GetResponse } from "../Utils/Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "../Utils/Axios/RequestParameters";
import { SignUpInputFields } from "../Utils/SignUpInputFields";
import swal from "sweetalert";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import StateCityCountry from "../Admin/Components/StateCityCountry";
import SimpleBackDrops from "../components/SimpleBackDrops";
import { UpdateDetails } from "../features/AgentDetailsSlice";
import { FileInputFields } from "../Utils/RegistrationRequestInputFields";

export default function UPdateProfile() {
  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  const Token = useSelector((state) => state.AgentToken.value);

  const navigate = useNavigate();
  console.log({ AgentDetails });
  const [data, setdata] = useState({
    name: AgentDetails["name"] ? AgentDetails["name"] : "",
    email: AgentDetails["email"] ? AgentDetails["email"] : "",
    mobileNo: AgentDetails["mobile"] ? AgentDetails["mobile"] : "",
    cmpName: AgentDetails["cmpName"] ? AgentDetails["cmpName"] : "",
    add1: AgentDetails["add1"] ? AgentDetails["add1"] : "",
    add2: AgentDetails["add2"] ? AgentDetails["add2"] : "",
    add3: AgentDetails["add3"] ? AgentDetails["add3"] : "",
    city: AgentDetails["city"] ? AgentDetails["city"] : "",
    state: AgentDetails["state"] ? AgentDetails["state"] : "",
    country: AgentDetails["country"] ? AgentDetails["country"] : "",
    pincode: AgentDetails["pincode"] ? AgentDetails["pincode"] : "",
    phone: AgentDetails["phone"] ? AgentDetails["phone"] : "",
    alternateEmail: AgentDetails["alternateEmail"]
      ? AgentDetails["alternateEmail"]
      : "",
    pan: AgentDetails["pan"] ? AgentDetails["pan"] : "",
    pandocImg: AgentDetails["pandocImg"] ? AgentDetails["pandocImg"] : "",
    dateOfBirth: AgentDetails["dateOfBirth"]
      ? moment(AgentDetails["dateOfBirth"]).format("YYYY-MM-DD")
      : "",
    title: AgentDetails["title"] ? AgentDetails["title"] : "",
    lastName: AgentDetails["lastName"] ? AgentDetails["lastName"] : "",
    aadharNo: AgentDetails["aadharNo"] ? AgentDetails["aadharNo"] : "",
    gstcompanyName: AgentDetails["gstcompanyName"]
      ? AgentDetails["gstcompanyName"]
      : "",
    gstno: AgentDetails["gstno"] ? AgentDetails["gstno"] : "",
    gstphone: AgentDetails["gstphone"] ? AgentDetails["gstphone"] : "",
    gstemail: AgentDetails["gstemail"] ? AgentDetails["gstemail"] : "",
    gstaddress: AgentDetails["gstaddress"] ? AgentDetails["gstaddress"] : "",
    gstpincode: AgentDetails["gstpincode"] ? AgentDetails["gstpincode"] : "",
    gststate: AgentDetails["gststate"] ? AgentDetails["gststate"] : "",
    gstcity: AgentDetails["gstcity"] ? AgentDetails["gstcity"] : "",
    logo: AgentDetails["logo"] ? AgentDetails["logo"] : "",
  });

  const [UpdatedDataField, setUpdatedDataField] = useState(SignUpInputFields);
  const dispatch = useDispatch();
  const [AllError, setAllError] = useState({
    name: false,
    email: false,
    mobileNo: false,
    cmpName: false,
    add1: false,
    add2: false,
    add3: false,
    city: false,
    state: false,
    country: false,
    pincode: false,
    phone: false,
    alternateEmail: false,
    pan: false,
    pandocImg: false,
    dateOfBirth: false,
    title: false,
    lastName: false,
    aadharNo: false,
    gstcompanyName: false,
    gstno: false,
    gstphone: false,
    gstemail: false,
    gstaddress: false,
    gstpincode: false,
    gststate: false,
    gstcity: false,
    logo: false,
  });

  // .........................,,,,,,,,,,,,,,,,,,,,,,,.............................................................................
  const [Render, setRender] = React.useState("render");
  const [UploadImages, setUploadImages] = React.useState("");

  const [Loader, setLoader] = React.useState(false);

  const Rerender = () => {
    setRender(() => {
      return Render === "render" ? "rerender" : "render";
    });
  };
  const updateAgentDetails = async () => {
    try {
      const Data = {
        token: Token,
        typeDetId: 0,
        name: "",
        distributorCode: "",
        cmpName: "",
        add1: "",
        add2: "",
        add3: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        phone: "",
        mobile: "",
        fax: "",
        pan: "",
        email: "",
        tranPwd: "",
        dateOfBirth: "2022-11-26T09:26:28.086Z",
        distributorLevel: 0,
        level1DistributorId: 0,
        level2DistributorId: 0,
        joiningDate: "2022-11-26T09:26:28.086Z",
        padd1: "",
        padd2: "",
        padd3: "",
        pcity: "",
        pstate: "",
        pcountry: "",
        ppincode: "",
        pphone: "",
        pmobile: "",
        pfax: "",
        isCustomer: true,
        isActive: true,
        activationRemark: "",
        commandId: CRUDCommands.ListAll,
        createBy: 0,
        createDate: "2022-11-26T09:26:28.086Z",
        createIp: "",
        updateBy: 0,
        updateDate: "2022-11-26T09:26:28.086Z",
        updateIp: "",
        gender: "",
        isPaid: true,
        isProfileUpdated: true,
        invoiceNo: 0,
        paidDateTime: "2022-11-26T09:26:28.086Z",
        paidAmount: 0,
        isForm60: true,
        registrationAmount: 0,
        maintenanceChargeDetId: 0,
        isGenerateComm: true,
        isTranPwdUpdated: true,
        isTranAllowed: true,
        tranAllowedRemark: "",
        maritalStatus: "",
        occupation: "",
        activationDate: "2022-11-26T09:26:28.086Z",
        isEmailSmssent: true,
        isFirstLogin: true,
        logoImageUrl: "",
        isLogoDisplay: true,
        masterDistributorId: 0,
        userSeqNo: 0,
        loginName: "",
        pwd: "",
        isAllowLimitAllocationToDownline: true,
        alternateEmail: "",
        pandocImg: "",
        userRemark: "",
        isSendBalanceReminder: true,
        reminderBalance: 0,
        reminderInterval: 0,
        isSendReminderEmail: true,
        isSendReminderSms: true,
        isSerTaxOnSerCharge: true,
        isAllowToAddDownline: true,
        isTranPwdMendate: true,
        anniversaryDate: "2022-11-26T09:26:28.086Z",
        isSendDobintimationToCompany: true,
        title: "",
        lastName: "",
        designation: "",
        labelCaption: "",
        isSendPromotionalEmail: true,
        distributorUserAccessTimeId: 0,
        activenessCategoryId: 0,
        visitingCardReceiptDate: "2022-11-26T09:26:28.086Z",
        aadharNo: "",
        gstcompanyName: "",
        gstno: "",
        gstphone: "",
        gstemail: "",
        gstaddress: "",
        gstpincode: "",
        gststate: "",
        gstcity: "",
        countryCode: "",
        logo: "",
        userDistributorID: AgentDetails.distributorId,
        distributorId: AgentDetails.distributorId,
        limitBalance: 0,
        creditAllowed: 0,
        level1DistributorCode: "",
        transactionCredit: 0,
        tranCreditUtilized: 0,
        salesPersonId: 0,
        verificationCode: "",
        distributorGroupConfiguration: "",
        holdAmt: 0,
        countryLastUpdated: "2022-11-26T09:26:28.087Z",
        masterDistributorName: "",
        masterDistributorCode: "",
        bankLastUpdated: "2022-11-26T09:26:28.087Z",
        stateLastUpdated: "2022-11-26T09:26:28.087Z",
        cityLastUpdated: "2022-11-26T09:26:28.087Z",
        rewardPointBalance: 0,
        taxExemptionRate: 0,
        taxExemptionAmt: 0,
        taxExemptionCertImg: "",
        isDisplayApicommission: true,
        applyConvenienceFeeOnRdspg: true,
        distributorTaxID: 0,
        temporaryCredit: 0,
        cashBackBalance: 0,
        accountBalance: 0,
        effTaxExemptionAmt: 0,
        effTaxExemptionRate: 0,
        categoryName: "",
        description: "",
        typeDetailName: "",
        typeCode: "",
        code: "",
        typeMasterName: "",
        isTdsexemptionCertificateProvided: true,
        isRechargeAll: true,
        isCoBranded: true,
        effTaxExemptionDate: "2022-11-26T09:26:28.087Z",
        fromJoiningDate: moment(new Date("2022-1-1")).format(
          "MM/DD/YYYY 23:59:59"
        ),
        toJoiningDate: moment().format("MM/DD/YYYY 23:59:59"),
        accountType: [
          {
            distributorId: AgentDetails.distributorId,
            accountBalance: 0,
            limitBalance: 0,
            creditAllowed: 0,
            transactionCredit: 0,
            tranCreditUtilized: 0,
            cashBackBalance: 0,
            distAccBalance: 0,
            holdAmt: 0,
            temporaryCredit: 0,
            accountdistributorId: 0,
            accountTypeCode: "",
            accountTypeName: "",
          },
        ],
      };
      const Response = await GetResponse(
        Data,
        RDSLinks.DistributorMaster.DistributorMaster,
        {}
      );
      console.log({ UpdatedProfileResponse: Response[0] });
      if (Object.keys(Response[0]).length > 5) {
        dispatch(UpdateDetails(Response[0]));
        sessionStorage.setItem("AgentDetails", JSON.stringify(Response[0]));
      }
    } catch (error) {}
  };
  // .................................................................................................................................
  const handleSubmit = async (event) => {
    setLoader(true);
    event.preventDefault();
    try {
      const AllErrorArray = Object.keys(AllError).filter((Error) => {
        return AllError[Error] === true;
      });
      if (AllErrorArray.length > 0) {
        console.log(AllErrorArray);
        swal("Incorrect Entry", " Please Enter All Fields Correctly", "error");
      } else {
        const requestData = {
          token: AgentDetails.token ? AgentDetails.token : "",
          distributorId: AgentDetails.distributorId,
          typeDetId: AgentDetails.typeDetId,
          name: data["name"],
          distributorCode: AgentDetails.distributorCode,
          cmpName: data["cmpName"],
          cmpName: data["cmpName"],
          add1: data["add1"],
          add2: data["add2"],
          add3: data["add3"],
          city: data["city"],
          state: data["state"],
          country: data["country"],
          pincode: data["pincode"],
          phone: data["phone"],
          mobile: data["mobileNo"],
          fax: AgentDetails.fax,
          pan: data["pan"],
          email: data["email"],
          tranPwd: "",
          dateOfBirth: moment(data["dateOfBirth"]).format("MM/DD/YYYY"),
          distributorLevel: AgentDetails.distributorLevel,
          level1DistributorId: AgentDetails.level1DistributorId,
          level2DistributorId: AgentDetails.level2DistributorId,
          joiningDate: "2022-11-17T11:56:10.278Z",
          padd1: data["add1"],
          padd2: data["add2"],
          padd3: data["add3"],
          pcity: data["city"],
          pstate: data["state"],
          pcountry: data["country"],
          ppincode: data["pincode"],
          pphone: data["phone"],
          pmobile: "",
          pfax: "",
          isCustomer: false,
          isActive: true,
          activationRemark: "",
          commandId: CRUDCommands.UpdateProfile,
          createBy: AgentDetails.distributorId,
          createDate: "2022-11-17T11:56:10.278Z",
          createIp: "",
          updateBy: AgentDetails.distributorId,
          updateDate: "2022-11-17T11:56:10.278Z",
          updateIp: "",
          gender: AgentDetails.gender,
          isPaid: true,
          isProfileUpdated: true,
          invoiceNo: 0,
          paidDateTime: "2022-11-17T11:56:10.278Z",
          paidAmount: 0,
          isForm60: true,
          registrationAmount: 0,
          maintenanceChargeDetId: 0,
          isGenerateComm: true,
          isTranPwdUpdated: true,
          isTranAllowed: true,
          tranAllowedRemark: "",
          maritalStatus: "",
          occupation: "",
          activationDate: "2022-11-17T11:56:10.278Z",
          isEmailSmssent: true,
          isFirstLogin: true,
          logoImageUrl: "",
          isLogoDisplay: true,
          masterDistributorId: 0,
          userSeqNo: 0,
          loginName: "",
          pwd: "",
          isAllowLimitAllocationToDownline: true,
          alternateEmail: data["alternateEmail"],
          pandocImg: data["pandocImg"],
          userRemark: "",
          isSendBalanceReminder: true,
          reminderBalance: 0,
          reminderInterval: 0,
          isSendReminderEmail: true,
          isSendReminderSms: true,
          isSerTaxOnSerCharge: true,
          isAllowToAddDownline: true,
          isTranPwdMendate: true,
          anniversaryDate: "2022-11-17T11:56:10.278Z",
          isSendDobintimationToCompany: true,
          title: data["title"],
          lastName: data["lastName"],
          designation: "",
          labelCaption: "",
          isSendPromotionalEmail: true,
          distributorUserAccessTimeId: 0,
          activenessCategoryId: 0,
          visitingCardReceiptDate: "2022-11-17T11:56:10.278Z",
          aadharNo: data["aadharNo"],
          gstcompanyName: data["gstcompanyName"],
          gstno: data["gstno"],
          gstphone: data["gstphone"],
          gstemail: data["gstemail"],
          gstaddress: data["gstaddress"],
          gstpincode: data["gstpincode"],
          gststate: data["gststate"],
          gstcity: data["gstcity"],
          logo: data["logo"],
          countryCode: "IND",
          userDistributorID: 0,
          typeId: 0,
          limitBalance: 0,
          creditAllowed: 0,
          level1DistributorCode: "",
          transactionCredit: 0,
          tranCreditUtilized: 0,
          salesPersonId: 0,
          verificationCode: "",
          distributorGroupConfiguration: "",
          holdAmt: 0,
          countryLastUpdated: "2022-11-17T11:56:10.278Z",
          masterDistributorName: "",
          masterDistributorCode: "",
          bankLastUpdated: "2022-11-17T11:56:10.278Z",
          stateLastUpdated: "2022-11-17T11:56:10.278Z",
          cityLastUpdated: "2022-11-17T11:56:10.278Z",
          rewardPointBalance: 0,
          taxExemptionRate: 0,
          taxExemptionAmt: 0,
          taxExemptionCertImg: "",
          isDisplayApicommission: true,
          applyConvenienceFeeOnRdspg: true,
          accountType: [
            {
              distributorAccountTypeId: 0,
              distributorId: 0,
              accountTypeId: 0,
              maintenanceChargeDetId: 0,
              isSendBalanceReminder: true,
              reminderBalance: 0,
              reminderInterval: 0,
              isSendReminderEmail: true,
              isSendReminderSms: true,
              commandId: 0,
              createBy: 0,
              createDate: "2022-11-17T11:56:10.278Z",
              createIp: "",
              updateBy: 0,
              updateDate: "2022-11-17T11:56:10.278Z",
              updateIp: "",
              isTranAllowed: true,
              tranAllowedRemark: "",
              limitBalance: 0,
              creditAllowed: 0,
              transactionCredit: 0,
              tranCreditUtilized: 0,
              holdAmt: 0,
            },
          ],
        };
        console.log(requestData);

        const formdata = new FormData();
        formdata.append("Data", JSON.stringify(requestData));
        formdata.append("Image", UploadImages);
        const Response = await GetResponse(
          formdata,
          RDSLinks.DistributorMaster.UpdateDistRegWithPostFile,
          {
            "Content-Type": "multipart/form-data",
          }
        );
        console.log(Response);
        if (!Response.errorDesc) {
          if (Response === "True") {
            updateAgentDetails();
            swal(" Success", `Updated Profile Successfully`, "success").then(
              function () {
                navigate("/");
              }
            );
          } else if (Response === "False") {
            swal("Failed", `unable to Update`, "error").then(function () {});
          }
        } else {
          swal(
            " Error Occured",
            `${Response.errorDesc.split(":")[1]}`,
            "error"
          );
        }
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      swal(" Error Occured", `Internal Server Error`, "error");
    }
    setLoader(false);
  };
  // const handleChange = (e) => {
  //   setdata({ ...data, [e.target.name]: e.target.value });
  // };

  const handleChange = (e, regex) => {
    setdata({ ...data, [e.target.name]: e.target.value });

    const IsValid = regex.test(e.target.value);
    console.log(IsValid);
    if (IsValid === true) {
      setAllError({ ...AllError, [e.target.name]: false });
    } else {
      setAllError({ ...AllError, [e.target.name]: true });
    }
  };

  const handleFile = (e) => {
    // const file = e.target.value;
    // console.log(e.target.file);
    // console.log(e);

    // setUploadImages({ ...UploadImages, [e.target.name]: e.target.files[0] });
    setUploadImages(e.target.files[0]);

    // setUploadImages([...UploadImages, file]);
  };

  useEffect(() => {
    console.log(data);

    return () => {
      "second";
    };
  }, [data]);

  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <SimpleBackDrops Loader={Loader} />

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Update Profile
          </Typography>
          <Box
            component="form"
            validate="true"
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
            key={Render}
          >
            <Grid container spacing={2}>
              {SignUpInputFields.map((Fields, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    key={`${Fields.name}${index}`}
                  >
                    <TextField
                      //  error={true}
                      autoComplete="given-name"
                      type={Fields.type}
                      name={Fields.name}
                      value={data[Fields.name]}
                      error={AllError[Fields.name]}
                      helperText={AllError[Fields.name] ? Fields.errorMsg : ""}
                      select={Fields.options.length > 0 ? true : false}
                      required={Fields.required}
                      fullWidth
                      id={Fields.name}
                      label={Fields.label}
                      InputLabelProps={
                        Fields.type === "file" || Fields.type === "date"
                          ? { shrink: true }
                          : false
                      }
                      onChange={(e) => handleChange(e, Fields.regex)}
                    >
                      {Fields.options.length > 0 ? (
                        Fields.options.map((option) => (
                          <MenuItem
                            key={`${option.label}`}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </TextField>
                  </Grid>
                );
              })}
              <StateCityCountry
                state={data.state}
                city={data.city}
                country={data.country}
                pincode={data.pincode}
                ValidateInput={handleChange}
                grid={4}
              />
              {FileInputFields.map((Fields, index) => {
                return (
                  <Grid item xs={12} sm={6} lg={4} key={Fields.name}>
                    <TextField
                      //  error={true}
                      autoComplete="given-name"
                      type="file"
                      name={Fields.name}
                      // value={data[Fields.name]}
                      error={AllError[Fields.name]}
                      helperText={AllError[Fields.name] ? Fields.errorMsg : ""}
                      // select={Fields.options.length > 0 ? true : false}
                      required={Fields.required}
                      fullWidth
                      id={Fields.name}
                      label={Fields.label}
                      webkitdirectory="true"
                      InputLabelProps={
                        Fields.type === "file" || Fields.type === "date"
                          ? { shrink: true }
                          : false
                      }
                      onChange={(e) => handleFile(e)}
                    ></TextField>
                  </Grid>
                );
              })}
            </Grid>

            <Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Update Changes
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
