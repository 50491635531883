import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
  SubMenu,
} from "react-pro-sidebar";


import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Link } from "react-router-dom";
import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import { NavLink } from "react-router-dom";
import { Box, Button, Checkbox, colors, IconButton } from "@mui/material";
import { Colors } from "../Utils/Constants";
import { red } from "@mui/material/colors";
import MenuIcon from "@mui/icons-material/Menu";
import { Container } from "@mui/system";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

export default function ResponsiveSidebar({ openDrawer, LinkList }) {
  const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
    useProSidebar();

  React.useEffect(() => {
    toggleSidebar(openDrawer);
  }, [openDrawer]);

  const [Icon, SetIcon] = React.useState(false);
  const UpdateIcon = () => {
    SetIcon(Icon === false ? true : false);
  };
  // console.log(collapseSidebar);
  React.useEffect(() => {
    // console.log(openDrawer);
  }, [openDrawer]);
  const MenuItems = ({ Links, index,level }) => {
    return (
      <Tooltip title={Links.Name} placement="right">
        <MenuItem
          icon={<Links.Icon />}
          level={level}
          component={
            <NavLink
              key={Links.Name}
              style={({ isActive }) => ({
                textDecoration: "none",
                ...(isActive
                  ? { color: Colors.PrimaryColor[400] ,backgroundColor: Colors.SecondaryColor[800]}
                  : { color: Colors.SecondaryColor[800],}),
              })}
              to={Links.Link}
            />
          }
          onClick={() => collapseSidebar(true)}
        >
          {Links.Name}
        </MenuItem>
      </Tooltip>
    );
  };

  const Submenu = ({ Links, index ,level}) => {
    return (
      <Tooltip title={Links.Name} placement="right">
      <SubMenu
        label={Links.Name}
        icon={<Links.Icon />}
        style={{ color: Colors.SecondaryColor[900] }}
        level={level}
      >
        {Links.NestedLinks.map((Link, index) => {
          return Link.NestedLinks.length > 0 ? (
            <Submenu Links={Link} index={index} key={`${Links.Name}${index}`}  level={1}/>
          ) : (
            <MenuItems
              Links={Link}
              index={index}
              key={`${Links.Name}${index}`}
              level={level}
            />
          );
        })}
      </SubMenu>
      </Tooltip>
    );
  };

  return (
    <Box
      sx={{
        height: "88%",
        backgroundColor: "white",
        color: Colors.SecondaryColor[900],
        position: "fixed",
        zIndex: 1200,
      }}
    >
      <Box
        sx={{
          height: "100%",
          backgroundColor: "white",
          overflowY: "auto",
        }}
      >
        <Sidebar
          style={{ maxWidth: "8vw", top: broken ? 80 : 0 }}
          defaultCollapsed={true}
          width='300px'
          breakPoint="sm"
        >
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                return {
                  // color: disabled ? '#f5d9ff' : '#d359ff',
                  color: active
                    ? Colors.PrimaryColor[800]
                    : Colors.SecondaryColor[800],
                };
              },
            }}
          >
            <MenuItem
              icon={
                <MenuOpenIcon
                  sx={{
                    transform:
                      Icon === true ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                />
              }
              onClick={() => {
                collapseSidebar();
                UpdateIcon();
              }}
              style={{ top: 0, position: "sticky" }}
            >
              Collapse
            </MenuItem>

            {LinkList.map((Links, index) => {
              return Links.NestedLinks.length > 0 ? (
                <Submenu Links={Links} key={`${Links.Name}${index}`} level={0}/>
              ) : (
                <MenuItems Links={Links} key={`${Links.Name}${index}`} level={0}/>
              );
            })}

           
          </Menu>
        </Sidebar>
        <main></main>
      </Box>
    </Box>
  );
}
