import { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Typography, Fab } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import CRUDActions from "./CRUDActions";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  CourierServiceLinks, CRUDCommands,
} from "../../Utils/Axios/RequestParameters";
import { Colors } from "../../Utils/Constants";
import ActiveDeactive from "./ActiveDeactive";
import swal from "sweetalert";
import SimpleBackDrops from "../../components/SimpleBackDrops";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import CommonAddEditModal from "./CommonAddEditModal";
export default function CourierTypeDataGrid() {
  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);

  const addData = {
    courierTypeId: 0,
    courierTypeCode: "",
    courierTypeName: "",
    isActive: false,
    commandId: CRUDCommands.ListAll,
    createBy: 0,
    createDate: "2022-11-29T06:00:34.948Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-11-29T06:00:34.948Z",
    updateIp: "",
  };
  const dataFields = [
    {
      Label: "Courier Type Code",
      name: "courierTypeCode",
      Type: "text",
      IsError: false,
      ErrorMsg: "Please Enter Courier Type",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Courier Type Name",
      name: "courierTypeName",
      Type: "text",
      IsError: false,
      ErrorMsg: "please Enter Courier Name",
      validReg: /[^\n]+/,
      options: [],
    },
  ];
  const InitialError = {
    courierTypeId: false,
    courierTypeCode: false,
    courierTypeName: false,
  };
  const RequiredInputs = ["courierTypeCode", "courierTypeName"];
  const getServices = async () => {
    setLoader(true);
    try {
      // const Response = await axios.get(CourierServiceLinks.CourierType.GetAll);
      const Response = await GetResponse(
        addData,
        CourierServiceLinks.CourierType,
        {}
      );
      console.log(Response);
      if (Response.length > 0 && Array.isArray(Response)) {
        setServiceAPI(Response);
      } else {
        swal("error", "No Records Found", "error");
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Records Found", "error");
    }
    setLoader(false);
  };

  useEffect(() => {
    getServices();
  }, [Row]);

  const columns = useMemo(
    () => [
      { field: "courierTypeCode", headerName: "Courier Type Code", width: 200 },

      { field: "courierTypeName", headerName: "Courier Type Name", width: 200 },

      {
        field: "isActive",
        headerName: "is Active",
        width: 200,
        type: "boolean",
        renderCell: (params) => (
          <ActiveDeactive
            {...{
              UpdateURL: CourierServiceLinks.CourierType,
              activeName: "isActive",
              SelectedFieldName: "this Courier Type",
              row: params.row,
            }}
          />
        ),
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 150,
        renderCell: (params) => (
          <CRUDActions
            {...{
              params,
              rowId,
              setRowId,
              dataFields,
              InitialError,
              RequiredInputs,
              EditURL: CourierServiceLinks.CourierType,
              DeleteURL: CourierServiceLinks.CourierType,
              RowDeleteId: params.row.courierTypeId,
              name: "Courier Type",
            }}
            key={Row}
          />
        ),
      },
    ],
    [rowId, Row]
  );

  return (
    <>
      <SimpleBackDrops Loader={Loader} />

      <CommonAddEditModal
        openModal={openModal}
        handleModalClose={handleModalClose}
        handleModalOpen={handleModalOpen}
        URL={CourierServiceLinks.CourierType}
        row={addData}
        InitialError={InitialError}
        RequiredInputs={RequiredInputs}
        dataFields={dataFields}
        Type={"Add"}
        name={"Courier Type"}
        key={Row}
      />

      <Box
        sx={{
          height: 559,
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
          // backgroundColor:Colors.PrimaryBackgroundColor,
          // alignItems:'center'
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            textAlign: "center",
            p: 1,
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          Courier Type
        </Typography>

        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            ml: 5,
            mt: 1,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
          onClick={handleModalOpen}
        >
          <Add />
        </Fab>

        <DataGrid
          key={Row}
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          rows={ServiceAPI}
          getRowId={(row) => row.courierTypeId}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
