import React from "react";
import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Fab,
  TableCell,
  TableRow,
  Table,
  TableContainer,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CRUDCommands,
  RDSLinks,
  RequestObj,
} from "../../../Utils/Axios/RequestParameters";
import { Colors } from "../../../Utils/Constants";
import ActiveDeactive from "../../../Admin/Components/ActiveDeactive";
import swal from "sweetalert";
import MenuActions from "./MenuActions";
import SubMenuPage from "./SubMenuPage";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Chip, CircularProgress, IconButton } from "@mui/material";
import PageActions from "./PageActions";
import CommonAddEditModal from "../../../Admin/Components/CommonAddEditModal";

export default function MenuDataGrid() {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            responsiveScrollMaxHeight: { maxHeight: "399px" },
            root: {
              width: "98%",
            },
          },
        },
        MUIDataTableHeadCell: {
          data: {
            fontSize: "10px",
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: Colors.PrimaryBackgroundColor[700],
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              color: "white",
            },
          },
        },
        MuiTableHead: {
          styleOverrides: {
            root: {
              maxHeight: "100px",
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              maxHeight: "100px",
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: "10px",
            },
          },
        },
      },
    });

  const [PanelOptions, setPanelOPtions] = useState(["pleasewait"]);
  const [PanelOptionsObj, setPanelOPtionsObj] = useState(["pleasewait"]);

  // const PanelOptions = ["Admin", "Agent", "Provider"];
  const [SelectedPanel, setSelectedPanel] = React.useState("Admin");
  const [inputSelectedPanel, setInputSelectedPanel] = React.useState("");

  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const SiteMGMTDetails = useSelector((state) => state.SiteMGMTDetails.value);

  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);

  const [Loader, setLoader] = useState(false);
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };

  const [ServiceAPI, setServiceAPI] = useState([]);

  const addData = {
    menuId: 0,
    panelId: 0,
    parentMenuId: 0,
    menuCaption: "",
    menuControlName: "",
    menuLevel: 0,
    orderNo: 0,
    logoUrl: "",
    isNewWindow: true,
    isActive: true,
    subMenuNo: 0,
    totSubMenu: 0,
    menuKey: "",
    isMaintenance: true,
    maintenanceMsg: "",
    commandId: CRUDCommands.ListAll,
    createBy: 0,
    createDate: "2022-12-20T10:54:56.992Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-12-20T10:54:56.992Z",
    updateIp: "",
    parentMenuCaption: "",
  };
  const InitialError = {
    menuCaption: false,
    menuControlName: false,
    menuLevel: false,
    orderNo: false,
    logoUrl: false,
    subMenuNo: false,
    totSubMenu: false,
  };
  const RequiredInputs = [
    "menuCaption",
    "menuControlName",
    "menuLevel",
    "orderNo",
    "logoUrl",
    "subMenuNo",
    "totSubMenu",
  ];

  const [addNewdata, setAddNewData] = useState(addData);
  const MenuDataFields = [
    {
      name: "menuCaption",
      Label: "Menu Caption",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
      grid: 12,
    },
    {
      name: "logoUrl",
      Label: "Font Awesome Icon",
      Type: "text",
      IsError: false,
      grid: 12,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      name: "orderNo",
      Label: "Order No",
      Type: "text",
      IsError: false,
      grid: 12,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },

    {
      name: "isActive",
      Label: "Active ?",
      Type: "CheckBox",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
      grid: 12,
    },
  ];
  useEffect(() => {
    if (PanelOptions.length > 1) {
      console.log(PanelOptions.length);
      const SelectedPanelIdObj = PanelOptionsObj.filter(
        (opt) => opt.panelName === SelectedPanel
      );
      console.log(SelectedPanelIdObj);
      var addData = addNewdata;
      addData.panelId = SelectedPanelIdObj[0].panelId;
      setAddNewData(addData);
    }
  }, [PanelOptions, SelectedPanel]);
  const getServices = async () => {
    setLoader(true);
    try {
      if (PanelOptions.length < 2) {
        const Options = await GetResponse(
          RequestObj.PanelMaster,
          RDSLinks.PanelMaster,
          {}
        );
        setPanelOPtions(() => Options.map((Opt) => Opt.panelName));
        setPanelOPtionsObj(Options);
      }

      if (PanelOptions.length > 1) {
        console.log(PanelOptions, PanelOptionsObj, SelectedPanel);
        const SelectedPanelIdObj = PanelOptionsObj.filter(
          (opt) => opt.panelName === SelectedPanel
        );
        addData.panelId = SelectedPanelIdObj[0].panelId;
      }
      // const Response = await axios.get( RDSLinks.MenuRight.GetMenuByPanelRole);
      console.log(addData);
      const Response = await GetResponse(addData, RDSLinks.MenuMaster, {});
      console.log(Response);
      if (Response.length > 0) {
        setServiceAPI(Response);
      } else if (Response.length === 0) {
        setServiceAPI(Response);
        swal("Error", "No Records Found", "error");
      } else {
        swal("Error", "No Records Found", "error");
      }
    } catch (error) {
      console.log(error);
      swal("Error", "No Records Found", "error");
      setLoader(false);
    }
    setLoader(false);
  };

  useEffect(() => {
    getServices();
  }, [Row, SelectedPanel]);

  const columns = useMemo(
    () => [
      {
        name: "menuCaption",
        label: "Menu Caption",
        options: {
          customHeadLabelRender: () => (
            <div style={{ width: 120 }}>Menu Caption</div>
          ),
        },
      },

      // {
      //   name: "menuControlName",
      //   label: "Control Name",
      //   options: {
      //     customHeadLabelRender: () => (
      //       <div style={{ width: 130 }}>Control Name</div>
      //     ),
      //   },
      // },
      {
        name: "menuLevel",
        label: "Level",
        options: {
          customHeadLabelRender: () => <div style={{ width: 60 }}>Level</div>,
        },
      },
      {
        name: "isActive",
        label: "isActive",
        options: {
          customHeadLabelRender: () => (
            <div style={{ width: 80 }}>Active ?</div>
          ),

          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <ActiveDeactive
                  {...{
                    UpdateURL: RDSLinks.MenuMaster,
                    activeName: "isActive",
                    SelectedFieldName: "this Menu ",
                    row: {
                      ...ServiceAPI[tableMeta.rowIndex],
                      parentMenuCaption: "",
                    },
                  }}
                />
              </>
            );
          },
        },
      },

      {
        name: "actions",
        label: "Actions",
        options: {
          customHeadLabelRender: () => (
            <div style={{ width: 100 }}>Actions</div>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <MenuActions
                {...{
                  row: ServiceAPI[tableMeta.rowIndex],
                  InitialError,
                  RequiredInputs,
                }}
              />
            );
          },
        },
      },
    ],
    [rowId, Row, ServiceAPI]
  );

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: "none", //"single",
    filterType: "textField",
    rowsPerPage: 100,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      return (
        <React.Fragment>
          <tr>
            <td colSpan={6}>
              {ServiceAPI[rowMeta["rowIndex"]].submenu.length > 0 ? (
                <SubMenuPage
                  SelectedMenu={ServiceAPI[rowMeta["rowIndex"]].submenu}
                />
              ) : (
                <></>
              )}

              {ServiceAPI[rowMeta["rowIndex"]].page !== null &&
              ServiceAPI[rowMeta["rowIndex"]].page.length > 0 ? (
                <>
                  <TableContainer
                    component={Box}
                    sx={{
                      pl: "5%",
                      width: "95%",
                      mt: 3,
                      borderWidth: 5,
                      borderColor: "black",
                    }}
                  >
                    <Table aria-label="collapsible table">
                      <TableRow sx={{ bgcolor: Colors.SecondaryColor[400] }}>
                        <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                          Page Name
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Page URL
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                      {ServiceAPI[rowMeta["rowIndex"]].page.map((IndPage) => {
                        return (
                          <TableRow
                            hover
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {IndPage.pageCaption}
                            </TableCell>
                            <TableCell align="center">
                              {IndPage.pageUrl}
                            </TableCell>
                            <TableCell align="center">
                              <PageActions row={IndPage} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <></>
              )}
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };

  const Title = ({ name, handleAdd }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {Loader && (
          <CircularProgress
            size={24}
            style={{ marginLeft: 15, position: "relative", top: 4 }}
          />
        )}
        <Box sx={{ fontSize: "25px", fontWeight: "bold", width: "50%" }}>
          Menu
        </Box>
      </Box>
    );
  };
  const theme = getMuiTheme();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          pl: 2,
        }}
      >
        {openModal && (
          <CommonAddEditModal
            openModal={openModal}
            handleModalClose={handleModalClose}
            handleModalOpen={handleModalOpen}
            URL={RDSLinks.MenuMaster}
            InitialError={InitialError}
            RequiredInputs={RequiredInputs}
            row={addNewdata}
            dataFields={MenuDataFields}
            Type={"Add"}
            name={"Menu"}
          />
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            p: 2,
          }}
        >
          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
              ml: 5,
              mt: 1,
              mr: 5,
              bgcolor: green[500],
              "&:hover": { bgcolor: green[700] },
            }}
            onClick={handleModalOpen}
          >
            <Add />
          </Fab>
          <Autocomplete
            value={SelectedPanel}
            onChange={(event, newValue) => {
              setSelectedPanel(newValue);
            }}
            inputValue={inputSelectedPanel}
            onInputChange={(event, newInputValue) => {
              setInputSelectedPanel(newInputValue);
            }}
            id="controllable-states-demo"
            options={PanelOptions}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Select Panel" />
            )}
          />
        </Box>
        <ThemeProvider theme={theme}>
          <MUIDataTable
            title={<Title />}
            data={ServiceAPI}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </Box>
    </>
  );
}
