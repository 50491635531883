import { createSlice } from "@reduxjs/toolkit";

export const ModeSlice = createSlice({
  name: "Mode",
  initialState: {
    value: "light",
  },
  reducers: {
    UpdateMode: (state, action) => {
      state.value = action.payload;
    },
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
  },
});

// Action creators are generated for each case reducer function
export const { UpdateMode } = ModeSlice.actions;

export default ModeSlice.reducer;
