import { createSlice } from "@reduxjs/toolkit";
const Token = sessionStorage.getItem('AdminToken')
export const AdminTokenSlice = createSlice({
  name: "AdminToken",
  initialState: {
    value: Token?Token:null,
  },
  reducers: {
    UpdateAdminToken: (state, action) => {
      state.value = action.payload;
    },
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    ClearAdminToken:(state, action)=>{
      state.value = null;
  
    },
  },
});

// Action creators are generated for each case reducer function
export const { UpdateAdminToken,ClearAdminToken } = AdminTokenSlice.actions;

export default AdminTokenSlice.reducer;