import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add } from "@mui/icons-material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import InventoryIcon from "@mui/icons-material/Inventory";
import PaymentsIcon from "@mui/icons-material/Payments";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Chip,
  TextField,
  Tooltip,
  Fab,
  IconButton,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import DeliveryDetails from "./DeliveryDetails";
import OrderDetails from "./OrderDetails";
import PaymentDetails from "./PaymentDetails";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import aramex from "../../assets/images/aramex.png";
import DTDC from "../../assets/images/DTDC.jpg";
import FedEx from "../../assets/images/FedEx.png";
import ShreeMaruti from "../../assets/images/ShreeMaruti.png";
import Amazon from "../../assets/images/Amazon.png";
import Ekart from "../../assets/images/Ekart.png";
import Dilihivery from "../../assets/images/Dilihivery.png";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import PinDropIcon from "@mui/icons-material/PinDrop";
import ForwardIcon from "@mui/icons-material/Forward";
import InfoIcon from "@mui/icons-material/Info";
import ScaleIcon from "@mui/icons-material/Scale";
import { Colors } from "../../Utils/Constants";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import {
  RequestObj,
  WalletLinks,
  PaymentGateWayLinks,
  CourierServiceLinks,
  CRUDCommands,
} from "../../Utils/Axios/RequestParameters";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import SimpleBackDrops from "../SimpleBackDrops";
import { NavigateBeforeTwoTone, PasswordOutlined } from "@mui/icons-material";
export default function BookingMainPage() {
  const steps = ["Pickup-Delivery Details", "Order Details", "Payment"];

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [TotalOrderDetailAmount, setTotalOrderDetailAmount] = useState(0);
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  console.log({ AgentDetails });
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Page 1111111111111111111111111111111>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const location = useLocation();
  const navigate = useNavigate();
  const SelectedProvider = location.state;
  SelectedProvider.pickupCountryId = 109;
  SelectedProvider.deliveryCountryId = 109;

  const [Loader, setLoader] = useState(false);
  const [Page1data, setPage1data] = React.useState(SelectedProvider);
  console.log(Page1data);

  const Page1Validations = {
    pickupCompanyName: false,
    pickupMobileNo: false,
    pickupFirstName: false,
    pickupLastName: false,
    pickupEmail: false,
    pickupAddress1: false,
    pickupAddress2: false,
    pickupAddress3: false,
    pickupCityId: false,
    pickupStateId: false,
    pickupCountryId: false,
    deliveryCompanyName: false,
    deliveryMobileNo: false,
    deliveryFirstName: false,
    deliveryLastName: false,
    deliveryEmail: false,
    deliveryAddress1: false,
    deliveryAddress2: false,
    deliveryAddress3: false,
    deliveryCityId: false,
    deliveryStateId: false,
    deliveryCountryId: false,
  };
  const [Page1AllError, SetPage1AllError] = useState(Page1Validations);

  const Page1PickUpDataFields = [
    {
      name: "pickupCompanyName",
      label: "Company Name",
      Required: false,
      type: "text",
      options: [],
      errorMsg: "Please Enter Correct Pickup Company Name ",
      grid: 6,
      Error: false,
      pattern: "",
      regex: /^[A-Za-z]([a-zA-Z0-9]|[- @\.#&!])*$/,
    },
    {
      name: "pickupMobileNo",
      label: "Mobile No ",
      Required: true,
      type: "number",
      options: [],
      errorMsg: "Enter Correct Pickup Mobile No.",
      grid: 6,
      Error: false,
      pattern: "",
      regex: /^(\+\d{1,3}[- ]?)?\d{10}$/,
    },
    {
      name: "pickupFirstName",
      label: "First Name",
      Required: true,
      type: "text",
      options: [],
      errorMsg: "Please Enter Correct Pickup First Name",
      grid: 6,
      Error: false,
      pattern: "",
      regex: /^[a-z ,.'-]+$/i,
    },
    {
      name: "pickupLastName",
      label: "Last Name",
      Required: true,
      type: "text",
      errorMsg: "Please Enter Correct Pickup Last Name",
      options: [],
      grid: 6,
      Error: false,
      pattern: "",
      regex: /^[a-z ,.'-]+$/i,
    },
    {
      name: "pickupEmail",
      label: "Email",
      Required: true,
      type: "email",
      options: [],
      grid: 12,
      Error: false,
      pattern: "",
      regex:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      errorMsg: "Please Enter Correct Pickup Email Id",
    },
    {
      name: "pickupAddress1",
      label: "Building,Flat No,Area Name",
      Required: true,
      type: "text",
      options: [],
      grid: 12,
      Error: false,
      pattern: "",
      regex: /[A-Za-z0-9'\.\-\s\,]/,
      errorMsg: "Please Enter Correct Pickup Address",
    },
    {
      name: "pickupAddress2",
      label: "Address Line 2",
      Required: false,
      type: "text",
      options: [],
      grid: 12,
      Error: false,
      pattern: "",
      regex: /[A-Za-z0-9'\.\-\s\,]/,
      errorMsg: "Please Enter Correct Pickup Address",
    },
    {
      name: "pickupAddress3",
      label: "Address Line 3",
      Required: false,
      type: "text",
      options: [],
      grid: 12,
      Error: false,
      pattern: "",
      regex: /[A-Za-z0-9'\.\-\s\,]/,
      errorMsg: "Please Enter Correct Pickup Address",
    },
  ];
  const Page1DeliveryDataFields = [
    {
      name: "deliveryCompanyName",
      label: "Company Name",
      Required: false,
      type: "text",
      options: [],
      errorMsg: "Please Enter Correct Delivery Company Name",
      grid: 6,
      Error: false,
      pattern: "",
      regex: /^[A-Za-z]([a-zA-Z0-9]|[- @\.#&!])*$/,
    },
    {
      name: "deliveryMobileNo",
      label: "Mobile No ",
      Required: true,
      type: "number",
      options: [],
      errorMsg: "Please Enter Correct Delivery Mobile No.",
      grid: 6,
      Error: false,
      pattern: "",
      regex: /^(\+\d{1,3}[- ]?)?\d{10}$/,
    },
    {
      name: "deliveryFirstName",
      label: "First Name",
      Required: true,
      type: "text",
      options: [],
      errorMsg: "Please Enter Correct Delivery First Name",
      grid: 6,
      Error: false,
      pattern: "",
      regex: /^[a-z ,.'-]+$/i,
    },
    {
      name: "deliveryLastName",
      label: "Last Name",
      Required: true,
      type: "text",
      options: [],
      errorMsg: "Please Enter Correct Delivery Last Name",
      grid: 6,
      Error: false,
      pattern: "",
      regex: /^[a-z ,.'-]+$/i,
    },
    {
      name: "deliveryEmail",
      label: "Email",
      Required: true,
      type: "email",
      options: [],
      grid: 12,
      Error: false,
      pattern: "",
      regex:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      errorMsg: "Please Enter Correct Delivery Email Address",
    },
    {
      name: "deliveryAddress1",
      label: "Building,Flat No,Area Name",
      Required: true,
      type: "text",
      options: [],
      grid: 12,
      Error: false,
      pattern: "",
      regex: /[A-Za-z0-9'\.\-\s\,]/,
      errorMsg: "Please Enter Correct Delivery Address",
    },
    {
      name: "deliveryAddress2",
      label: "Address Line 2",
      Required: false,
      type: "text",
      options: [],
      grid: 12,
      Error: false,
      pattern: "",
      regex: /[A-Za-z0-9'\.\-\s\,]/,
      errorMsg: "Please Enter Correct Delivery Address",
    },
    {
      name: "deliveryAddress3",
      label: "Address Line 3",
      Required: false,
      type: "text",
      options: [],
      grid: 12,
      Error: false,
      pattern: "",
      regex: /[A-Za-z0-9'\.\-\s\,]/,
      errorMsg: "Please Enter Correct Delivery Address",
    },
  ];
  const Page1validate = (e, regex) => {
    setPage1data({ ...Page1data, [e.target.name]: e.target.value });
    const IsValid = regex.test(e.target.value);
    console.log(IsValid);
    if (IsValid === true) {
      SetPage1AllError({ ...Page1AllError, [e.target.name]: false });
    } else {
      SetPage1AllError({ ...Page1AllError, [e.target.name]: true });
    }
  };

  const handleSubmit = () => {
    console.log(Page1data);
    console.log(Page2data);
  };
  const CheckPage1Input = () => {
    let AllGood = false;
    const PickupRequiredEmptyErrorFields = Page1PickUpDataFields.filter(
      (Inputs) => {
        console.log(
          Inputs.Required,
          Page1AllError[Inputs["name"]],
          Page1data[Inputs["name"]],
          (Inputs.Required === true &&
            Page1AllError[Inputs["name"]] === true) ||
            Page1data[Inputs["name"]] === ""
        );
        return (
          (Inputs.Required === true &&
            Page1AllError[Inputs["name"]] === true) ||
          Page1data[Inputs["name"]] === "" ||
          Page1data[Inputs["name"]] === 0 ||
          Page1data[Inputs["name"]] === null
        );
      }
    );
    const DeliveryRequiredEmptyErrorFields = Page1DeliveryDataFields.filter(
      (Inputs) => {
        console.log(
          Inputs.Required,
          Page1AllError[Inputs["name"]],
          Page1data[Inputs["name"]],
          (Inputs.Required === true &&
            Page1AllError[Inputs["name"]] === true) ||
            Page1data[Inputs["name"]] === ""
        );
        return (
          (Inputs.Required === true &&
            Page1AllError[Inputs["name"]] === true) ||
          Page1data[Inputs["name"]] === "" ||
          Page1data[Inputs["name"]] === 0 ||
          Page1data[Inputs["name"]] === null
        );
      }
    );

    console.log(
      PickupRequiredEmptyErrorFields,
      DeliveryRequiredEmptyErrorFields
    );
    if (
      PickupRequiredEmptyErrorFields.length > 0 ||
      DeliveryRequiredEmptyErrorFields.length > 0
    ) {
      swal(
        "Sorry",
        `Please Enter All * Required Details \n ${PickupRequiredEmptyErrorFields.map(
          (emptyInputs) => emptyInputs.errorMsg
        ).join(",\n")} ,\n${DeliveryRequiredEmptyErrorFields.map(
          (emptyInputs) => emptyInputs.errorMsg
        ).join("\n")}`,
        "error"
      );
    } else if (
      PickupRequiredEmptyErrorFields.length === 0 ||
      DeliveryRequiredEmptyErrorFields.length === 0
    ) {
      AllGood = true;
    }
    return AllGood;
  };
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Page -11111111111111111111>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Page 22222222222222222222>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const Page2SampleData = {
    orderDetId: 0,
    orderId: 0,
    productDesc: "",
    sku: "",
    hsn: "",
    qty: 0,
    price: 0,
    totalAmount: 0,
  };
  const [Page2data, setPage2data] = React.useState([Page2SampleData]);
  const Page2Validations = {
    productDesc: false,
    sku: false,
    hsn: false,
    qty: false,
    price: false,
    totalAmount: false,
  };
  const [Page2AllError, SetPage2AllError] = useState([Page2Validations]);
  const [Page2render, setPage2render] = React.useState("Page2render");

  const UpdateTotalAmount = () => {
    const Amount = Page2data.reduce(function (acc, obj) {
      return acc + obj.totalAmount;
    }, 0);
    console.log({ Amount });
    setTotalOrderDetailAmount(Amount);
  };
  const Page2Rerender = () => {
    setPage2render(
      Page2render === "Page2render" ? "Page2rerender" : "Page2render"
    );
  };

  useEffect(() => {
    console.log(Page2data);
    console.log(Page2AllError);
  }, [Page2data, Page2AllError]);
  const Page2DeleteBox = (index) => {
    setPage2data(() => {
      const tempdata = Page2data;
      tempdata.splice(index, 1);
      return tempdata;
    });
    SetPage2AllError(() => {
      const tempAllError = Page2AllError;
      tempAllError.splice(index, 1);
      return tempAllError;
    });
    Page2Rerender();
  };
  const Page2AddBox = () => {
    setPage2data([...Page2data, Page2SampleData]);
    SetPage2AllError([...Page2AllError, Page2Validations]);
  };

  // useEffect(()=>{
  //   UpdateTotalAmount()

  // },[Page2data])

  const Page2ItemDetailsFields = [
    {
      name: "productDesc",
      label: "Product Description",
      Required: true,
      type: "text",
      options: [],
      errorMsg: "Please Enter Product Description",
      grid: 2.4,
      Error: false,
      regex: /[a-zA-Z0-9/s]/,
    },
    {
      name: "sku",
      label: "SKU",
      Required: false,
      type: "text",
      options: [],
      errorMsg: "Please Enter SKU",
      grid: 2.4,
      Error: false,
      regex: /[a-zA-Z0-9]/,
    },
    {
      name: "hsn",
      label: "HSN",
      Required: false,
      type: "text",
      options: [],
      errorMsg: "Please Enter HSN",
      grid: 2.4,
      Error: false,
      regex: /[a-zA-Z0-9]/,
    },
    {
      name: "qty",
      label: "Quantity",
      Required: true,
      type: "number",
      options: [],
      errorMsg: "please Enter Quantity",
      grid: 2.4,
      Error: false,
      regex: /[0-9]/,
    },
    {
      name: "price",
      label: "Price",
      Required: true,
      type: "number",
      options: [],
      errorMsg: "Please Enter Price",
      grid: 2.4,
      Error: false,
      regex: /[0-9]/,
    },
    // {
    //   name: "totalAmount",
    //   label: "TotalAmount",
    //   Required: true,
    //   type: "number",
    //   options: [],
    //   errorMsg: "please enter Total Amount ",
    //   grid: 1.5,
    //   Error: false,
    //   regex: /[0-9]/,
    // },
  ];

  const Page2validate = (e, index, regex) => {
    const tempdata = Page2data;
    if (e.target.name === "price") {
      tempdata[index] = {
        ...tempdata[index],
        [e.target.name]: e.target.value,
        totalAmount: e.target.value * tempdata[index]["qty"],
      };
      setPage2data(tempdata);
      UpdateTotalAmount();
      // Page2Rerender();
    } else if (e.target.name === "qty") {
      tempdata[index] = {
        ...tempdata[index],
        [e.target.name]: e.target.value,
        totalAmount: e.target.value * tempdata[index]["price"],
      };
      setPage2data(tempdata);
      UpdateTotalAmount();
      // Page2Rerender();
    } else {
      tempdata[index] = { ...tempdata[index], [e.target.name]: e.target.value };
      setPage2data(tempdata);
    }

    const IsValid = regex.test(e.target.value);
    const tempAllError = Page2AllError;
    if (IsValid === true) {
      tempAllError[index] = { ...tempAllError[index], [e.target.name]: false };
      SetPage2AllError(tempAllError);
    } else {
      tempAllError[index] = { ...tempAllError[index], [e.target.name]: true };
      SetPage2AllError(tempAllError);
    }
  };

  const CheckPage2Input = () => {
    var AllGood = false;
    const AllEmptyFieldsMasterArray = Page2data.map(
      (IndObjDetail, OrderDetailIndex) => {
        const AllemptyFieldsArray = Page2ItemDetailsFields.filter(
          (indInput, index) => {
            return (
              indInput.Required === true &&
              (Page2AllError[OrderDetailIndex][indInput["name"]] === true ||
                Page2data[OrderDetailIndex][indInput["name"]] === "" ||
                Page2data[OrderDetailIndex][indInput["name"]] === 0 ||
                Page2data[OrderDetailIndex][indInput["name"]] === null)
            );
          }
        );
        return AllemptyFieldsArray;
      }
    );

    const AllEmptyFieldsFilterArray = AllEmptyFieldsMasterArray.filter(
      (indArray, index) => {
        return indArray.length > 0;
      }
    );
    if (AllEmptyFieldsFilterArray.length > 0) {
      const Text = AllEmptyFieldsFilterArray.map((indArray, index) => {
        const AllEmptyFields = indArray.map((indFields) => {
          return indFields.errorMsg;
        });

        return AllEmptyFields;
      });
      swal(
        "error",
        `${Text.map((IndArray, index) =>
          IndArray.map((indtext) => `\n ${indtext} of Item `)
        )}`,
        "error"
      );
    } else if (AllEmptyFieldsFilterArray.length === 0) {
      AllGood = true;
    }
    return AllGood;
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Page -22222222222222222222>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Page 33333333333333333333>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const [PGGroupTypeOptions, setPGGroupTypeOptions] = useState([]);
  const [PaymentGetwayDetailsAllOptions, setPaymentGetwayDetailsAllOptions] =
    useState([]);
  const [PaymentGetwayDetailsOptions, setPaymentGetwayDetailsOptions] =
    useState([]);
  const [PGGroupType, setPGGroupType] = useState("");
  const [PaymentGetwayDetails, setPaymentGetwayDetails] = useState("");
  const [TransactionPassword, setTransactionPassword] = useState("");
  const UpdatedTransactionPassword = async () => {
    if (PaymentGetwayDetails !== "" && PaymentGetwayDetails !== null) {
      const SelectedPaymentGetway = JSON.parse(PaymentGetwayDetails);
      console.log({ SelectedPaymentGetway });
      console.log();
      if (SelectedPaymentGetway.pggroupTypeCode.trim() === "RDS") {
        const Response = await Swal.fire({
          title: "Transaction password",
          input: "password",
          showDenyButton: true,
          icon: "question",
          inputPlaceholder: "Enter your Transaction password",
          inputValidator: (value) => {
            if (!value) {
              return "please enter transaction Password!";
            }
          },
          inputAttributes: {
            maxlength: 10,
            autocapitalize: "off",
            autocorrect: "off",
          },
          confirmButtonText: "Submit",
          denyButtonText: "Cancel",
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
            denyButton: "order-3",
          },
        });

        if (Response.isConfirmed === true && Response.value !== null) {
          setTransactionPassword(Response.value);
        } else {
          setPaymentGetwayDetails("");
          const tempCompletedSteps = completed;

          delete tempCompletedSteps[activeStep];
          console.log(tempCompletedSteps);
          setCompleted(tempCompletedSteps);
        }
      }
    }
  };
  useEffect(() => {
    console.log({ PaymentGetwayDetails });
    UpdatedTransactionPassword();
  }, [PaymentGetwayDetails]);
  const GetPGGroupTypeOptions = async () => {
    try {
      const Response = await GetResponse(
        RequestObj.wallet.GetPGGroupType,
        PaymentGateWayLinks.GetPGGroupType,
        {}
      );
      if (Response.length > 0) {
        setPGGroupTypeOptions(Response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetPaymentGetwayDetailsAllOptions = async () => {
    try {
      const requestData = {
        pgGroupTypeID: 0,
        isAutoLimitProcessing: 1,
        processCode: "COU",
        paymentGatewayPGAPILinkID: 0,
        typeDetID: AgentDetails.typeDetId,
        commandID: 3,
      };
      console.log({ requestData });
      const Response = await GetResponse(
        requestData,
        PaymentGateWayLinks.GetPaymentGateWay,
        {}
      );
      if (Response.length > 0) {
        setPaymentGetwayDetailsAllOptions(Response);
      } else {
        setPaymentGetwayDetailsAllOptions([]);
        swal("Sorry!", "No Payment Gateway Found", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GetPaymentGetwayDetailsOptions = async (value) => {
    const SelectedPGType = JSON.parse(value);
    const tempArray = PaymentGetwayDetailsAllOptions.filter((selectedPG) => {
      console.log(selectedPG.pggroupTypeId, SelectedPGType);
      return selectedPG.pggroupTypeId === SelectedPGType.pggroupTypeId;
    });

    if (tempArray.length > 0) {
      setPaymentGetwayDetailsOptions(tempArray);
    } else {
      setPaymentGetwayDetailsOptions([]);
      swal("Sorry!", "No Payment Gateway Found", "error");
    }
  };
  const handlePGGroupType = (event, value) => {
    if (value !== null) {
      setPGGroupType(value);
      setPaymentGetwayDetails("");
      GetPaymentGetwayDetailsOptions(value);
    }
  };

  const handlePaymentGetway = (event, value) => {
    if (value !== null) {
      setPaymentGetwayDetails(value);
      handleComplete();
    }
  };
  useEffect(() => {
    GetPGGroupTypeOptions();
    GetPaymentGetwayDetailsAllOptions();
  }, []);

  const CheckPage3Input = () => {
    var AllGood = false;
    if (PGGroupType !== "" && PaymentGetwayDetails !== "") {
      AllGood = true;
      handleComplete();
    }
    return AllGood;
  };
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Page -333333333333333333333>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <LocalShippingIcon />,
      2: <InventoryIcon />,
      3: <PaymentsIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    var AllGood = true;
    console.log({ activeStep });
    if (activeStep === 0) {
      AllGood = CheckPage1Input();
    } else if (activeStep === 1) {
      AllGood = CheckPage2Input();
    } else if (activeStep === 2) {
      AllGood = CheckPage3Input();
    }

    if (AllGood === true) {
      handleComplete();
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    } else {
      const tempCompletedSteps = completed;

      delete tempCompletedSteps[activeStep];
      console.log(tempCompletedSteps);
      setCompleted(tempCompletedSteps);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  //  .................................BookingFun.................................................................................
  const DoBooking = async () => {
    setLoader(true);
    try {
      const neworderDetails = Page2data.map((data) => {
        return {
          orderDetId: data["orderDetId"],
          orderId: data["orderId"],
          productDesc: data["productDesc"],
          sku: data["sku"],
          hsn: data["hsn"],
          qty: data["qty"],
          price: data["price"],
          totalAmount: data["totalAmount"],
          createBy: AgentDetails.distributorId,
          createDate: "2022-11-29T06:32:42.384Z",
          createIP: "",
          updateBy: AgentDetails.distributorId,
          updateDate: "2022-11-29T06:32:42.384Z",
          updateIP: "",
        };
      });
      const Data = Page1data;
      const PaymentDetails = JSON.parse(PaymentGetwayDetails);
      Data.orderDetails = neworderDetails;
      Data.pgid = PaymentDetails.pgid;
      Data.paymentGatewayPGAPILinkID = PaymentDetails.paymentGatewayPgapilinkId;
      Data.paymentGatewayPGAPIProcessLinkID =
        PaymentDetails.paymentGatewayPgapiprocessLinkId;
      Data.tranPwd = TransactionPassword;
      Data.createBy = AgentDetails.distributorId;
      Data.updateBy = AgentDetails.distributorId;
      Data.bookedAdminUserId = 1; //AgentDetails.distributorId;
      Data.userDistributorId = AgentDetails.distributorId;
      Data.typeDetId = AgentDetails.typeDetId;

      const RequestData = {
        orders: [Data],
        commandID: CRUDCommands.Booking,
        errorDesc: "",
        errorCode: "",
      };
      console.log({ RequestData });

      const Response = await GetResponse(
        RequestData,
        CourierServiceLinks.Order,
        {}
      );
      console.log(Response);
      if (Response.errorDesc === "" || Response.orders[0].status === "BC") {
        swal("success", `${Response.orders[0].statusDescription} `, "success", {
          timer: 2000,
        });
        if (
          Response.orders[0].status === "BC" ||
          Response.orders[0].refNo !== ""
        ) {
          navigate("/BookingDetails", { state: Response });
        }
      } else if (Response.errorDesc) {
        if (
          Response.orders[0].status === "BC" ||
          Response.orders[0].refNo !== ""
        ) {
          navigate("/BookingDetails", { state: Response });
        } else {
          swal(
            "error",
            ` ${
              Response.orders[0].statusDescription !== ""
                ? `Status : ${Response.orders[0].statusDescription} \n \n \n Reference No : ${Response.orders[0].refNo}`
                : ""
            } \n \n   \n   ${Response.errorDesc}`,
            "error"
          );
        }
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
    setLoader(false);
  };
  //  .................................BookingFun.................................................................................

  return (
    <Box
      sx={{
        width: "100%",
        p: { xs: 2, sm: 5 },

        pl: { xs: 2, sm: 15 },
        backgroundColor: "white",
      }}
    >
      <SimpleBackDrops Loader={Loader} />
      <Paper
        elevation={3}
        sx={{
          position: "sticky",
          top: 81,
          zIndex: 1100,
          width: "100%",
          backgroundColor: "white",
          p: 2,
          zIndex: 1000,
        }}
      >
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={0}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Paper>
      <Paper elevation={2} sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            height: "39%",
            // backgroundColor: "lightblue",
            display: "flex",
            justifyContent: "space-around",
            // boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            overflow: "hidden",
            mt: 2,
            mb: 1,
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {SelectedProvider.courierServiceProviderName === "Delhivery" ? (
              <img src={Dilihivery} alt="logo" width="150" height="64" />
            ) : SelectedProvider.courierServiceProviderName === "DTDC" ? (
              <img src={DTDC} alt="logo" width="150" height="64" />
            ) : SelectedProvider.courierServiceProviderName === "Ekart" ? (
              <img src={Ekart} alt="logo" width="150" height="64" />
            ) : SelectedProvider.courierServiceProviderName ===
              "Shree Maruti Courier" ? (
              <img src={ShreeMaruti} alt="logo" width="150" height="64" />
            ) : (
              <img src={Amazon} alt="logo" width="150" height="64" />
            )}
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", color: Colors.PrimaryColor[800] }}
              >
                {SelectedProvider.courierServiceProviderName}
              </Typography>
              <Typography
                sx={{ fontWeight: "bold", color: Colors.SecondaryColor[800] }}
              >
                ₹ {SelectedProvider.netAmount}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "50%",
              display: { xs: "none", md: "flex" },
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box>{`Up to ${SelectedProvider.serviceDays} Days Estimated Delivery, ${SelectedProvider.serviceType}`}</Box>
            <Box>
              <InfoIcon sx={{ color: "#273f87" }} />
            </Box>
          </Box>
        </Box>
      </Paper>
      {/* <Outlet /> */}
      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<.................Steps.......................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
      <Box
        component="form"
        validate="true"
        onSubmit={handleSubmit}
        sx={{ pt: 1, display: "flex", justifyContent: "space-around" }}
      >
        {activeStep === 0 ? (
          <DeliveryDetails
            PickUpDataFields={Page1PickUpDataFields}
            AllError={Page1AllError}
            validate={Page1validate}
            DeliveryDataFields={Page1DeliveryDataFields}
            data={Page1data}
          />
        ) : activeStep === 1 ? (
          <OrderDetails
            data={Page2data}
            render={Page2render}
            AddBox={Page2AddBox}
            PickUpDataFields={Page2ItemDetailsFields}
            AllError={Page2AllError}
            validate={Page2validate}
            DeleteBox={Page2DeleteBox}
            TotalOrderDetailAmount={TotalOrderDetailAmount}
          />
        ) : activeStep === 2 ? (
          <PaymentDetails
            PGGroupTypeOptions={PGGroupTypeOptions}
            handlePGGroupType={handlePGGroupType}
            PGGroupType={PGGroupType}
            handlePaymentGetway={handlePaymentGetway}
            PaymentGetwayDetailsOptions={PaymentGetwayDetailsOptions}
            PaymentGetwayDetails={PaymentGetwayDetails}
          />
        ) : (
          <></>
        )}
      </Box>
      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<.................Steps.......................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<.................Navigation Buttons.......................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

      <Grid container spacing={5} sx={{ pt: 2 }}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              color: "white",

              backgroundColor: red[500],
              "&:hover": {
                backgroundColor: red[800],
              },
            }}
            disabled={activeStep === 0}
            onClick={handleBack}
            //   onClick={() => CloseEditOrder()}
          >
            <ArrowLeftIcon />
            Previous
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            // type="submit"
            fullWidth
            variant="contained"
            disabled={activeStep === steps.length - 1}
            sx={{
              color: "white",

              backgroundColor: green[500],
              "&:hover": {
                backgroundColor: green[800],
              },
            }}
            onClick={handleNext}
            // onClick={() => handleSubmit}
          >
            Next <ArrowRightIcon />
          </Button>
        </Grid>
      </Grid>
      {allStepsCompleted() ? (
        <Grid
          item
          xs={12}
          sx={{
            bottom: 0,
            position: "sticky",
          }}
        >
          <Button
            fullWidth
            variant="contained"
            sx={{
              mt: 5,
              color: "white",

              backgroundColor: green[500],
              "&:hover": {
                backgroundColor: green[800],
              },
            }}
            onClick={() => {
              CheckPage3Input();
              DoBooking();
            }}
          >
            Confirm Order
          </Button>
        </Grid>
      ) : (
        <></>
      )}
      {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<.................Navigation Buttons.......................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
    </Box>
  );
}
