import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UpdateRow } from "../../../features/RowSlice";
import {
  Grid,
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  TextField,
  MenuItem,
  CssBaseline,
  Container,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import swal from "sweetalert";
import { Colors } from "../../../Utils/Constants";
import { CRUDCommands, RDSLinks } from "../../../Utils/Axios/RequestParameters";
import { Troubleshoot } from "@mui/icons-material";
import { blueGrey } from "@mui/material/colors";
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  paddingTop: 1,
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", md: "80%", lg: "80%" },
  height: { xs: 700, sm: 600 },
  //   scrollY:true,
  //   flexWrap:Scr,
  // overflow: "auto",
  bgcolor: "white",
  //   border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  pb: 0,
  p: 0,
  m: 0,
};
// Type = Edit or ADD

export default function AssignRoleModal({
  row,
  openModal,
  handleModalOpen,
  handleModalClose,
}) {
  const dispatch = useDispatch();
  const [Loader, setLoader] = React.useState(false);
  const [AssignData, setAssignData] = React.useState([]);
  const [AllAllowedRights, setAllAllowedRights] = React.useState({
    isAdd: false,
    isEdit: false,
    isDelete: false,
    isPrint: false,
    isAllowed: false,
  });
  const UpdateAllRow = (e, name) => {
    setAllAllowedRights({ ...AllAllowedRights, [name]: e.target.checked });
    const NewAssignArray = AssignData.map((data) => {
      return { ...data, [name]: e.target.checked };
    });
    setAssignData(NewAssignArray);
  };
  const [Render, setRender] = React.useState("render");
  const sampleData = {
    menuId: 0,
    roleId: 0,
    isAllowed: true,
    isAdd: true,
    isEdit: true,
    isDelete: true,
    isPrint: true,
    commandId: 0,
    createBy: 0,
    createDate: "2022-12-09T11:41:45.572Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-12-09T11:41:45.572Z",
    updateIp: "",
    menuCaption: "",
    menuLevel: 0,
  };

  const handleChange = (e, columnName, index) => {
    console.log({ e });
    var Updatedlength = 0;

    const NewArray = AssignData.map((assignRow, rowindex) => {
      if (index === rowindex) {
        return { ...assignRow, [columnName]: e.target.checked };
      } else {
        return { ...assignRow };
      }
    });

    setAssignData(NewArray);
  };

  useEffect(() => {
    if (AssignData.length > 0) {
      var TempAllAllowedRightsLength = {
        isAdd: 0,
        isEdit: 0,
        isDelete: 0,
        isPrint: 0,
        isAllowed: 0,
      };
      const NewAssignData = AssignData.map((data) => {
        TempAllAllowedRightsLength = {
          isAdd:
            data.isAdd === true
              ? TempAllAllowedRightsLength.isAdd + 1
              : TempAllAllowedRightsLength.isAdd,
          isEdit:
            data.isEdit === true
              ? TempAllAllowedRightsLength.isEdit + 1
              : TempAllAllowedRightsLength.isEdit,
          isDelete:
            data.isDelete === true
              ? TempAllAllowedRightsLength.isDelete + 1
              : TempAllAllowedRightsLength.isDelete,
          isPrint:
            data.isPrint === true
              ? TempAllAllowedRightsLength.isPrint + 1
              : TempAllAllowedRightsLength.isPrint,
          isAllowed:
            data.isAllowed === true
              ? TempAllAllowedRightsLength.isAllowed + 1
              : TempAllAllowedRightsLength.isAllowed,
        };
      });
      if (NewAssignData.length === AssignData.length) {
        setAllAllowedRights({
          isAdd:
            TempAllAllowedRightsLength.isAdd === AssignData.length
              ? true
              : false,
          isEdit:
            TempAllAllowedRightsLength.isEdit === AssignData.length
              ? true
              : false,
          isDelete:
            TempAllAllowedRightsLength.isDelete === AssignData.length
              ? true
              : false,
          isPrint:
            TempAllAllowedRightsLength.isPrint === AssignData.length
              ? true
              : false,
          isAllowed:
            TempAllAllowedRightsLength.isAllowed === AssignData.length
              ? true
              : false,
        });
      }
    }
  }, [AssignData]);

  const handleSubmit = async () => {
    setLoader(true);
    console.log({ AssignData });
    try {
      const response = await GetResponse(
        AssignData,
        RDSLinks.MenuRight.Update,
        {}
      );
      console.log({ response });
      if (response === true) {
        swal("success", "Rights Updated Successfully", "success");
        dispatch(UpdateRow());
      } else if (response.errorDesc) {
        swal("error", response.errorDesc, "error");
      } else {
        swal("error", "Something went Wrong", "error");
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
    setLoader(false);
  };
  const columns = useMemo(
    () => [
      { id: "menuCaption", label: "Menu", width: 250 },
      { id: "isAdd", label: "Add ?", width: 50 },
      {
        id: "isEdit",
        label: "Edit ?",
        width: 50,
        align: "center",
      },
      {
        id: "isDelete",
        label: "Delete ?",
        width: 50,
        align: "center",
      },
      {
        id: "isPrint",
        label: "Print ?",
        width: 50,
        align: "center",
      },
      {
        id: "isAllowed",
        label: "Allowed ?",
        width: 50,
        align: "center",
      },
    ],
    [AssignData]
  );
  const Rerender = () => {
    setRender(() => {
      return Render === "render" ? "rerender" : "render";
    });
  };
  const UpdateAssignData = async () => {
    try {
      setLoader(true);
      const RequestData = {
        menuId: 0,
        roleId: row.roleId,
        isAllowed: true,
        isAdd: true,
        isEdit: true,
        isDelete: true,
        isPrint: true,
        commandId: CRUDCommands.ListAll,
        createBy: 0,
        createDate: "2022-12-09T11:41:45.572Z",
        createIp: "",
        updateBy: 0,
        updateDate: "2022-12-09T11:41:45.572Z",
        updateIp: "",
        menuCaption: "",
        menuLevel: 0,
      };
      const Response = await GetResponse(
        RequestData,
        RDSLinks.MenuRight.ListAll,
        {}
      );
      if (Response.length > 0 && Array.isArray(Response)) {
        var TempAllAllowedRightsLength = {
          isAdd: 0,
          isEdit: 0,
          isDelete: 0,
          isPrint: 0,
          isAllowed: 0,
        };
        const NewAssignData = Response.map((data) => {
          TempAllAllowedRightsLength = {
            isAdd:
              data.isAdd === true
                ? TempAllAllowedRightsLength.isAdd + 1
                : TempAllAllowedRightsLength.isAdd,
            isEdit:
              data.isEdit === true
                ? TempAllAllowedRightsLength.isEdit + 1
                : TempAllAllowedRightsLength.isEdit,
            isDelete:
              data.isDelete === true
                ? TempAllAllowedRightsLength.isDelete + 1
                : TempAllAllowedRightsLength.isDelete,
            isPrint:
              data.isPrint === true
                ? TempAllAllowedRightsLength.isPrint + 1
                : TempAllAllowedRightsLength.isPrint,
            isAllowed:
              data.isAllowed === true
                ? TempAllAllowedRightsLength.isAllowed + 1
                : TempAllAllowedRightsLength.isAllowed,
          };
          console.log({ TempAllAllowedRightsLength });
          return { ...data, commandId: CRUDCommands.Update };
        });
        setAssignData(NewAssignData);

        if (NewAssignData.length === Response.length) {
          setAllAllowedRights({
            isAdd:
              TempAllAllowedRightsLength.isAdd === Response.length
                ? true
                : false,
            isEdit:
              TempAllAllowedRightsLength.isEdit === Response.length
                ? true
                : false,
            isDelete:
              TempAllAllowedRightsLength.isDelete === Response.length
                ? true
                : false,
            isPrint:
              TempAllAllowedRightsLength.isPrint === Response.length
                ? true
                : false,
            isAllowed:
              TempAllAllowedRightsLength.isAllowed === Response.length
                ? true
                : false,
          });
        }
      } else {
        swal("error", "No Rights Found", "error");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
    setLoader(false);
  };
  useEffect(() => {
    UpdateAssignData();
  }, [row]);
  useEffect(() => {
    console.log(AllAllowedRights);
  }, [AllAllowedRights]);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => {
          handleModalClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Container component="main" maxWidth="lg" sx={styles}>
            <SimpleBackDrops Loader={Loader} />
            {/* <CssBaseline /> */}
            <Typography
              // component="h1"
              variant="h5"
              sx={{
                width: "40vw",
                backgroundColor: Colors.SecondaryBackGroundColor[400],
                color: "white",
                height: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                boxShadow:
                  "0 5px 10px rgba(0,0,0,0.16), 0 5px 5px rgba(0,0,0,0.16)",
                mb: 1,
                fontSize: { xs: 15, sm: 20 },
              }}
            >
              Assign Rights
            </Typography>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ height: 500 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            width: column.width,
                            color: Colors.SecondaryColor[500],
                            fontWeight: "bold",
                            fontSize: 18,
                          }}
                        >
                          {column.label === "Menu" ? (
                            column.label
                          ) : (
                            <FormControlLabel
                              label={column.label}
                              control={
                                <Checkbox
                                  checked={AllAllowedRights[column.id]}
                                  // color={row[column.id]===true?'success':'warning'}
                                  indeterminate={!AllAllowedRights[column.id]}
                                  onChange={(e) => {
                                    UpdateAllRow(e, column.id);
                                  }}
                                />
                              }
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {AssignData.map((row, index) => {
                      console.log({ row });
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row}>
                          {columns.map((column) => {
                            console.log(row[column.id]);
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "menuCaption" &&
                                row.menuLevel === 1 ? (
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      color: blueGrey[900],
                                    }}
                                  >
                                    {row[column.id]}
                                  </Typography>
                                ) : column.id === "menuCaption" &&
                                  row.menuLevel === 2 ? (
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      color: blueGrey[700],
                                    }}
                                  >
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {row[column.id]}
                                  </Typography>
                                ) : column.id === "menuCaption" &&
                                  row.menuLevel === 3 ? (
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      color: blueGrey[400],
                                    }}
                                  >
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {row[column.id]}
                                  </Typography>
                                ) : (
                                  <FormControlLabel
                                    label={
                                      row[column.id] === true ? "Yes" : "No"
                                    }
                                    control={
                                      <Checkbox
                                        checked={row[column.id]}
                                        color={
                                          row[column.id] === true
                                            ? "success"
                                            : "warning"
                                        }
                                        // indeterminate={checked[0] !== checked[1]}
                                        onChange={(e) => {
                                          handleChange(e, column.id, index);
                                        }}
                                      />
                                    }
                                  />
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Grid container spacing={1} sx={{ pt: 2, pb: 2 }}>
              <Grid item xs={6} sm={6} lg={6}>
                <Button
                  fullWidth
                  color="error"
                  variant="contained"
                  // sx={{ mt: 1, mb: 1 }}
                  onClick={() => handleModalClose()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <Button
                  fullWidth
                  color="success"
                  variant="contained"
                  // sx={{ mt: 1, mb: 1 }}
                  onClick={(e) => handleSubmit(e)}
                >
                  Assign
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Fade>
      </Modal>
    </>
  );
}
