import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import Link from '@mui/material/Link';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import swal from "sweetalert";
import { Colors } from "../Utils/Constants";
import { useSelector } from "react-redux";
import { Paper } from "@mui/material";
import { GetResponse } from "../Utils/Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "../Utils/Axios/RequestParameters";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useState } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
const theme = createTheme();

export default function ChangeTnxPassword(props) {
  // const {Authenticate,validate,Error,data} = props
  const navigate = useNavigate();
  const AgentDetails = useSelector((store) => store.AgentDetails.value);
  const UpdatedData = {
    distributorId: AgentDetails.distributorId,
    accountTypeID: 0,
    oldPWD: "",
    newPWD: "",
    updateBy: AgentDetails.distributorId,
    isActive: true,
    remarks: "",
    commandId: CRUDCommands.ChangeTnxPassword,
  };
  const [data, setData] = useState(UpdatedData);
  const [Loader, setLoader] = useState(false);

  const [showOldTnxPassword, setShowOldTnxPassword] = useState(false);
  const [showNewTnxPassword, setShowNewTnxPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [AllError, SetAllError] = useState({
    oldPWD: false,
    newPWD: false,
  });

  const validate = (e) => {
    const regex =
      /^(?=.*[a-z])(?=.*\d)(?=.*[@_$!%*?&])[A-Za-z\d@_$!%*?&]{3,10}$/;
    setData({ ...data, [e.target.name]: e.target.value });
    const IsValid = regex.test(e.target.value);
    console.log(IsValid);
    if (IsValid === true) {
      SetAllError({ ...AllError, [e.target.name]: false });
    } else {
      SetAllError({ ...AllError, [e.target.name]: true });
    }
  };

  const Authenticate = async (event) => {
    setLoader(true);

    event.preventDefault();

    //  Call Axios
    try {
      if (data.oldPWD !== "" && data.newPWD !== "") {
        const Response = await GetResponse(
          data,
          RDSLinks.DistributorMaster.DistributorDetails,
          {}
        );
        console.log(data);
        console.log(Response);

        if (!Response["errorDesc"]) {
          swal("Success", "Password Updated Successfully", "success");
          navigate("/");
        } else {
          swal("Failed", Response["errorDesc"], "error");
        }
      } else {
        swal("error", "Please enter Correct password", "error");
      }
      setLoader(false);
    } catch (error) {
      swal("error", "Internal Server Error", "error");
      console.log(error);
      setLoader(false);
    }
  };
  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "87vh",
        // backgroundColor: Colors.PrimaryBackgroundColor[200],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs" className="SignInBox">
          <CssBaseline />
          <Paper
            sx={{
              marginTop: 1,
              padding: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: 5,

              //  boxShadow: "0 2px 6px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            }}
            elevation={4}
          >
            <Avatar sx={{ bgcolor: Colors.SecondaryBackGroundColor }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Change Transaction Password
            </Typography>
            <Box
              component="form"
              onSubmit={(event) => Authenticate(event)}
              validate="true"
            >
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="outlined-adornment-password">
                  Old Transaction Password
                </InputLabel>
                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  error={AllError["oldPWD"]}
                  helperText={
                    AllError["oldPWD"]
                      ? "Minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                      : ""
                  }
                  name="oldPWD"
                  label="Old Transaction Password"
                  inputProps={{
                    pattern: "^[A-Za-z0-9_-]*$",
                  }}
                  id="password"
                  value={data.oldPWD}
                  onChange={validate}
                  type={showOldTnxPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowOldTnxPassword(() => !showOldTnxPassword)
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showOldTnxPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="outlined-adornment-password">
                  New Transaction Password
                </InputLabel>
                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  error={AllError["newPWD"]}
                  helperText={
                    AllError["newPWD"] == true
                      ? "Minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                      : ""
                  }
                  name="newPWD"
                  label="New Transaction Password"
                  inputProps={{
                    pattern: "^[A-Za-z0-9_-]*$",
                  }}
                  id="password"
                  value={data.newPWD}
                  onChange={validate}
                  type={showNewTnxPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowNewTnxPassword(() => !showNewTnxPassword)
                        }
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewTnxPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 1,
                  mb: 2,
                  bgcolor: Colors.PrimaryBackgroundColor[500],
                  "&:hover": { bgcolor: Colors.PrimaryBackgroundColor[800] },
                }}
                //  color='warning'
              >
                Update Transaction Password
              </Button>
            </Box>
          </Paper>
        </Container>
      </ThemeProvider>
    </Box>
  );
}
