import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UpdateRow } from "../../../features/RowSlice";
import {
  Grid,
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  TextField,
  MenuItem,
  Container,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import swal from "sweetalert";
import { Colors } from "../../../Utils/Constants";
import { RegistrationRequestInputFields } from "../../../Utils/RegistrationRequestInputFields";
import StateCityCountry from "../StateCityCountry";
import GSTInputs from "../../../components/GSTInputs";
import moment from "moment";
import AgentSelection from "../../../components/AgentSelection";
import { CRUDCommands } from "../../../Utils/Axios/RequestParameters";
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  paddingTop: 1,
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", md: "60%", lg: "80%" },
  height: { xs: 700, sm: 600 },
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  pb: 0,
  p: 0,
  m: 0,
};

export default function ApproveAgentRegistrationRequestModal({
  row,
  openModal,
  handleModalOpen,
  handleModalClose,
  Type,
  URL,
  name,
  // dataFields,
}) {
  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  const [EnterGST, setEnterGST] = useState(false);
console.log({AgentRegistrationRequestData:row})
  const InitialErrors = {
    typeDetId: false,
    name: false,
    distributorCode: false,
    cmpName: false,
    add1: false,
    add2: false,
    add3: false,
    city: false,
    state: false,
    country: false,
    pincode: false,
    phone: false,
    mobile: false,
    pan: false,
    email: false,
    tranPwd: false,
    dateOfBirth: false,
    gender: false,
    alternateEmail: false,
    title: false,
    lastName: false,
    aadharNo: false,
    gstcompanyName: false,
    gstno: false,
    gstphone: false,
    gstemail: false,
    gstaddress: false,
    gstpincode: false,
    gststate: false,
    gstcity: false,
    typeId: false,
    distributorGroupConfiguration: false,
  };
  const [data, setData] = useState({
    ...row,
    dateOfBirth:
      row.dateOfBirth !== ""
        ? moment(row.dateOfBirth).format("YYYY-MM-DD")
        : "",
  });
  const [Errors, setErrors] = useState(InitialErrors);
  const RequiredInputs = [
    "title",
    "name",
    "lastName",
    "mobileNo",
    "cmpName",
    "pan",
    "email",
    "add1",
  ];
  const dispatch = useDispatch();
  const [Loader, setLoader] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const AllErrors = Object.keys(Errors).filter(
        (key) => Errors[key] === true
      );
      const AllMissingFields = RequiredInputs.filter(
        (inputs) => data[inputs] === ""
      );
      if (AllErrors.length === 0 && AllMissingFields.length === 0) {
        const RequestData = {
            token: "",
            distributorId: 0,
            typeDetId: data["typeDetId"],
            name: data["name"],
            distributorCode: "",
            cmpName: data["cmpName"],
            add1: data["add1"],
            add2: data["add2"],
            add3: data["add3"],
            city: data["city"],
            state: data["state"],
            country: data["country"],
            pincode: data["pincode"],
            phone: data["phone"],
            mobile: data["mobileNo"],
            fax: "",
            pan: data["pan"],
            email: data["email"],
            tranPwd: "",
            dateOfBirth:data['dateOfBirth']  !==""?moment(data["dateOfBirth"]).format("MM/DD/YYYY"):"01/01/1990",
            distributorLevel: 0,
            level1DistributorId: 0,
            level2DistributorId: 0,
            joiningDate: "2022-11-11T10:43:42.325Z",
            padd1: data["add1"],
            padd2: data["add2"],
            padd3: data["add3"],
            pcity: data["city"],
            pstate: data["state"],
            pcountry: data["country"],
            ppincode: data["pincode"],
            pphone: data["phone"],
            pmobile: data["mobileNo"],
            pfax: "",
            isCustomer: false,
            isActive: true,
            activationRemark: "",
            commandId: CRUDCommands.ConfirmRegRequest,
            createBy: 0,
            createDate: "2022-11-11T10:43:42.325Z",
            createIp: "",
            updateBy: 0,
            updateDate: "2022-11-11T10:43:42.325Z",
            updateIp: "",
            gender: "",
            cardId: 0,
            isPaid: true,
            isProfileUpdated: true,
            invoiceNo: 0,
            paidDateTime: "2022-11-11T10:43:42.325Z",
            paidAmount: 0,
            isForm60: true,
            registrationAmount: 0,
            maintenanceChargeDetId: 0,
            isGenerateComm: true,
            isTranPwdUpdated: true,
            isTranAllowed: true,
            tranAllowedRemark: "",
            maritalStatus: "",
            occupation: "",
            activationDate: "2022-11-11T10:43:42.325Z",
            isEmailSmssent: true,
            isFirstLogin: true,
            logoImageUrl: "",
            isLogoDisplay: true,
            masterDistributorId: 0,
            userSeqNo: 0,
            loginName: "",
            pwd: "",
            isAllowLimitAllocationToDownline: true,
            alternateEmail: "",
            pandocImg: "",
            userRemark: "",
            isSendBalanceReminder: true,
            reminderBalance: 0,
            reminderInterval: 0,
            isSendReminderEmail: true,
            isSendReminderSms: true,
            isSerTaxOnSerCharge: true,
            isAllowToAddDownline: true,
            isTranPwdMendate: true,
            anniversaryDate: "2022-11-11T10:43:42.325Z",
            isSendDobintimationToCompany: true,
            title: data["title"],
            lastName: data["lastName"],
            designation: "",
            labelCaption: "",
            isSendPromotionalEmail: true,
            distributorUserAccessTimeId: 0,
            activenessCategoryId: 0,
            visitingCardReceiptDate: "2022-11-11T10:43:42.325Z",
            aadharNo: data["aadharNo"],
            gstcompanyName: data["gstcompanyName"],
            gstno: data["gstno"],
            gstphone: data["gstphone"],
            gstemail: data["gstemail"],
            gstaddress: data["gstaddress"],
            gstpincode: data["gstpincode"],
            gststate: data["gststate"],
            gstcity: data["gstcity"],
            countryCode: "IND",
            logo: data["logo"],
            userDistributorID: 0,
            typeId: data["typeId"],
            limitBalance: 0,
            creditAllowed: 0,
            level1DistributorCode: data["requestedDistributorCode"],
            transactionCredit: 0,
            tranCreditUtilized: 0,
            salesPersonId: 0,
            verificationCode: data["verificationId"],
            distributorGroupConfiguration: data["distributorGroupConfiguration"],
            holdAmt: 0,
            countryLastUpdated: "2022-11-11T10:43:42.325Z",
            masterDistributorName: "",
            masterDistributorCode: "",
            bankLastUpdated: "2022-11-11T10:43:42.325Z",
            stateLastUpdated: "2022-11-11T10:43:42.325Z",
            cityLastUpdated: "2022-11-11T10:43:42.325Z",
            rewardPointBalance: 0,
            taxExemptionRate: 0,
            taxExemptionAmt: 0,
            taxExemptionCertImg: "",
            isDisplayApicommission: true,
            applyConvenienceFeeOnRdspg: true,
            code: "",
            typeCode: "",
            description: "",
            categoryName: "",
            typeDetailName: "",
            typeMasterName: "",
            accountType: [
              {
                distributorAccountTypeId: 0,
                distributorId: 0,
                accountTypeId: 0,
                maintenanceChargeDetId: 0,
                isSendBalanceReminder: true,
                reminderBalance: 0,
                reminderInterval: 0,
                isSendReminderEmail: true,
                isSendReminderSms: true,
                commandId: 0,
                createBy: 0,
                createDate: "2022-11-11T10:43:42.325Z",
                createIp: "",
                updateBy: 0,
                updateDate: "2022-11-11T10:43:42.325Z",
                updateIp: "",
                isTranAllowed: true,
                tranAllowedRemark: "",
                limitBalance: 0,
                creditAllowed: 0,
                transactionCredit: 0,
                tranCreditUtilized: 0,
                holdAmt: 0,
                accountTypeCode: "", // temp added manually due to required filled
                accountTypeName: "", // temp added manually due to required filled
              },
            ],
          };

        console.log(RequestData);

        const response = await GetResponse(RequestData, URL, {});

        if (!response["errorDesc"]) {
          swal(
            "Approved!",
            `Registration Done Successfully! \n  ${response}`,
            "success",
            {
              // timer: 2000,
            }
          );

          handleModalClose();
          setData(row);
          dispatch(UpdateRow());
        } else {
          swal(
            "Registration Failed!",
            `${response["errorDesc"].split(":")[1]}`,
            "error",
            {
              // timer: 2000,
            }
          );

          // handleModalClose();
          // setData(row);
          // dispatch(UpdateRow());
        }
      } else {
        swal(
          "Sorry!",
          `Please Enter All Fields Correctly! \n ${AllErrors.join(
            ","
          )}  ${AllMissingFields.join(",")} - not filled Correctly`,
          "error",
          {
            // timer: 2000,
          }
        );
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
    setLoader(false);
  };

  const handleCheck = (event) => {
    setData({ ...data, [event.target.name]: event.target.checked });
  };

  const validateStateCityCountry = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    console.log(e.target.name, e.target.value);
  };
 
  const ValidateInput = (event, regex) => {
    setData({ ...data, [event.target.name]: event.target.value });
    console.log(event.target.name, event.target.value);

    if (data[event.target.name]    ) {
      const IsValid = regex.test(event.target.value);
      if (IsValid === false) {
        setErrors({ ...Errors, [event.target.name]: true });
      } else {
        setErrors({ ...Errors, [event.target.name]: false });
      }
    }
  
  };

  const Isvalid=(event,regex)=>{
    if (data[event.target.name]    ) {
      const IsValid = regex.test(event.target.value);
      if (IsValid === false) {
        setErrors({ ...Errors, [event.target.name]: true });
      } else {
        setErrors({ ...Errors, [event.target.name]: false });
      }
    }
    if(RequiredInputs.includes(event.target.name) ===false  &&  data[event.target.name] ===""){
      setErrors({ ...Errors, [event.target.name]: false });
    }
  }

  React.useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Container component="main" maxWidth="lg" sx={styles}>
            <SimpleBackDrops Loader={Loader} />
            {/* <CssBaseline /> */}
            <Typography
              component="h1"
              variant="h5"
              sx={{
                width: "50vw",
                backgroundColor: Colors.SecondaryBackGroundColor[400],
                color: "white",
                height: 70,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                boxShadow:
                  "0 5px 10px rgba(0,0,0,0.16), 0 5px 5px rgba(0,0,0,0.16)",
                mb: 1,
                fontSize: { xs: 15, sm: 20 },
              }}
            >
              {Type} {name}
            </Typography>
            <Box
              component="form"
              validate="true"
              onSubmit={handleSubmit}
              sx={{
                // overflow: "auto",
                height: { xs: 700, sm: 600 },
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              <Box
                sx={{
                  overflow: "auto",
                  height: { xs: 580, sm: 480 },
                  paddingTop: 2,
                }}
              >
                <Grid container spacing={2} sx={{ overflow: "auto" }}>
                  <Grid item xs={12} sm={6} lg={3}>
                    {" "}
                    <TextField
                      id="outlined-disabled"
                      label="Parent Distributor"
                      name="requestedDistributorCode"
                      InputLabelProps={{ shrink: true }}
                      value={data["requestedDistributorCode"]}
                      fullWidth
                      disabled={true}
                      autoFocus={true}
                      onChange={(e) => validateStateCityCountry(e, /.*/)}
                    />
                  </Grid>
                  <AgentSelection
                    AgentTypeValue={data.typeId}
                    AgentCategoryValue={data.typeDetId}
                    AgentGroupValue={data.distributorGroupConfiguration}
                    grid={3}
                    ValidateInput={ValidateInput}
                  />
                  {RegistrationRequestInputFields.map((Fields, index) => {
                    return Fields.type === "CheckBox" ? (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={3}
                        key={`${Fields.name}${Fields.IsError}`}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                data[Fields.name] === true ||
                                data[Fields.name] === "on"
                                  ? true
                                  : false
                              }
                              onChange={handleCheck}
                              name={Fields.name}
                            />
                          }
                          label={Fields.name}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6} lg={3} key={Fields.name}>
                        <TextField
                          error={Errors[Fields.name]}
                          // autoComplete="given-name"
                          type={Fields.type}
                          name={Fields.name}
                          value={data[Fields.name]}
                          select={Fields.options.length > 0 ? true : false}
                          required={Fields.required}
                          fullWidth
                          id={Fields.name}
                          label={Fields.label}
                          InputLabelProps={{ shrink: true }}
                          onChange={(event) =>
                            ValidateInput(event, Fields.regex)
                          }
                          onBlur={(event)=>Isvalid(event, Fields.regex)}
                          helperText={
                            Errors[Fields.name] === true ? Fields.errorMsg : ``
                          }
                        >
                          {Fields.options.length > 0 ? (
                            Fields.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))
                          ) : (
                            <></>
                          )}
                        </TextField>
                      </Grid>
                    );
                  })}

                  <StateCityCountry
                    state={data.state}
                    city={data.city}
                    country={data.country}
                    pincode={data.pincode}
                    ValidateInput={validateStateCityCountry}
                    grid={3}
                  />
                  
                  {EnterGST ? (
                    <GSTInputs
                      gstCompanyValue={data.gstcompanyName}
                      gstnovalue={data.gstno}
                      gstphoneValue={data.gstphone}
                      gstemailValue={data.gstemail}
                      gstaddressValue={data.gstaddress}
                      gststateValue={data.gststate}
                      gstcityValue={data.gstcity}
                      gstpincodeValue={data.gstpincode}
                      gstcompanyError={Errors["gstcompanyName"]}
                      gstnoError={Errors["gstno"]}
                      gstphoneError={Errors["gstphone"]}
                      gstemailError={Errors["gstemail"]}
                      gstaddressError={Errors["gstaddress"]}
                      gstpincodeError={Errors["gstpincode"]}
                      ValidateInput={ValidateInput}
                      grid={3}
                    />
                  ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={EnterGST}
                            onChange={(e) => setEnterGST(e.target.checked)}
                          />
                        }
                        label={
                          Type === "Add"
                            ? "Enter GST Details"
                            : "Update GST Details"
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Grid container spacing={1} sx={{ padding: 1 }}>
                <Grid item xs={6} sm={6} lg={6}>
                  <Button
                    fullWidth
                    color="error"
                    variant="contained"
                    // sx={{ mt: 1, mb: 1 }}
                    onClick={() => handleModalClose()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} lg={6}>
                  <Button
                    type="submit"
                    fullWidth
                    color="success"
                    variant="contained"
                    // sx={{ mt: 1, mb: 1 }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    {Type}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Fade>
      </Modal>
    </>
  );
}
