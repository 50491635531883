import { GetResponse } from "./Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "./Axios/RequestParameters";

async function GetCountryId() {
  var countryOptions = [{}];
  try {
    const AllCountry = await GetResponse(
      {
        countryId: 0,
        countrycode: "",
        continentId: 0,
        continentName: "",
        countryname: "",
        isActive: true,
        commandId: CRUDCommands.ListAll,
        createdBy: 0,
        createDateTime: "2022-10-28T10:26:40.943Z",
        createIp: "string",
        updatedBy: 0,
        updateDateTime: "2022-10-28T10:26:40.943Z",
        upDateIp: "string",
        countryCodeAir: "string",
        isBlocked: true,
        countryCodeAir3: "string",
      },

      RDSLinks.CountryMaster,
      {}
    );
    console.log(AllCountry);

    if (AllCountry.length > 0) {
      const newArray = AllCountry.map((Country) => {
        //   {
        //     "countryID": 109,
        //     "countryCode": "0091",
        //     "countryname": "India",
        //     "isActive": true,
        //     "countryCodeAIR": "IN",
        //     "isBlocked": false,
        //     "countryCodeAIR3": "IND",
        //     "continentID": 1,
        //     "continentName": "Asia"
        //   },
        return {
          label: Country.countryname,
          value: Country.countryId,
          countryname: Country.countryname,
          countryId: Country.countryId,
        };
      });
      countryOptions = newArray;
    } else {
      countryOptions = [
        { label: "Please Refresh the Page ", value: "", countryId: "" },
      ];
    }
  } catch (error) {
    console.log(error);
  }

  return countryOptions;
}
export { GetCountryId };
