import { Box, Fab, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Edit } from "@mui/icons-material";
import { green, red } from "@mui/material/colors";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch } from "react-redux";
import { UpdateRow } from "../../features/RowSlice";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import swal from "sweetalert";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import { CRUDCommands } from "../../Utils/Axios/RequestParameters";
import CommonAddEditModal from "./CommonAddEditModal";
const CRUDActions = ({
  params,
  rowId,
  RequiredInputs,
  InitialError,
  dataFields,
  EditURL,
  DeleteURL,
  RowDeleteId,
  name,
}) => {
  const dispatch = useDispatch();
  const [openModal, setopenModal] = useState(false);
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const Type = "Update";
  const DeleteRow = async () => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this row data!",
      icon: "warning",
      buttons: ["No, cancel it !", "Yes, I am sure!"],
      dangerMode: true,
      timer: 5000,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        console.log(`${DeleteURL}${RowDeleteId}`);

        var Data = params.row;

        Data.commandId = CRUDCommands.Delete;
        console.log({ Data });
        const Response = await GetResponse(Data, DeleteURL);
        console.log(Response);
        dispatch(UpdateRow());
        if (Response === true) {
          swal({
            title: "Deleted!",
            text: `The Selected row  is Deleted Successfully!`,
            icon: "success",
          });
        } else {
          swal({
            title: "Error!",
            text: `Some Error Occured`,
            icon: "error",
          });
        }
      } else {
        swal({
          title: "Cancelled",
          text: "Your Data is safe :)",
          icon: "error",
        });
      }
    });
  };

  useEffect(() => {
    if (rowId === params.id && success) setSuccess(false);
  }, [rowId]);

  return (
    <>
      {openModal === true ? (
        <CommonAddEditModal
          openModal={openModal}
          handleModalClose={handleModalClose}
          handleModalOpen={handleModalOpen}
          row={params.row}
          RequiredInputs={RequiredInputs}
          InitialError={InitialError}
          dataFields={dataFields}
          Type={Type}
          URL={EditURL}
          name={name}
        />
      ) : (
        <></>
      )}

      <Box
        sx={{
          m: 1,
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Tooltip title="Delete" arrow>
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            bgcolor: red[400],
            "&:hover": { bgcolor: red[700] },
          }}
          onClick={DeleteRow}
        >
          <DeleteForeverIcon />
        </Fab>
          </Tooltip>
          <Tooltip title="Edit" arrow>

        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            bgcolor: green[400],
            "&:hover": { bgcolor: green[700] },
          }}
          onClick={() => handleModalOpen()}
        >
          <Edit />
        </Fab>
        </Tooltip>

        {name === "Courier Api" || name === "Courier Service Provider" ? (
        <Tooltip title="Add link" arrow>

          <Fab
            color="primary"
            sx={{
              width: 40,
              height: 40,
            }}
          >
            <InsertLinkIcon />
          </Fab>
          </Tooltip>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default CRUDActions;
