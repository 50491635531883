import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { UpdateRow } from "../../../features/RowSlice";
import {
  Grid,
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  TextField,
  MenuItem,
  CssBaseline,
  Container,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import swal from "sweetalert";
import { Colors } from "../../../Utils/Constants";
import { CRUDCommands } from "../../../Utils/Axios/RequestParameters";
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  paddingTop: 1,
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", md: "40%", lg: "30%" },
  height: { xs: 700, sm: 350 },
  //   scrollY:true,
  //   flexWrap:Scr,
  // overflow: "auto",
  bgcolor: "white",
  //   border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  pb: 0,
  p: 0,
  m: 0,
};
// Type = Edit or ADD

export default function MenuAddEditModal({
  row,
  dataFields,
  openModal,
  handleModalOpen,
  handleModalClose,
  Type,
  URL,
  name,
}) {
  const dispatch = useDispatch();
  const [Loader, setLoader] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);

  const [Render, setRender] = React.useState("render");

  const Rerender = () => {
    setRender(() => {
      return Render === "render" ? "rerender" : "render";
    });
  };
  const [data, setData] = React.useState(row);
  React.useEffect(() => {
    Type === "Add"
      ? setData({ ...data, commandId: CRUDCommands.Add })
      : setData({ ...data, commandId: CRUDCommands.Update });
  }, [Type]);
  const [UpdatedDataField, setUpdatedDataField] = React.useState(dataFields);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const ErrorObjArray = UpdatedDataField.filter((Fields, index) => {
        return Fields.IsError === true;
      });
      const ErrorFields = ErrorObjArray.map((Fields) => {
        return Fields.Label;
      });
      const AllFields = dataFields.map((Fields, index) => {
        return Fields.name;
      });
      const MissingFields = AllFields.filter((Fields) => {
        return data[Fields] === "";
      });

      console.log(MissingFields, ErrorObjArray, ErrorFields);
      if (MissingFields.length === 0 && ErrorObjArray.length === 0) {
        const newData = data;
        Type === "Add"
          ? (newData.commandId = CRUDCommands.Add)
          : (newData.commandId = CRUDCommands.Update);
        const response = await GetResponse(newData, URL, {});
        console.log(response, "From Add Edit Modal", newData, URL);
        if (response === true) {
          setLoader(false);
          swal("Success!", `${name}  ${Type}'d Successfully!`, "success", {});

          handleModalClose();
          dispatch(UpdateRow());
          GotoDefault();
          setData(row);
        } else if (response === false) {
          setLoader(false);
          swal("Error!", `Some Error Occured!`, "error", {});
        } else if (response.errorDesc) {
          setLoader(false);
          swal("Error!", response.errorDesc, "error", {});
        }
      } else {
        swal(
          "Sorry!",
          `Please Enter All Fields Correctly! \n ${MissingFields.join(
            ","
          )}  ${ErrorFields.join(",")} - not filled Correctly`,
          "error",
          {
            // timer: 2000,
          }
        );
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      swal("Failed!", "Internal Server Error", "error");
      console.log(error);
    }
  };
  console.log(dataFields);
  const handleCheck = (event) => {
    setData({ ...data, [event.target.name]: event.target.checked });
  };
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    // SetnewDataFields({...newDataFields,[event.target.name]:event.target.value})
  };

  const ValidateInput = (event, index) => {
    setData({ ...data, [event.target.name]: event.target.value });

    const SelectedField = dataFields[index];
    console.log({ event });
    console.log({ index });
    console.log({ SelectedField });

    if (data[event.target.name]) {
      const IsValid = SelectedField.validReg.test(event.target.value);
      console.log({ IsValid });
      if (IsValid === false) {
        const TempField = { ...SelectedField, IsError: true, autoFocus: true };
        const TempArray = UpdatedDataField;
        TempArray[index] = TempField;
        setUpdatedDataField(TempArray);
      } else {
        const TempField = {
          ...SelectedField,
          IsError: false,
          autoFocus: false,
        };
        const TempArray = UpdatedDataField;
        TempArray[index] = TempField;
        setUpdatedDataField(TempArray);
      }
    }
  };
  const GotoDefault = () => {
    setData(row);
  };
  React.useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => {
          handleModalClose();
          GotoDefault();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Container component="main" maxWidth="lg" sx={styles}>
            <SimpleBackDrops Loader={Loader} />
            {/* <CssBaseline /> */}
            <Typography
              component="h1"
              variant="h5"
              sx={{
                width: "20vw",
                backgroundColor: Colors.SecondaryBackGroundColor[400],
                color: "white",
                height: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                boxShadow:
                  "0 5px 10px rgba(0,0,0,0.16), 0 5px 5px rgba(0,0,0,0.16)",
                mb: 1,
                fontSize: { xs: 15, sm: 20 },
              }}
            >
              {Type} {name}
            </Typography>
            <Box
              component="form"
              validate="true"
              onSubmit={handleSubmit}
              sx={{
                // overflow: "auto",
                height: { xs: 500, sm: 310 },
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              <Box
                sx={{
                  overflow: "auto",
                  height: { xs: 500, sm: 230 },
                  paddingTop: 2,
                }}
                key={Render}
              >
                <Grid container spacing={2} sx={{ overflow: "auto" }}>
                  {data.parentMenuCaption !== "" && (
                    <Grid item xs={12}>
                      <TextField
                        disabled={true}
                        type="text"
                        name="parentMenuCaption"
                        value={data.parentMenuCaption}
                        fullWidth
                        id="Parent Menu"
                        label="Parent Menu"
                      ></TextField>
                    </Grid>
                  )}
                  {UpdatedDataField.map((Fields, index) => {
                    return Fields.Type === "CheckBox" ? (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={Fields.grid ? Fields.grid : 4}
                        key={Fields.name}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                data[Fields.name] === true ||
                                data[Fields.name] === "on"
                                  ? true
                                  : false
                              }
                              onChange={handleCheck}
                              name={Fields.name}
                            />
                          }
                          label={Fields.name}
                        />
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        sm={Fields.grid ? Fields.grid : 6}
                        lg={Fields.grid ? Fields.grid : 6}
                        key={Fields.name}
                      >
                        <TextField
                          error={Fields.IsError}
                          // autoComplete="given-name"
                          type={Fields.Type}
                          name={Fields.name}
                          value={data[Fields.name]}
                          select={Fields.Type === "Select" ? true : false}
                          required
                          fullWidth
                          id={Fields.name}
                          label={Fields.Label}
                          InputLabelProps={{ shrink: true }}
                          InputProps={
                            Fields.Type === "number"
                              ? { inputProps: { min: 0, maxLength: 10 } }
                              : {}
                          }
                          autoFocus={
                            index === 0 || Fields.autoFocus ? true : false
                          }
                          // onChange={handleChange}
                          onChange={(event) => ValidateInput(event, index)}
                          helperText={
                            Fields.IsError === true ? Fields.ErrorMsg : ``
                          }
                        >
                          {Fields.Type === "Select" ||
                          Fields.options.length > 0 ? (
                            Fields.options.map((option) => (
                              <MenuItem key={option.label} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))
                          ) : (
                            <></>
                          )}
                        </TextField>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              <Grid container spacing={1} sx={{ padding: 2 }}>
                <Grid item xs={6} sm={6} lg={6}>
                  <Button
                    fullWidth
                    color="error"
                    variant="contained"
                    sx={{ mt: 1, mb: 1 }}
                    onClick={() => {
                      handleModalClose();
                      GotoDefault();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} lg={6}>
                  <Button
                    type="submit"
                    fullWidth
                    color="success"
                    variant="contained"
                    sx={{ mt: 1, mb: 1 }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    {Type}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Fade>
      </Modal>
    </>
  );
}
