import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
// import AccountCircle from '@mui/icons-material/Adb';
import AccountCircle from "@mui/icons-material/AccountCircle";

import InstaCourier from "../assets/images/InstaCourierLogo.png";
import { Link, NavLink } from "react-router-dom";
import { Colors } from "../Utils/Constants";

const pages = [
  {
    Name: "Home",
    Link: "/",
  },
  {
    Name: "About",
    Link: "/",
  },
  {
    Name: "Contact",
    Link: "/",
  },
];
const settings = ["Log In"];

function HomeNavbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "white",
        position: "sticky",
        top: 0,
        zIndex: 1200,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "flex",
                md: "none",
                color: Colors.PrimaryColor[800],
              },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((Links) => (
                <MenuItem key={Links} onClick={handleCloseNavMenu}>
                  <NavLink
                    key={Links.Link}
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      marginTop: 2,
                      marginBottom: 2,
                      color: "white",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      ...(isActive
                        ? { color: Colors.PrimaryColor[800] }
                        : { color: Colors.SecondaryColor[700] }),
                    })}
                    to={Links.Link}
                  >
                    <Typography textAlign="center">{Links.Name}</Typography>
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="warning"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <img src={InstaCourier} alt="logo" width={150} />
          </IconButton>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((Links) => (
              <Button
                key={Links}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                <NavLink
                  key={Links.Link}
                  style={({ isActive }) => ({
                    textDecoration: "none",
                    marginTop: 2,
                    marginBottom: 2,
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    ...(isActive
                      ? { color: Colors.PrimaryColor[800] }
                      : { color: Colors.SecondaryColor[700] }),
                  })}
                  to={Links.Link}
                >
                  <Typography textAlign="center">{Links.Name}</Typography>
                </NavLink>
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    {setting}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default HomeNavbar;
