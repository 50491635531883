import StarBorder from "@mui/icons-material/StarBorder";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReportIcon from "@mui/icons-material/Report";
import PsychologyIcon from "@mui/icons-material/Psychology";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FeedbackIcon from "@mui/icons-material/Feedback";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import HelpIcon from "@mui/icons-material/Help";
import { NavigationLinks } from "../router/NavigationLinks";
import ApiIcon from "@mui/icons-material/Api";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SellIcon from "@mui/icons-material/Sell";
import LinkIcon from "@mui/icons-material/Link";
import InventoryIcon from "@mui/icons-material/Inventory";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import WalletIcon from "@mui/icons-material/Wallet";
import PaymentsIcon from "@mui/icons-material/Payments";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PasswordIcon from "@mui/icons-material/Password";
import GroupIcon from "@mui/icons-material/Group";
import Person4Icon from "@mui/icons-material/Person4";
import GridViewIcon from "@mui/icons-material/GridView";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import GroupsIcon from "@mui/icons-material/Groups";

const AdminDashBoardLinkList = [
  {
    Name: "Master",
    NestedLinks: [
      {
        Name: "Main",
        Link: "/Admin/",
        NestedLinks: [
          {
            Name: "Agents",
            Icon: SupportAgentIcon,
            Link: "/Admin/Agents",
            NestedLinks: [],
          },
          {
            Name: "Agents Group",
            Icon: Diversity1Icon,
            Link: "/Admin/AgentGroup",
            NestedLinks: [],
          },
          {
            Name: "Agents Type",
            Icon: GroupsIcon,
            Link: "/Admin/AgentType",
            NestedLinks: [],
          },
          //  {
          //   Name: "Agents Category",
          //   Icon: GroupIcon,
          //   Link: "/Admin/AgentCategory",
          //   NestedLinks: [],
          // },
          {
            Name: "Registration Request",
            Icon: PersonAddIcon,
            Link: "/Admin/RegistrationRequest",
            NestedLinks: [],
          },
          {
            Name: "Banks",
            Icon: AccountBalanceIcon,
            Link: "/Admin/Banks",
            NestedLinks: [],
          },
          {
            Name: "Interface",
            Icon: GridViewIcon,
            Link: "/Admin/Interface",
            NestedLinks: [],
          },
        ],
        Icon: SupportAgentIcon,
      },
      {
        Name: "Courier",
        Icon: LocalShippingIcon,
        // Link: "/DashBoard/OverView",
        NestedLinks: [
          {
            Name: "CourierType",
            Link: NavigationLinks.Admin.CourierType,
            NestedLinks: [],
            Icon: InventoryIcon,
          },
          {
            Name: "CourierAPI",

            Link: NavigationLinks.Admin.CourierAPI,
            NestedLinks: [],
            Icon: LocalShippingIcon,
          },
          {
            Name: "CourierServiceProvider",

            Link: NavigationLinks.Admin.CourierServiceProvider,
            NestedLinks: [],
            Icon: SupervisorAccountIcon,
          },

          {
            Name: "CommissionConfiguration",
            Link: NavigationLinks.Admin.CommissionConfiguration,
            NestedLinks: [],
            Icon: SellIcon,
          },
        ],
      },
    ],
    Icon: EngineeringIcon,
  },
  {
    Name: "Transactions",
    NestedLinks: [
      {
        Name: "Wallet",
        Link: "/Admin/",
        NestedLinks: [
          {
            Name: "Payment Reciept",
            Link: "/Admin/",
            NestedLinks: [],
            Icon: PaymentsIcon,
          },
          {
            Name: "Payment Paid",
            Link: "/Admin/",
            NestedLinks: [],
            Icon: PaymentsIcon,
          },
          {
            Name: "Top-Up Request",
            Link: "/Admin/",
            NestedLinks: [
              // {
              //   Name: "Online Top_Up",
              //   Link: '/Admin/',
              //   NestedLinks: [],
              //   Icon: RequestPageIcon,
              // },
              {
                Name: "Offline Top_Up ",
                Link: "/Admin/PendingTopUpRequest",
                NestedLinks: [],
                Icon: RequestPageIcon,
              },
            ],
            Icon: RequestPageIcon,
          },
        ],
        Icon: WalletIcon,
      },
    ],
    Icon: RequestPageIcon,
  },
  {
    Name: "User Managment",
    Link: "/Admin/",
    NestedLinks: [
      {
        Name: "Users",
        Link: "/Admin/Users",
        NestedLinks: [],
        Icon: ManageAccountsIcon,
      },
      {
        Name: "Roles",
        Link: "/Admin/Role",
        NestedLinks: [],
        Icon: ManageAccountsIcon,
      },
      {
        Name: "Change Password",
        Link: "/Admin/",
        NestedLinks: [],
        Icon: PasswordIcon,
      },
    ],
    Icon: ManageAccountsIcon,
  },
];

export { AdminDashBoardLinkList };
