import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavigationLinks } from "../../router/NavigationLinks";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import { useSelector, useDispatch } from "react-redux";
import { UpdateDetails } from "../../features/AgentDetailsSlice";
import swal from "sweetalert";
import AdminSignIn from "../Components/AdminSignIn";
import { CRUDCommands, RDSLinks, UserMaster } from "../../Utils/Axios/RequestParameters";
import { UpdateAdminToken } from "../../features/AdminTokenSlice";
import { Box } from "@mui/system";
import { UpdateAdminDetails } from "../../features/AdminDetailsSlice";
import SimpleBackDrops from "../../components/SimpleBackDrops";
export default function AdminSignInPage() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  const [errorText, setErrorText] = useState();
  const [empty, setEmpty] = useState(false);
  const [InvalidIdPassword, setInvalidIdPassword] = useState(false);
  const [Loader, setLoader] = useState(false);

  const [Response, setResponse] = useState("");
  const [data, setdata] = useState({
    UserName: "",
    Password: "",
  });
  const [Error, setError] = useState({
    UserName: false,
    Password: false,
  });

  const validate = (event) => {
    if (event.target.value.match(event.target.pattern)) {
      setErrorText("");
      setdata({ ...data, [event.target.name]: event.target.value });
    } else {
      setError({ ...Error, [event.target.name]: true });
    }
  };

  const Authenticate = async (event) => {
    setLoader(true);
    event.preventDefault();

    //  Call Axios
    try {
      if (data.UserName !== "" && data.Password !== "") {
        const Data = {
          token: "",
          userId: 0,
          roleId: 0,
          loginName: data.UserName,
          userName: "",
          pwd: data.Password,
          email: "",
          mobile: "",
          isActive: true,
          commandId: CRUDCommands.VerifyLogin,
          createBy: 0,
          createDate: "2022-10-22T09:54:11.152Z",
          createIp: "",
          updateBy: 0,
          updateDate: "2022-10-22T09:54:11.152Z",
          updateIp: "",
          panelId: 0,
          ualid: 0,
          userAccessTimeId: 0,
          isTemporaryCreditAllowed: true,
          panelCode: "ADMIN",
        };

        const Response = await GetResponse(Data, UserMaster.GetAll);
        console.log(Data);
        console.log(Response);

        if (!Response.errorDesc) {
          dispatch(UpdateAdminDetails(Response));
          dispatch(UpdateAdminToken(Response.token));
          sessionStorage.setItem("AdminToken", Response.token);
          sessionStorage.setItem("AdminDetails", JSON.stringify(Response));

          Navigate(NavigationLinks.Admin.Admin);
        } else {
          setResponse(Response);
          setInvalidIdPassword(true);
        }
      } else {
        setEmpty(true);
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (empty == true) {
      swal(
        "Oops!",
        `Please Enter ${data.UserName == "" ? "UserName" : ""}  ${
          data.UserName == "" && data.Password == "" ? "and" : ""
        } ${data.Password == "" ? "Password" : ""} `,
        "error"
      );
      setEmpty(false);
    }
  }, [empty]);

  useEffect(() => {
    if (InvalidIdPassword == true) {
      // const error =
      //   Response.VerifyLoginResponse.Error[
      //     "@attributes"
      //   ].errorDescription.split(":")[1];
      const error = Response.errorDesc;
      swal("Oops!", `${error}`, "error");
      setInvalidIdPassword(false);
    }
  }, [InvalidIdPassword]);

  useEffect(() => {
    console.log({ AgentDetails });
  }, [AgentDetails]);
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SimpleBackDrops Loader={Loader} />
      <AdminSignIn
        Authenticate={Authenticate}
        validate={validate}
        Error={Error}
        data={data}
        empty={empty}
      />
    </Box>
  );
}
