import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import SignUpPage from "../pages/SignUpPage";
import AvailablePartners from "../pages/AvailablePartners";
import ChangePassword from "../pages/ChangePassword";
import AgentHomePage from "../pages/AgentHomePage";
import NewOrder from "../pages/NewOrder";
import OverViewPage from "../pages/OverViewPage";
import UpdateProfile from "../pages/UpdateProfile";
import AdminDashBoard from "../Admin/Pages/AdminDashBoard";
import CourierAPIDataGrid from "../Admin/Components/CourierAPIDataGrid";
import AdminSignInPage from "../Admin/Pages/AdminSignInPage";
import CourierServiceProviderDataGrid from "../Admin/Components/CourierServiceProvider/CourierServiceProviderDataGrid";
import CourierTypeDataGrid from "../Admin/Components/CourierTypeDataGrid";
import CommissionConfigurationDataGrid from "../Admin/Components/CommissionConfigurationDataGrid";
import { useSelector } from "react-redux";
import AdminMainPage from "../Admin/Pages/AdminMainPage";
import UsersDataGrid from "../Admin/Components/UsersDataGrid";
import NotFoundPage from "../components/NotFoundPage";
import UserRolesDataGrid from "../Admin/Components/UserRole/UserRolesDataGrid";
import AgentsGroupDataGrid from "../Admin/Components/AgentGroup/AgentsGroupDataGrid";
import BookingMainPage from "../components/Booking/BookingMainPage";
import DeliveryDetails from "../components/Booking/DeliveryDetails";
import OrderDetails from "../components/Booking/OrderDetails";
import PaymentDetails from "../components/Booking/PaymentDetails";
import PendingRegistrationRequestDataGrid from "../Admin/Components/RegistrationRequest/PendingRegistrationRequestDataGrid";
import TopUpOffline from "../pages/Top-UP/TopUpOffline";
import TopUpOnline from "../pages/Top-UP/TopUpOnline";
import PendingTopUpOfflineDataGrid from "../Admin/Components/TopUpOffline/PendingTopUpOfflineDataGrid";
import BankDataGrid from "../Admin/Components/Bank/BankDataGrid";
import ChangeTnxPassword from "../pages/ChangeTnxPassword";
import AgentTypeDataGrid from "../Admin/Components/AgentType/AgentTypeDataGrid";
import SiteManagementMainPage from "../SiteManagement/Pages/SiteManagementMainPage";
import SiteManagementSignInPage from "../SiteManagement/Pages/SiteManagementSignInPage";
import SiteMGMTDashBoard from "../SiteManagement/Components/SiteMGMTDashBoard";
import InterfaceDataGrid from "../Admin/Components/Interface/InterfaceDataGrid";
import AgentsDataGrid from "../Admin/Components/Agents/AgentsDataGrid";
import EmailTemplateDataGrid from "../SiteManagement/Components/EmailTemplate/EmailTemplateDataGrid";
import GetReportFilter from "../components/Reports/GetReportFilter";
import ReportDataGrid from "../components/Reports/ReportDataGrid";
import BookingDetails from "../components/Booking/BookingDetails";
import BankDetails from "../components/BankDetails";
import BookingHistoryDataGrid from "../components/BookingHistory/BookingHistoryDataGrid";
import DownlinesAgentsDataGrid from "../pages/DownlinesAgents/DownlinesAgentsDataGrid";
import MenuDataGrid from "../SiteManagement/Components/Menu/MenuDataGrid";
import AdminBookingHistoryDataGrid from "../Admin/Components/BookingHistory/AdminBookingHistoryDataGrid";
export default function Router() {
  const AdminToken = useSelector((state) => state.AdminToken.value);
  const AgentToken = useSelector((state) => state.AgentToken.value);
  const SiteMGMTToken = useSelector((state) => state.SiteMGMTToken.value);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        {AgentToken ? (
          <Route path="/" element={<AgentHomePage />}>
            <Route path="/" element={<OverViewPage />}></Route>
            <Route path="/DashBoard" element={<OverViewPage />}></Route>

            <Route path="/NewOrder" element={<NewOrder />}></Route>
            <Route path="/UpdatePassword" element={<ChangePassword />}></Route>
            <Route
              path="/UpdateTnxPassword"
              element={<ChangeTnxPassword />}
            ></Route>

            <Route path="/UpdateProfile" element={<UpdateProfile />}></Route>
            <Route path="/Order" element={<OverViewPage />}></Route>
            <Route path="/checkStatus" element={<OverViewPage />}></Route>
            <Route
              path="/bookinghistory"
              element={<BookingHistoryDataGrid />}
            ></Route>
            <Route path="/Report" element={<OverViewPage />}></Route>
            <Route path="/Plan" element={<OverViewPage />}></Route>
            <Route path="/Settings" element={<OverViewPage />}></Route>
            <Route path="/FeedBack" element={<OverViewPage />}></Route>
            <Route path="/Help" element={<OverViewPage />}></Route>
            <Route path="/cancellation" element={<OverViewPage />}></Route>
            <Route path="/BankDetails" element={<BankDetails />}></Route>

            <Route path="/AddAgent" element={<SignUpPage />}></Route>
            <Route
              path="/DownlineAgents"
              element={<DownlinesAgentsDataGrid />}
            ></Route>

            <Route
              path="/DownlineTopUpRequest"
              element={<PendingTopUpOfflineDataGrid />}
            ></Route>

            <Route
              path="/AvailablePartners"
              element={<AvailablePartners />}
            ></Route>
            <Route path="/Booking" element={<BookingMainPage />}>
              <Route
                path="/Booking/DeliveryDetails"
                element={<DeliveryDetails />}
              ></Route>
              <Route
                path="/Booking/OrderDetails"
                element={<OrderDetails />}
              ></Route>
              <Route
                path="/Booking/PaymentDetails"
                element={<PaymentDetails />}
              ></Route>
            </Route>

            <Route path="/BookingDetails" element={<BookingDetails />}></Route>

            <Route path="/TopUpOffline" element={<TopUpOffline />}></Route>
            <Route path="/TopUpOnline" element={<TopUpOnline />}></Route>
            <Route path="/AccountLedger" element={<GetReportFilter />}></Route>
            <Route path="/OrderHistory" element={<GetReportFilter />}></Route>
            <Route path="/ReportDataGrid" element={<ReportDataGrid />}></Route>
          </Route>
        ) : (
          <>
            <Route path="/" element={<HomePage />}></Route>
          </>
        )}

        {AdminToken !== null ? (
          <Route path="/Admin" element={<AdminMainPage />}>
            <Route path="/Admin/" element={<AdminDashBoard />} />

            <Route path="/Admin/CourierAPI" element={<CourierAPIDataGrid />} />
            <Route
              path="/Admin/CourierType"
              element={<CourierTypeDataGrid />}
            />
            <Route
              path="/Admin/CommissionConfiguration"
              element={<CommissionConfigurationDataGrid />}
            />

            <Route
              path="/Admin/CourierServiceProvider"
              element={<CourierServiceProviderDataGrid />}
            />
            <Route path="/Admin/Users" element={<UsersDataGrid />} />
            <Route path="/Admin/Role" element={<UserRolesDataGrid />} />
            <Route path="/Admin/AgentType" element={<AgentTypeDataGrid />} />
            <Route path="/Admin/Agents" element={<AgentsDataGrid />} />

            {/* <Route
              path="/Admin/AgentCategory"
              element={<UserRolesDataGrid />}
            /> */}

            <Route path="/Admin/AgentGroup" element={<AgentsGroupDataGrid />} />
            <Route path="/Admin/Interface" element={<InterfaceDataGrid />} />

            <Route
              path="/Admin/RegistrationRequest"
              element={<PendingRegistrationRequestDataGrid />}
            />
            <Route
              path="/Admin/PendingTopUpRequest"
              element={<PendingTopUpOfflineDataGrid />}
            />
            <Route path="/Admin/Banks" element={<BankDataGrid />} />
            <Route path="/Admin/BookingHistory" element={<AdminBookingHistoryDataGrid />} />

          </Route>
        ) : (
          <>
            <Route path="/Admin" element={<AdminSignInPage />}></Route>
          </>
        )}

        {SiteMGMTToken !== null ? (
          <Route path="/SiteMGMT" element={<SiteManagementMainPage />}>
            <Route path="/SiteMGMT/" element={<SiteMGMTDashBoard />} />
            <Route
              path="/SiteMGMT/EmailTemplateMaster"
              element={<EmailTemplateDataGrid />}
            />
             <Route
              path="/SiteMGMT/Menu"
              element={<MenuDataGrid />}
            />
          </Route>
        ) : (
          <>
            <Route
              path="/SiteMGMT"
              element={<SiteManagementSignInPage />}
            ></Route>
          </>
        )}
        <Route path="/signup" element={<SignUpPage />}></Route>
        <Route path="/*" element={<NotFoundPage URL="/" />}></Route>
        <Route path="/Admin/*" element={<NotFoundPage URL="/Admin" />}></Route>
        <Route
          path="/SiteMGMT/*"
          element={<NotFoundPage URL="/SiteMGMT" />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}
