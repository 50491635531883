import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { UpdateRow } from "../../../features/RowSlice";
import {
  Grid,
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  TextField,
  MenuItem,
  CssBaseline,
  Container,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import swal from "sweetalert";
import { Colors } from "../../../Utils/Constants";
import { useEffect } from "react";
import { GetBanks } from "../../../Utils/GetBanks";
import moment from "moment";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  paddingTop: 1,
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", md: "60%", lg: "50%" },
  height: { xs: 700, sm: 450 },
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  pb: 0,
  p: 0,
  m: 0,
};
// Type = Edit or ADD
export default function TopUpOfflineProcessModal({
  row,
  openModal,
  handleModalOpen,
  handleModalClose,
  Type,
  URL,
  name,
  // updatedDataFields,
}) {
  const dataFields = [
    {
      name: "chqDdno",
      label: "Transaction No",
      type: "text",
      options: [],
      required: true,
      grid: 4,
      value: "",
      disable: true,
      regex: /[A-Za-z0-9'\.\-\s\,]/,
      errorMsg: "Please Enter Correct Address",
    },
    {
      name: "cashChqDddate",
      label: "Date",
      type: "text",
      options: [],
      value: "",
      disable: true,
      grid: 4,
      regex: /[A-Za-z0-9'\.\-\s\,]/,
      errorMsg: "Please Enter Correct Address",
    },
    {
      name: "bankName",
      label: "Bank Name",
      type: "text",
      options: [],
      disable: true,
      value: "",
      grid: 4,
      regex: /[A-Za-z0-9'\.\-\s\,]/,

      errorMsg: "Please Enter Correct Address",
    },
    {
      name: "bankBranch",
      label: "Bank Branch",
      type: "text",
      options: [],
      value: "",
      disable: true,
      grid: 4,
      regex: /[A-Za-z0-9'\.\-\s\,]/,

      errorMsg: "Please Enter Correct Address",
    },
    {
      name: "requestAmount",
      label: "Request Amount",
      type: "text",
      options: [],
      value: "",
      grid: 4,
      regex: /[A-Za-z0-9'\.\-\s\,]/,

      errorMsg: "Please Enter Correct Address",
    },
    {
      name: "bankId",
      label: "Deposited Bank",
      type: "text",
      options: [],
      value: "",
      grid: 4,
      regex: /[A-Za-z0-9'\.\-\s\,]/,
      errorMsg: "Please Enter Correct Address",
    },
    {
      name: "requestRemark",
      label: "Request Remark",
      type: "text",
      options: [],
      disable: true,
      value: "",
      grid: 4,
      regex: /[A-Za-z0-9'\.\-\s\,]/,

      errorMsg: "Please Enter Correct Address",
    },
  ];
  const [updatedDataFields, setUpdatedDataFields] = React.useState(dataFields);
  useEffect(() => {
    setData(row);
  }, [row]);
  const [data, setData] = React.useState(row);
  console.log({ row });
  const dispatch = useDispatch();
  const [Loader, setLoader] = React.useState(false);
  const [Render, setRender] = React.useState("render");
  const Rerender = () => {
    setRender(() => {
      return Render === "render" ? "rerender" : "render";
    });
  };

  const [BankOptions, setBankOptions] = React.useState([
    {
      label: "Not Found",
      value: "",
    },
  ]);

  const UpdateOptions = async () => {
    if (data["toDistId"] === 0) {
      const TempBankOptions = await GetBanks();
      console.log({ TempBankOptions });
      const newOptions = TempBankOptions.map((option) => {
        return { label: option.bankName, value: option.bankId };
      });
      setBankOptions(newOptions);
    }
  };

  React.useEffect(() => {
    UpdateOptions();
  }, []);

  React.useEffect(() => {
    if (data["toDistId"] === 0) {
      setUpdatedDataFields(() =>
        updatedDataFields.map((Fields) => {
          return {
            ...Fields,
            options: Fields.name === "bankId" ? BankOptions : Fields.options,
          };
        })
      );
    } else {
      setUpdatedDataFields(() =>
        updatedDataFields.filter((Fields) => {
          return Fields.name !== "bankId";
        })
      );
    }
  }, [BankOptions]);
  //   React.useEffect(()=>{
  //     const newdataFields=dataFields.map((Fields)=>{
  //         return {...Fields,options:BankOptions}
  //     })
  // setUpdatedDataFields(newdataFields)
  //   },[BankOptions])

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      const MissingFields = updatedDataFields.filter((Fields, index) => {
        return (
          updatedDataFields[index].required === true && data[Fields] === ""
        );
      });

      console.log(MissingFields);
      if (MissingFields.length === 0) {
        const ApproveTopUpRequestData = {
          commandId: 12,
          paymentAffiliateTranNo: "",
          amount: data["requestAmount"],
          paymentDetId: data["paymentDetId"],
          topupRequestID: data["topupRequestId"],
          fromDistID: data["fromDistId"],
          toDistID: data["toDistId"],
          requestRemark: "",
          requestAmount: data["requestAmount"],
          paymentGatewayPGAPIProcessLinkID: 0, //data['requestAmount'],
          paymentGatewayPgapilinkId: data["paymentGatewayPgapilinkId"],
          createBy: data["createBy"],
          createIP: data["createIp"],
          ext1: "",
          ext2: "",
          ext3: "",
          ext4: "",
          ext5: "",
          chqDdno: data["chqDdno"],
          cashChqDddate: moment(data["cashChqDddate"]).format(
            "MM/DD/YYYY 23:59:59"
          ),
          bankName: data["bankName"],
          bankBranch: data["bankBranch"],
          city: data["city"],
          state: data["state"],
          bankId: data["bankId"],
          accountTypeID: data["accountTypeID"],
          processCode: "RFCD",
          isPGProcessedFromAdmin: data["requestAmount"],
          isSuccess: true,
        };

        const response = await GetResponse(ApproveTopUpRequestData, URL, {});
        console.log(ApproveTopUpRequestData);
        if (!response["errorDesc"]) {
          swal("Approved!", `${response}`, "success", {
            // timer: 2000,
          });

          handleModalClose();
          setData(row);
          dispatch(UpdateRow());
        } else {
          swal(
            "Registration Failed!",
            `${response["errorDesc"].split(":")[1]}`,
            "error",
            {
              // timer: 2000,
            }
          );

          handleModalClose();
          setData(row);
          dispatch(UpdateRow());
        }
      } else {
        swal("Sorry!", `Please Enter All Fields Correctly!`, "error", {
          // timer: 2000,
        });
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      swal("Failed!", "Internal Server Error", "error");
      console.log(error);
    }
  };
  // console.log(updatedDataFields);
  const handleCheck = (event) => {
    setData({ ...data, [event.target.name]: event.target.checked });
  };
  const ValidateInput = (event, index) => {
    setData({ ...data, [event.target.name]: event.target.value });
    console.log(event.target.name, event.target.value);

    const SelectedField = updatedDataFields[index];
    // console.log({ SelectedFieldvalue });
    // console.log({ event });
    // console.log({ index });
    console.log({ SelectedField });
  };
  React.useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Container component="main" maxWidth="lg" sx={styles}>
            <SimpleBackDrops Loader={Loader} />
            {/* <CssBaseline /> */}
            <Typography
              component="h1"
              variant="h5"
              sx={{
                width: "25vw",
                backgroundColor: Colors.SecondaryBackGroundColor[400],
                color: "white",
                height: 70,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                boxShadow:
                  "0 5px 10px rgba(0,0,0,0.16), 0 5px 5px rgba(0,0,0,0.16)",
                mb: 1,
                fontSize: { xs: 15, sm: 20 },
              }}
            >
              {Type} {name}
            </Typography>
            <Box
              component="form"
              validate="true"
              onSubmit={handleSubmit}
              sx={{
                // overflow: "auto",
                height: { xs: 600, sm: 450 },
                paddingLeft: 2,
                paddingRight: 2,
              }}
              key={Render}
            >
              <Box
                sx={{
                  overflow: "auto",
                  height: { xs: 580, sm: 280 },
                  paddingTop: 2,
                }}
                key={Render}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{ overflow: "auto" }}
                  key={Render}
                >
                  {updatedDataFields.map((Fields, index) => {
                    return Fields.type === "CheckBox" ? (
                      <Grid item xs={12} sm={6} md={6} lg={3} key={Fields.name}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                data[Fields.name] === true ||
                                data[Fields.name] === "on"
                                  ? true
                                  : false
                              }
                              onChange={handleCheck}
                              name={Fields.name}
                            />
                          }
                          label={Fields.name}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6} lg={6} key={Fields.name}>
                        <TextField
                          error={Fields.IsError}
                          // autoComplete="given-name"
                          type={Fields.type}
                          name={Fields.name}
                          value={data[Fields.name]}
                          select={Fields.options.length > 0 ? true : false}
                          required={Fields.required}
                          fullWidth
                          id={Fields.name}
                          label={Fields.label}
                          disabled={Fields.disable}
                          InputLabelProps={{ shrink: true }}
                          autoFocus={
                            index === 0 || Fields.autoFocus ? true : false
                          }
                          // onChange={(event)=>handleChange(event)}
                          onChange={(event) => ValidateInput(event, index)}
                          helperText={
                            Fields.IsError === true ? Fields.errorMsg : ``
                          }
                        >
                          {Fields.options.length > 0 ? (
                            Fields.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))
                          ) : (
                            <></>
                          )}
                        </TextField>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              <Grid container spacing={1} sx={{ padding: 2 }}>
                <Grid item xs={6} sm={6} lg={6}>
                  <Button
                    fullWidth
                    color="error"
                    variant="contained"
                    // sx={{ mt: 1, mb: 1 }}
                    onClick={() => handleModalClose()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} lg={6}>
                  <Button
                    type="submit"
                    fullWidth
                    color="success"
                    variant="contained"
                    // sx={{ mt: 1, mb: 1 }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    {Type}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Fade>
      </Modal>
    </>
  );
}
