import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../../Utils/Constants";
import { useSelector } from "react-redux";
import moment from "moment";
import { GetPanelName } from "../../Utils/GetPanelName";
export default function GetReportFilter() {
  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  const [DateOptions, setDateOptions] = useState([
    "Today",
    "Yesterday",
    "Last Week",
    "Prior 30 Days",
    "Prior 90 Days",
  ]);
  const [selectedDate, setselectedDate] = useState("Today");

  const handleselectedDate = (event, newselectedDate) => {
    if (newselectedDate !== null) {
      setselectedDate(newselectedDate);
    }
  };
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const ISAdmin = pathname.includes("admin");
  console.log({ ISAdmin });
  const Panel = GetPanelName();

  const name =
    pathname === "/OrderHistory"
      ? "Order History"
      : pathname === "/AccountLedger"
      ? "Account Ledger "
      : "";

  const reportType =
    pathname === "/OrderHistory"
      ? "OrderDetails"
      : pathname === "/AccountLedger"
      ? "AgentAccTranMasterDetailLedger"
      : "";

  const AccountTypeOptions = AgentDetails.accountType.map((Opt) => {
    return { label: Opt.accountTypeName, value: Opt.accountTypeId };
  });
  const [requestData, setRequestData] = useState({
    reportType: reportType,
    distributorCode: AgentDetails.distributorCode,
    cmpName: AgentDetails.cmpName,
    distributorID: AgentDetails.distributorId,
    fromDate: "2022-01-05",
    toDate: "2022-11-30",
    accountTypeID: 1,
    refNo: "",
  });

  const updateDate = () => {
    const date = moment().format("YYYY-MM-DD");
    const Yesterday = moment().subtract(1, "d").format("YYYY-MM-DD");
    const Week = moment().subtract(7, "d").format("YYYY-MM-DD");
    const Month = moment().subtract(30, "d").format("YYYY-MM-DD");
    const ThreeMonths = moment().subtract(90, "d").format("YYYY-MM-DD");

    if (selectedDate === "Today") {
      setRequestData({ ...requestData, ["toDate"]: date, ["fromDate"]: date });
    } else if (selectedDate === "Yesterday") {
      setRequestData({
        ...requestData,
        ["fromDate"]: Yesterday,
      });
    } else if (selectedDate === "Last Week") {
      setRequestData({
        ...requestData,
        ["fromDate"]: Week,
      });
    } else if (selectedDate === "Prior 30 Days") {
      setRequestData({
        ...requestData,
        ["fromDate"]: Month,
      });
    } else if (selectedDate === "Prior 90 Days") {
      setRequestData({
        ...requestData,
        ["fromDate"]: ThreeMonths,
      });
    }
  };

  useEffect(() => {
    updateDate();
  }, [selectedDate]);

  const handleFilter = (e) => {
    setRequestData({ ...requestData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const newData = {
      reportType: reportType,
      distributorCode: AgentDetails.distributorCode,
      cmpName: AgentDetails.cmpName,
      distributorID: AgentDetails.distributorId,
      fromDate: moment(requestData["fromDate"]).format("MM/DD/YYYY"),
      toDate: moment(requestData["toDate"]).format("MM/DD/YYYY 23:59:59"),
      accountTypeID: requestData["accountTypeID"],
      refNo: "",
    };
    console.log(newData);
    navigate("/ReportDataGrid", {
      state: { requestData: newData, name: name },
    });
  };
  return (
    <Paper elevation={4} sx={{ borderRadius: 5, m: { xs: 2, md: 10 }, p: 5 }}>
      <Typography
        sx={{
          bgcolor: Colors.PrimaryColor[500],
          color: "white",
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 1,
          fontWeight: "bold",
        }}
      >
        {name} Report
      </Typography>
      <Box
        component="form"
        validate="true"
        onSubmit={handleSubmit}
        sx={{ mt: 3, width: "100%" }}
        // key={Render}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <ToggleButtonGroup
              color="warning"
              value={selectedDate}
              exclusive
              onChange={handleselectedDate}
              aria-label="Platform"
              fullWidth
            >
              {DateOptions.map((opt) => {
                return <ToggleButton value={opt}>{opt}</ToggleButton>;
              })}
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <TextField
              autoComplete="given-name"
              type={"date"}
              name={"fromDate"}
              required
              value={requestData.fromDate}
              fullWidth
              id={"fromDate"}
              label={"From Date"}
              onChange={(e) => handleFilter(e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={6}>
            <TextField
              autoComplete="given-name"
              type={"date"}
              name={"toDate"}
              required
              value={requestData.toDate}
              fullWidth
              id={"toDate"}
              label={"To Date"}
              onChange={(e) => handleFilter(e)}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <TextField
              autoComplete="given-name"
              // type={"number"}
              required
              select={true}
              name={"accountTypeID"}
              value={requestData["accountTypeID"]}
              fullWidth
              id={"accountTypeID"}
              label={"Wallet Account"}
              onChange={(e) => handleFilter(e)}
              InputLabelProps={{ shrink: true }}
            >
              {AccountTypeOptions.map((acctype) => {
                return (
                  <MenuItem key={acctype.value} value={acctype.value}>
                    {acctype.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
              Get {name} Report
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
