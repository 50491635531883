import { Box, Button, Grid, TextField } from "@mui/material";
import React from "react";

export default function DateNoFilter({
  FilterFields,
  filterData,
  handleFilter,
  handleSubmit,
}) {
  return (
    <Box
      component="form"
      validate="true"
      onSubmit={handleSubmit}
      sx={{ mt: 3, width: "100%" }}
      // key={Render}
    >
      <Grid container spacing={2}>
        {FilterFields.map((Fields, index) => {
          return (
            <Grid item xs={6} sm={3} lg={2} key={`${Fields.name}${index}`}>
              <TextField
                autoComplete="given-name"
                type={Fields.type}
                name={Fields.name}
                value={filterData[Fields.name]}
                fullWidth
                id={Fields.name}
                label={Fields.label}
                onChange={(e) => handleFilter(e)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          );
        })}
        <Grid item xs={6} sm={3} lg={2}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 1, mb: 1 }}
          >
            Filter
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
