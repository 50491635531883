import { createSlice } from "@reduxjs/toolkit";
  
const AdminDetail=sessionStorage.getItem('AdminDetails')
export const AdminDetailsSlice = createSlice({
  
  name: "AdminDetails",
  initialState: {
    value:AdminDetail?JSON.parse(AdminDetail):{},
  },
  reducers: {
    UpdateAdminDetails: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    },
    ClearAdminDetails: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { UpdateAdminDetails,ClearAdminDetails } = AdminDetailsSlice.actions;

export default AdminDetailsSlice.reducer;
