import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Colors } from "../../../Utils/Constants";
import MenuActions from "./MenuActions";
import PageActions from "./PageActions";
import ActiveDeactive from "../../../Admin/Components/ActiveDeactive";
import { RDSLinks } from "../../../Utils/Axios/RequestParameters";

function Row(props) {
  const { row } = props;
  console.log({ Submenu: row });
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow hover sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.menuCaption}
        </TableCell>
        {/* <TableCell align="center">{row.menuControlName}</TableCell> */}
        <TableCell align="center">{row.menuLevel}</TableCell>
        <TableCell align="center">
          {" "}
          <ActiveDeactive
            {...{
              UpdateURL: RDSLinks.MenuMaster,
              activeName: "isActive",
              SelectedFieldName: "this Agent ",
              row: { ...row, parentMenuCaption: "" },
            }}
          />
        </TableCell>
        <TableCell align="center">
          {" "}
          <MenuActions
            {...{
              row: row,
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow hover>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, width: "100%" }}>
              {row.submenu && row.submenu.length > 0 ? (
                <SubMenuPage SelectedMenu={row.submenu} />
              ) : (
                <></>
              )}
              <TableContainer
                component={Box}
                sx={{
                  pl: "5%",
                  width: "95%",
                  mt: 3,
                  borderWidth: 5,
                  borderColor: "black",
                }}
              >
                <Table aria-label="collapsible table">
                  {row.page !== null && row.page.length > 0 ? (
                    <>
                      <TableRow sx={{ bgcolor: Colors.SecondaryColor[400] }}>
                        <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                          Page Name
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Page URL
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Action
                        </TableCell>
                      </TableRow>

                      {
                      row.page.map((IndPage)=>{
                        return (<TableRow
                          hover
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {IndPage.pageCaption}
                          </TableCell>
                          <TableCell align="center">{IndPage.pageUrl}</TableCell>
                          <TableCell align="center">
                            <PageActions row={IndPage} />
                          </TableCell>
                        </TableRow>)
                      })
                      
                     }
                    </>
                  ) : (
                    <></>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function SubMenuPage(props) {
  const { SelectedMenu } = props;
  return (
    <>
      <TableContainer
        component={Box}
        sx={{
          pl: "5%",
          width: "95%",
          mt: 3,
          borderWidth: 5,
          borderColor: "black",
        }}
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow sx={{ bgcolor: Colors.PrimaryColor[500] }}>
              <TableCell />
              <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                Menu 
              </TableCell>
              {/* <TableCell
                align="center"
                sx={{ color: "white", fontWeight: "bold" }}
              >
                Control Name
              </TableCell> */}
              <TableCell
                align="center"
                sx={{ color: "white", fontWeight: "bold" }}
              >
                Level
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "white", fontWeight: "bold" }}
              >
                Active ?
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "white", fontWeight: "bold" }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {SelectedMenu.map((row) => {
              return (
                <>
                  <Row key={row.menuId} row={row} />
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
