import { GetResponse } from "./Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "./Axios/RequestParameters";

const GetAgentTypeOptions = async () => {
  const requestData = {
    typeId: 0,
    typeCode: "",
    typeCodeDesc: "",
    description: "",
    commandId: CRUDCommands.ListAll,
    createBy: 0,
    createDate: "2022-11-14T10:35:49.491Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-11-14T10:35:49.491Z",
    updateIp: "",
    isInternal: true,
  };
  var AllOptions = [{}];
  const response = await GetResponse(
    requestData,
    RDSLinks.DistributorTypeMaster,
    {}
  );

  if (response.length > 0 && Array.isArray(response)) {
    const options = response.map((opt) => {
      return { label: opt.description, value: opt.typeId };
    });
    AllOptions = options;
  } else {
    AllOptions = [
      {
        label: "No Agent Found",
        value: "",
      },
    ];
  }
  return AllOptions;
};
export { GetAgentTypeOptions };
