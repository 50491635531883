import React, { useEffect, useState } from "react";
import { GetCity } from "../../Utils/GetCity";
import { GetCountry } from "../../Utils/GetCountry";
import { GetState } from "../../Utils/GetState";
import { Grid, TextField, MenuItem } from "@mui/material";

function StateCityCountry({
  countryKey="country",
  cityKey="city",
  stateKey="state",
  pincodekey="pincode",
  state,
  city,
  country,
  pincode,
  grid,
  ValidateInput,
}) {
  const [CountryOptions, setCountryOptions] = useState([
    { label: country ? country : "Refresh the Page", value: country },
  ]);
  const [StateOptions, setStateOptions] = useState([
    { label: state ? state : "Please Select Country", value: state },
  ]);
  const [CityOptions, setCityOptions] = useState([
    { label: city ? city : "Please Select State", value: city },
  ]);

  const GetCountryOptions = async () => {
    if (CountryOptions.length < 2) {
      const TempCountryOptions = await GetCountry();
      setCountryOptions(TempCountryOptions);

      if (TempCountryOptions.length > 0) {
        if (country !== "") {
          const selectedCountryArray = TempCountryOptions.filter(
            (Indcountry) => {
              return Indcountry.label === country;
            }
          );
          const SelectedCountry = selectedCountryArray[0];

          const TempStateOptions = await GetState(SelectedCountry);
          setStateOptions(TempStateOptions);
          if (state !== "") {
            const selectedStateArray = TempStateOptions.filter((IndState) => {
              return IndState.label === state;
            });
            const SelectedState = selectedStateArray[0];

            const TempCityOptions = await GetCity(SelectedState);
            setCityOptions(TempCityOptions);
          }
        }
      }
    }
  };
  useEffect(() => {
    GetCountryOptions();
  }, []);

  const GetStateOptions = async (country) => {
    if (country !== "") {
      const selectedCountryArray = CountryOptions.filter((Indcountry) => {
        return Indcountry.label === country;
      });
      const SelectedCountry = selectedCountryArray[0];

      const TempStateOptions = await GetState(SelectedCountry);
      setStateOptions(TempStateOptions);
    }
  };

  const GetCityOptions = async (state) => {
    if (state !== "") {
      const selectedStateArray = StateOptions.filter((IndState) => {
        return IndState.label === state;
      });
      const SelectedState = selectedStateArray[0];

      const TempCityOptions = await GetCity(SelectedState);
      setCityOptions(TempCityOptions);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          name={countryKey}
          value={country}
          select={true}
          required={true}
          fullWidth
          id={countryKey}
          label="Select Country"
          // InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            ValidateInput(e, /[^\n]+/);
            GetStateOptions(e.target.value);
          }}
        >
          {CountryOptions.length > 0 ? (
            CountryOptions.map((option) => (
              <MenuItem key={`${option.label}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          ) : (
            <></>
          )}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          name={stateKey}
          value={state}
          select={true}
          required={true}
          fullWidth
          id={stateKey}
          label="Select state"
          // InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            ValidateInput(e, /[^\n]+/);
            GetCityOptions(e.target.value);
          }}
        >
          {StateOptions.length > 0 ? (
            StateOptions.map((option) => (
              <MenuItem key={`${option.label}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          ) : (
            <></>
          )}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          name={cityKey}
          value={city}
          select={true}
          required={true}
          fullWidth
          id={cityKey}
          label="Select City"
          // InputLabelProps={{ shrink: true }}
          onChange={(e) => ValidateInput(e, /[^\n]+/)}
        >
          {CityOptions.length > 0 ? (
            CityOptions.map((option) => (
              <MenuItem key={`${option.label}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          ) : (
            <></>
          )}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          type="number"
          name={pincodekey}
          value={pincode}
          required={true}
          fullWidth
          id={pincodekey}
          label="Pincode"
          onChange={(e) => ValidateInput(e, /[0-9]{3,6}/)}
        ></TextField>
      </Grid>
    </>
  );
}
export default React.memo(StateCityCountry);
