import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Cancel, Delete, Done } from "@mui/icons-material";
import { Chip, Box } from "@mui/material";
import { green, red } from "@mui/material/colors";
import Swal from "sweetalert2";
import { UpdateRow } from "../../../features/RowSlice";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import {
  CourierServiceLinks,
  CRUDCommands,
} from "../../../Utils/Axios/RequestParameters";
import CourierTrackingModal from "./CourierTrackingModal";
import CourierDetailModal from "./CourierDetailModal";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SwipeLeftIcon from "@mui/icons-material/SwipeLeft";
import SwipeRightIcon from "@mui/icons-material/SwipeRight";

export default function BookingHistoryAction({ status, OrderData }) {
  const dispatch = useDispatch();
  console.log({ status, OrderData });

  const [openModal, setopenModal] = useState(false);
  const [openDetailModal, setopenDetailModal] = useState(false);

  const handleModalOpen = () => {
    setopenModal(true);
  };
  const handleModalClose = () => {
    setopenModal(false);
  };

  const handleDetailModalOpen = () => {
    setopenDetailModal(true);
  };
  const handleDetailModalClose = () => {
    setopenDetailModal(false);
  };

  const CancelOrder = () => {
    Swal.fire({
      title: "Are you Sure ",
      input: "text",
      inputPlaceholder: "For what reason do you want to cancel the order?",
      icon: "question",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "submit",
      showLoaderOnConfirm: true,
      //   preConfirm: async (login) => {
      //     var data = requestData;
      //     var key = giveKey(login);
      //     data[key] = login;
      //     console.log(data);
      //     return await GetResponse(
      //       data,
      //       RDSLinks.DistributorMaster.DistributorMaster,
      //       {}
      //     )
      //       .then((response) => {
      //         console.log({response})
      //         if (response.errorDesc) {
      //           // throw new Error(response.errorDesc);
      //           Swal.showValidationMessage(`Kindly Provide Accurate Information: ${response.errorDesc}`);
      //         }
      //         return response;
      //       })
      //       .catch((error) => {
      //         Swal.showValidationMessage(`Request failed: ${error}`);
      //       });
      //   },
      //   allowOutsideClick: () => !Swal.isLoading(),
      // }).then((result) => {
      // console.log(result);
      // if (result.value) {
      //   Swal.fire({
      //     title: "Success",
      //     text: `${result.value}!`,
      //     icon: "success",
      //   });
      // }
    });
  };

  const ProcessBooking = () => {
    Swal.fire({
      title: "Process Booking",
      html: `<input id="swal-input1" class="swal2-input" placeholder="API Tracking Number">
    <input id="swal-input2" class="swal2-input" placeholder="API Transaction Number">`,
      icon: "question",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "submit",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        const TrackingNumber = document.getElementById("swal-input1").value;
        const ApiTransactionNumber =
          document.getElementById("swal-input2").value;
        console.log(TrackingNumber, TrackingNumber);

        let requestData = OrderData;
        requestData.apitrackingNumber = TrackingNumber;
        requestData.apitransactionNo = ApiTransactionNumber;
        let newRequestdata = {
          orders: [requestData],
          errorDesc: null,
          errorCode: null,
          commandId: CRUDCommands.ProcessBooking,
        };
        console.log(newRequestdata);

        return await GetResponse(newRequestdata, CourierServiceLinks.Order, {})
          .then((response) => {
            console.log({ response });
            if (response.errorDesc) {
              // throw new Error(response.errorDesc);
              Swal.showValidationMessage(
                `Kindly Provide Accurate Information: ${response.errorDesc}`
              );
            }
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      console.log(result);
      if (result.value) {
        Swal.fire({
          title: "Success",
          text: `${result.value.orders[0].statusDescription}!`,
          icon: "success",
        });
        dispatch(UpdateRow());
      }
    });
  };

  const ProcessPayment = () => {
    Swal.fire({
      title: "Are you Sure ? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Process Payment",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        let requestData = {
          orders: [OrderData],
          errorDesc: null,
          errorCode: null,
          commandId: CRUDCommands.VerifyPayment,
        };
        return await GetResponse(requestData, CourierServiceLinks.Order, {})
          .then((response) => {
            console.log({ response });
            if (response.errorDesc) {
              // throw new Error(response.errorDesc);
              Swal.showValidationMessage(response.errorDesc);
            }
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      console.log(result);
      if (result.value) {
        Swal.fire({
          title: "Success",
          text: `${result.value.orders[0].statusDescription}!`,
          icon: "success",
        });
      }
      dispatch(UpdateRow());
    });
  };

  const Reject = () => {
    Swal.fire({
      title: "Are you Sure ? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Reject",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        let requestData = {
          orders: [OrderData],
          errorDesc: null,
          errorCode: null,
          commandId: CRUDCommands.RejectBooking,
        };
        return await GetResponse(requestData, CourierServiceLinks.Order, {})
          .then((response) => {
            console.log({ response });
            if (response.errorDesc) {
              // throw new Error(response.errorDesc);
              Swal.showValidationMessage(response.errorDesc);
            }
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      console.log(result);
      if (result.value) {
        Swal.fire({
          title: "Success",
          text: `${result.value.orders[0].statusDescription}!`,
          icon: "success",
        });
      }
      dispatch(UpdateRow());
    });
  };
  const TrackParcel = () => {
    handleModalOpen();
  };

  const ShowDetailModal = () => {
    handleDetailModalOpen();
  };

  const CourierDetailModalFC = () => {
    return (
      <>
        {openDetailModal && (
          <CourierDetailModal
            OrderData={OrderData}
            openDetailModal={openDetailModal}
            handleDetailModalOpen={handleDetailModalOpen}
            handleDetailModalClose={handleDetailModalClose}
          />
        )}
        <Chip
          label="View"
          onClick={ShowDetailModal}
          icon={<ReceiptIcon style={{ color: "white" }} />}
          sx={{
            width: 100,
            // height: 40,
            color: "white",
            bgcolor: green[300],
            "&:hover": { bgcolor: green[700] },
          }}
        />
      </>
    );
  };
  switch (status) {
    case "BC":
      return (
        <Box
          sx={{
            m: 1,
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CourierDetailModalFC />

          {openModal && (
            <CourierTrackingModal
              OrderData={OrderData}
              openModal={openModal}
              handleModalOpen={handleModalOpen}
              handleModalClose={handleModalClose}
            />
          )}
          <Chip
            label="Track Shipment"
            onClick={() => TrackParcel()}
            icon={<LocalShippingIcon style={{ color: "white" }} />}
            sx={{
              width: 160,
              // height: 40,
              color: "white",
              bgcolor: green[300],
              "&:hover": { bgcolor: green[700] },
            }}
          />
          {OrderData.isPickupDone === false && (
            <Chip
              label="Cancel order"
              onClick={() => CancelOrder()}
              icon={<Cancel style={{ color: "white" }} />}
              sx={{
                width: 160,
                // height: 40,
                color: "white",
                bgcolor: red[300],
                "&:hover": { bgcolor: red[700] },
              }}
            />
          )}
        </Box>
      );
    case "PR":
      return (
        <Box
          sx={{
            m: 1,
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CourierDetailModalFC />
          <Chip
            label="Process Payment"
            onClick={() => ProcessPayment()}
            icon={<SwipeRightIcon style={{ color: "white" }} />}
            sx={{
              width: 160,
              // height: 40,
              color: "white",
              bgcolor: green[300],
              "&:hover": { bgcolor: green[700] },
            }}
          />
        </Box>
      );
    case "BR":
      return (
        <Box
          sx={{
            m: 1,
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CourierDetailModalFC />
          <Chip
            label="Process Booking"
            onClick={() => ProcessBooking()}
            icon={<SwipeRightIcon style={{ color: "white" }} />}
            sx={{
              width: 160,
              // height: 40,
              color: "white",
              bgcolor: green[300],
              "&:hover": { bgcolor: green[700] },
            }}
          />
          <Chip
            label="Reject Order"
            onClick={() => Reject()}
            icon={<SwipeLeftIcon style={{ color: "white" }} />}
            sx={{
              width: 160,
              // height: 40,
              color: "white",

              bgcolor: red[300],
              "&:hover": { bgcolor: red[700] },
            }}
          />
        </Box>
      );
    case "BF":
      return (
        <Box
          sx={{
            m: 1,
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CourierDetailModalFC />
          <Chip
            label="Booking Failed"
            // onClick={handleDelete}
            disabled={true}
            sx={{
              width: 160,
              // height: 40,
              color: "white",

              bgcolor: red[300],
              "&:hover": { bgcolor: red[700] },
            }}
          />
        </Box>
      );
    case "PF":
      return (
        <Box
          sx={{
            m: 1,
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CourierDetailModalFC />
          <Chip
            label="Payment Failed"
            // onClick={handleDelete}
            disabled={true}
            sx={{
              width: 160,
              // height: 40,
              color: "white",

              bgcolor: red[300],
              "&:hover": { bgcolor: red[700] },
            }}
          />
        </Box>
      );
    default:
      return (
        <Box
          sx={{
            m: 1,
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CourierDetailModalFC />
          <Chip
            label={OrderData.statusDescription}
            // onClick={handleDelete}
            disabled={true}
            sx={{
              width: 160,
              // height: 40,
              color: "white",

              bgcolor: red[300],
              "&:hover": { bgcolor: red[700] },
            }}
          />
        </Box>
      );
  }
}
