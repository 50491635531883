import { createSlice } from "@reduxjs/toolkit";
  const AgentDetail=sessionStorage.getItem('AgentDetails')

export const AgentDetailSlice = createSlice({
  
  name: "AgentDetails",
  initialState: {
    value: AgentDetail?JSON.parse(AgentDetail):null,
  },
  reducers: {
    UpdateDetails: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    }, ClearAgentDetails: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { UpdateDetails,ClearAgentDetails } = AgentDetailSlice.actions;

export default AgentDetailSlice.reducer;
