import * as React from "react";
import { DataGrid, GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import moment from "moment";
import { useSelector } from "react-redux";
import { GetResponse } from "../Utils/Axios/GetResponse";
import { CourierServiceLinks } from "../Utils/Axios/RequestParameters";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
export default function OrdersGrid() {
  const [rows, setRows] = useState([]);

  const columns = useMemo(
    () => [
      // { field: 'id', headerName: 'ID', width: 70 },
      {
        field: "statusDesc",
        headerName: "Booking Status",
        description: "This column has a value getter and is not sortable.",
        sortable: true,
        width: 150,
      },
      {
        field: "requestDateTime",
        headerName: "Order Date",
        width: 160,
        renderCell: (params) => (
          <p>
            {moment(params.row.requestDateTime).format("DD/MMM/YYYY hh:mm a")}
          </p>
        ),
      },
      { field: "referanceNo", headerName: "Reference No", width: 110 },
      {
        field: "courierServiceProviderName",
        headerName: "Courier Provider",
        description: "This column has a value getter and is not sortable.",
        sortable: true,
        width: 160,
      },
      { field: "pickUpFrom", headerName: "Pickup From", width: 120 },
      { field: "deliveryTo", headerName: "Deliver To", width: 120 },

      { field: "pickupPincode", headerName: "Pickup Pincode", width: 120 },

      { field: "deliveryPincode", headerName: "Deliver Pincode", width: 120 },

      {
        field: "totalAmount",
        headerName: "Amount",
        type: "number",
        width: 70,
      },
     
      {
        field: "isPickupDone",
        headerName: "Pickup Done",
        width: 100,
        renderCell: (params) => {
          console.log(params.row.isPickupDone);
          return params.row.isPickupDone === true ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="warning" />
          );
        },
      },
    ],
    [rows]
  );

  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  const GetData = async () => {
    const RequestData = {
      reportType: "OrderDetails",
      distributorCode: AgentDetails.distributorCode,
      cmpName: AgentDetails.cmpName,
      distributorID: AgentDetails.distributorId,
      fromDate: moment().subtract(1, "d").format("MM/DD/YYYY 00:00:01"),
      toDate: moment().format("MM/DD/YYYY 23:59:59"),
      accountTypeID: 0,
      refNo: "",
    };

    try {
      const Response = await GetResponse(
        RequestData,
        CourierServiceLinks.CourierAPIReport,
        {}
      );
      if (Response.length > 0) {
        setRows(Response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetData();
  }, []);

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Button>
          <Link to="/bookinghistory" style={{ textDecoration: "none" }}>
            {" "}
            View All
          </Link>
        </Button>
        <GridFooter
          sx={{
            border: "none", // To delete double border.
            pl: 2,
          }}
        />
      </GridFooterContainer>
    );
  };
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      getRowId={(row) => row.orderId}
      components={{ Footer: CustomFooter }}
      pageSize={5}
      rowsPerPageOptions={[5]}
      // checkboxSelection
    />
  );
}
