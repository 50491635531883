import { GetResponse } from "./Axios/GetResponse";
import { RDSLinks, RequestObj } from "./Axios/RequestParameters";

const GetBanks = async () => {
  try {
    const response = await GetResponse(
      RequestObj.Bank.GetBank,
      RDSLinks.GetBank,
      {}
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};
export { GetBanks };
