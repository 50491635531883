import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Chip, Box } from "@mui/material";
import { green, red } from "@mui/material/colors";

import CourierTrackingModal from "./CourierTrackingModal";
import Swal from "sweetalert2";
import {
  CourierServiceLinks,
  CRUDCommands,
} from "../../Utils/Axios/RequestParameters";
import { GetResponse } from "../../Utils/Axios/GetResponse";

export default function BookingHistoryAction({ status, OrderData }) {
  const dispatch = useDispatch();
  console.log({ status, OrderData });

  const [openModal, setopenModal] = useState(false);
  const handleModalOpen = () => {
    setopenModal(true);
  };
  const handleModalClose = () => {
    setopenModal(false);
  };

  const CancelOrder = () => {
    Swal.fire({
      title: "Are you Sure ",
      input: "text",
      inputPlaceholder: "For what reason do you want to cancel the order?",
      icon: "question",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "submit",
      showLoaderOnConfirm: true,
      preConfirm: async (remark) => {
        const Requestdata = {
          couOrdeCancelMaster: [
            {
              couorderCancelId: 0,
              orderId: OrderData.orderId,
              orderRequestId: OrderData.orderRequest.orderRequestId,
              orderRequestDetId: OrderData.orderRequest.orderRequestDetID,
              isComplaint: false,
              distributorId: OrderData.distributorId,
              interfaceId: OrderData.interfaceId,
              cancelRemark: remark,
              currentSessionID: OrderData.orderRequest.currentSessionID,
              tranClientIp: OrderData.tranClientIp,
              userId: 0,
              userDistributorId: OrderData.userDistributorId,
              createBy: OrderData.createBy,
              createIp: OrderData.createIp,
              totalAmount: OrderData.totalAmount,
              deliveryChargeRefund: 0,
              codchargeRefund: 0,
              fuelChargeRefund: 0,
              supplierGstrefund: 0,
              totalAmountRefund: 0,
              companyChargesRefund: 0,
              commissionAmountRefund: 0,
              tdsrefundPer: 0,
              tdsamountRefund: 0,
              courierServiceProviderCancellationCharges: 0,
              companyCancellationCharges: 0,
              sgst: OrderData.sgst,
              cgst: OrderData.cgst,
              igst: OrderData.igst,
              totalRefundAmount: 0,
              totalTranCharges: 0,
              totalPGSerTaxAmt: 0,
              isCancelled: true,
              isRefunded: false,
              refNo: OrderData.refNo,
              status: OrderData.status,
              statusDescription: OrderData.statusDescription,
              pgid: OrderData.pgid,
              cancellationRequestRemark: "",
              cancelRequestDateTime: "2023-01-20T10:30:51.429Z",
              cancelDateTime: "2023-01-20T10:30:51.429Z",
              paymentGatewayPgapilinkId: OrderData.paymentGatewayPGAPILinkID,
              orderCancelId: 0,
              orderCancelDetId: 0,
              level1DistributorId: OrderData.level1DistributorId,
              level1CommCalculated: OrderData.level1CommCalculated,
              level1CommTdscalculated: OrderData.level1CommTdscalculated,
              level1CommNet: OrderData.level1CommNet,
              level1CommIsProcessed: OrderData.level1CommIsProcessed,
              level1CommProcessedDateTime:
                OrderData.level1CommProcessedDateTime,
              level2DistributorId: OrderData.level2DistributorId,
              level2CommCalculated: OrderData.level2CommCalculated,
              level2CommTdscalculated: OrderData.level2CommTdscalculated,
              level2CommNet: OrderData.level2CommNet,
              level2CommIsProcessed: OrderData.level2CommIsProcessed,
              level2CommProcessedDateTime:
                OrderData.level2CommProcessedDateTime,
              createDate: "2023-01-20T10:30:51.429Z",
              updateBy: 0,
              updateDate: "2023-01-20T10:30:51.429Z",
              updateIp: "",
              fromCancelDate: "2023-01-01",
              toCancelDate: "2023-01-20",
              orderCancelPaymentDetail: [
                {
                  ...OrderData.orderPaymentDetail[0],
                  isCancel: true,
                },
              ],
            },
          ],
          errorDesc: "",
          errorCode: "",
          commandId: CRUDCommands.CancellationRequest,
        };

        console.log(Requestdata);
        return await GetResponse(
          Requestdata,
          CourierServiceLinks.CancelOrder,
          {}
        )
          .then((response) => {
            console.log({ response });
            if (response.errorDesc) {
              // throw new Error(response.errorDesc);
              Swal.showValidationMessage(
                `Kindly Provide Accurate Information: ${response.errorDesc}`
              );
            }
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      console.log(result);
      if (result.value.couOrdeCancelMaster[0].isCancelled === true) {
        Swal.fire({
          title: "Success",
          text: `Cancelled Successfully!`,
          icon: "success",
        });
      }
    });
  };
  const TrackParcel = () => {
    handleModalOpen();
  };
  switch (status) {
    case "BC":
      return (
        <Box
          sx={{
            m: 1,
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {openModal && (
            <CourierTrackingModal
              OrderData={OrderData}
              openModal={openModal}
              handleModalOpen={handleModalOpen}
              handleModalClose={handleModalClose}
            />
          )}
          <Chip
            label="Track Shipment"
            onClick={() => TrackParcel()}
            sx={{
              width: 160,
              // height: 40,
              color: "white",
              bgcolor: green[300],
              "&:hover": { bgcolor: green[700] },
            }}
          />

          {OrderData.isPickupDone === false &&
            OrderData.orderPaymentDetail[0].isCancel === false && (
              <Chip
                label="Cancel Order"
                onClick={() => CancelOrder()}
                sx={{
                  width: 160,
                  // height: 40,
                  color: "white",
                  bgcolor: red[300],
                  "&:hover": { bgcolor: red[700] },
                }}
              />
            )}
        </Box>
      );

    case "BF":
      return (
        <Box
          sx={{
            m: 1,
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Chip
            label="Booking Failed"
            // onClick={handleDelete}
            disabled={true}
            sx={{
              width: 160,
              // height: 40,
              color: "white",

              bgcolor: red[300],
              "&:hover": { bgcolor: red[700] },
            }}
          />
        </Box>
      );
    case "PF":
      return (
        <Box
          sx={{
            m: 1,
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Chip
            label="Payment Failed"
            // onClick={handleDelete}
            disabled={true}
            sx={{
              width: 160,
              // height: 40,
              color: "white",

              bgcolor: red[300],
              "&:hover": { bgcolor: red[700] },
            }}
          />
        </Box>
      );
    default:
      return (
        <Box
          sx={{
            m: 1,
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Chip
            label={OrderData.statusDescription}
            // onClick={handleDelete}
            disabled={true}
            sx={{
              width: 160,
              // height: 40,
              color: "white",

              bgcolor: red[300],
              "&:hover": { bgcolor: red[700] },
            }}
          />
        </Box>
      );
  }
}
