import { createSlice } from "@reduxjs/toolkit";

export const RowSlice = createSlice({
  name: "Row",
  initialState: {
    value: "updated",
  },
  reducers: {
    UpdateRow: (state, action) => {
      state.value = state.value === "updated" ? "Reupdated" : "updated"
      
    },
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
  },
});

// Action creators are generated for each case reducer function
export const { UpdateRow } = RowSlice.actions;

export default RowSlice.reducer;
