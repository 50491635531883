import React from "react";
import { Box } from "@mui/material";
export default function DashBoard() {
  return (
    <Box>
      <Box>
        <h3>Admin DashBoard</h3>
      </Box>
    </Box>
  );
}
