import { Box, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Colors } from "../../Utils/Constants";
import CountryStateCityId from "../CountryStateCityId";

export default function DeliveryDetails({
  DeliveryDataFields,
  PickUpDataFields,
  AllError,
  data,
  validate,
}) {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Paper
          elevation={3}
          sx={{
            padding: 2,
          }}
        >
          <Typography
            sx={{
              backgroundColor: Colors.PrimaryColor[800],
              color: "white",
              width: "100%",
              p: 1,
              mb: 2,
              borderRadius: 1,
              fontSize: 20,
            }}
          >
            {" "}
            Pickup Details
          </Typography>

          <Grid container spacing={2}>
            {PickUpDataFields.map((Fields, index) => {
              return (
                <Grid item xs={12} lg={Fields.grid} key={Fields.name}>
                  <TextField
                    autoComplete="given-name"
                    error={AllError[Fields.name]}
                    InputProps={
                      Fields.type === "number"
                        ? { inputProps: { min: 0, maxLength: 10 } }
                        : {}
                    }
                    helperText={AllError[Fields.name] ? Fields.errorMsg : ""}
                    name={Fields.name}
                    value={data[Fields.name]}
                    required={Fields.Required}
                    fullWidth
                    id={Fields.name}
                    label={Fields.label}
                    type={Fields.type}
                    autoFocus={index === 0}
                    onChange={(e) => validate(e, Fields.regex)}
                  />
                </Grid>
              );
            })}
            <CountryStateCityId
              state={data.pickupStateId}
              city={data.pickupCityId}
              country={data.pickupCountryId}
              CountryLabel={"pickupCountryId"}
              StateLabel={"pickupStateId"}
              CityLabel={"pickupCityId"}
              ValidateInput={validate}
              grid={4}
            />
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Paper
          elevation={3}
          sx={{
            padding: 2,
          }}
        >
          <Typography
            sx={{
              backgroundColor: Colors.PrimaryColor[800],
              color: "white",
              width: "100%",
              p: 1,
              mb: 2,
              borderRadius: 1,
              fontSize: 20,
            }}
          >
            {" "}
            Delivery Details
          </Typography>
          <Grid container spacing={2}>
            {DeliveryDataFields.map((Fields) => {
              return (
                <Grid item xs={12} lg={Fields.grid} key={Fields.name}>
                  <TextField
                    autoComplete="given-name"
                    error={AllError[Fields.name]}
                    inputProps={{ pattern: Fields.pattern }}
                    helperText={AllError[Fields.name] ? Fields.errorMsg : ""}
                    name={Fields.name}
                    value={data[Fields.name]}
                    required={Fields.Required}
                    fullWidth
                    id={Fields.name}
                    label={Fields.label}
                    type={Fields.type}
                    onChange={(e) => validate(e, Fields.regex)}
                  />
                </Grid>
              );
            })}
            <CountryStateCityId
              state={data.deliveryStateId}
              city={data.deliveryCityId}
              country={data.deliveryCountryId}
              CountryLabel={"deliveryCountryId"}
              StateLabel={"deliveryStateId"}
              CityLabel={"deliveryCityId"}
              ValidateInput={validate}
              grid={4}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
