import { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Typography, Fab } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import { Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Colors } from "../../../Utils/Constants";
import ActiveDeactive from "../../../Admin/Components/ActiveDeactive";
import swal from "sweetalert";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import { CRUDCommands, RDSLinks, RequestObj } from "../../../Utils/Axios/RequestParameters";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import EmailTemplateActions from "./EmailTemplateActions";
import EmailTemplateModal from "./EmailTemplateModal";
export default function EmailTemplateDataGrid() {
  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);

  const dataFields = [
    {
      Label: "Agent Type Code",
      name: "typeCode",
      Type: "string",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Agent Type Description",
      name: "typeCodeDesc",
      Type: "string",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Description",
      name: "description",
      Type: "string",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
  ];
  const getServices = async () => {
    setLoader(true);
    try {
      //   const Response = await axios.get(CourierServiceLinks.CourierType.GetAll);
      const Response = await GetResponse(
        RequestObj.EmailTemplateMaster,
        RDSLinks.EmailTemplateMaster,
        {}
      );
      console.log(Response);
      if (Response.length > 0) {
        setServiceAPI(Response);
      } else {
        setServiceAPI([]);
        swal("error", "No Records Found", "error");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      swal("error", "No Records Found", "error");
    }
    setLoader(false);
  };

  useEffect(() => {
    getServices();
  }, [Row]);

  const addData = {
    emailTemplateId: 0,
    templateCode: "",
    template: "",
    isActive: true,
    description: "",
    commandId: CRUDCommands.ListAll,
    createBy: 0,
    createDate: "2022-11-29T04:35:38.553Z",
    createIp: "",
    updateBy: 0,
    isActiveDeActive: true,
    updateDate: "2022-11-29T04:35:38.553Z",
    updateIp: "",
  };
  const columns = useMemo(
    () => [
      { field: "templateCode", headerName: "templateCode", width: 200 },

      {
        field: "description",
        headerName: "Description",
        width: 200,
      },

      {
        field: "isActive",
        headerName: "isActive",
        width: 200,
        type: "boolean",
        renderCell: (params) => (
          <ActiveDeactive
            {...{
              UpdateURL: RDSLinks.EmailTemplateMaster,
              activeName: "isActive",
              SelectedFieldName: "this Email Template",
              row: params.row,
            }}
          />
        ),
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 150,
        renderCell: (params) => (
          <EmailTemplateActions
            {...{
              params,
              rowId,
              setRowId,
              dataFields,
              EditURL: RDSLinks.EmailTemplateMaster,
              DeleteURL: RDSLinks.EmailTemplateMaster,
              RowDeleteId: params.row.emailTemplateId,
              name: "Email Template",
            }}
            key={Row}
          />
        ),
      },
    ],
    [rowId, Row]
  );

  return (
    <>
      <SimpleBackDrops Loader={Loader} />

      <EmailTemplateModal
        openModal={openModal}
        handleModalClose={handleModalClose}
        handleModalOpen={handleModalOpen}
        URL={RDSLinks.EmailTemplateMaster}
        row={addData}
        dataFields={dataFields}
        Type={"Add"}
        name={"Email Template"}
        key={Row}
      />

      <Box
        sx={{
          height: 559,
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
          // backgroundColor:Colors.PrimaryBackgroundColor,
          // alignItems:'center'
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            textAlign: "center",
            p: 1,
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          Email Template
        </Typography>

        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            ml: 5,
            mt: 1,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
          onClick={handleModalOpen}
        >
          <Add />
        </Fab>

        <DataGrid
          key={Row}
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          rows={ServiceAPI}
          getRowId={(row) => row.emailTemplateId}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
