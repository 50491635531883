import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { UpdateRow } from "../../../features/RowSlice";
import InputUnstyled from "@mui/base/InputUnstyled";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import {
  Grid,
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  TextField,
  MenuItem,
  CssBaseline,
  Container,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { GetResponse } from "../../../Utils/Axios/GetResponse";
import SimpleBackDrops from "../../../components/SimpleBackDrops";
import swal from "sweetalert";
import { Colors } from "../../../Utils/Constants";
import { CRUDCommands } from "../../../Utils/Axios/RequestParameters";
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  paddingTop: 1,
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", md: "60%", lg: "50%" },
  height: { xs: 700, sm: 400 },
  //   scrollY:true,
  //   flexWrap:Scr,
  // overflow: "auto",
  bgcolor: "white",
  //   border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  pb: 0,
  p: 0,
  m: 0,
};
// Type = Edit or ADD

export default function EmailTemplateModal({
  row,
  dataFields,
  openModal,
  handleModalOpen,
  handleModalClose,
  Type,
  URL,
  name,
}) {
  const dispatch = useDispatch();
  const [Loader, setLoader] = React.useState(false);

  const [data, setData] = React.useState(row);

  const [UpdatedDataField, setUpdatedDataField] = React.useState(dataFields);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const ErrorObjArray = UpdatedDataField.filter((Fields, index) => {
        return Fields.IsError == true;
      });
      const ErrorFields = ErrorObjArray.map((Fields) => {
        return Fields.Label;
      });
      const AllFields = dataFields.map((Fields, index) => {
        return Fields.name;
      });
      const MissingFields = AllFields.filter((Fields) => {
        return data[Fields] === "";
      });

      console.log(MissingFields, ErrorObjArray, ErrorFields);
      if (MissingFields.length === 0 && ErrorObjArray.length === 0) {
        const TempData = data;
        Type === "Add"
          ? (TempData.commandId = CRUDCommands.Add)
          : (TempData.commandId = CRUDCommands.Update);
        const response = await GetResponse(TempData, URL, {});
        console.log(response, "From Add Edit Modal", TempData, URL);
        if (response === true) {
          setLoader(false);
          swal("Success!", `${name}  ${Type}'d Successfully!`, "success", {
            timer: 2000,
          });

          handleModalClose();
          dispatch(UpdateRow());
          GotoDefault();
          setData(row);
        } else if (response === false) {
          setLoader(false);
          swal("Error!", `Something in Incorrect`, "success", {
            timer: 2000,
          });
        } else if (response.errorDesc) {
          swal("Sorry!", `${response.errorDesc}`, "error", {
            // timer: 2000,
          });
        }
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      swal("Failed!", "Internal Server Error", "error");
      console.log(error);
    }
  };
  console.log(dataFields);
  const handleCheck = (event) => {
    setData({ ...data, [event.target.name]: event.target.checked });
  };
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    // SetnewDataFields({...newDataFields,[event.target.name]:event.target.value})
  };

  const ValidateInput = (event, index) => {
    setData({ ...data, [event.target.name]: event.target.value });

    // const SelectedField = dataFields[index];
    // console.log({ event });
    // console.log({ index });
    // console.log({ SelectedField });

    // if (data[event.target.name]) {
    //   const IsValid = SelectedField.validReg.test(event.target.value);
    //   console.log({ IsValid });
    //   if (IsValid === false) {
    //     const TempField = { ...SelectedField, IsError: true, autoFocus: true };
    //     const TempArray = UpdatedDataField;
    //     TempArray[index] = TempField;
    //     setUpdatedDataField(TempArray);
    //   } else {
    //     const TempField = {
    //       ...SelectedField,
    //       IsError: false,
    //       autoFocus: false,
    //     };
    //     const TempArray = UpdatedDataField;
    //     TempArray[index] = TempField;
    //     setUpdatedDataField(TempArray);
    //   }
    // }
  };
  const GotoDefault = () => {
    setData(row);
  };
  React.useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => {
          handleModalClose();
          GotoDefault();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Container component="main" maxWidth="lg" sx={styles}>
            <SimpleBackDrops Loader={Loader} />
            {/* <CssBaseline /> */}
            <Typography
              component="h1"
              variant="h5"
              sx={{
                width: "40vw",
                backgroundColor: Colors.SecondaryBackGroundColor[400],
                color: "white",
                height: 70,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                boxShadow:
                  "0 5px 10px rgba(0,0,0,0.16), 0 5px 5px rgba(0,0,0,0.16)",
                mb: 1,
                fontSize: { xs: 15, sm: 20 },
              }}
            >
              {Type} {name}
            </Typography>
            <Box
              component="form"
              validate="true"
              onSubmit={handleSubmit}
              sx={{
                // overflow: "auto",
                height: { xs: 700, sm: 400 },
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              <Box
                sx={{
                  overflow: "auto",
                  height: { xs: 500, sm: 260 },
                  paddingTop: 2,
                }}
                // key={Render}
              >
                <Grid container spacing={2} sx={{ overflow: "auto" }}>
                  <Grid item xs={12} sm={6} lg={6} key={"TemplateCode"}>
                    <TextField
                      type={"text"}
                      name={"templateCode"}
                      value={data["templateCode"]}
                      required
                      fullWidth
                      id={"templateCode"}
                      label={"Template Code"}
                      autoFocus={true}
                      onChange={(event) => ValidateInput(event)}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} key={"description"}>
                    <TextField
                      type={"text"}
                      name={"description"}
                      value={data["description"]}
                      required
                      fullWidth
                      id={"description"}
                      label={"Description"}
                      autoFocus={true}
                      onChange={(event) => ValidateInput(event)}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} key={"template"}>
                    <TextField
                      multiline
                      minRows={4}
                      name={"template"}
                      value={data["template"]}
                      required
                      fullWidth
                      id={"template"}
                      label={"Template"}
                      onChange={(event) => ValidateInput(event)}
                    />
                  </Grid>{" "}
                </Grid>
              </Box>
              <Grid container spacing={1} sx={{ padding: 2 }}>
                <Grid item xs={6} sm={6} lg={6}>
                  <Button
                    fullWidth
                    color="error"
                    variant="contained"
                    sx={{ mt: 1, mb: 1 }}
                    onClick={() => {
                      handleModalClose();
                      GotoDefault();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} lg={6}>
                  <Button
                    type="submit"
                    fullWidth
                    color="success"
                    variant="contained"
                    sx={{ mt: 1, mb: 1 }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    {Type}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Fade>
      </Modal>
    </>
  );
}
