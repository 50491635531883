import React, { useState, useEffect } from "react";
import TopUPOfflineUI from "../../components/Top-Up/TopUPOfflineUI";
import { GetPaymentMode } from "../../Utils/GetPaymentMode";
import { GetState } from "../../Utils/GetState";
import { GetCity } from "../../Utils/GetCity";
import { GetBanks } from "../../Utils/GetBanks";
import { useSelector } from "react-redux";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import {
  WalletLinks,
  PaymentGateWayLinks,
} from "../../Utils/Axios/RequestParameters";
import swal from "sweetalert";
import moment from "moment";
export default function TopUpOffline() {
  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  console.log({ distributorId: AgentDetails.distributorId });
  const [PaymentModeOptions, setPaymentModeOptions] = useState([]);
  const [PaymentMode, setPaymentMode] = useState("Cash");
  const [CityOptions, setCityOptions] = useState([
    { label: "Select State First", value: "" },
  ]);
  const [StateOptions, setStateOptions] = useState([
    { label: "Refresh the Page ", value: "" },
  ]);
  const [BankOptions, setBankOptions] = useState([
    { label: "Refresh the Page ", value: "" },
  ]);
  const [data, setdata] = useState({
    amount: 0,
    requestRemark: "",
    requestAmount: "",
    chqDdno: "",
    cashChqDddate: "",
    bankName: "",
    bankBranch: "",
    city: "",
    state: "",
    bankId: 0,
    accountTypeID: 0,
  });
  const [AllError, setAllError] = useState({
    amount: false,
    requestRemark: false,
    requestAmount: false,
    chqDdno: false,
    cashChqDddate: false,
    bankName: false,
    bankBranch: false,
    city: false,
    state: false,
    bankId: false,
    accountTypeID: false,
  });

  useEffect(() => {
    try {
      const GetData = async () => {
        const TempPaymentModeOptions = await GetPaymentMode();
        if (
          TempPaymentModeOptions.length > 0 &&
          Array.isArray(TempPaymentModeOptions)
        ) {
          setPaymentModeOptions(TempPaymentModeOptions);
        }

        const Country = {
          label: "India",
          value: "India",
          countryname: "India",
          countryId: 109,
        };
        const StateOptionsnow = await GetState(Country);
        setStateOptions(StateOptionsnow);

        const BankOptions = await GetBanks();
        setBankOptions(BankOptions);
      };
      GetData();
    } catch (error) {
      console.log(error);
    }

    return () => {
      "";
    };
  }, []);
  const GetCityOptions = async (SelectedState) => {
    const SelectedStateArray = StateOptions.filter((options) => {
      return SelectedState === options.value;
    });

    const SelectedStateOBJ = SelectedStateArray[0];
    const CityOPtionsnow = await GetCity(SelectedStateOBJ);
    setCityOptions(CityOPtionsnow);
  };

  const handlePaymentMode = (event, newPaymentMode) => {
    if (newPaymentMode !== null) {
      setPaymentMode(newPaymentMode);
    }
  };
  const handleChange = (e, regex) => {
    setdata({ ...data, [e.target.name]: e.target.value });
    if (e.target.name === "state") {
      GetCityOptions(e.target.value);
    }

    const IsValid = regex.test(e.target.value);
    console.log(IsValid);
    if (IsValid === true) {
      setAllError({ ...AllError, [e.target.name]: false });
    } else {
      setAllError({ ...AllError, [e.target.name]: true });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const SelectedPaymentModeArray = PaymentModeOptions.filter((Mode) => {
      return Mode.pgName === PaymentMode;
    });
    const SelectedPaymentMode = SelectedPaymentModeArray[0];
    console.log(data["cashChqDddate"]);
    console.log(new Date(data["cashChqDddate"]));
    console.log(moment(new Date(data["cashChqDddate"])).format("MM/DD/YYYY"));

    const AddTopUpRequest = {
      commandId: 11,
      paymentAffiliateTranNo: "",
      amount: data["requestAmount"],
      paymentDetId: 0,
      topupRequestID: 0,
      fromDistID: AgentDetails.distributorId,
      toDistID: AgentDetails.level1DistributorId,
      requestRemark: data["requestRemark"],
      requestAmount: data["requestAmount"],
      paymentGatewayPGAPIProcessLinkID:
        SelectedPaymentMode["paymentGatewayPgapiprocessLinkId"],
      paymentGatewayPgapilinkId:
        SelectedPaymentMode["paymentGatewayPgapilinkId"],
      createBy: AgentDetails.distributorId,
      createIP: "",
      ext1: "",
      ext2: "",
      ext3: "",
      ext4: "",
      ext5: "",
      chqDdno: data["chqDdno"],
      cashChqDddate:
        data["cashChqDddate"] !== null && data["cashChqDddate"] !== ""
          ? moment(new Date(data["cashChqDddate"])).format("MM/DD/YYYY")
          : moment().format("MM/DD/YYYY"),
      bankName: data["bankName"],
      bankBranch: data["bankBranch"],
      city: data["city"],
      state: data["state"],
      bankId: data["bankId"],
      accountTypeID: 1,
      processCode: "RFCD",
      isPGProcessedFromAdmin: 0,
      isSuccess: true,
    };
    console.log({ AddTopUpRequest });
    console.log({ SelectedPaymentMode });
    console.log({ data });
    const TopUpResponse = await GetResponse(
      AddTopUpRequest,
      WalletLinks.Wallet,
      {}
    );
    console.log(TopUpResponse);
    if (!TopUpResponse.errorDesc) {
      swal("Success!", TopUpResponse, "success");
    } else {
      swal("Failed!", TopUpResponse.errorDesc, "error");
    }
  };

  console.log(PaymentModeOptions);
  return (
    <TopUPOfflineUI
      PaymentModeOptions={PaymentModeOptions}
      PaymentMode={PaymentMode}
      handlePaymentMode={handlePaymentMode}
      AllError={AllError}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      CityOptions={CityOptions}
      StateOptions={StateOptions}
      BankOptions={BankOptions}
      data={data}
    />
  );
}
