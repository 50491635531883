import React,{useState,useEffect} from "react";
import { GetCity } from "../Utils/GetCity";
import { GetState } from "../Utils/GetState";
import { Grid, TextField, MenuItem } from "@mui/material";

// <<<<<<<<<<<HowtoUseComponent>>>>>>>>>

//  <GSTInputs
//  gstCompanyValue={}
//  gstnovalue={}
//  gstphoneValue={}
//  gstemailValue={}
//  gstaddressValue={}
//  gststateValue={}
//  gstcityValue={}
//  gstpincodeValue={}
//  gstcompanyError= {},
//  gstnoError= {},
//  gstphoneError= {},
//  gstemailError= {},
//  gstaddressError={},
//  gstpincodeError= {},
//  ValidateInput={}
//  grid={3}
//  />

// <<<<<<<<<<<HowtoUseComponent>>>>>>>>>

export default function GSTInputs({
  gstcompanyName = "gstcompanyName",
  gstnoName = "gstno",
  gstphoneName = "gstphone",
  gstemailName = "gstemail",
  gstaddressName = "gstaddress",
  gststateName = "gststate",
  gstcityName = "gstcity",
  gstpincodeName = "gstpincode",
  gstcompanyError = false,
  gstnoError = false,
  gstphoneError = false,
  gstemailError = false,
  gstaddressError = false,
  gstpincodeError = false,
  gstCompanyValue,
  gstnovalue,
  gstphoneValue,
  gstemailValue,
  gstaddressValue,
  gststateValue,
  gstcityValue,
  gstpincodeValue,
  ValidateInput,
  grid,
}) {
  const [StateOptions, setStateOptions] = useState([
    {
      label: gststateValue ? gststateValue : "Please Select Country",
      value: gststateValue,
    },
  ]);
  const [CityOptions, setCityOptions] = useState([
    {
      label: gstcityValue ? gstcityValue : "Please Select State",
      value: gstcityValue,
    },
  ]);
  const GSTRequired =
    gstCompanyValue !== "" ||
    gstnovalue !== "" ||
    gstphoneValue != "" ||
    gstemailValue !== "" ||
    gstemailValue !== "" ||
    gstaddressValue !== "" ||
    gststateValue !== "" ||
    gstcityValue !== "" ||
    gstpincodeValue !== "";
  const SelectedCountry = {
    label: "India",
    value: "India",
    countryname: "India",
    countryId: 109,
  };

  const GetStateOptions = async () => {
    if (StateOptions.length < 2) {
      const TempStateOptions = await GetState(SelectedCountry);
      setStateOptions(TempStateOptions);
      if (gststateValue !== "") {
        const selectedStateArray = TempStateOptions.filter((IndState) => {
          return IndState.label === gststateValue;
        });
        const SelectedState = selectedStateArray[0];

        const TempCityOptions = await GetCity(SelectedState);
        setCityOptions(TempCityOptions);
      }
    }
  };

  useEffect(() => {
    GetStateOptions();
  }, []);

  const GetCityOptions = async (state) => {
    if (state !== "") {
      const selectedStateArray = StateOptions.filter((IndState) => {
        return IndState.label === state;
      });
      const SelectedState = selectedStateArray[0];

      const TempCityOptions = await GetCity(SelectedState);
      setCityOptions(TempCityOptions);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          type="text"
          name={gstcompanyName}
          error={gstcompanyError}
          helperText={
            gstcompanyError ? `Please Enter Correct Company` : ""
          }
          value={gstCompanyValue}
          required={GSTRequired}
          fullWidth
          id={gstcompanyName}
          label="GST Company Name"
          onChange={(e) => ValidateInput(e, /^[A-Z]([a-zA-Z0-9]|[- @\.#&!])*$/)}
        ></TextField>
      </Grid>
      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          type="text"
          name={gstnoName}
          value={gstnovalue}
          error={gstnoError}
          helperText={gstnoError ? `Please Enter Correct GST Number` : ""}
          required={GSTRequired}
          fullWidth
          id={gstnoName}
          label="GST Number"
          onChange={(e) =>
            ValidateInput(
              e,
              /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i
            )
          }
        ></TextField>
      </Grid>
      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          type="number"
          name={gstphoneName}
          value={gstphoneValue}
          error={gstphoneError}
          helperText={
            gstphoneError ? `Please Enter Correct GST Phone Number` : ""
          }
          required={GSTRequired}
          fullWidth
          id={gstphoneName}
          label="GST Phone Number"
          onChange={(e) => ValidateInput(e, /^(\+\d{1,3}[- ]?)?\d{10}$/)}
        ></TextField>
      </Grid>
      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          type="email"
          name={gstemailName}
          value={gstemailValue}
          error={gstemailError}
          helperText={gstemailError ? `Please Enter Correct GST Email ` : ""}
          required={GSTRequired}
          fullWidth
          id={gstemailName}
          label="GST Email ID"
          onChange={(e) =>
            ValidateInput(
              e,
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            )
          }
        ></TextField>
      </Grid>
      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          type="text"
          name={gstaddressName}
          value={gstaddressValue}
          error={gstaddressError}
          helperText={
            gstaddressError ? `Please Enter Correct Company Address` : ""
          }
          required={GSTRequired}
          fullWidth
          id={gstaddressName}
          label="GST Address"
          onChange={(e) => ValidateInput(e, /[A-Za-z0-9'\.\-\s\,]/)}
        ></TextField>
      </Grid>
      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          name={gststateName}
          value={gststateValue}
          select={true}
          required={GSTRequired}
          fullWidth
          id={gststateName}
          label="Select GST state"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            ValidateInput(e, /[^\n]+/);
            GetCityOptions(e.target.value);
          }}
        >
          {StateOptions.length > 0 ? (
            StateOptions.map((option) => (
              <MenuItem key={`${option.label}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          ) : (
            <></>
          )}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          name={gstcityName}
          value={gstcityValue}
          select={true}
          required={GSTRequired}
          fullWidth
          id="city"
          label="Select GST City"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => ValidateInput(e, /[^\n]+/)}
        >
          {CityOptions.length > 0 ? (
            CityOptions.map((option) => (
              <MenuItem key={`${option.label}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          ) : (
            <></>
          )}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} lg={grid}>
        <TextField
          autoComplete="given-name"
          type="number"
          name={gstpincodeName}
          value={gstpincodeValue}
          error={gstpincodeError}
          helperText={gstpincodeError ? `Please Enter Correct Pincode` : ""}
          required={GSTRequired}
          fullWidth
          id="pincode"
          label="GST Pincode"
          onChange={(e) => ValidateInput(e, /[0-9]{3,6}/)}
        ></TextField>
      </Grid>
    </>
  );
}
