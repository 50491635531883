import './App.css';
import Router from './router/Router';
import { Box, createTheme, Stack, ThemeProvider } from "@mui/material";
import { useState ,useEffect} from 'react';
import { useSelector } from 'react-redux';

function App() {
  // const [mode, setMode] = useState("light");
  const mode = useSelector((state) => state.Mode.value)
  console.log(mode)
  const darkTheme = createTheme({
    palette: {
      mode: mode,
     
    },
  });
  useEffect(() => {
   console.log(mode)
  
    return () => {
      "second"
    }
  }, [mode])
  
  return (
    <ThemeProvider theme={darkTheme}>
    <Box bgcolor={"background.default"} color={"text.primary"}>
      <Router/>
    </Box>
  </ThemeProvider>
  );
}

export default App;
