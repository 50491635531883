import React from "react";
import aramex from "../assets/images/aramex.png";
import DTDC from "../assets/images/DTDC.jpg";
import FedEx from "../assets/images/FedEx.png";
import ShreeMaruti from "../assets/images/ShreeMaruti.png";
import Amazon from "../assets/images/Amazon.png";
import Ekart from "../assets/images/Ekart.png";
import Dilihivery from "../assets/images/Dilihivery.png";
import notfound from "../assets/images/notfound.png";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import PinDropIcon from "@mui/icons-material/PinDrop";
import ForwardIcon from "@mui/icons-material/Forward";
import InfoIcon from "@mui/icons-material/Info";
import ScaleIcon from "@mui/icons-material/Scale";
import { Box } from "@mui/system";
import { Colors } from "../Utils/Constants";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetResponse } from "../Utils/Axios/GetResponse";
import { CourierServiceLinks } from "../Utils/Axios/RequestParameters";
import EditOrder from "./EditOrder";
import EditIcon from "@mui/icons-material/Edit";
import { green } from "@mui/material/colors";
import Skeleton from "@mui/material/Skeleton";
import swal from "sweetalert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useSelector } from "react-redux";
export default function AvailablePartners() {
  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  const [AllProvider, SetAllProvider] = useState([]);
  const location = useLocation();
  const data = location.state;
  const [SearchData, setSearchData] = useState(data);
  const [OpenEditOrder, setOpenEditOrder] = useState(false);
  const [ShowNetFare, setShowNetFare] = useState(false);

  const HandleEditOrderClick = () => {
    setOpenEditOrder(() => (OpenEditOrder === true ? false : true));
  };
  const CloseEditOrder = () => {
    setOpenEditOrder(false);
  };
  const navigate = useNavigate();
  const GotoPookingPage = (SelectedProvider) => {
    navigate("/Booking", { state: SelectedProvider });
  };
  const getdata = async () => {
    try {
      console.log(SearchData);
      const NewData = {
        orders: [
          {
            distributorId: AgentDetails.distributorId,
            interfaceId: 1,
            pickupPincode: SearchData["pickupPincode"],
            deliveryPincode: SearchData["deliveryPincode"],
            courierTypeID: parseFloat(SearchData["courierTypeID"]),
            weightInKg: parseFloat(SearchData["weightInKg"]),
            lengthInCm: parseFloat(SearchData["lengthInCm"]),
            widthInCm: parseFloat(SearchData["widthInCm"]),
            heightInCm: parseFloat(SearchData["heightInCm"]),
            isPrepaid: SearchData["isPrepaid"],
            valueInRs: SearchData["parcelValue"],
            deliveryCharge: 0,
            codcharge: 0,
            fuelCharge: 0,
            supplierGst: 0,
            totalAmount: 0,
            companyCharges: 0,
            commissionAmount: 0,
            tdsper: 0,
            tdsamount: 0,
            sgst: 0,
            cgst: 0,
            igst: 0,
            netAmount: 0,
            apiid: 0,
            courierServiceProviderId: 0,
            courierServiceProviderName: "",
            orderId: 0,
            refNo: "",
            parcelDetails: "",
            pickupCompanyName: "",
            pickupMobileNo: "",
            pickupFirstName: "",
            pickupLastName: "",
            pickupEmail: "",
            pickupAddress1: "",
            pickupAddress2: "",
            pickupAddress3: "",
            pickupCityId: 0,
            pickupStateId: 0,
            pickupCountryId: 0,
            publishedTotalAmount: 0,
            deliveryCompanyName: "",
            deliveryMobileNo: "",
            deliveryFirstName: "",
            deliveryLastName: "",
            deliveryEmail: "",
            deliveryAddress1: "",
            deliveryAddress2: "",
            deliveryAddress3: "",
            deliveryCityId: 0,
            deliveryStateId: 0,
            deliveryCountryId: 0,
            historyCommissionConfigId: 0,
            level1DistributorId: 0,
            level1CommCalculated: 0,
            level1CommTdscalculated: 0,
            level1CommNet: 0,
            level1CommIsProcessed: true,
            level1CommProcessedDateTime: "2022-11-17T11:30:21.505Z",
            level2DistributorId: 0,
            level2CommCalculated: 0,
            level2CommTdscalculated: 0,
            level2CommNet: 0,
            level2CommIsProcessed: true,
            level2CommProcessedDateTime: "2022-11-17T11:30:21.505Z",
            totalCancelRefundAmount: 0,
            totalRefundAmount: 0,
            totalAgencyCharges: 0,
            status: "",
            requestDateTime: "2022-11-17T11:30:21.505Z",
            bookingDateTime: "2022-11-17T11:30:21.505Z",
            pgid: 0,
            tranClientIp: "",
            serverIp: "",
            paymentGatewayPgapilinkId: 0,
            bookedAdminUserId: 0,
            orderRequestDetId: 0,
            userDistributorId: 0,
            apitransactionNo: "",
            apitrackingNumber: "",
            isPickupDone: true,
            createBy: 0,
            createDate: "2022-11-17T11:30:21.505Z",
            createIp: "",
            updateBy: 0,
            updateDate: "2022-11-17T11:30:21.505Z",
            updateIp: "",
            serviceType: "",
            apiValue: "",
            otherCharge: 0,
            serviceDays: 0,
            estimateDate: "2022-11-17T11:30:21.505Z",
            remark: "",
            orderDetails: [
              {
                orderDetId: 0,
                orderId: 0,
                productDesc: "",
                sku: "",
                hsn: "",
                qty: 0,
                price: 0,
                totalAmount: 0,
                createBy: 0,
                createDate: "2022-12-07T09:35:26.376Z",
                createIP: "",
                updateBy: 0,
                updateDate: "2022-12-07T09:35:26.376Z",
                updateIP: "",
              },
            ],
            orderRequest: {
              orderRequestId: 0,
              orderRequestDetID: 0,
              currentSessionID: "",
              clientRequestID: "",
              gstNo: "",
              gstEmailID: "",
              gstCompanyName: "",
              gstMobileNo: "",
              gstAddress: "",
              gstFlat: "",
              gstStreet: "",
              gstpin: "",
              gstState: "",
              gstCity: "",
              remark: "",
              bookingRequestNo: "",
              productID: 0,
            },
            orderPaymentDetail: [
              {
                orderPaymentDetID: 0,
                merchantTxnID: "",
                orderReferenceNo: "",
                invoiceNo: "",
                paymentAffiliateTranNo: "",
                paymentAffiliateDateTime: "2022-12-08T10:34:26.355Z",
                rootPNRef: "",
                rootAuthCode: "",
                isSucceed: true,
                remark: "",
                merchantID: "",
                vendor: "",
                partner: "",
                custIPAddress: "",
                respURL: "",
                respMethod: "",
                currCode: "",
                messageType: "",
                gmtOffset: "",
                ext1: "",
                ext2: "",
                ext3: "",
                ext4: "",
                ext5: "",
                amount: 0,
                isOrder: true,
                isCancel: true,
                isRefund: true,
              },
            ],
            orderCancel: [
              {
                cgst: 0,
                codChargeRefund: 0,
                commissionAmountRefund: 0,
                companyCancellationCharges: 0,
                companyChargesRefund: 0,
                couOrderCancelID: 0,
                courierServiceProviderCancellationCharges: 0,
                deliveryChargeRefund: 0,
                fuelChargeRefund: 0,
                igst: 0,
                isCancelSuccess: true,
                isComplaint: true,
                orderCancelDetId: 0,
                orderCancelId: 0,
                sgst: 0,
                status: "",
                supplierGSTRefund: 0,
                tdsAmountRefund: 0,
                totalAmountRefund: 0,
                totalRefundAmount: 0,
                userID: 0,
              },
            ],
            tracking: [
              {
                checkpoints: [
                  {
                    location: "",
                  },
                ],
              },
            ],
            fromDate: "2022-12-07T09:35:26.376Z",
            toDate: "2022-12-07T09:35:26.376Z",
            statusId: 0,
          },
        ],
        errorDesc: "",
        errorCode: "",
        commandId: 0,
      };

      const Response = await GetResponse(
        NewData,
        CourierServiceLinks.Search,
        {}
      );
      if (!Response.errorDesc) {
        if (Response.orders.length > 0) {
          SetAllProvider(Response.orders);
        }
      } else {
        swal("error", Response.errorDesc, "error");
        SetAllProvider([]);
      }
    } catch (error) {
      swal("error", "No Courier Service Provider Found", "error");
      SetAllProvider([]);
      console.log(error);
    }
  };
  useEffect(() => {
    SetAllProvider([]);
    getdata();
  }, [SearchData]);

  const UpdateSearchData = (data) => {
    setSearchData(data);
  };
  return (
    <>
      <EditOrder
        OldSearch={SearchData}
        UpdateSearchData={UpdateSearchData}
        OpenEditOrder={OpenEditOrder}
        CloseEditOrder={CloseEditOrder}
      />
      <Box
        sx={{
          width: "90vw",
          height: "87.5vh",
          p: { xs: 2, sm: 5 },
          pl: { xs: 2, sm: 10 },
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: 50,
            backgroundColor: Colors.PrimaryColor[50],
            boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            display: "flex",
            justifyContent: "Space-around",
            alignItems: "center",
            borderRadius: 3,
            marginBottom: 2,
            color: Colors.SecondaryColor[500],
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box component={"h3"} sx={{ display: { xs: "none", lg: "flex" } }}>
              <WhereToVoteIcon sx={{ color: Colors.SecondaryColor[500] }} />
              &nbsp;From Pincode&nbsp;:
            </Box>
            <Box component={"h4"}> &nbsp;{SearchData.pickupPincode} &nbsp;</Box>
          </Box>
          <ForwardIcon
            sx={{ color: Colors.SecondaryColor[500], fontSize: 20 }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box component={"h3"} sx={{ display: { xs: "none", lg: "flex" } }}>
              <PinDropIcon sx={{ color: Colors.SecondaryColor[500] }} />
              &nbsp;To Pincode&nbsp;:
            </Box>
            <Box component={"h4"}>
              {" "}
              &nbsp;{SearchData.deliveryPincode} &nbsp;
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box component={"h3"} sx={{ display: { xs: "none", lg: "flex" } }}>
              <ScaleIcon sx={{ color: Colors.SecondaryColor[500] }} />
              &nbsp;Weight&nbsp;:
            </Box>
            <Box component={"h4"}> &nbsp;{SearchData.weightInKg} kg &nbsp;</Box>
          </Box>
          <Tooltip
            title={ShowNetFare === false ? "Show Net Price" : "Hide Net Price"}
          >
            <Switch
              checked={ShowNetFare}
              onChange={() => setShowNetFare(!ShowNetFare)}
              inputProps={{
                "aria-label":
                  ShowNetFare === false ? "Show Net Price" : "Hide Net Price",
              }}
            />
          </Tooltip>
          <Button
            variant="contained"
            sx={{
              color: "white",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              height: "80%",
              backgroundColor: Colors.PrimaryColor[900],
              "&:hover": {
                backgroundColor: Colors.SecondaryBackGroundColor[500],
              },
            }}
            onClick={() => HandleEditOrderClick()}
          >
            <EditIcon />
            <Box component={"h4"} sx={{ display: { xs: "none", lg: "block" } }}>
              &nbsp;Edit&nbsp;Search
            </Box>
          </Button>
        </Box>

        <Box
          sx={{
            overflow: "auto",
            height: { xs: 600, sm: 450 },
            paddingTop: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={2} sx={{ overflow: "auto" }}>
            {AllProvider.length > 0
              ? AllProvider.map((Provider) => {
                  return (
                    <Grid xs={12} md={6} lg={4}>
                      <Box
                        sx={{
                          width: "90%",
                          height: 180,
                          backgroundColor: "white",
                          boxShadow:
                            "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                          transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
                          borderRadius: 3,
                          margin: 3,
                          "&:hover": {
                            boxShadow:
                              "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                          },
                          padding: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: "39%",
                            // backgroundColor: "lightblue",
                            display: "flex",
                            justifyContent: "space-between",
                            borderRadius: 2,
                            boxShadow:
                              "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                            overflow: "hidden",
                          }}
                        >
                          {Provider.courierServiceProviderName ===
                          "Delhivery" ? (
                            <img
                              src={Dilihivery}
                              alt="logo"
                              width="150"
                              height="64"
                            />
                          ) : Provider.courierServiceProviderName === "DTDC" ? (
                            <img
                              src={DTDC}
                              alt="logo"
                              width="150"
                              height="64"
                            />
                          ) : Provider.courierServiceProviderName ===
                            "Ekart" ? (
                            <img
                              src={Ekart}
                              alt="logo"
                              width="150"
                              height="64"
                            />
                          ) : Provider.courierServiceProviderName ===
                            "Shree Maruti Courier" ? (
                            <img
                              src={ShreeMaruti}
                              alt="logo"
                              width="150"
                              height="64"
                            />
                          ) : Provider.courierServiceProviderName ===
                            "Amazon (SWA)" ? (
                            <img
                              src={Amazon}
                              alt="logo"
                              width="150"
                              height="64"
                            />
                          ) : (
                            <img
                              src={notfound}
                              alt="logo"
                              width="150"
                              height="64"
                            />
                          )}
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: Colors.PrimaryColor[800],
                                fontWeight: "bold",
                              }}
                            >
                              {Provider.courierServiceProviderName}
                            </Typography>
                            <Typography
                              sx={{
                                color: Colors.SecondaryColor[800],
                                fontWeight: "bold",
                                textDecoration: ShowNetFare
                                  ? "line-through"
                                  : "none",
                              }}
                            >
                              ₹ {Provider.publishedTotalAmount}
                            </Typography>
                            {ShowNetFare && (
                              <Typography
                                sx={{
                                  color: Colors.SecondaryColor[800],
                                  fontWeight: "bold",
                                }}
                              >
                                ₹ {Provider.netAmount}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            height: "60%",
                            // backgroundColor: "greenyellow",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            alignItems: "center",
                            borderRadius: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box>{`Up to ${Provider.serviceDays} Days Estimated Delivery, ${Provider.serviceType}`}</Box>
                            <Box>
                              <InfoIcon sx={{ color: "#273f87" }} />
                            </Box>
                          </Box>
                          <Button
                            variant="contained"
                            fullWidth
                            sx={{
                              color: "white",
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              backgroundColor: green[400],
                              "&:hover": {
                                backgroundColor: green[600],
                              },
                            }}
                            onClick={() => GotoPookingPage(Provider)}
                          >
                            Book
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })
              : Array(6).fill(
                  <Grid xs={12} md={6} lg={4}>
                    <Box
                      sx={{
                        width: "90%",
                        height: 180,
                        backgroundColor: "white",
                        boxShadow:
                          "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                        transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
                        borderRadius: 3,
                        margin: 3,
                        "&:hover": {
                          boxShadow:
                            "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                        },
                        padding: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "30%",
                          // backgroundColor: "lightblue",
                          display: "flex",
                          justifyContent: "space-between",
                          borderRadius: 2,
                          boxShadow:
                            "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                          overflow: "hidden",
                        }}
                      >
                        <Skeleton
                          // animation='wave'
                          variant="rounded"
                          width="100%"
                          height="100%"
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          height: "30%",
                          pt: 2,

                          // backgroundColor: "greenyellow",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          borderRadius: 2,
                        }}
                      >
                        <Skeleton
                          // animation='wave'
                          variant="rounded"
                          width="100%"
                          height="100%"
                        />
                      </Box>

                      <Box
                        sx={{
                          width: "100%",
                          height: "35%",

                          pt: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                          borderRadius: 2,
                        }}
                      >
                        <Skeleton
                          // animation='wave'
                          variant="rounded"
                          width="100%"
                          height="100%"
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
