import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import ResponsiveSidebar from "../../components/ResponsiveSidebar";
import AdminNavbar from "../Components/AdminNavbar";

import { AdminDashBoardLinkList } from "../../Utils/AdminDashBoardLinkList";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "../../Utils/Axios/RequestParameters";
import { useSelector } from "react-redux";
import AdminResponsiveSideBar from "../../components/AdminResponsiveSideBar";

export default function AdminMainPage() {
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const HandleDrawer = () => {
    console.log(openDrawer);
    setOpenDrawer(openDrawer === true ? false : true);
  };
  const [MenuItemsArray, setMenuItemsArray] = React.useState([]);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);

  React.useEffect(() => {
    const getServices = async () => {
      try {
        const addData = {
          menuId: 0,
          roleId: AdminDetails.roleId,
          panelId:  AdminDetails.panelId,
          parentMenuId: 0,
          menuCaption: "",
          menuControlName: "",
          menuLevel: 0,
          orderNo: 0,
          logoUrl: "",
          isNewWindow: true,
          isActive: true,
          subMenuNo: 0,
          totSubMenu: 0,
          menuKey: "",
          isMaintenance: true,
          maintenanceMsg: "",
          commandId: CRUDCommands.ListAll,
          createBy: 0,
          createDate: "2022-12-12T07:17:41.412Z",
          createIp: "",
          updateBy: 0,
          updateDate: "2022-12-12T07:17:41.412Z",
          updateIp: "",
          hasChildMenu: 0,
          parentMenuCaption: "",
        };
        // const Response = await axios.get( RDSLinks.MenuRight.GetMenuByPanelRole);
        console.log(addData);
        const Response = await GetResponse(
          addData,
          RDSLinks.MenuRight.GetMenuByPanelRole,
          {}
        );
        console.log(Response);
        if (Response.length > 0) {
          setMenuItemsArray(Response);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getServices()
  }, []);

  return (
    <ProSidebarProvider>
      <Box sx={{ display: "flex" }}>
        <AdminNavbar HandleDrawer={HandleDrawer} />
      </Box>

      <Box>
        {/* <MainNavbar AppName={'Insta Courier'} Applink='/'/> */}
        <Box sx={{ display: "flex", marginTop: 10 }}>
          {/* <ResponsiveSidebar
            openDrawer={openDrawer}
            LinkList={AdminDashBoardLinkList}
          /> */}

          {MenuItemsArray.length > 0 && (
            <AdminResponsiveSideBar
              openDrawer={openDrawer}
              LinkList={MenuItemsArray}
            />
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100vw",
              pl: { sx: 0, sm: 10 },
            }}
          >
            <Outlet />
          </Box>
        </Box>

        {/* <Container  ></Container> */}
      </Box>
    </ProSidebarProvider>
  );
}
