import React from "react";
import { GetResponse } from "./Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "./Axios/RequestParameters";

async function GetState(SelectedCountry) {
  console.log({ SelectedCountryInGetState: SelectedCountry });
  var StateOptions = [{}];
  if (SelectedCountry["countryId"] !== "") {
    const AllState = await GetResponse(
      {
        stateId: 0,
        countryId: SelectedCountry.countryId,
        stateCode: "",
        stateName: "",
        zoneId: 0,
        isActive: true,
        commandId: CRUDCommands.ListAll,
        createBy: 0,
        createDate: "2022-11-01T13:11:27.025Z",
        createIp: "",
        updateBy: 0,
        updateDate: "2022-11-01T13:11:27.025Z",
        updateIp: "",
        accPlaceOfSupplyId: "",
        gstcode: "",
        countrycode: "",
        countryname: "",
        zoneName: "",
      },
      RDSLinks.StateMaster,
      {}
    );

    if (AllState.length > 0) {
      // {
      //     "stateID": 43,
      //     "stateCode": "A006",
      //     "stateName": "WB",
      //     "isActive": true,
      //     "countryID": 109,
      //     "zoneID": 1,
      //     "countryCode": "0091",
      //     "countryname": "India",
      //     "zoneName": "West",
      //     "accPlaceOfSupplyID": "89",
      //     "gstCode": "06"
      //   },
      const newArray = AllState.map((State) => {
        return {
          label: State.stateName,
          value: State.stateName,
          countryname: State.countryname,
          isActive: State.isActive,
          stateName: State.stateName,
          stateId: State.stateId,
        };
      });
      StateOptions = newArray;
    } else {
      StateOptions = [
        {
          label: `No States Found in ${SelectedCountry.countryname}`,
          value: "",
          stateId: "",
        },
      ];
    }
  }
  return StateOptions;
}
export { GetState };
