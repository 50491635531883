import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import Link from '@mui/material/Link';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import swal from "sweetalert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import Swal from "sweetalert2";
import { GetResponse } from "../Utils/Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "../Utils/Axios/RequestParameters";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link to="/">Insta Courier Service</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn(props) {
  const { Authenticate, validate, error, data } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const requestData = {
    token: "",
    distributorId: 0,
    typeDetId: 0,
    name: "",
    distributorCode: "",
    cmpName: "",
    add1: "",
    add2: "",
    add3: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    phone: "",
    mobile: "",
    fax: "",
    pan: "",
    email: "",
    tranPwd: "",
    dateOfBirth: "2022-12-28T12:11:46.956Z",
    distributorLevel: 0,
    level1DistributorId: 0,
    level2DistributorId: 0,
    joiningDate: "2022-12-28T12:11:46.956Z",
    padd1: "",
    padd2: "",
    padd3: "",
    pcity: "",
    pstate: "",
    pcountry: "",
    ppincode: "",
    pphone: "",
    pmobile: "",
    pfax: "",
    isCustomer: true,
    isActive: true,
    activationRemark: "",
    commandId: CRUDCommands.ForgotPassword,
    createBy: 0,
    createDate: "2022-12-28T12:11:46.957Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-12-28T12:11:46.957Z",
    updateIp: "",
    gender: "",
    isPaid: true,
    isProfileUpdated: true,
    invoiceNo: 0,
    paidDateTime: "2022-12-28T12:11:46.957Z",
    paidAmount: 0,
    isForm60: true,
    registrationAmount: 0,
    maintenanceChargeDetId: 0,
    isGenerateComm: true,
    isTranPwdUpdated: true,
    isTranAllowed: true,
    tranAllowedRemark: "",
    maritalStatus: "",
    occupation: "",
    activationDate: "2022-12-28T12:11:46.957Z",
    isEmailSmssent: true,
    isFirstLogin: true,
    logoImageUrl: "",
    isLogoDisplay: true,
    masterDistributorId: 0,
    userSeqNo: 0,
    loginName: "",
    pwd: "",
    isAllowLimitAllocationToDownline: true,
    alternateEmail: "",
    pandocImg: "",
    userRemark: "",
    isSendBalanceReminder: true,
    reminderBalance: 0,
    reminderInterval: 0,
    isSendReminderEmail: true,
    isSendReminderSms: true,
    isSerTaxOnSerCharge: true,
    isAllowToAddDownline: true,
    isTranPwdMendate: true,
    anniversaryDate: "2022-12-28T12:11:46.957Z",
    isSendDobintimationToCompany: true,
    title: "",
    lastName: "",
    designation: "",
    labelCaption: "",
    isSendPromotionalEmail: true,
    distributorUserAccessTimeId: 0,
    activenessCategoryId: 0,
    visitingCardReceiptDate: "2022-12-28T12:11:46.957Z",
    aadharNo: "",
    gstcompanyName: "",
    gstno: "",
    gstphone: "",
    gstemail: "",
    gstaddress: "",
    gstpincode: "",
    gststate: "",
    gstcity: "",
    countryCode: "",
    logo: "",
    userDistributorID: 0,
    typeId: 0,
    limitBalance: 0,
    creditAllowed: 0,
    level1DistributorCode: "",
    transactionCredit: 0,
    tranCreditUtilized: 0,
    salesPersonId: 0,
    verificationCode: "",
    distributorGroupConfiguration: "",
    holdAmt: 0,
    countryLastUpdated: "2022-12-28T12:11:46.957Z",
    masterDistributorName: "",
    masterDistributorCode: "",
    bankLastUpdated: "2022-12-28T12:11:46.957Z",
    stateLastUpdated: "2022-12-28T12:11:46.957Z",
    cityLastUpdated: "2022-12-28T12:11:46.957Z",
    rewardPointBalance: 0,
    taxExemptionRate: 0,
    taxExemptionAmt: 0,
    taxExemptionCertImg: "",
    isDisplayApicommission: true,
    applyConvenienceFeeOnRdspg: true,
    distributorTaxID: 0,
    temporaryCredit: 0,
    cashBackBalance: 0,
    accountBalance: 0,
    effTaxExemptionAmt: 0,
    effTaxExemptionRate: 0,
    categoryName: "",
    description: "",
    typeDetailName: "",
    typeCode: "",
    code: "",
    typeMasterName: "",
    isTdsexemptionCertificateProvided: true,
    isRechargeAll: true,
    isCoBranded: true,
    effTaxExemptionDate: "2022-12-28T12:11:46.957Z",
    fromJoiningDate: "2022-12-28T12:11:46.957Z",
    toJoiningDate: "2022-12-28T12:11:46.957Z",
    accountType: [
      {
        distributorId: 0,
        accountBalance: 0,
        limitBalance: 0,
        creditAllowed: 0,
        transactionCredit: 0,
        tranCreditUtilized: 0,
        cashBackBalance: 0,
        distAccBalance: 0,
        holdAmt: 0,
        temporaryCredit: 0,
        accountTypeId: 0,
        accountTypeCode: "",
        accountTypeName: "",
      },
    ],
  };
  const giveKey = (value) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    ) {
      return "email";
    } else if (/^(\+\d{1,3}[- ]?)?\d{10}$/.test(value)) {
      return "mobile";
    } else {
      return "distributorCode";
    }
  };
  const forgotPassword = () => {
    Swal.fire({
      title: "Forgot Password",
      input: "text",
      inputPlaceholder:'Enter Registered Email / Mobile No / User Name',
      icon: "question",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "submit",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        var data = requestData;
        var key = giveKey(login);
        data[key] = login;
        console.log(data);
        return await GetResponse(
          data,
          RDSLinks.DistributorMaster.DistributorMaster,
          {}
        )
          .then((response) => {
            console.log({response})
            if (response.errorDesc) {
              // throw new Error(response.errorDesc);
              Swal.showValidationMessage(`Kindly Provide Accurate Information: ${response.errorDesc}`);
            }
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      console.log(result);
      if (result.value) {

        Swal.fire({
          title: "Success",
          text: `${result.value}!`,
          icon: "success",
        });
      }
    });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs" className="SignInBox">
          <CssBaseline />
          <Box
            sx={{
              //  marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={(event) => Authenticate(event)}
              validate="true"
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="UserName"
                label="User Name"
                name="UserName"
                error={error.UserName}
                helperText={error.UserName == true ? "Incorrect UserName" : ""}
                autoComplete="UserName"
                autoFocus
                value={data.UserName}
                pattern="\d{10}"
                onChange={validate}
              />

              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  error={error.Password}
                  helperText={
                    error.Password == true ? "Incorrect password" : ""
                  }
                  name="Password"
                  label="Password"
                  pattern="^[A-Za-z0-9_-]*$"
                  id="password"
                  value={data.Password}
                  onChange={validate}
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(() => !showPassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 2 }}
                color="warning"
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs={12}>
                  <Button onClick={forgotPassword}>Forgot Password</Button>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/SignUp">
                    Don't have an account? Sign Up As a Agent
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 1, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </>
  );
}
