import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import Link from '@mui/material/Link';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import swal from "sweetalert";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link to="/">Insta Courier Service</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function AdminSignIn(props) {
  const { Authenticate, validate, Error, data } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs" className="SignInBox">
          <CssBaseline />

          <Paper
            elevation={4}
            sx={{
              //  marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
              borderRadius: 5,
            }}
          >
            <Avatar sx={{ bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Admin Panel
            </Typography>
            <Box
              component="form"
              onSubmit={(event) => Authenticate(event)}
              validate="true"
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="UserName"
                label="User Name"
                name="UserName"
                error={Error.UserName}
                helperText={Error.UserName === true ? "Incorrect UserName" : ""}
                autoComplete="UserName"
                autoFocus
                value={data.UserName}
                pattern="\d{10}"
                onChange={validate}
              />

              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  error={Error.Password}
                  helperText={
                    Error.Password === true ? "Incorrect password" : ""
                  }
                  name="Password"
                  label="Password"
                  pattern="^[A-Za-z0-9_-]*$"
                  id="password"
                  value={data.Password}
                  onChange={validate}
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(() => !showPassword)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 2 }}
                color="warning"
              >
                LOGIN
              </Button>
            </Box>
          </Paper>
          <Copyright sx={{ mt: 1, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </>
  );
}
