import { createSlice } from "@reduxjs/toolkit";
const Token = sessionStorage.getItem('AgentToken')
export const AgentTokenSlice = createSlice({
  name: "AgentToken",
  initialState: {
    value: Token?Token:null,
  },
  reducers: {
    UpdateAgentToken: (state, action) => {
      state.value = action.payload;
    },
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    ClearAgentToken:(state, action)=>{
      state.value = null;
  
    },
  },
});

// Action creators are generated for each case reducer function
export const { UpdateAgentToken,ClearAgentToken } = AgentTokenSlice.actions;

export default AgentTokenSlice.reducer;