import { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Typography, Fab } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import { GetResponse } from "../../Utils/Axios/GetResponse";
import CRUDActions from "./CRUDActions";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { CRUDCommands, UserMaster } from "../../Utils/Axios/RequestParameters";
import { Colors } from "../../Utils/Constants";
import ActiveDeactive from "./ActiveDeactive";
import swal from "sweetalert";
import SimpleBackDrops from "../../components/SimpleBackDrops";
import CommonAddEditModal from "./CommonAddEditModal";

export default function UsersDataGrid() {
  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);
  const addData = {
    token: "",
    userId: 0,
    roleId: 0,
    loginName: "",
    userName: "",
    pwd: "",
    email: "",
    mobile: "",
    isActive: true,
    commandId:CRUDCommands.ListAll,
    createBy: 0,
    createDate: "2022-11-03T09:08:31.506Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-11-03T09:08:31.506Z",
    updateIp: "",
    panelId: 0,
    ualid: 0,
    userAccessTimeId: 0,
    isTemporaryCreditAllowed: true,
    panelCode: "",
  };

  const InitialError={
    roleId:false,
    loginName:false,
    userName:false,
    pwd:false,
    email:false,
    mobile:false,
    panelId: false,
    ualid: false,
    userAccessTimeId: false,
    panelCode: false,
   
  }

  const RequiredInputs=[
    "roleId",
    "loginName",
    "userName",
    "pwd",
    "email",
    "mobile",
    "panelId",
    "ualid",
    "userAccessTimeId",
    "panelCode"
  ]
  const dataFields = [
    {
      Label: "Role Id",
      name: "roleId",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Login Name",
      name: "loginName",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "User Name",
      name: "userName",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Password",
      name: "pwd",
      Type: "password",
      IsError: false,
      validReg:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@._$!%*?&])[A-Za-z\d@._$!%*?&]{8,15}$/,
      ErrorMsg:
        "8 to 15 Characters, at least one uppercase letter, one lowercase letter, one number and one special character",
      options: [],
    },
    {
      Label: "Email",
      name: "email",
      Type: "text",
      IsError: false,
      validReg: /^[A-Za-z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      ErrorMsg: "Invalid email address",
      options: [],
    },
    {
      Label: "Mobile NO",
      name: "mobile",
      Type: "number",
      IsError: false,
      validReg: /^(\+\d{1,3}[- ]?)?\d{10}$/,
      ErrorMsg: "Invalid mobile number ",
      options: [],
    },
    {
      Label: "Panel ID",
      name: "panelId",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "UAL Id",
      name: "ualid",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "User Access Time Id",
      name: "userAccessTimeId",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
    {
      Label: "Panel Code",
      name: "panelCode",
      Type: "text",
      IsError: false,
      ErrorMsg: " ",
      validReg: /[^\n]+/,
      options: [],
    },
  ];
  const getServices = async () => {
    setLoader(true);
    // const Response = await axios.get(UserDetails.GetAll);
    try {
      const Response = await GetResponse(addData, UserMaster.GetAll, {});
      console.log(Response);
      if (Response.length > 0) {
        setServiceAPI(Response);
      } else {
        swal("error", "No Records Found", "error");
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Records Found", "error");
    }
    setLoader(false);
  };

  useEffect(() => {
    getServices();
  }, [Row]);

  const columns = useMemo(
    () => [
      {
        field: "loginName",
        headerName: "Login Name",
        width: 150,
      },
      {
        field: "userName",
        headerName: "User Name",
        width: 150,
      },

      { field: "email", headerName: "Email", width: 200 },
      {
        field: "mobile",
        headerName: "Mobile No",
        width: 150,
      },

      {
        field: "isActive",
        headerName: "is Active",
        width: 80,
        type: "boolean",
        renderCell: (params) => (
          <ActiveDeactive
            {...{
              UpdateURL: UserMaster.GetAll,
              activeName: "isActive",
              SelectedFieldName: "this User",
              row: params.row,
            }}
          />
        ),
      },
      {
        field: "isTemporaryCreditAllowed",
        headerName: "Temporary Credit Allowed",
        width: 190,
        type: "boolean",
        renderCell: (params) => (
          <ActiveDeactive
            {...{
              UpdateURL: UserMaster.GetAll,
              activeName: "isTemporaryCreditAllowed",
              SelectedFieldName: " the TemporaryCreditAllowed of this User",
              row: params.row,
            }}
          />
        ),
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 225,
        renderCell: (params) => (
          <CRUDActions
            {...{
              params,
              rowId,
              setRowId,
              dataFields,
              RequiredInputs,
              InitialError,
              EditURL: UserMaster.GetAll,
              DeleteURL: UserMaster.GetAll,
              RowDeleteId: params.row.userId,
              name: "Users",
            }}
          />
        ),
      },
    ],
    [rowId]
  );

  return (
    <>
      <SimpleBackDrops Loader={Loader} />

      <CommonAddEditModal
        openModal={openModal}
        handleModalClose={handleModalClose}
        handleModalOpen={handleModalOpen}
        URL={UserMaster.GetAll}
        row={addData}
        RequiredInputs={RequiredInputs}
        InitialError={InitialError}
        dataFields={dataFields}
        Type={"Add"}
        name={"Users"}
      />

      <Box
        sx={{
          height: 559,
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
          // backgroundColor:Colors.PrimaryBackgroundColor,
          // alignItems:'center'
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            textAlign: "center",
            p: 1,
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          Users
        </Typography>

        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            ml: 5,
            mt: 1,
            bgcolor: green[500],
            "&:hover": { bgcolor: green[700] },
          }}
          onClick={handleModalOpen}
        >
          <Add />
        </Fab>

        <DataGrid
          key={Row}
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          rows={ServiceAPI}
          getRowId={(row) => row.userId}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
