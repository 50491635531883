import {
  Box,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  Stack,
  Chip,
  Fab,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { Colors } from "../../Utils/Constants";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { useEffect } from "react";
export default function OrderDetails({
  data,
  render,
  AddBox,
  PickUpDataFields,
  AllError,
  validate,
  DeleteBox,
  TotalOrderDetailAmount
}) {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Box
         key={render}
         >
          <Box
            sx={{
              backgroundColor: Colors.PrimaryColor[800],
              color: "white",
              width: "100%",
              p: 1,
              mb: 2,
              borderRadius: 1,
              fontSize: 20,
              display:'flex',
              justifyContent:'space-between',
              position:'sticky',
              top:200,
            }}
          >
            {" "}
            <Typography sx={{fontSize: 20,}}> Order Details</Typography>
            <Typography sx={{fontSize: 20,}}>Total Amount : {TotalOrderDetailAmount}</Typography>

           
          </Box>
         
          {data.map((IndData, dataIndex) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 2,
                  width: "100%",
                }}
                key={`${dataIndex}OrderItem`}
              >
                <Chip
                  label={dataIndex + 1}
                  variant="outlined"
                  sx={{ m: 1, color: Colors.SecondaryColor[900] }}
                />
                <Paper elevation={2} sx={{ p: 1 }}>
                  <Grid container spacing={1}>
                    {PickUpDataFields.map((Fields, index) => {
                      console.log(IndData)
                      return (
                        <Grid
                          item
                          xs={12}
                          lg={Fields.grid}
                          key={`${dataIndex}${Fields.name}`}
                        >
                          <TextField
                            autoComplete="given-name"
                            error={AllError[dataIndex][Fields.name]}
                            inputProps={{ pattern: Fields.regex }}
                            helperText={Fields.helperText}
                            name={Fields.name}
                            value={IndData[Fields.name]  === 'totalAmount'? IndData.qty*IndData.price:data[Fields.name]}
                            defaultValue={IndData[Fields.name]}
                            required={Fields.Required}
                            fullWidth
                            id={Fields.name}
                            disabled={
                              Fields.name === "totalAmount" ? true : false
                            }
                            label={Fields.label}
                            type={Fields.type}
                            InputProps={
                              Fields.type === "number"
                                ? {
                                    inputProps: {
                                      min: 0,
                                      max: 255,
                                      maxLength: 10,
                                    },
                                  }
                                : {}
                            }
                            autoFocus={AllError[dataIndex][Fields.name]}
                            onBlur={(e) => validate(e, dataIndex, Fields.regex)}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Paper>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Tooltip
                    title={`Delete Details of Item ${dataIndex + 1} `}
                    placement="bottom"
                  >
                    <IconButton
                      aria-label="delete"
                      size="medium"
                      sx={{
                        visibility: dataIndex !== 0 ? "visible" : "hidden",
                        margin: 1,
                        "&:hover": { color: Colors.PrimaryColor[600] },
                      }}
                      onClick={() => DeleteBox(dataIndex)}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Box>
            );
          })}
          <Tooltip title="Add New Item" placement="right">
            <Fab
              aria-label="add"
              onClick={() => AddBox()}
              size="small"
              sx={{
                bgcolor: Colors.PrimaryColor[300],
                color: "white",
                "&:hover": { bgcolor: Colors.PrimaryColor[800] },
              }}
            >
              <Add />
            </Fab>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
}
