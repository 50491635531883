import React, { useEffect, useState } from "react";
import { GetCityId } from "../Utils/GetCityId";
import { GetCountryId } from "../Utils/GetCountryId";
import { GetStateId } from "../Utils/GetStateId";
import { Grid, TextField, MenuItem } from "@mui/material";

export default function CountryStateCityId({
  state,
  city,
  country,
  grid,
  ValidateInput,
  CountryLabel,
  StateLabel,
  CityLabel,
}) {
  const [CountryOptions, setCountryOptions] = useState([
    { label: country ? country : "Refresh the Page", value: country },
  ]);
  const [StateOptions, setStateOptions] = useState([
    { label: state ? state : "Please Select Country", value: state },
  ]);
  const [CityOptions, setCityOptions] = useState([
    { label: city ? city : "Please Select State", value: city },
  ]);

  const GetCountryOptions = async () => {
    const TempCountryOptions = await GetCountryId();
    setCountryOptions(TempCountryOptions);

    if (TempCountryOptions.length > 0) {
      if (country !== 0) {
        const selectedCountryArray = TempCountryOptions.filter((Indcountry) => {
          return Indcountry.value === country;
        });
        const SelectedCountry = selectedCountryArray[0];

        const TempStateOptions = await GetStateId(SelectedCountry);
        setStateOptions(TempStateOptions);
        if (state !== 0) {
          const selectedStateArray = TempStateOptions.filter((IndState) => {
            return IndState.value === state;
          });
          const SelectedState = selectedStateArray[0];

          const TempCityOptions = await GetCityId(SelectedState);
          setCityOptions(TempCityOptions);
        }
      }
    }
  };
  useEffect(() => {
    GetCountryOptions();
  }, []);

  const GetStateOptions = async (country) => {
    if (country !== 0) {
      const selectedCountryArray = CountryOptions.filter((Indcountry) => {
        return Indcountry.value === country;
      });
      const SelectedCountry = selectedCountryArray[0];

      const TempStateOptions = await GetStateId(SelectedCountry);
      setStateOptions(TempStateOptions);
    }
  };

  const GetCityOptions = async (state) => {
    if (state !== 0) {
      const selectedStateArray = StateOptions.filter((IndState) => {
        return IndState.value === state;
      });
      const SelectedState = selectedStateArray[0];

      const TempCityOptions = await GetCityId(SelectedState);
      setCityOptions(TempCityOptions);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={12} lg={grid}>
        <TextField
          autoComplete="given-name"
          name={CountryLabel}
          value={country}
          select={true}
          required={true}
          fullWidth
          id="country"
          label="Select Country"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            ValidateInput(e, /[^\n]+/);
            GetStateOptions(e.target.value);
          }}
        >
          {CountryOptions.length > 0 ? (
            CountryOptions.map((option) => (
              <MenuItem key={`${option.label}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          ) : (
            <></>
          )}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={12} lg={grid}>
        <TextField
          autoComplete="given-name"
          name={StateLabel}
          value={state}
          select={true}
          required={true}
          fullWidth
          id="state"
          label="Select state"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            ValidateInput(e, /[^\n]+/);
            GetCityOptions(e.target.value);
          }}
        >
          {StateOptions.length > 0 ? (
            StateOptions.map((option) => (
              <MenuItem key={`${option.label}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          ) : (
            <></>
          )}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={12} lg={grid}>
        <TextField
          autoComplete="given-name"
          name={CityLabel}
          value={city}
          select={true}
          required={true}
          fullWidth
          id="city"
          label="Select City"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => ValidateInput(e, /[^\n]+/)}
        >
          {CityOptions.length > 0 ? (
            CityOptions.map((option) => (
              <MenuItem key={`${option.label}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          ) : (
            <></>
          )}
        </TextField>
      </Grid>
    </>
  );
}
