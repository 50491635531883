import React, { useState, useEffect } from "react";
import SignUp from "../components/SignUp";
import { GetResponse } from "../Utils/Axios/GetResponse";
import { CRUDCommands, RDSLinks } from "../Utils/Axios/RequestParameters";
import { SignUpInputFields } from "../Utils/SignUpInputFields";
import swal from "sweetalert";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import SimpleBackDrops from "../components/SimpleBackDrops";
import { useSelector } from "react-redux";
export default function SignUpPage() {
  const AgentDetails = useSelector((state) => state.AgentDetails.value);
  console.log({ AgentDetails });
  const navigate = useNavigate();

  useEffect(() => {
    if (AgentDetails) {
      if (AgentDetails.isAllowToAddDownline === false) {
        swal("Sorry", "You are not Allow to Add new Agent").then(() => {
          navigate("/");
        });
      }
    }
  }, []);
  const [data, setdata] = useState({
    name: "",
    email: "",
    mobileNo: "",
    cmpName: "",
    add1: "",
    add2: "",
    add3: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    phone: "",
    alternateEmail: "",
    pan: "",
    pandocImg: "",
    dateOfBirth: "",
    title: "",
    lastName: "",
    aadharNo: "",
    gstcompanyName: "",
    gstno: "",
    gstphone: "",
    gstemail: "",
    gstaddress: "",
    gstpincode: "",
    gststate: "",
    gstcity: "",
    logo: "",
  });

  const [UpdatedDataField, setUpdatedDataField] = useState(SignUpInputFields);
  const [AllError, setAllError] = useState({
    name: false,
    email: false,
    mobileNo: false,
    cmpName: false,
    add1: false,
    add2: false,
    add3: false,
    city: false,
    state: false,
    country: false,
    pincode: false,
    phone: false,
    alternateEmail: false,
    pan: false,
    pandocImg: false,
    dateOfBirth: false,
    title: false,
    lastName: false,
    aadharNo: false,
    gstcompanyName: false,
    gstno: false,
    gstphone: false,
    gstemail: false,
    gstaddress: false,
    gstpincode: false,
    gststate: false,
    gstcity: false,
    logo: false,
  });

  // .........................,,,,,,,,,,,,,,,,,,,,,,,.............................................................................
  const [Render, setRender] = React.useState("render");
  const [Loader, setLoader] = React.useState(false);

  const Rerender = () => {
    setRender(() => {
      return Render === "render" ? "rerender" : "render";
    });
  };

  // .................................................................................................................................
 console.log({Signup:AgentDetails})
  const handleSubmit = async (event) => {
    try {
      setLoader(true);
      event.preventDefault();
      const AllErrorArray = Object.keys(AllError).filter((Error) => {
        return AllError[Error] === true;
      });
      if (AllErrorArray.length > 0) {
        console.log(AllErrorArray);
        swal("Incorrect Entry", " Please Enter All Fields Correctly", "error");
      } else {
        const requestData = {
          CommandId: CRUDCommands.RegRequest,
          verificationId: 0,
          name: data["name"],
          email: data["email"],
          countryCode: "IND",
          mobileNo: data["mobileNo"],
          verificationCode: "",
          isVerified: true,
          verifiedDateTime: "2022-11-11T06:42:22.428Z",
          verifiedIp: "1",
          createBy: 1,
          createDate: "2022-11-11T06:42:22.428Z",
          createIp: "1",
          distributorId: 0,
          updateBy: 1,
          updateDate: "2022-11-11T06:42:22.428Z",
          updateIp: "str",
          typeDetId: 1,
          referredByDistributorCode: "",
          tranPwd: "",
          pwd: "",
          cmpName: data["cmpName"],
          add1: data["add1"],
          add2: data["add2"],
          add3: data["add3"],
          city: data["city"],
          state: data["state"],
          country: data["country"],
          pincode: data["pincode"],
          phone: data["phone"],
          alternateEmail: data["alternateEmail"],
          officeSpace: "",
          businessType: "",
          isIataagent: true,
          yearInBusiness: "",
          pan: data["pan"],
          pandocImg: data["pandocImg"],
          taxExemptionRate: 0,
          taxExemptionAmt: 0,
          taxExemptionCertImg: "",
          padd1: data["add1"],
          padd2: data["add2"],
          padd3: data["add3"],
          pcity: data["city"],
          pstate: data["state"],
          pcountry: data["country"],
          ppincode: data["pincode"],
          pphone: data["phone"],
          eduQualification: "",
          occupation: "",
          userRemark: "",
          status: "",
          remark: "",
          dateOfBirth: moment(data["dateOfBirth"]).format("MM/DD/YYYY"),
          requestedDistributorId: AgentDetails ? AgentDetails.distributorId : 0,
          anniversaryDate: "2022-11-11T06:42:22.428Z",
          title: data["title"],
          lastName: data["lastName"],
          designation: "",
          aadharNo: data["aadharNo"],
          gstcompanyName: data["gstcompanyName"],
          gstno: data["gstno"],
          gstphone: data["gstphone"],
          gstemail: data["gstemail"],
          gstaddress: data["gstaddress"],
          gstpincode: data["gstpincode"],
          gststate: data["gststate"],
          gstcity: data["gstcity"],
          logo: data["logo"],
          requestNo: "",
          requestType: "s",
          ownerName: "",
          agencyName: "",
          requestTypeDet: "",
          requestedDistributorCode: "",
          requestDateTime: "2022-11-11T06:42:22.428Z",
          typeID: 0,
          fromRequestDate: "2022-11-11T06:42:22.428Z",
          toRequestDate: "2022-11-11T06:42:22.428Z",
        };
        console.log(requestData);
        const Response = await GetResponse(
          requestData,
          RDSLinks.DistributorMaster.DistributorRegistration,
          {}
        );
        console.log(Response);
        if (!Response.errorDesc) {
          swal(" Accepted Request", `${Response}`, "success").then(function () {
            navigate("/");
          });
        } else {
          swal(
            " Error Occured",
            `${Response.errorDesc.split(":")[1]}`,
            "error"
          );
        }
      }
      setLoader(false);
    } catch (error) {
      swal("Error", "Internal Server Error", "Error");
      console.log(error);
      setLoader(false);
    }
  };
  // const handleChange = (e) => {
  //   setdata({ ...data, [e.target.name]: e.target.value });
  // };

  const handleChange = (e, regex) => {
    setdata({ ...data, [e.target.name]: e.target.value });

    const IsValid = regex.test(e.target.value);
    console.log(IsValid);
    if (IsValid === true) {
      setAllError({ ...AllError, [e.target.name]: false });
    } else {
      setAllError({ ...AllError, [e.target.name]: true });
    }
  };
  // const handleChange = (event) => {
  //   SetData({ ...Data, [event.target.name]: event.target.value });
  // };

  useEffect(() => {
    console.log(data);

    return () => {
      "second";
    };
  }, [data]);

  return (
    <>
      <SimpleBackDrops Loader={Loader} />
      <SignUp
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        data={data}
        AllError={AllError}
        SignUpInputFields={UpdatedDataField}
        Render={Render}
        key={Render}
        name={AgentDetails ? "Add Agent" : "Sign Up"}
      />
    </>
  );
}
