import React from "react";
import Navbar from "../components/HomeNavbar";
import SignInPage from "./SignInPage";
import TypeWriterEffect from "react-typewriter-effect";
import { Box, Paper } from "@mui/material";
export default function MainHomePage() {
  return (
    <Box className="HomePage">
      <Navbar />
      <Box>
        <TypeWriterEffect
          textStyle={{
            fontFamily: "Red Hat Display",
            color: "white",
            backgroundColor: "#212a62",
            fontWeight: 500,
            paddingTop: "1vh",
            paddingBottom: "1vh",
            // paddingLeft: "2vw",
            // alignItems:"center",
            display: "flex",
            fontSize: "18px",
            justifyContent: "center",
            height: "40px",
            borderRadius: "10px",
            margin: "12px",
          }}
          startDelay={2000}
          cursorColor="white"
          multiText={[
            "Now Don't Get Trouble Just for a Courier",
            "Compare courier ",
            "Choose your Courier Partner ",
            "And Get your Courier Picked at Your Door Step",
            "Fall in love with our service 😍!",
          ]}
          multiTextDelay={1000}
          typeSpeed={30}
        />
      </Box>
      <Box
        className="SignInContainer"
        sx={{ height: { xs: "97vh", md: "77vh" } }}
      >
        <Paper elevation={10} sx={{ borderRadius: "10px" }}>
          <SignInPage />
          {/* <Box></Box> */}
        </Paper>
      </Box>
    </Box>
  );
}
