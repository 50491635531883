import { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Typography, Fab } from "@mui/material";
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid";

import { grey } from "@mui/material/colors";
import { GetResponse } from "../Utils/Axios/GetResponse";

import { useDispatch, useSelector } from "react-redux";

import {
  CourierServiceLinks,
  CRUDCommands,
  RDSLinks,
} from "../Utils/Axios/RequestParameters";
import { Colors } from "../Utils/Constants";
import swal from "sweetalert";
import SimpleBackDrops from "../components/SimpleBackDrops";
export default function BankDataGrid() {
  const Row = useSelector((state) => state.Row.value);
  const AdminDetails = useSelector((state) => state.AdminDetails.value);
  const userId = AdminDetails.userId;
  console.log({ Row });
  const [pageSize, setPageSize] = useState(25);
  const BoxHeight = pageSize < 10 ? pageSize * 100 : 1000;
  const [rowId, setRowId] = useState(null);
  const [openModal, setopenModal] = useState(false);
  const [Loader, setLoader] = useState(false);

  const handleModalOpen = () => {
    console.log("Opening Modal ");
    setopenModal(true);
  };
  const handleModalClose = () => {
    console.log("Closing Modal ");
    setopenModal(false);
  };
  const [ServiceAPI, setServiceAPI] = useState([]);

  const addData = {
    bankId: 0,
    bankType: "L",
    bankCode: "",
    bankName: "",
    bankBranch: "",
    acName:"",
    acno: "",
    add1: "",
    add2: "",
    add3: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    bankImageUrl: "",
    bankSlipFileName: "",
    bankUrl: "",
    bankACNo: "",
    commandId:CRUDCommands.ListAll,
    createBy: 0,
    createDate: "2022-11-16T07:00:21.360Z",
    createIp: "",
    updateBy: 0,
    updateDate: "2022-11-16T07:00:21.360Z",
    updateIp: "",
    ifsccode: "",
    isActive: true,
    orderNo: 0,
  };

  const getServices = async () => {
    setLoader(true);
    try {
      //   const Response = await axios.get(RDSLinks.GetBank);
      const Response = await GetResponse(addData, RDSLinks.GetBank, {});
      console.log(Response);
      if (Response.length > 0 && Array.isArray(Response)) {
        setServiceAPI(Response);
      } else {
        swal("error", "No Records Found", "error");
      }
    } catch (error) {
      console.log(error);
      swal("error", "No Records Found", "error");
    }
    setLoader(false);
  };

  useEffect(() => {
    getServices();
  }, [Row]);

  const columns = useMemo(
    () => [
      { field: "bankCode", headerName: "Bank Code", width: 200 },
      { field: "bankName", headerName: "Bank Name", width: 200 },
      { field: "bankBranch", headerName: "Bank Branch", width: 200 },
      { field: "acno", headerName: "Account Number", width: 200 },
      { field: "acName", headerName: "Account Holder Name", width: 200 },
      { field: "ifsccode", headerName: "IFSC Code", width: 150 },
      {field: "isActive",headerName: "Active ?",width: 200,type: "boolean"},
    ],
    [rowId, Row]
  );

  return (
    <>
      <SimpleBackDrops Loader={Loader} />

      <Box
        sx={{
          height: { xs: 649, md: 549 },
          width: "99%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 5,
          m: 1,
          mb: 0,
          // backgroundColor:Colors.PrimaryBackgroundColor,
          // alignItems:'center'
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            textAlign: "center",
            p: 1,
            bgcolor: Colors.PrimaryColor[500],
            color: "white", // Colors.SecondaryColor,
            fontWeight: "bolder",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          Bank Details
        </Typography>

        <DataGrid
          key={Row}
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          rows={ServiceAPI}
          getRowId={(row) => row.bankId}
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          getRowSpacing={(params) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          sx={{
            [`& .${gridClasses.row}`]: {
              bgcolor: (theme) =>
                theme.palette.mode === "light" ? grey[200] : grey[900],
            },
            mt: 1,
            mb: 1,
            ml: 0.5,
            mr: 0.5,
            borderRadius: 5,
          }}
          onCellEditCommit={(params) => setRowId(params.id)}
        />
      </Box>
    </>
  );
}
