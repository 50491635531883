import { useLocation } from "react-router-dom";
const GetPanelName = () => {
  var Panel = "";
  const location = useLocation();
  const URL = location.pathname;
  console.log({ URL });
  if (URL.includes("Admin")) {
    Panel = "Admin";
  } else if (URL.includes("SiteMGMT")) {
    Panel = "SiteMGMT";
  } else {
    Panel = "Agent";
  }
  console.log({ PanelName: Panel });
  return Panel;
};
export { GetPanelName };
